import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const whats_news = [
  {
    id: 1,
    tittle: "Accept Afterpay In-Person and Online",
    description:
      "Make sure you never miss out on a big sale by using Afterpay—a buy now, pay later payment option now available In-Person and Online. Select from the world's best banks for integrated payments. Mobile iPad and mPOS payments are simple...",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%201.webp",
    href: "/pagos/pagos-en-persona",
  },
  {
    id: 2,
    tittle: "Invu KDS",
    description:
      "Introducing the first-party solution for managing your kitchen. View all your orders on one screen and stay on track with digital tickets, notifications and timers. Get detailed kitchen reports on your station performance and remove bottlenecks to increase efficiency...",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%202.webp",
    href: "/productos/kds",
  },
  {
    id: 3,
    tittle: "Invu Team Management",
    description:
      "Now enjoy free time clock and time card reporting for your team. Upgrade to Team Plus for advanced features like unlimited roles and robust sales reporting. Totally dominate your inventory using innovative tools to increase the efficiency and visibility of your business...",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%203.webp",
    href: "/mas-herramientas/gestion-de-inventarios",
  },
  {
    id: 4,
    tittle: "Order integrations",
    description:
      "Make sure you never miss out on a big sale by using Afterpay—a buy now, pay later payment option now available In-Person and Online. With Afterpay, you can let customers pay for purchases in 4 interest-free installments over 6 weeks, while you get paid right away...",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%204.webp",
    href: "/mas-herramientas/integraciones",
  },
  {
    id: 5,
    tittle: "Payments platform",
    description:
      "Make sure you never miss out on a big sale by using Afterpay—a buy now, pay later payment option now available In-Person and Online. With Afterpay, you can let customers pay for purchases in 4 interest-free installments over 6 weeks, while you get paid right away...",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%205.avif",
    href: "/pagos/general",
  },
  {
    id: 6,
    tittle: "Invu2GO E-commerce",
    description:
      "Grow your business online with a professional eCommerce website. Build your store fast, launch today, and you’re in business. Expand your business to the whole world, sell to anyone, anywhere, anytime. Our platform adds value to companies from various industries..",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%206.webp",
    href: "/productos/comercio-electronico",
  },
];

const other_news = [
  {
    id: 1,
    name: "Marketing",
    description:
      "Point of sale marketing is a type of in-store marketing campaign that takes place in and around the point of sale. This would usually be a till or checkout within a retail environment like a shop or restaurant.  If you’re looking to find some new customers, gift cards are a fantastic marketing vehicle. Your customers can buy gift cards for their friends, extending the reach of your business.",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%207.jpeg",
    href: "/productos/marketing",
  },
  {
    id: 2,
    name: "Professional services",
    description:
      " Invu has built a suite of integrated tools to help save you time, so you can focus on growing revenue and delivering exceptional service to your clients.  Whether you need a POS device or want to accept all your payments virtually, Invu has easy-to-use and flexible professional service credit card processing tools that let you email an invoice, set up recurring payments, or accept payments online.",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%208.webp",
    href: "/servicios-profesionales",
  },
  {
    id: 3,
    name: "Manage Risk",
    description:
      "Gain insight into payment fraud patterns and set custom rules and alerts to detect and manage risk. Invu is the best solution for your security and that of your company.   For businesses looking for fraud prevention solutions, Invu now offers Risk Manager, a customizable fraud prevention and risk management system.",
    image:
      "https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%209.avif",
    href: "/payments/risk-manage",
  },
];

const Whats_news = () => {
  useDocumentTitle("Qué hay de  nuevo | Invu POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <section class="w-full px-4 py-24 mx-auto max-w-7xl md:w-4/5">
        <div class="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          <div class="col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-3">
            <h2 class="mb-2 text-5xl font-extrabold leading-tight text-gray-900">
              Whats news
            </h2>
            <p class="mb-10 max-w-2xl text-lg text-gray-500">
              Finding the right point of sale system for your company is all
              about knowing your business, knowing the features you need, and
              knowing the right questions to ask.
            </p>
            <a>
              <img
                src="https://img.invupos.com/webinvu/Whats%20News%20module/whats%20news%2010.jpeg"
                class="object-cover w-full h-72 mb-5 bg-center rounded"
                alt=""
                loading="lazy"
              />
            </a>
          </div>
          {whats_news.map((news) => (
            <Link
              to={`${prefix}` + news.href}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="border rounded-lg">
                <Link
                  to={`${prefix}` + news.href}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <img
                    src={news.image}
                    class="object-cover w-full h-56 mb-5 bg-center rounded"
                    alt=""
                    loading="lazy"
                  />
                </Link>
                <div className="p-3">
                  <p class="mb-2 text-xs font-semibold tracking-wider text-gray-400 uppercase">
                    news
                  </p>
                  <h2 class="mb-2 text-xl font-bold leading-snug text-gray-900">
                    <Link
                      to={`${prefix}` + news.href}
                      onClick={() => window.scrollTo(0, 0)}
                      class="text-gray-900 hover:text-purple-700"
                    >
                      {news.tittle}
                    </Link>
                  </h2>
                  <p class="mb-4 text-sm font-normal text-gray-600">
                    {news.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <section class="w-full px-4 py-12 mx-auto max-w-7xl md:w-4/5">
        <h2 class="pb-8 mb-12 text-2xl font-extrabold leading-tight text-gray-900 border-b border-gray-200 md:text-4xl">
          Other News
        </h2>
        <div class="w-full xl:w-4/6">
          <div class="flex flex-col space-y-10">
            {other_news.map((nextnews) => (
              <Link
                to={`${prefix}` + nextnews.href}
                onClick={() => window.scrollTo(0, 0)}
              >
                <div class="grid grid-cols-1 gap-6 md:grid-cols-4 p-4 bg-gray-50 rounded-lg">
                  <img
                    src={nextnews.image}
                    class="object-cover w-full h-56 rounded-lg col-span-1 bg-center"
                    alt=""
                    loading="lazy"
                  />
                  <div class="col-span-1 md:col-span-3">
                    <h2 class="mb-2 text-xl font-extrabold leading-snug text-gray-800">
                      <p class="mb-2 text-xs font-semibold tracking-wider text-gray-400 uppercase">
                        news
                      </p>
                      <Link
                        to={`${prefix}` + nextnews.href}
                        onClick={() => window.scrollTo(0, 0)}
                        class="text-gray-900 hover:text-purple-700"
                      >
                        {nextnews.name}
                      </Link>
                    </h2>
                    <p class="mb-3 text-sm font-normal text-gray-500">
                      {nextnews.description}
                    </p>
                    <Link
                      class="inline-block px-8 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 rounded hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring"
                      to={`${prefix}` + nextnews.href}
                    >
                      Learn more
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Whats_news;
