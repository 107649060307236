import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_electronic_signature = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("Firma electrónica en Venezuela | Invu POS");

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content=" Conoce como obtener una firma electrónica en Venezuela, las ventajas de usar este tipo de firmas y mas preguntas frecuentes, entra y conoce."
        />
        <meta name="keywords" content="Firma electronica en Venezuela" />
        <meta
          name="keywords"
          content="Como obtener firma electronica, Requisitos de firma electronica"
        />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%203/Image%20details%20blog%203.png"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 18, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Firma electrónica en Venezuela; todo lo que debes saber
            </h1>

            <p className="text-gray-500 max-w-xl">
              La firma electrónica en Venezuela es una herramienta que permite
              la comunicación y la ejecución de negocios entre las personas.
              Igualmente, en las estructuras de comercio, lo que les facilita a
              las personas elaborar contratos por medio de canales electrónicos.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            Además, la pandemia por covid-19, dio un impulso a la nueva dinámica
            laboral, debido a restricciones sociales, lo cual, significa una
            ventaja para este tipo de operaciones comerciales. Por lo tanto,
            debes conocer que es una firma electrónica en Venezuela y sus
            principales características.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />

          <h2 className="font-semibold text-xl ">
            ¿Qué es una firma electrónica?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-8">
            La firma electrónica
            <span className="font-semibold">
              es un documento celebrado entre las partes por medio de un canal
              digital y herramientas tecnológicas,
            </span>
            y sirve para dar fe de los participantes del mismo. Dicha firma
            tiene como objetivo asociar lo propuesto en el contrato y sus
            términos, con las personas protagonistas, en otras palabras,
            atribuye autoría a quienes lo elaboran. Ahora bien, el régimen legal
            que controla el tema de las firmas electrónicas está contenido en el
            decreto de Fuerza de Ley sobre mensajes de datos y Firmas
            electrónicas. Dictaminada en 2000, la cual establece el valor,
            desarrollo y eficacia de este tipo de acciones. El empleo de firmas
            electrónicas le da un valor similar al que tienen las firmas
            autógrafas, permitiendo que los contratos realizados bajo esta
            modalidad sean seguros.
          </p>

          <h2 className="font-semibold text-xl ">
            Cómo obtener una firma electrónica en Venezuela
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-8">
            Para que una firma electrónica en Venezuela pueda tener validez y
            eficacia en Venezuela,
            <span className="font-semibold">
              es importante que esté debidamente certificada,
            </span>
            o que permite garantizar los datos y autenticidad de la misma. De
            igual modo, evita que sean falsificadas y aseguran de manera
            razonable la protección de la información. El certificado de firma
            electrónica es un documento emitido por un Proveedor de Certificados
            Electrónicos, y tiene que estar acreditado por la Superintendencia
            de Servicios de Certificados Electrónicos. Esta institución es la
            encargada de supervisar, acreditar y controlar a los Proveedores de
            Servicios de Certificación. En otras palabras, las empresas o
            entidades públicas o privadas que llevan a cabo la gestión de la
            certificación de firmas electrónicas en Venezuela.
          </p>

          <h2 className="font-semibold text-xl ">
            Contratos con firma electrónica en Venezuela
          </h2>

          <p className="mt-2 text-gray-700 leading-7 mb-8">
            La norma reguladora del Decreto Ley 1204, del 13 de diciembre del
            2000,{" "}
            <span className="font-semibold">
              reconoce que la validez de la firma electrónica cumpla con los
              requisitos especificados.{" "}
            </span>
            Además, al contrario de la legislación de otros países, en este caso
            es contemplando la falta de cumplimiento de dichos requisitos supone
            la no validez de la firma electrónica.
          </p>

          <p className="mt-1 text-gray-700 leading-7 mb-8 font-semibold">
            Ahora bien, los requisitos que debes cumplir son los siguientes:
          </p>

          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Asegurarte de que la información utilizada para la generación del
              contrato con firma electrónica pueda ser usada una sola vez, y
              garantizar, razonablemente, la confidencialidad del documento.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Brindar seguridad suficiente para que esta no pueda ser
              falsificada con la tecnología existente en cada momento.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              No manipular la integridad del Mensaje de Datos.
            </li>
          </ul>

          <p className="mt-2 text-gray-700 leading-7 mb-8">
            De esta manera, es contemplado el equivalente a la firma electrónica
            avanzada, la cual es llamada firma electrónica, pero en Venezuela no
            existe un similar a la opción simple.
          </p>

          <h2 className="font-semibold text-xl ">Tipos de firma electrónica</h2>

          <p className="mt-1 text-gray-700 leading-7 mb-8">
            A continuación, te muestro un resumen de los tipos de firma
            electrónica que puedes encontrar:
          </p>

          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">Biométrica:</span> es la
              firma electrónica manuscrita y capturada por medio de dispositivos
              móviles con la capacidad de recopilar datos biométricos.{" "}
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Con certificados digitales:
              </span>{" "}
              Es una firma que cuenta con un certificado digital emitido por una
              institución que valida la misma y la documentación del firmante.{" "}
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Código de solamente un uso (OTP Email y OTP SMS):
              </span>{" "}
              firma asociada al envío de un código OTP a través de SMS, al
              número de teléfono del firmante.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">En la nube:</span> es
              realizada por medio de un certificado digital alojado en un
              servidor externo (HSM) con autenticación robusta.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">Desatendida:</span> es una
              firma con certificado electrónico que no requiere intervención de
              la persona.
            </li>
          </ul>

          <h2 className="mt-8 font-semibold text-xl ">
            Ventajas de usar una firma electrónica
          </h2>

          <p className="mt-2 text-gray-700 leading-7 mb-8">
            Sin lugar a dudas, los contratos de firmas electrónicas{" "}
            <span className="font-semibold">
              son una gran ventaja al tener la misma influencia y seguridad
              jurídica{" "}
            </span>
            que los que son suscritos por medio de firmas autógrafas. Debido a
            las circunstancias que actualmente atraviesa el país, es considerado
            como un elemento potenciador de la tramitación de certificados con
            firma electrónica. <br></br> Este{" "}
            <span className="font-semibold">
              sirve para llevar a cabo operaciones y negociaciones comerciales
            </span>
            de manera segura entre ambas partes. Igualmente, te permite elaborar
            contratos sin tener las limitaciones de la distancia entre los
            participantes, lo que supone un beneficio importante para las
            empresas.
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_electronic_signature;
