import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_cash_flow = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "Consejos para diseñar la página web de un restaurante | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Está claro, como no podía ser de otro modo, que los conocimientos del propietario de un restaurante siempre van a estar más relacionados con el mundo culinario que con el del diseño, y la programación, de páginas web. "
        />
        <meta name="keywords" content="Paginas web para restaurantes" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2012/Cover%20blog%2012%20.png"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 07, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Qué es el cash flow y cómo mejorarlo
            </h1>

            <p className="text-gray-500 max-w-xl">
              Ya sea el dueño de un negocio o el alto directivo de una empresa,
              cualquier responsable empresarial debería ser capaz de poder leer,
              y analizar, un balance financiero. Además, estas personas deberían
              de estar preparadas para actuar en consecuencia. Y es aquí donde
              cobra especial importancia el término económico cash flow, un
              concepto que podría traducirse como flujo de caja.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <h2 className="font-semibold text-xl ">¿Qué es el cash flow?</h2>
          <p className="text-gray-700 mt-2  leading-7">
            No hay más que recurrir a la traducción del inglés para saber por
            dónde van los tiros. Y es que el cash flow no es otra cosa que el
            neto de tesorería de una empresa durante un periodo de tiempo
            determinado. Esto es, los ingresos y los gastos. También llamado
            flujo de efectivo o flujo de tesorería, el cash flow revela cuál es
            el estado de salud financiera de una empresa. Permite saber si la
            firma dispone de la liquidez suficiente para afrontar pagos a
            proveedores y acreedores y nóminas de la plantilla.
          </p>
          <p className="text-gray-700 mt-2 leading-7 mb-8">
            El caso es que puede haber empresas que parezcan tener una excelente
            salud financiera pero que, en realidad, carezcan de liquidez. Esto
            es debido a que su departamento de contabilidad analiza los ingresos
            ordinarios sin tener en cuenta los gastos. Es en este escenario
            donde conocer el cash flow permite identificar cuál es el estado
            real de las cuentas de la empresa.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <h3 className="font-semibold text-xl ">Elaborar el flujo de caja</h3>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            A la hora de analizar el cash flow, los responsables pueden hacer
            una tabla sobre la que plasmar tres indicadores que permiten
            interpretar y gestionar el cash flow
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Flujo de tesorería de la actividad <br></br>
            </li>
            <p className="mt-1 text-gray-700 leading-7 px-6 ">
              Son los excedentes de tesorería que genera la propia actividad de
              la empresa. Es el mejor reflejo posible del día a día.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-2 ">
              Flujo de tesorería de la inversión <br></br>
            </li>
            <p className="mt-1 text-gray-700 leading-7 px-6 ">
              Incluye el gasto resultante de la compra de activos fijos.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-2 ">
              Flujo de tesorería de financiación <br></br>
            </li>
            <p className="mt-1 text-gray-700 leading-7 px-6 ">
              Informa tanto de los gastos como de los ingresos asociados a la
              financiación de la compañía (pago de dividendos, préstamos…)
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-8">Tipos de cash flow</h2>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Al igual que sucede con otros señalizadores económicos, también
            existen diferentes tipos de cash flow, en este caso:
          </p>
          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Free Cash Flow <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Es el flujo de fondos generado por una compañía, con independencia
              de cómo esté financiada esta compañía. En tal sentido, representa
              el flujo de efectivo de la firma como si ésta se financiara
              enteramente con capital propio, aislando los efectos impositivos
              del financiamiento. Este tipo de cash flow debe ser definido con
              una base después de impuestos y antes de deducir los gastos
              financieros como intereses.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Capital Cash Flow <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Se puede definir este tipo como la suma de los cash flow que
              reciben los inversores, Esto es: dividendos para los accionistas,
              cambios en el nivel de deuda e intereses para los obligacionistas.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Equity Cash Flow <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Esta categoría de cash flow representa el flujo de efectivo
              residual de una empresa, una vez que se han abonado intereses e
              impuestos. Se puede obtener restando los intereses y sumando o
              restando –según corresponda- los cambios en el endeudamiento al
              Capital Cash Flow.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Cash flow positivo <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Señala que en la caja de la empresa entra más dinero del que sale.
              Entonces, se dispone de un excedente de tesorería que permite
              afrontar deudas, repartir dividendos y afrontar inversiones.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Cash flow negativo <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Aquí sale más dinero del que entra, por lo que la empresa se
              encuentra en una situación de déficit. Para solucionar esta
              coyuntura negativa, la compañía deberá ser capaz de, además de
              revisar los gastos, generar nuevos ingresos.
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-8">
            ¿Para qué sirve calcular el cash flow?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Hay tres razones fundamentales que hacen necesario el cálculo del
            cash flow:
          </p>
          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Comprobar la viabilidad de la empresa a largo plazo <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Analizar el cash flow permite descubrir el verdadero estado
              financiero de una empresa, lo que, también, da la posibilidad de
              prever futuros problemas o necesidades. Los resultados obtenidos,
              tras el análisis, permiten adoptar las medidas adecuadas para
              evitar -o minimizar- los efectos de estas futuras dificultades. La
              consecuencia será una mayor estabilidad a medio y largo plazo.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Optimización de gastos <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Al realizar un seguimiento del cash flow pueden aparecer gastos
              innecesarios, superfluos o incluso duplicados que suponen una
              importante carga económica para la empresa. Si se identifican,
              podrán ser suprimidos para acercarse a un cash flow positivo. Esto
              también permite crear una pequeña reserva de dinero para afrontar
              gastos inesperados o épocas de dificultades económicas.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Mejora de las relaciones con bancos e inversores <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Tanto si se contacta con bancos o con inversores privados, en la
              búsqueda de financiación, la presentación de informes precisos
              hará que la confianza sobre una empresa aumente.
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-8">
            Anticipar el flujo de caja
          </h2>
          <p className="mt-2 text-gray-700 leading-7 ">
            Más allá del desarrollo de análisis de situación, anticipar el flujo
            de caja futuro permitirá adelantarse a futuras situaciones, ya sean
            estas positivas o negativas. El primer paso para hacer esta
            previsión de flujo de caja consistirá en identificar cobros y pagos.
            Servirá una simple hoja Excel, colocando a un lado los pagos y al
            otro los cobros. La previsión se puede hacer siguiendo diferentes
            periodos de tiempo, ya sean mensuales, trimestrales o anuales. Para
            ello es necesario tener en cuenta las fechas en las que el dinero
            sale de la caja, no la fecha que aparece en las facturas.
          </p>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Cuando se tengan clasificados gastos e ingresos se podrá establecer
            una previsión de flujo de caja a partir de los resultados que se
            obtengan de esta clasificación. Es posible hacer una anticipación de
            gastos recurrentes, como también hacerse una idea de los ingresos
            que se pueden tener en el futuro según diversos escenarios.
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_cash_flow;
