import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import CTA_home from '../Home/CTA_home';
import Latest_blog from '../Home/Latest_blog';
import useLocalePrefix from "../utils/useLocalePrefix";

const Resources_gallery = () => {
  const prefix = useLocalePrefix();

  return (
    <>

    <section className="bg-[#000000]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
        
        <div className="grid gap-16 lg:grid-cols-2">
          <div className="lg:pr-10 content-center">
            <h1 class="font-bold xl:text-[67px] lg:text-5xl md:text-5xl text-4xl text-white">
            {t("presstitlesection1")}
            </h1>
            <p class="mt-5 text-gray-400 text-lg leading-loose max-w-2xl font-light">
           {t("pressdescriptionsection1")}
            </p>

            <p className='mt-10 text-gray-400 text-lg leading-loose italic font-semibold'>{t("pressdescriptionspan1")}</p>
          </div>
          <div>
            <img
              className="object-cover w-full h-96 rounded-2xl shadow-lg sm:h-[400px]"
              src="https://images.pexels.com/photos/23496636/pexels-photo-23496636/free-photo-of-group-sitting-around-laptop.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
    <h1 class="block text-4xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight mb-8">{t("saladeprensatexttitle")}</h1>
    <div className="px-0 mb-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-0">
        <div className="p-8 rounded shadow-xl sm:p-16">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2">
              <h3 className="mb-4 text-4xl font-semibold text-black">{t("saladeprensacardlogotitle")}</h3>
              <p className="mb-4 text-base text-gray-700">
                {t("saladeprensacardlogodescription")}
              </p>
              <Link 
                onClick={() => window.scrollTo(0, 0)}
                to={"/gallery-resources/logotypes-invu"}
                className="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                {t("saladeprensabuttondownload")}
              </Link>
            </div>
            <div className="mt-6 lg:mt-0 lg:w-1/2 lg:pl-5 flex items-center justify-center">
              <img src="https://img.invupos.com/webinvu/logotypes%20resources/Invu%20POS-logo.png" className='h-full w-full rounded-t-lg object-cover md:h-auto md:w-80'/>
            </div>
          </div>
        </div>
      </div>

    
      <div className="grid gap-8 row-gap-5 md:grid-cols-2">
        <div className="relative overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div
           class="flex flex-col rounded-lg bg-white text-surface md:max-w-xl lg:flex-row">
          <img
            class="h-full w-full rounded-t-lg object-cover md:h-auto md:w-64"
            src="https://img.invupos.com/webinvu/press/MXHEROIMG1%281%29.png"
            alt="" />
          <div class="flex flex-col justify-start p-4">
            <h5 class="mb-2 text-xl font-semibold text-black">{t("saladeprensacard1title")}</h5>
            <p class="mb-4 text-base text-gray-700">
              {t("saladeprensacard1description")}
            </p>
            <div>
            <Link 
            onClick={() => window.scrollTo(0, 0)}
            to={"/gallery-resources/hardware"}
            class="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
              {t("saladeprensabuttondownload")}
            </Link >
            </div>
          </div>
        </div>
        </div>
        

        <div className="relative overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div
           class="flex flex-col rounded-lg bg-white text-surface md:max-w-xl lg:flex-row">
          <img
            class="h-full w-full rounded-t-lg object-cover md:h-auto md:w-80"
            src="https://img.invupos.com/webinvu/press/30%281%29.png"
            alt="" />
          <div class="flex flex-col justify-start p-4">
          <h5 class="mb-2 text-xl font-semibold text-black">{t("saladeprensacard2title")}</h5>
            <p class="mb-4 text-base text-gray-700">
              {t("saladeprensacard2description")}
            </p>
            <div>
            <Link 
            onClick={() => window.scrollTo(0, 0)}
            to={"/gallery-resources/vectores-del-sistema"}
            class="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                {t("saladeprensabuttondownload")}
            </Link >
            </div>
          </div>
        </div>
        </div>

        <div className="relative overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div
           class="flex flex-col rounded-lg bg-white text-surface md:max-w-xl lg:flex-row">
          <img
            class="h-full w-full rounded-t-lg object-cover md:h-auto md:w-64 p-4 rounded-xl"
            src="https://img.invupos.com/webinvu/press/avatar%283%29.png"
            alt="" />
          <div class="flex flex-col justify-start p-4">
            <h5 class="mb-2 text-xl font-semibold text-black">{t("saladeprensacard3title")}</h5>
            <p class="mb-4 text-base text-gray-700">
              {t("saladeprensacard3description")}
            </p>
            <div>
            <Link 
            onClick={() => window.scrollTo(0, 0)}
            to={"/gallery-resources/3D"}
            class="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                {t("saladeprensabuttondownload")}
            </Link >
            </div>
          </div>
        </div>
        </div>

        <div className="relative overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div
           class="flex flex-col rounded-lg bg-white text-surface md:max-w-xl lg:flex-row">
          <img
            class="h-full w-full rounded-t-lg object-cover md:h-auto md:w-80"
            src="https://img.invupos.com/webinvu/KDS%20module/kds.png"
            alt="" />
          <div class="flex flex-col justify-start p-4">
          <h5 class="mb-2 text-xl font-semibold text-black">{t("saladeprensacard4title")}</h5>
            <p class="mb-4 text-base text-gray-700">
              {t("saladeprensacard4description")}
            </p>
            <div>
            <Link 
            onClick={() => window.scrollTo(0, 0)}
            to={"/gallery-resources/KDS"}
            class="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                {t("saladeprensabuttondownload")}
            </Link >
            </div>
          </div>
        </div>
        </div>


      </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-6 lg:px-8 lg:py-20">
      <div className="p-8 rounded-2xl sm:p-20 bg-[#121212] md:px-12 sm:px-12">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-2/5 content-center">
            <h2 className="font-sans text-3xl font-bold tracking-tight text-white sm:text-[50px] sm:leading-none mb-4">
              {t("dealerctatitlesection3")}
            </h2>
          </div>
          <div className="lg:w-11/12 content-center">

          <div class="grid xl:grid-cols-5 lg:grid-cols-5 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-5 md:gap-5">
            {/* Card 1 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofuse2k24.png"/>
              </div>
            </div>
            {/* Card 2 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofvalue2k24.png"/>
              </div>
            </div>

             {/* Card 3 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/csupport2k24.png"/>
              </div>
            </div>

             {/* Card 4 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/Getapp.png"/>
              </div>
            </div>

            {/* Card 5 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/nvidia.png"/>
              </div>
            </div>

             {/* Card 6 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofuse2k23.png"/>
              </div>
            </div>
            
              {/* Card 7 */}
              <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofvalue2k23.png"/>
              </div>
            </div>

                {/* Card 8 */}
                <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/csupport2k23.png"/>
              </div>
            </div>

                {/* Card 9 */}
                <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/mrecommended2k23.png"/>
              </div>
            </div>
            
          </div>

          </div>
        </div>
      </div>
    </div>

    <Latest_blog/>

    <div className='-mt-5 mb-16'>
    <CTA_home/>
    </div>
    </>
  );
};

export default Resources_gallery;
