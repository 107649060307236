// TermsAndConditionsModal.js
import React from 'react';

const TermsAndConditionsReferralsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
    className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex items-center justify-center"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="relative bg-white rounded-lg shadow-lg max-w-3xl w-full h-auto max-h-[90vh] overflow-y-auto p-6">
      <header className="mb-6 border-b border-gray-200 pb-4">
        <h2
          id="modal-title"
          className="text-2xl font-semibold text-gray-900"
        >
          Plan de Referidos: Gana Comisiones con INVU POS
        </h2>
      </header>
      <div className="space-y-6">
        <div>
          <p className='text-gray-800 mb-4'>Con INVU POS, puedes ganar recompensas en efectivo al recomendar nuevos negocios que se suscriban a INVU POS. A continuación, se detallan los pasos y requisitos para participar en el programa de referidos de INVU POS.</p>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Requisitos del Programa
          </h3>
          <ul className="list-disc list-inside space-y-2">
            <li><strong>Ubicación:</strong> Los remitentes y referidos deben estar establecidos en Panamá.</li>
            <li><strong>Elegibilidad:</strong> Los colaboradores de INVU POS sí podrán participar en este programa de referidos.</li>
            <li><strong>Nuevos Usuarios:</strong> Los referidos deben ser nuevos en INVU POS. Los referidos que ya tienen una cuenta existente o que ya se comunicaron con el equipo de ventas no califican para participar en el programa de referidos.</li>
          </ul>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Procedimiento para Ingresar al Plan de Referidos de INVU POS
          </h3>
          <ol className="list-decimal list-inside space-y-4">
            <li>
              <strong>Paso 1: Registro en el programa de referidos</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Acceso al Sitio Web:</strong> Ingrese al sitio web de INVU POS a través del siguiente enlace: <a className="text-blue-500">INVU POS Referidos</a>.</li>
                <li><strong>Sección de Referidos:</strong> Una vez en la sección de referidos, lea y acepte las políticas del plan.</li>
                <li><strong>Formulario de Registro:</strong> Complete el formulario con la información solicitada por INVU POS.</li>
              </ul>
            </li>
            <li>
              <strong>Paso 2: Verificación y contacto</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Revisión del Formulario:</strong> El equipo de ventas de INVU POS verificará toda la información proporcionada en el formulario.</li>
                <li><strong>Contacto con el Referido:</strong> Una vez verificada la información, el equipo de ventas se comunicará con la persona que está refiriendo el nuevo establecimiento para iniciar las conversaciones de negociación.</li>
              </ul>
            </li>
            <li>
              <strong>Paso 3: Suscripción y pago de comisión</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Instalación y Funcionamiento:</strong> Una vez que el nuevo local esté suscrito, debidamente instalado y en funcionamiento con INVU POS, el remitente será notificado.</li>
                <li><strong>Notificación de Pago:</strong> El remitente recibirá una llamada o un correo electrónico del equipo de ventas de INVU POS para coordinar el pago de $250 por el referido.</li>
              </ul>
            </li>
            <li>
              <strong>Paso 4: Negocios no aceptados y renuncias</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Notificación de No Aceptación:</strong> Si un negocio no cumple con los requisitos del proceso de referidos, el remitente será notificado y se le explicará la situación.</li>
                <li><strong>Renuncia a la Comisión:</strong> Si durante el proceso el negocio decide no ingresar al sistema INVU POS, no se otorgará la comisión al remitente.</li>
                <li><strong>Reaplicaciones No Aceptadas:</strong> Si un negocio que inicialmente aplicó al plan de referidos y no fue aceptado decide ingresar nuevamente, no será elegible para el plan de referidos.</li>
              </ul>
            </li>
          </ol>
        </div>

        <div>
          <p className='text-gray-800 mb-4 '>Al seguir estos pasos, los participantes pueden asegurarse de que sus referidos sean procesados correctamente y recibir sus comisiones correspondientes. Además, se garantiza la transparencia del proceso en caso de que un negocio no cumpla con los requisitos o decida no continuar con INVU POS.</p>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Plazos y pagos
          </h3>
          <p className="mb-4">
            Los plazos para recibir comisiones varían según el tiempo transcurrido entre que el referido se comunica con el equipo de ventas, crea una cuenta y comienza a procesar los pagos.
          </p>
          <p>
            No hay garantía de que se acepten todas las recomendaciones para obtener recompensas. Las recompensas por comisiones tienen un período de espera de 60 días entre el momento en que se realiza la recomendación que cumple con los requisitos y el momento en que se verá el pago en efectivo en el saldo de la cuenta.
          </p>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Remuneración del programa de referidos de INVU POS
          </h3>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Referidos por colaboradores de INVU POS:</strong> $250 USD. Elegibilidad: Todos los colaboradores de INVU POS son elegibles para participar en el programa de referidos. Condiciones: Los colaboradores pueden referir a INVU POS tantas veces lo deseen. Comisión: Por cada negocio referido que cumpla con los requisitos, el colaborador recibirá una comisión de $250 USD.</li>
            <li><strong>Referidos fuera de INVU POS:</strong> $250 USD. Elegibilidad: Cualquier persona que no sea colaboradora de INVU POS puede participar en el programa de referidos sin restricciones. Comisión: Por cada negocio referido que cumpla con los requisitos, el participante recibirá una comisión de $250 USD.</li>
            <li><strong>Sucursal adicional:</strong> $100 USD. Condiciones: Si un referido es un negocio con múltiples sucursales, se manejará de la siguiente manera: Primera sucursal: La primera sucursal referida recibirá una comisión de $250 USD. Sucursales adicionales: Cada sucursal adicional del mismo negocio recibirá una comisión de $100 USD.</li>
          </ul>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Términos y condiciones del programa de referidos de INVU POS
          </h3>
          <ol className="list-decimal list-inside space-y-4">
            <li><strong>Definiciones:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Remitente:</strong> Usuario que refiere a un nuevo negocio al programa.</li>
                <li><strong>Referido:</strong> Nuevo negocio que recibe una recomendación del remitente para unirse a INVU POS.</li>
                <li><strong>Programa:</strong> Programa de referidos de INVU POS diseñado para recompensar a los remitentes por atraer nuevos negocios.</li>
              </ul>
            </li>
            <li><strong>Elegibilidad:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Los remitentes y referidos deben estar ubicados en Panamá.</li>
                <li>Los referidos deben ser nuevos en INVU POS. No califican aquellos que ya tienen una cuenta existente o que ya se comunicaron previamente con el equipo de ventas de INVU POS.</li>
              </ul>
            </li>
            <li><strong>Método de recomendación:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Los remitentes deben utilizar el formulario de referidos que será proporcionado por INVU POS para referir a nuevos negocios.</li>
                <li>Las recomendaciones hechas sin utilizar este formulario NO serán elegibles para comisiones.</li>
              </ul>
            </li>
            <li><strong>Proceso de referencia:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><strong>Formulario de Referido:</strong> Los referidos deben completar el formulario de referidos usando el enlace proporcionado, <a className="text-blue-500">https://invupos.com/recursos/referidos</a>. Una vez haya llenado este formulario, un asesor del departamento de ventas se pondrá en contacto con el referido.</li>
                <li><strong>Evaluación del Equipo de Ventas:</strong> El equipo de ventas de INVU POS evaluará si el referido es adecuado para el sistema. Todos los referidos que NO cumplan con los requisitos de evaluación por parte del equipo de ventas NO serán elegibles para este programa de referidos.</li>
              </ul>
            </li>
            <li><strong>Comisiones y pagos:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Las comisiones se otorgan según los siguientes criterios: Referidos que llegan por parte de los trabajadores de INVU POS: $150 USD. Referidos que llegan fuera de los trabajadores de INVU POS: $250 USD. Sucursal Adicional: $100 USD.</li>
                <li>Las recompensas por comisiones tienen un período de espera de 60 días entre la realización de la recomendación que cumple con los requisitos y el pago.</li>
                <li>No hay garantía de que todas las recomendaciones resulten en recompensas.</li>
              </ul>
            </li>
            <li><strong>Restricciones:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>El programa no aplica para restaurantes que ya tienen el sistema de INVU POS.</li>
                <li>INVU POS se reserva el derecho de modificar, suspender o terminar el programa de referidos en cualquier momento sin previo aviso.</li>
                <li>INVU POS se reserva el derecho de descalificar a cualquier remitente o referido que no cumpla con los términos y condiciones del programa.</li>
              </ul>
            </li>
            <li><strong>Responsabilidad:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>INVU POS no se hace responsable por ningún problema técnico, fallas de red, interrupciones del servicio o cualquier otro problema fuera de su control que pueda afectar la participación en el programa de referidos.</li>
                <li>Al participar en el programa, los remitentes y referidos aceptan liberar y eximir de responsabilidad a INVU POS de cualquier reclamo, daño o responsabilidad derivada de su participación en el programa.</li>
              </ul>
            </li>
            <li><strong>Privacidad:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>La información recopilada de los remitentes y referidos se utilizará únicamente para la administración del programa de referidos y se tratará de acuerdo con la política de privacidad de INVU POS.</li>
              </ul>
            </li>
            <li><strong>Negocios no captados:</strong>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Si un negocio no cumple con los requisitos del proceso de referidos, el remitente será notificado y se le explicará la situación.</li>
                <li>Si durante el proceso el negocio decide no ingresar al sistema INVU POS, no se otorgará la comisión al remitente.</li>
                <li>Si un negocio que inicialmente aplicó al plan de referidos y no fue aceptado decide ingresar nuevamente, no será elegible para el plan de referidos. INVU POS se reserva el derecho de admitir un negocio.</li>
              </ul>
            </li>
            <li><strong>Contacto:</strong> Para cualquier pregunta o aclaración sobre el programa de referidos, los participantes pueden contactar a INVU POS a través del soporte al cliente.</li>
          </ol>
        </div>
      </div>
      <div className="flex justify-start mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Aceptar
            </button>
          </div>
          <div>

          <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition"
          aria-label="Close"
        >
          <svg
            viewBox="0 0 24 24"
            className="w-5 h-5 text-gray-600"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

          </div>
    </div>
  </div>
  );
};

export default TermsAndConditionsReferralsModal;
