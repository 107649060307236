import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import Pricing_plans from "./Pricing_plans";
import CTA_Blog from "../Home/CTA_Blog";
import Testimonials from "../Home/Testimonials";
import Hardware from "../Home/Hardware";
import Hardware_business from "./Hardware_business";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import Global_pricing from "./Global_pricing";
import FAQ_business_types from "./FAQ_business_types";

const features = [
  {
    item: "Future-focused, connected tools",
  },
  {
    item: "Enhanced, user-friendly experience",
  },
  {
    item: "Easy launch and maintenance",
  },
  {
    item: "Customized processing rates and no hidden fees",
  },
  {
    item: "Customer insight and communication",
  },
];

const cards = [
  {
    name: "Gift cards",
    description:
      "From diving sale to boosting brand awareness, gift cards are an oportunity to turn new customers into loyal ones.",
    imgSrc: "https://img.invupos.com/webinvu/Retail%20module/Retail%201.webp",
    altImg: "",
  },
  {
    name: "Customer loyalty programs",
    description:
      "Keep loyal customers coming back again and again with personalized promotions, rewards, and incentives.",
    imgSrc: "https://img.invupos.com/webinvu/Retail%20module/Retail%202.webp",
    altImg: "",
  },
  {
    name: "Online ordering",
    description:
      "These days, real-time ordering is a must for any restaurant. Give customers the convenience of ordering.",
    imgSrc: "https://img.invupos.com/webinvu/Retail%20module/Retail%203.webp",
    altImg: "",
  },
];

const Large_business = ({country}) => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("GRANDES EMPRESAS | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const cardsbusinesstypesgenerallargebusiness = t("cardsbusinesstypesgenerallargebusiness", {
    returnObjects: true,
  });

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://img.invupos.com/webinvu/Large%20business%20module%20/Large%20business%201.avif"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-3xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                {t("businesstypesgenerallargebusinessuppercase")}
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              {t("businesstypesgenerallargebusinesstitle")}
            </h1>

            <p className="mt-5 max-w-2xl text-sm lg:text-base text-gray-200 leading-loose">
              {t("businesstypesgenerallargebusinessdescription")}
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                {t("businesstypesgenerallargebusinessbutton1")}
              </button>

              <Link
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                to={`${prefix}` + "/servicios-profesionales"}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("businesstypesgenerallargebusinessbutton2")}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-screen-xl  px-4 py-20 mx-auto sm:px-6 lg:px-8">
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl mb-6">
                <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font">
                  &mdash; {t("businesstypesgenerallargebusinesssection2uppercase")}
                </p>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                  {t("businesstypesgenerallargebusinesssection2title")}
                </h2>
              </div>
              <div className="-mt-1">

                  <div className="flex items-center max-w-4xl p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("businesstypesgenerallargebusinesssection2item1")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-4xl p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("businesstypesgenerallargebusinesssection2item2")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-4xl p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("businesstypesgenerallargebusinesssection2item3")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-4xl p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("businesstypesgenerallargebusinesssection2item4")}
                    </span>
                  </div>

              </div>
            </div>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
              <div className="flex flex-col items-end px-3">
                <img
                  className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                  src="https://images.pexels.com/photos/6476260/pexels-photo-6476260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
                <img
                  className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                  src="https://images.pexels.com/photos/6476259/pexels-photo-6476259.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
              <div className="px-3">
                <img
                  className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                  src="https://images.pexels.com/photos/7710156/pexels-photo-7710156.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Hardware_business />
      <section className=" mt-8">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font">
                &mdash; {t("businesstypesgenerallargebusinesssection3uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("businesstypesgenerallargebusinesssection3title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection3itemtitle1")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("businesstypesgenerallargebusinesssection3itemdescription1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection3itemtitle2")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("businesstypesgenerallargebusinesssection3itemdescription2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection3itemtitle3")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("businesstypesgenerallargebusinesssection3itemdescription3")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7433853/pexels-photo-7433853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-black">
                &mdash; {t("businesstypesgenerallargebusinesssection4uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("businesstypesgenerallargebusinesssection4title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection4itemtitle1")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("businesstypesgenerallargebusinesssection4itemdescription1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection4itemtitle2")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                   {t("businesstypesgenerallargebusinesssection4itemdescription2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    {t("businesstypesgenerallargebusinesssection4itemtitle3")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("businesstypesgenerallargebusinesssection4itemdescription3")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/6592659/pexels-photo-6592659.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
              {t("cardsbusinesstypestitle")}
            </h2>
          </div>
          <div class="grid grid-cols-1 lg:gap-10 md:gap-5 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {cardsbusinesstypesgenerallargebusiness.map((card) => (
              <div className="p-5 bg-pirDarkGray rounded-lg">
                <a className="">
                  <img
                    src={card.imgSrc}
                    class="object-cover w-full h-56 mb-5 bg-center rounded"
                    alt=""
                  />
                </a>
                <h3 class="mb-2 text-lg font-semibold text-gray-900">
                  <a class="text-gray-100 hover:text-gray-300  hover:underline">
                    {card.name}
                  </a>
                </h3>
                <p class="mb-3 text-sm max-w-lg font-normal text-gray-500">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {(country.toLowerCase() === 'panama' || country === 'Panama' || country === 'PANAMA') && <Global_pricing />}
      <FAQ_business_types />
      <CTA_Blog />
      <Testimonials />
    </>
  );
};

export default Large_business;
