import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";

const Home_repair_services = () => {
  const [isOpen, setIsOpen] = useState(false);
  useDocumentTitle("Invu POS | Home Business");
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652295659/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c06af32f11_kchj4q.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-60"></div>
        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                Home & Repair Business
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              Manage all his busines {""}
              <strong class="font-extrabold text-white sm:block">
                of services with only one system.
              </strong>
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              Save time with custom tools that work together to help you take
              care of your customers and drive your business.
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                onClick={() => setIsOpen(true)}
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/general/business-types"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Provide great service in each project
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Our easy-to-use management tools will help you stay
                  up-to-date, build stronger relationships, and encourage repeat
                  business. The best repair and services POS systems not only
                  handle inventory management and sale of merchandise, but also
                  are required to operate a repairs and service departament.
                </p>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652304729/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c28ffdf405_vr2hgy.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Get to know all your customers better
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Check in multiple customer items (such as an electric tool or
                  a lawn mower) and print claim tags. Enter description of work
                  to be done and service notes. Print quotes and invoices which
                  show due dates, charges for items and services and amount due.
                  Collect deposits from customers on work orders and set minimum
                  deposit rules.
                </p>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652304728/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c2ad079d0f_dlc0ks.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Empower your trades with our system
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  In an environment that offers retail, repairs, services, and
                  even trades; selling is no longer ordinary. That’s why our
                  repair POS software is designed to be efficient, yet flexible
                  enough to meet unique business models. Many of our features
                  are completely customizable, so you can tailor them to your
                  exact needs.
                </p>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652305769/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c2f5520509_trjr6x.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Personalize your experience
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Invu POS is built to keep your shop running smoothly, with
                  customized experiences, processes, and tools. Organize top
                  sellers, most repaired, and other favorites. Group items
                  together using item compatibility and dependency
                </p>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652306118/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c30b74358a_h9dsnp.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="relative">
          <img
            className="object-cover w-full h-50 sm:h-96"
            src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652306410/Branding%20Invu%20/Home%20and%20repeair%20services/Downloader.la_-627c31c26d5ed_rhyhfm.jpg"
            alt=""
          />
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50" />
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Features
            </p>
          </div>
          <h2 className="max-w-xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            Repairs and Services Store POS specific features
          </h2>
        </div>
        <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Quotes and invoices
            </h6>
            <p className="text-sm text-gray-900">
              With the invoice in progress functionality, you can quote a
              customer, print the quote, then when the customer comes in to
              purchase, simply recall the on hold invoice and process the sale.
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Store credits</h6>
            <p className="text-sm text-gray-900">
              Issue store credits to customers that can be applied to future
              transactions. Track and view service history of customer-owned
              items checked in for service work.
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Email marketing</h6>
            <p className="text-sm text-gray-900">
              Deliver targeted campaigns by querying and downloading customer
              data for use with popular email marketing applications.
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Editable product </h6>
            <p className="text-sm text-gray-900">
              Ability to edit from the front register the short product
              description which appears on a receipt. Helpful when selling
              unique or customizable items.
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Service department management
            </h6>
            <p className="text-sm text-gray-900">
              Allows you to efficiently manage a service department – you can
              bill by hour or by job, as well as bill for services.
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#4F46E5"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">Special orders</h6>
            <p className="text-sm text-gray-900">
              Allows you to track special order items, from the customer order
              to receiving, to inventory to the POS transaction. Track and view
              service history of customer-owned items checked in for service
              work.
            </p>
          </div>
        </div>
      </div>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Home_repair_services;
