import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import { useLocation } from 'react-router-dom'; 


const Cookie_policy = () => {

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const prefix = useLocalePrefix();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');

    if (!hasConsent) {
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');
  
    if (!hasConsent) {
      if (
        location.pathname === "/en/politicas-de-cookies" ||
        location.pathname === "/es/politicas-de-cookies" ||
        location.pathname === "/politicas-de-cookies" 
      ) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!showBanner) {
      document.body.style.overflow = 'visible';
      return; 
    }
  
    if (showBanner) {
      document.body.style.overflow = 'hidden';
    }
  }, [showBanner]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    document.body.style.overflow = 'visible';
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBanner(false);
    document.body.style.overflow = 'visible';
  };

  const bannerClass = showBanner
  ? 'opacity-100 duration-1000' 
  : 'opacity-0 translate-y-10 duration-1000'; 


    if (showBanner) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  

  return (
<div class={`relative z-40 ${bannerClass}`}>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full text-blue-500 bg-gradient-to-r from-purple-600 to-blue-400 sm:mx-0 sm:h-12 sm:w-12">
              <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.9803 8.5468C17.5123 8.69458 17.0197 8.7931 16.5271 8.7931C14.2118 8.76847 12.3399 6.89655 12.3153 4.58128C12.3153 4.13793 12.3892 3.69458 12.537 3.27586C11.9951 2.68473 11.6995 1.92118 11.6995 1.13301C11.6995 0.812808 11.7488 0.492611 11.8473 0.172414C11.2315 0.0738918 10.6158 0 10 0C4.48276 0 0 4.48276 0 10C0 15.5172 4.48276 20 10 20C15.5172 20 20 15.5172 20 10C20 9.77833 20 9.55665 19.9754 9.33498C19.2611 9.26108 18.5468 8.99015 17.9803 8.5468ZM4.58128 7.31527C6.30542 7.31527 6.30542 10.0246 4.58128 10.0246C2.85714 10.0246 2.61084 7.31527 4.58128 7.31527ZM6.05912 15.7635C4.08867 15.7635 4.08867 12.8079 6.05912 12.8079C8.02956 12.8079 8.02956 15.7635 6.05912 15.7635ZM9.01478 1.33005C10.7389 1.33005 10.7389 4.28571 9.01478 4.28571C7.29064 4.28571 7.04434 1.33005 9.01478 1.33005ZM10.2463 8.84237C11.7241 8.84237 11.7241 10.8128 10.2463 10.8128C8.76848 10.8128 9.01478 8.84237 10.2463 8.84237ZM11.9704 16.9458C10.4926 16.9458 10.4926 14.9754 11.9704 14.9754C13.4483 14.9754 13.202 16.9458 11.9704 16.9458ZM16.6503 13.1034C15.4187 13.1034 15.4187 11.133 16.6503 11.133C17.8818 11.133 17.8818 13.1034 16.6503 13.1034Z" fill="#fff"/>
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{t("modalcookiesconsenttitle")}</h3>
              <div class="mt-2">
                  <p class="mt-2 text-sm text-gray-600 ">
                    {t("modalcookiesconsenttextdescription")} {" "}
                      <span className='underline font-bold text-[#3B82F6]'>
                        <Link
                          class="underline font-bold text-indigo-600"
                          to={`${[prefix]}` + "/politicas-de-cookies"}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                        {t("modalcookiesconsenttextspan")}
                        </Link>
                      </span>
                  </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button onClick={handleAccept} type="button" class="inline-flex w-full justify-center rounded-md bg-blue-500 text-white hover:bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto">Aceptar</button>
          <button onClick={handleReject} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Rechazar</button>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Cookie_policy
