import React, { useState } from "react";
import Modal_started from "../../../components/Home/Modal_started";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";

const Point_of_sale_tab = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="max-w-screen-xl px-4 py-12 mt-4 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            {/* <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                  clip-rule="evenodd"
                />
              </svg>
            </div> */}
            <div className="max-w-xl mb-6">
              <a>
                <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  {t("pointofsaletittle")}
                </h3>
              </a>
              <p className="text-gray-600 leading-loose">
                {t("pointofsaledescription")}
              </p>
              <div class="flex flex-col mt-3 flex-grow ">
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#ffcc00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("pointofsaleitem1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#ffcc00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("pointofsaleitem2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#ffcc00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("pointofsaleitem3")}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div class="flex flex-wrap gap-4 text-center">
                <button
                  class="inline-flex items-center justify-center  block w-full px-8 py-3 text-sm font-medium text-white rounded shadow bg-[#0045AD]  sm:w-auto hover:bg-opacity-95 focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="mr-3">{t("pointofsalebutton1")}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="4,4 22,4 19,14 4,14 "
                    />
                    <circle
                      cx="4"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <circle
                      cx="20"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="1,1 4,4 4,14 2,18 23,18 "
                    />
                  </svg>
                </button>

                <Link
                  class="block w-full px-2  py-3 text-sm font-medium bg-white rounded  text-[#0045AD] hover:text-opacity-80 sm:w-auto active:text-indigo-500 focus:outline-none focus:ring"
                  to={`${prefix}` + "/productos/punto-de-venta"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("pointofsalebutton2")}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-48 sm:h-48 xl:h-56 w-40 sm:w-48 xl:w-56"
                src="https://img.invupos.com/webinvu/Invu%20Products/Point%20of%20sale/POS%201.avif"
                alt=""
                loading="lazy"
              />
              <img
                className="object-cover w-32 h-32 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://img.invupos.com/webinvu/Invu%20Products/Point%20of%20sale/POS%202.avif"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="px-3">
              <img
                loading="lazy"
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://img.invupos.com/webinvu/Invu%20Products/Point%20of%20sale/POS%203.avif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Point_of_sale_tab;
