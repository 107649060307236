import React, { useState, useEffect } from 'react'
import CTA_home from '../Home/CTA_home'
import FAQ_business_types from './FAQ_business_types'
import FAQ_model_one from './FAQ_model_one'

const images = [
  "https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo4.jpg",
  "https://img.invupos.com/webinvu/Case%20studies/Sisu-photo5.jpg",
];

const Case_studies = () => {

  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <>

<article class="px-4 py-24 mx-auto max-w-6xl" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
  <div class="w-full mx-auto mb-10 text-left">
    <div class="pb-6 mb-3">
      <p className="inline-block mb-3 text-[14px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
      Caso de Éxito
      </p>
      <h1 class="mb-0 text-5xl font-bold text-gray-900 md:leading-tight md:text-7xl" itemprop="headline" title="Rise of Tailwind - A Utility First CSS Framework">
      SISU COFFEE STUDIO
      </h1>
    </div>
    <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-coffee-studio1.jpg" class="object-cover obect-top w-full h-[500px]  rounded-3xl" alt="Kutty" />
    <div className='max-w-full'>
        <p class="text-lg mt-6 text-gray-600">
        Soy Wilford Lamastus Jr., director de <span className='font-semibold'>Sisu Coffee Studio by Lamastus Family Estates,</span> y quiero contarles cómo <span className='font-semibold'>Invu POS</span> ha sido un aliado fundamental en el viaje de Sisu, desde sus inicios hasta su expansión en Calle Uruguay
        </p>
        <p class="text-lg mt-6 text-gray-600">
        La idea de Sisu Coffee nació en un momento especial. Un amigo nos mostró un espacio en <span className='font-semibold'>Casco Viejo,</span> y al verlo, supe que era el lugar ideal para algo nuevo. En nuestra tienda anterior, Bajareque, ya habíamos aprendido muchísimo sobre lo que implica crear una cafetería de especialidad, pero aquí queríamos ir más allá, ofreciendo no solo café de calidad, sino una experiencia única. Junto con mi esposa Simi, quien es una parte esencial de este proyecto, decidimos crear un espacio con un diseño único y minimalista que reflejara nuestros valores. Así nace <span className='font-semibold'>Sisu Coffee Studio.</span>
        </p>
    </div>
  </div>


  <section>
        <div class="max-w-screen-xl mx-auto">
          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="order-first w-full  max-w-screen-sm m-auto mt-12 lg:w-1/4 lg:order-first">
              <div class="p-4 transition  duration-500 ease-in-out transform bg-white border rounded-lg">
                <div class="flex py-2 mb-4 ">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-p26g21g_0uIlGbM6SrBDsGELRpffxj4T0Q&s"
                    class="w-12 h-12 rounded-full"
                  />
                  <div class="ml-3 mt-2 content-center">
                    <p class="text-sm font-medium text-gray-900">Sisu Coffee</p>
                    <p class="text-sm text-gray-500">- KDS</p>
                    <p class="text-sm text-gray-500">- Punto de venta</p>
                    <p class="text-sm text-gray-500">- Clau.io</p>

                  </div>
                </div>
                <div>
                  <div class="relative mt-auto text-center w-full inline-block px-8 py-3 text-white bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange rounded group">
                    <span class="text-sm font-medium transition-opacity group-hover:opacity-0">
                      Redes sociales
                    </span>

                    <ul class="absolute inset-0 flex items-center justify-center space-x-3 transition-opacity opacity-0 group-hover:opacity-100">
                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Twitter </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Instagram </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          // href=""
                          // target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Facebook </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full px-4 mt-12 prose lg:px-0 lg:w-3/3">
              <h2 className="text-2xl text-gray-900 font-light md:text-6xl">
              El nombre de “Sisu”
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              El nombre de “Sisu” vino de una inspiración temprana de Simi, quien me envió un mensaje a las 5:00 am con esa palabra y su significado en finlandés: <span className='italic'>resiliencia, coraje y valentía.</span> Quería un nombre que no estuviera asociado con el café Geisha ni con la cultura japonesa, y “Sisu” encajó a la perfección. Es fácil de pronunciar en cualquier idioma y representa esa fortaleza que caracteriza a quienes trabajan en esta industria tan demandante
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Como miembro de la cuarta generación de la <span className='font-semibold'>familia Lamastus,</span> llevo conmigo una herencia y un conocimiento profundo sobre el café. Mi rol en Sisu es el de ser impulsor de la evolución de nuestra marca <span className='font-semibold'>Lamastus Family Estates,</span> con la que hemos logrado hitos importantes. Nuestro café ha alcanzado precios superiores a los 6 mil dólares por libra en subastas privadas y ha sido reconocido en el mercado de lujo, con menciones en lugares tan icónicos como la serie <span className='italic'>Billions.</span>
              </p>
              <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo1.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" />

              <h2 className="mt-16 text-2xl text-gray-900 font-light md:text-6xl">
              La Diferencia que Hizo Invu POS
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Desde el principio, <span className='font-semibold'>Invu POS</span> ha sido esencial para el éxito de Sisu Coffee Studio. Cuando comenzamos, sabíamos que la calidad del café debía ir acompañada de un servicio impecable, y <span className='font-semibold'>Invu POS</span> nos dio las herramientas para lograrlo. Con un sistema que permite una <span className='font-semibold'>gestión rápida y sencilla de los pedidos,</span> hemos podido mantener la experiencia de nuestros clientes al nivel que buscamos, sin largas esperas y con un flujo constante, incluso en los momentos más ocupados.
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Además, la <span className='font-semibold'>gestión de inventarios en tiempo real</span> ha sido clave para nosotros. Trabajamos con granos de café muy exclusivos y de alta demanda, y el sistema nos ayuda a estar al tanto de cada gramo, evitando pérdidas y asegurando que siempre tengamos disponible el mejor producto
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Con la apertura de nuestra nueva sucursal en <span className='font-semibold'>Calle Uruguay,</span> Invu POS nos ha facilitado el proceso de expansión al permitirnos <span className='font-semibold'>gestionar ambas ubicaciones desde un mismo sistema.</span> Esto es crucial para nosotros, ya que podemos controlar ventas, inventarios y personal en ambas tiendas de manera eficiente. La capacidad de revisar nuestros reportes financieros en tiempo real nos permite tener una visión clara y precisa del desempeño de cada tienda, dándonos la información necesaria para tomar decisiones estratégicas.
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              La integración con diferentes métodos de pago también ha sido esencial en nuestra expansión. Al ofrecer opciones variadas, nuestros clientes pueden disfrutar de su experiencia en Sisu Coffee Studio sin preocuparse por limitaciones de pago, algo fundamental en el mercado actual
              </p>

              {/* <div class="mx-auto mt-6 max-w-2xl  lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 ">
                <div class="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg" alt="Two each of gray, white, and black shirts laying flat." class="h-full w-full object-cover object-center"/>
                </div>
                <div class="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
                    <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg" alt="Model wearing plain black basic tee." class="h-full w-full object-cover object-center"/>
                    </div>
                    <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo4.jpg" alt="Model wearing plain gray basic tee." class="h-full w-full object-cover object-center"/>
                    </div>
                </div>
                <div class="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
                    <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo5.jpg" alt="Model wearing plain white basic tee." class="h-full w-full object-cover object-center"/>
                </div>
              </div> */}

              {/* <img src="https://img.invupos.com/webinvu/Case%20studies/sisu-photo2.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" /> */}

                      <div className="carousel w-full relative overflow-hidden mt-8">
              {/* Slides */}
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`carousel-item w-full transition-opacity duration-700 ease-in-out ${
                    index === currentIndex ? "opacity-100" : "opacity-0 absolute"
                  }`}
                >
                  <img src={image} alt={`Slide ${index + 1}`} className="w-full object-cover w-full h-96 bg-center rounded-2xl" />
                </div>
              ))}

              {/* Prev & Next buttons */}
              <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                <button
                  onClick={prevSlide}
                  className="btn btn-circle bg-black bg-opacity-70 p-2 hover:bg-opacity-90 transition-all rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <button
                  onClick={nextSlide}
                  className="btn btn-circle bg-black bg-opacity-70 p-2 hover:bg-opacity-90 transition-all rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>

              <h2 className="mt-16 text-2xl text-gray-900 font-light md:text-6xl">
              Un Futuro Prometedor
              </h2>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Gracias a <span className='font-semibold'>Invu POS</span>, hemos podido consolidar nuestra marca y mantener la calidad y el control que caracterizan a Sisu Coffee Studio. Nos permite enfocarnos en lo que realmente importa: ofrecer un café de excelencia, mantener nuestras raíces familiares, y seguir innovando en un espacio donde la pasión por el café se vive y se respira. Sin duda, <span className='font-semibold'>Invu POS</span> ha sido el soporte que necesitábamos para lograr que nuestro negocio siga creciendo y manteniendo su identidad única
              </p>
              <p className="mt-6 text-gray-500 text-lg max-w-4xl">
              Si estás buscando un sistema que te ayude a gestionar de manera eficiente y a mantener altos estándares en un negocio de especialidad, Invu POS es la solución ideal.
              </p>

              <img src="https://img.invupos.com/webinvu/Case%20studies/Sisu-photo3.jpg" class="object-cover w-full h-96 bg-center rounded-2xl mt-8" />

            </div>
          </div>
        </div>




      </section>

</article>

<section className="bg-gray-50">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-gray-50">
      <div className=''>
        <h2 className="mb-6 font-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Ecosistema de Invu POS en Sisu Coffee Studio
        </h2>
      </div>
      <div className="grid gap-4 row-gap-5 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device1.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device2.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm bg-white">
          <img src="https://img.invupos.com/webinvu/Case%20studies/Device3.png"/>
          <a
            aria-label=""
            className="mt-2 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-700 hover:text-indigo-500"
          >
            Leer más
          </a>
        </div>
      </div>
    </div>
    </section>

<CTA_home/>

<FAQ_business_types />

    </>
  )
}

export default Case_studies