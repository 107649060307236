import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../utils/useLocalePrefix";
import Modal_started from "../../Home/Modal_started";



const Report_tab = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="mt-4 max-w-screen-xl px-4 py-6 mx-auto sm:px-6 lg:px-8 bg-white rounded-3xl">
        <div className="grid gap-10 lg:grid-cols-8">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg lg:col-span-3 col-span-5">
            <div className="max-w-xl mb-6">
              <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("postab3title")}
              </h3>
              <p className="text-gray-600 leading-loose text-lg">
                {t("postab3description")}{" "}
              </p>
              <div class="flex space-y-1 flex-col mt-3 flex-grow">
                <div class="flex items-center">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-lg leading-loose font-semibold">
                    {t("postab3item1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-lg leading-loose font-semibold">
                    {t("postab3item2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-lg leading-loose font-semibold">
                    {t("postab3item3")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-lg leading-loose font-semibold">
                    {t("postab3item4")}
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8 col-span-5 lg:-mt-0 -mt-12">
            <img
              className="object-cover w-full"
              src="https://img.invupos.com/webinvu/Casestudy/Report-mockup.webp"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Report_tab;
