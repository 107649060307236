import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";
import Pricing_plans from "./Pricing_plans";
import Modal_started from "../Home/Modal_started";
import { useTranslation } from "react-i18next";

const Global_pricing = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="mt-12 mb-16">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("pricingmoduleuppercase")}
              </p>
            </div>
            <h2 className=" mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("pricingmoduletitle")}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {t("pricingmoduledescription")}
            </p>
          </div>

          <div className="grid max-w-md gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
            <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded shadow-sm sm:items-center hover:shadow">
              <div className="text-center">
                <div className="text-lg font-semibold">INVU POS</div>
                <div className="flex items-center justify-center mt-2">
                  <div className="mr-1 mt-2 text-md text-gray-600">
                    {t("pricingmodulecarddescription1")}
                  </div>
                </div>{" "}
                <div className="flex mt-6 mb-6 items-center justify-center mt-2">
                  <div className="mr-1 text-5xl font-bold">$89</div>
                  <div className="text-gray-700">
                    / {t("pricingmodulemonth")}
                  </div>
                </div>
                <div>
                  <p className="inline-block px-3 py-2 mb-4 text-xs font-semibold tracking-wider text-indigo-600 uppercase rounded bg-indigo-100">
                    {t("pricingmoduleplan1")}
                  </p>
                </div>
                <div className="mt-2 space-y-0">
                  <div className="flex items-center max-w-auto p-2 max-w-md mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item1")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item2")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item3")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item4")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item5")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item6")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm text-left transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard1item7")}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex max-w-xs items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                >
                  {t("pricingmodulebutton1")}
                </button>
                <p className="max-w-xs mt-3 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                  {t("pricingmodulespan1")}
                </p>
              </div>
            </div>
            <div className="relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded shadow-sm sm:items-center hover:shadow border-indigo-600">
              <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
                <div className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded bg-indigo-600">
                  {t("pricingmodulemostpopular")}
                </div>
              </div>
              <div className="text-center">
                <div className="text-center">
                  <div className="text-lg font-semibold">E-Commerce</div>
                  <div className="flex items-center justify-center mt-2">
                    <div className="mr-1 mt-2 text-md text-gray-600">
                      {t("pricingmodulecarddescription2")}
                    </div>
                  </div>{" "}
                  <p className="inline-block px-3 py-px mt-4 text-[11px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    {t("pricingmodulestarting2")}
                  </p>
                  <div className="flex  mb-6 items-center justify-center mt-2">
                    <div className="mr-1 text-5xl font-bold">$49</div>
                    <div className="text-gray-700">
                      / {t("pricingmodulemonth")}
                    </div>
                  </div>
                  <div>
                    <p className="inline-block px-3 py-2 mb-4 text-xs font-semibold tracking-wider text-indigo-600 uppercase rounded bg-indigo-100">
                      {t("pricingmoduleplan2")}
                    </p>
                  </div>
                  <div className="mt-2 space-y-0">
                    <div className="flex items-center max-w-auto p-2 max-w-md mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item1")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item2")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item3")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-left text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item4")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item5")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item6")}
                      </span>
                    </div>
                    <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                      <div className="mr-2">
                        <svg
                          className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="#25A55C"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                        {t("pricingmodulecard2item7")}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => setIsOpen(true)}
                    className="inline-flex max-w-xs items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-indigo-600 rounded shadow-md hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
                  >
                    {t("pricingmodulebutton2")}
                  </button>
                  <p className="max-w-xs mt-3 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                    {t("pricingmodulespan2")}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded shadow-sm sm:items-center hover:shadow">
              <div className="text-center">
                <div className="text-lg font-semibold">Loyalty</div>
                <div className="flex items-center justify-center mt-2">
                  <div className="mr-1 mt-2 text-md text-gray-600">
                    {t("pricingmodulecarddescription3")}
                  </div>
                </div>{" "}
                <p className="inline-block px-3 py-px mt-4 text-[11px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("pricingmodulestarting3")}
                </p>
                <div className="flex  mb-6 items-center justify-center mt-2">
                  <div className="mr-1 text-5xl font-bold">$49</div>
                  <div className="text-gray-700">
                    / {t("pricingmodulemonth")}
                  </div>
                </div>
                <div>
                  <p className="inline-block px-3 py-2 mb-4 text-xs font-semibold tracking-wider text-indigo-600 uppercase rounded bg-indigo-100">
                    {t("pricingmoduleplan3")}
                  </p>
                </div>
                <div className="mt-2 space-y-0 ">
                  <div className="flex items-center max-w-auto p-2 max-w-md mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item1")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item2")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item3")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item4")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item5")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                      {t("pricingmodulecard3item6")}
                    </span>
                  </div>
                  <div className="flex items-center max-w-auto p-2 max-w-md  transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-left text-sm transition-colors duration-200 group-hover:text-indigo-500 mr-24">
                      {t("pricingmodulecard3item7")}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex max-w-xs items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-gray-900 focus:shadow-outline focus:outline-none"
                >
                  {t("pricingmodulebutton3")}
                </button>
                <p className="max-w-xs mt-3  text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                  {t("pricingmodulespan3")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Global_pricing;
