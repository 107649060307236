import React, { useEffect } from 'react';

const CookiePolicy_info = () => {
  useEffect(() => {
    // Cargar el script del enlace
    const script = document.createElement('script');
    script.id = 'termly-jssdk';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // Limpiar el efecto al desmontar el componente
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="px-4 py-24 mx-auto max-w-6xl">
    <div name="termly-embed" data-id="674c04af-0d4e-4c1a-a355-b1da79554434" />
  </section>
  );
};

export default CookiePolicy_info;