import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import Customers_saying_CTA from "../Home/Customers_saying_CTA";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Partners from "../Home/Partners";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const features_col_one = [
  {
    name: "Employee management and permissions",
  },
  {
    name: "Inventory tracking and alerts",
  },
  {
    name: "Products with variations & modifiers",
  },
  {
    name: "Online ordering",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Appointment booking",
  },
  {
    name: "Full payment terminal integrations",
  },
  {
    name: "Full and partial voids and refunds",
  },
  {
    name: "Table management",
  },
  {
    name: "Fixed or inclusive taxes ",
  },
  {
    name: "Custom order types (e.g. Uber eats)",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Guest mode (kiosk mode)",
  },
  {
    name: "Contactless, chip-and-pin, and swipe payments ",
  },
];

const Accouting_ERPs = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("CONTABILIDAD ERPs | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div>
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div className="mt-8 relative rounded-lg bg-black container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
            <img
              className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg"
              alt="bg"
            />
            <img
              className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0"
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg"
              alt="bg"
            />
            <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
              <h2 className="mb-3 text-xs text-center font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
                &mdash; {t("resourcesmoretoolsaccountinguppercase")}
              </h2>
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">
                {t("resourcesmoretoolsaccountingtitle")}
              </h1>
            </div>
            <div className="flex justify-center items-center mb-10 sm:mb-20">
              <button
                onClick={() => setIsOpen(true)}
                className="hover:text-white hover:bg-transparent lg:text-xl hover:border-white border bg-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700	focus:ring-white rounded text-black px-4 sm:px-8 py-1 sm:py-3 text-sm"
              >
                {t("resourcesmoretoolsaccountingbuttoncontact")}
              </button>
            </div>
          </div>
          <div className="container mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
            <Carousel
              className="object-cover z-10 relative sm:w-2/3 w-11/12"
              autoPlay={true}
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              interval={5000}
              swipeable={false}
              showStatus={false}
              showIndicators={false}
              swipeAnimationHandler={false}
              animationHandler="fade"
              handleTransition="fade"
            >
              <div className="relative">
                <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-70"></div>
                <img
                  src="https://img.invupos.com/webinvu/ERPS%20module/Erps%201.webp"
                  className="rounded-lg  hover:opacity-50"
                  alt="Sample Page"
                  role="img"
                />
              </div>
              <div className="relative">
                <div className="absolute rounded-lg inset-0 bg-black bg-opacity-70"></div>
                <img
                  className="rounded-lg"
                  src="https://img.invupos.com/webinvu/ERPS%20module/Erps%202.jpg"
                  alt="Sample Page"
                  role="img"
                />
              </div>
            </Carousel>
          </div>
        </div>
        <Partners />
        <section className="bg-pirBlack">
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div class="order-none lg:order-2 md:order-none">
                <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  {t("resourcesmoretoolsaccountingsection2title")}
                </h2>
                <div className="flex mt-6 my-6 max-w-4xl">
                  <p class="text-gray-500 leading-loose">
                    {t("resourcesmoretoolsaccountingsection2description")}
                  </p>
                </div>
                <button
                  class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span class="text-sm font-medium"> {t("resourcesmoretoolsaccountingsection2button")} </span>

                  <svg
                    class="w-5 h-5 ml-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://img.invupos.com/webinvu/ERPS%20module/Erps%203.jpg"
                  alt="reasons invu"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirDarkGray">
          <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div>
                <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  {t("resourcesmoretoolsaccountingsection3title")}
                </h2>
                <div className="flex mt-6 my-6">
                  <p class="text-gray-500 leading-loose">
                    {t("resourcesmoretoolsaccountingsection3description")}
                  </p>
                </div>
                <a
                  class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                  href="/resellers-program"
                >
                  <span class="text-sm font-medium"> {t("resourcesmoretoolsaccountingsection3button")} </span>

                  <svg
                    class="w-5 h-5 ml-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://img.invupos.com/webinvu/ERPS%20module/Erps%204.jpg"
                  alt="reasons invu"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <Customers_saying_CTA /> */}
      </div>
      {/* <FAQ_model_one /> */}
      {/* <Help_CTA /> */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-1">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("resourcesmoretoolsaccountingsection4uppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("resourcesmoretoolsaccountingsection4title")}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {t("resourcesmoretoolsaccountingction4description")}
            </p>
          </div>
        </div>
        <div className=" max-w-screen-lg mx-auto">
          <div className="grid grid-cols-1 gap-4 row-gap-5 lg:grid-cols-2">

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item1")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item2")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item3")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item4")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item5")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item6")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item7")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item8")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item9")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item10")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item11")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item12")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsaccountingsection4item13")}
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      <div className="bg-pirDarkGray">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
              <a className="mb-6 sm:mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-teal-accent-400">
                  <svg
                    class="text-white w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                </div>
              </a>
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                    >
                      <defs>
                        <pattern
                          id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                          x="0"
                          y="0"
                          width=".135"
                          height=".30"
                        >
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect
                        fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                        width="52"
                        height="24"
                      />
                    </svg>
                    <span className="relative"></span>
                  </span>{" "}
                 {t("resourcesmoretoolsaccountingsection5title")}
                </h2>
                <p className="text-base text-indigo-100 md:text-lg">
                  {t("resourcesmoretoolsaccountingsection5description")}
                </p>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center h-12 px-6 font-semibold tracking-wide text-black transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-white hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none"
                >
                  {t("resourcesmoretoolsaccountingsection5buttonstarted")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ_model_one />
    </>
  );
};

export default Accouting_ERPs;
