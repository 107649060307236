import React from 'react'
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

const Article_alliance_with_cloudbeds = () => {
    useDocumentTitle(
        "Invu POS anuncia una estratégica alianza con Cloudbeds | Invu POS"
      );
  return (
    <>
       <Helmet>
        <meta
          name="description"
          content="InvuPos, líder en soluciones tecnológicas para la industria de la hospitalidad en la región, se complace en anunciar una alianza estratégica con Cloudbeds, reconocida mundialmente por su rápido crecimiento en la tecnología hotelera. Cloudbeds ha emergido como un proveedor líder de software hotelero, brindando una plataforma integral que revoluciona la gestión hotelera y enriquece la experiencia del cliente."
        />
        <meta name="keywords" content="InvuPos anuncia una estratégica alianza con Cloudbeds para potenciar soluciones en el sector hotelero en América Latina." />
      </Helmet>
      <article class="px-4 py-24 mx-auto max-w-7xl -mt-14" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
  <div class="w-full mx-auto mb-12 text-left md:w-3/4 lg:w-1/2">
    <div className="flex space-x-3 mb-2">
      <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Artículo</p>
      <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Nota de prensa</p>
    </div>
    <h1 class="mb-6 text-3xl font-bold leading-tight text-gray-900 md:text-4xl" itemprop="headline">
        InvuPos anuncia una estratégica alianza con Cloudbeds para potenciar soluciones en el sector hotelero en América Latina.
    </h1>
    <p class="text-base text-gray-500 italic">Fecha de publicación —  <span className="font-semibold">January 22, 2024</span></p>
    <div class="pb-6 mb-6 border-b border-gray-200"></div>
    <p className="text-gray-700 leading-7 mb-6">
        InvuPos, líder en soluciones tecnológicas para la industria de la hospitalidad en la región, se complace en anunciar <span className='font-semibold'> una alianza estratégica con Cloudbeds</span>, reconocida mundialmente por su rápido crecimiento en la tecnología hotelera. Cloudbeds ha emergido como un proveedor líder de software hotelero, brindando una plataforma integral que revoluciona la gestión hotelera y enriquece la experiencia del cliente.
    </p>
    <p className="text-gray-700 leading-7 mb-6">
        Esta colaboración con Cloudbeds fortalece el liderazgo de InvuPos en el suministro de soluciones tecnológicas avanzadas para el mercado hotelero latinoamericano. InvuPos se destaca como una de las únicas empresas de sistemas de facturación en la región que se ha asociado con Cloudbeds, marcando un hito crucial en su misión de proveer a los hoteleros y anfitriones en Latinoamérica con las herramientas más vanguardistas y eficientes.
    </p>
    <div class="order-2 h-72 md:order-1 md:h-full rounded-xl">
      <img
        src="https://img.invupos.com/webinvu/Blog%20invu/alianza%20invupos%20cloudbeds.jpg"
        class="w-full h-full bg-center object-cover rounded-xl"
        alt=""
      />
    </div>

    <a class="flex items-center text-gray-700 mt-6" href="#">
      <div class="gradient-div"></div>
      <div class="ml-2">
        <p class="text-medium font-semibold text-gray-800">De: Invu POS</p>
      </div>
    </a>
    <div class="pb-6 mb-6 border-b border-gray-200"></div>

  </div>

  <div class="w-full mx-auto prose md:w-3/4 lg:w-1/2">



    <h2 className="font-semibold text-xl mt-12">
    Beneficios clave de la Alianza:
    </h2>
    <p className="mt-4 text-gray-700 leading-7">
    Soluciones integradas y eficientes: La integración de InvuPos con la Plataforma de Hospitalidad de Cloudbeds brindará a los clientes de InvuPos acceso a un conjunto amplio y cohesivo de herramientas para la gestión integral de las operaciones. Esta integración significa que los hoteles podrán sincronizar de manera eficiente la gestión de sus clientes y el consumo de sus huéspedes en restaurantes y otros servicios, lo que facilita una facturación y contabilidad más precisas y menos complicadas.
    </p>

    <h2 className="font-semibold text-xl mt-12">
    Mayor productividad y experiencia de usuario: 
    </h2>
    <p className="mt-4 text-gray-700 leading-7">
    Con esta alianza, los usuarios de InvuPos experimentarán una mejora notable en la productividad. Al unir los esfuerzos de Cloudbeds e InvuPos, los hoteles podrán registrar el consumo de los huéspedes directamente en sus cuentas de habitación, lo que resulta en un proceso de checkout más ágil y un mejor seguimiento del consumo, mejorando significativamente la experiencia del cliente y la eficiencia operativa del hotel.
    </p>

    <img src="https://img.invupos.com/webinvu/Blog%20invu/hotels_blog.png" class="object-cover w-full h-60 bg-center rounded-lg mt-8" alt="Nvidia" />

    <h2 className="font-semibold text-xl mt-12">
    Posicionamiento estratégico en el mercado: 
    </h2>
    <p className="mt-4 text-gray-700 leading-7">
    Esta alianza estratégica posiciona a InvuPos como un líder indiscutible en el mercado de América Central y del Sur, ofreciendo una solución completa y avanzada, respaldada por dos innovadores tecnológicos en el sector.
    </p>
    <p className="mt-4 text-gray-700 leading-7">
    <span className='font-semibold'>Rafi Turgman, CEO de InvuPos</span>, expresó su entusiasmo por esta colaboración estratégica: <span className='italic'>"Nos entusiasma enormemente asociarnos con Cloudbeds, una empresa que comparte nuestra visión de innovación y excelencia en la industria hotelera. Esta alianza no solo expande nuestras capacidades, sino que también refuerza nuestro compromiso de proporcionar a nuestros clientes las soluciones tecnológicas más avanzadas del mercado"</span>.
    </p>
    <p className="mt-4 text-gray-700 leading-7">
    Esta colaboración estratégica no solo beneficia a InvuPos y Cloudbeds, sino que también representa un avance significativo en la tecnología de gestión hotelera en América Latina. Ambas empresas están comprometidas a brindar a los clientes experiencias excepcionales y a contribuir al crecimiento continuo de la industria.
    </p>

    <div class="pb-6 mb-6 border-b border-gray-200"></div>
    <div class="flex items-center mb-6 space-x-2">
      <p class="text-base text-gray-500">
      January 22, 2024 — Written by:{" "}
        <span className="underline font-regular">Rafael Turgman</span> 
      </p>
    </div>
  </div>
</article>
    </>
  )
}

export default Article_alliance_with_cloudbeds;