import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Partners from "../Home/Partners";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const features_col_one = [
  {
    name: "Employee management and permissions",
  },
  {
    name: "Inventory tracking and alerts",
  },
  {
    name: "Products with variations & modifiers",
  },
  {
    name: "Online ordering",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Appointment booking",
  },
  {
    name: "Full payment terminal integrations",
  },
  {
    name: "Full and partial voids and refunds",
  },
  {
    name: "Table management",
  },
  {
    name: "Fixed or inclusive taxes ",
  },
  {
    name: "Custom order types (e.g. Uber eats)",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Guest mode (kiosk mode)",
  },
  {
    name: "Contactless, chip-and-pin, and swipe payments ",
  },
];

const Productions = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("PRODUCCIONES | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      {/* <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  INVENTORY
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Powerful inventory management software
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                Totally dominate your inventory using innovative tools to
                increase the efficiency and visibility of your business.
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://images.pexels.com/photos/7289720/pexels-photo-7289720.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="mt-8 relative rounded-lg bg-black container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
          <img
            className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg"
            alt="bg"
          />
          <img
            className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg"
            alt="bg"
          />
          <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
            <h2 className="mb-3 text-xs text-center font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
              &mdash; {t("resourcesmoretoolsproductionsuppercase")}
            </h2>
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">
              {t("resourcesmoretoolsproductionstitle")}
            </h1>
            <p className="text-base text-center text-gray-300 leading-loose mt-6">
                {t("resourcesmoretoolsproductionsdescription")}
            </p>
          </div>
          <div className="flex justify-center items-center mb-10 sm:mb-20">
            <button
              onClick={() => setIsOpen(true)}
              className="hover:text-white hover:bg-transparent lg:text-xl hover:border-white border bg-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700	focus:ring-white rounded text-black px-4 sm:px-8 py-1 sm:py-3 text-sm"
            >
              {t("resourcesmoretoolsproductionsbuttoncontact")}
            </button>
            {/* <button className="hover:bg-white hover:text-indigo-600 lg:text-xl hover:border-indigo-600 ml-3 sm:ml-6 bg-transparent transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700 focus:ring-white hover:bg-indigo-700-800 rounded border border-white text-white px-4 sm:px-8 py-1 sm:py-3 text-sm">
              Learn More
            </button> */}
          </div>
        </div>
        <div className="container mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
          <Carousel
            className="object-cover z-10 relative sm:w-2/3 w-11/12"
            autoPlay={true}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
            interval={5000}
            swipeable={false}
            showStatus={false}
            showIndicators={false}
            swipeAnimationHandler={false}
            animationHandler="fade"
            handleTransition="fade"
          >
            <div className="relative">
              <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-70"></div>
              <img
                src="https://img.invupos.com/webinvu/Productions%20module/Production%201.jpeg"
                className="rounded-lg  hover:opacity-50"
                alt="Sample Page"
                role="img"
              />
            </div>
            <div className="relative">
              <div className="absolute rounded-lg inset-0 bg-black bg-opacity-70"></div>
              <img
                className="rounded-lg"
                src="https://img.invupos.com/webinvu/Productions%20module/Production%202.webp"
                alt="Sample Page"
                role="img"
              />
            </div>
          </Carousel>
        </div>
      </div>
      <Partners />

      <section className="bg-pirBlack">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("resourcesmoretoolsproductionssection2uppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcesmoretoolsproductionstitle")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection2subtitle1")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection2description1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection2subtitle2")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection2description2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection2subtitle3")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection2description3")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection2subtitle4")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection2description4")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection2subtitle5")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection2description5")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Productions%20module/Production%203.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("resourcesmoretoolsproductionssection3uppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcesmoretoolsproductionssection3title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection3subtitle1")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection3description1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection3subtitle2")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection3description2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsproductionssection3subtitle3")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsproductionssection3description3")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Productions%20module/Production%204.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <Help_CTA />
      <FAQ_model_one /> */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-1">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("resourcesmoretoolsproductionssection4uppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("resourcesmoretoolsproductionssection4title")}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {t("resourcesmoretoolsproductionssection4description")}
            </p>
          </div>
        </div>
        <div className=" max-w-screen-lg mx-auto">
          <div className="grid grid-cols-1 gap-4 row-gap-5 lg:grid-cols-2">

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item1")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item2")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item3")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item4")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item5")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item6")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item7")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item8")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item9")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item10")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item11")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item12")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item13")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsproductionssection4item14")}
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      <div className="bg-pirDarkGray">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
              <a className="mb-6 sm:mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-teal-accent-400">
                  <svg
                    class="text-white w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                </div>
              </a>
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                    >
                      <defs>
                        <pattern
                          id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                          x="0"
                          y="0"
                          width=".135"
                          height=".30"
                        >
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect
                        fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                        width="52"
                        height="24"
                      />
                    </svg>
                    <span className="relative"></span>
                  </span>{" "}
                  {t("resourcesmoretoolsaccountingsection5title")}
                </h2>
                <p className="text-base text-indigo-100 md:text-lg">
                  {t("resourcesmoretoolsaccountingsection5description")}
                </p>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center h-12 px-6 font-semibold tracking-wide text-black transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-white hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none"
                >
                  {t("resourcesmoretoolsaccountingsection5buttonstarted")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ_model_one />
    </>
  );
};

export default Productions;
