import React from 'react'
import HeroMX from './HeroMX'
import Features from './Features'
import Products_main_tabs from './Products_tabs/Products_main_tabs'
import Pricing from './Pricing'
import Customers_carousel from "../../components/modules/Customers_carousel";
import CTA_home from './CTA_home'
import Industries_tabs_main from "./Industries/Industries_tabs_main";
import Hardware_equipment from './Hardware_equipment'
import Partners from '../../components/Home/Partners'
import Support from './Support'
import Latest_blog from '../../components/Home/Latest_blog'
import Members from '../../components/Home/Members'
import Contact from '../../components/Home/Contact'
import Point_of_sale_section from './Point_of_sale_section'
import Industries_carousel from '../../components/modules/Industries_carousel'
import CRM_section from './CRM_section'
import ContactMx from '../../components/Home/ContactMx'
import Industries_carousel_mx from '../../components/modules/Industries_carousel_mx'


const LandingMX = () => {
  return (
    <>
        <HeroMX/>
        <Products_main_tabs/>
        {/* <Point_of_sale_section/> */}
        {/* <CRM_section/> */}
        <Industries_carousel_mx/>
        {/* <Customers_carousel/> */}
        <CTA_home/>
        <Industries_tabs_main/>
        <Hardware_equipment/>
        <Partners/>
        <Support/>
        <Latest_blog/>
        <Members/>
        <ContactMx/>
    </>
  )
}

export default LandingMX