import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-sm lg:text-base text-left justify-start md:text-sm text-gray-700 font-semibold">
          {title}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-700">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const Article_invu_processor = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("Mejor Procesador de Pagos Online | Invu POS");
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Conoce los mejores procesadores de Pagos Online y como integrarlos a tu negocio con Invu POS para facilitar mas opciones de pago a los compradores."
        />
        <meta name="keywords" content="procesador de pagos online" />
        <meta
          name="keywords"
          content="procesador de pagos online, procesador de pagos online, procesador de tarjetas de credito, mejor procesador de pagos, mejores procesadores de pago, procesador de pagos internacionales"
        />
      </Helmet>

      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-64 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%203/Cover%20blog%203.avif"
              class=" w-full h-full bg-center object-container"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 25, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Invu POS, el mejor procesador de pagos online
            </h1>
            <p className="text-gray-500 max-w-xl">
              Es probable que estés buscando información relacionada con un
              procesador de pagos online o monedero electrónico. Debes saber que
              un procesador de pagos online sirve para múltiples cosas, ya sea
              para realizar transferencias en línea, hacer compras o cobrar tu
              dinero por Internet.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-2 leading-7">
            Por lo tanto, conoce sobre Invu POS, qué es este tipo de herramienta
            y cómo funciona:
          </p>
          <h2 className="font-semibold text-xl mt-6">
            ¿Qué es un procesador de pagos online?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-8">
            Un procesador de pagos online{" "}
            <span className="font-semibold">
              {" "}
              es un servicio que muchas empresas privadas ofrecen por medio de
              Internet.{" "}
            </span>{" "}
            A través de este servicio, gestionan los intercambios económicos
            virtuales de sus usuarios, pudiendo enviar y recibir dinero por esta
            vía. Los intercambios pueden ser ejecutados entre empresas y
            clientes, con proveedores o con personas naturales. Los procesadores
            de pagos digitales pueden cancelar sus compras, en sitios web como
            Amazon o Ebay. Además, puedes, por medio de ellos,{" "}
            <span className="font-semibold">
              {" "}
              recibir montos por servicios prestados por Internet{" "}
            </span>{" "}
            (para trabajadores independientes). También, recibir y enviar dinero
            a un familiar, no importa el lugar del mundo donde esté.
          </p>
          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />

          <h2 className="font-semibold text-xl mt-6">
            ¿Cómo funciona el procesador de pagos de Invu POS?
          </h2>

          <p className="mt-2 text-gray-700 leading-7">
            Este tipo de herramientas no es una cuenta bancaria, aunque te
            permite hacer algunas funciones similares. Por lo que debes
            utilizarlo con un fin,{" "}
            <span className="font-semibold">
              {" "}
              hacer transacciones comerciales en la red y no para acumular
              patrimonio en él.{" "}
            </span>{" "}
          </p>

          <p className="mt-2 text-gray-700 leading-7">
            La diferencia entre una cuenta bancaria y el procesador de pagos de
            Invu POS,{" "}
            <span className="font-semibold">
              {" "}
              es que este último no te paga interés por el capital que
              depositas,{" "}
            </span>{" "}
            y menos, te genera un número de cuenta. También, como usuario de un
            procesador de pagos, solamente puedes hacer operaciones con personas
            afiliadas a la misma plataforma. Algo que no sucede con las cuentas
            bancarias, que te facilitan las transacciones con otros bancos.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Tarjetas para procesadores de pagos
          </h2>

          <ul class=" list-inside mt-5">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">
                Tarjetas virtuales <br></br>
              </span>{" "}
              Son productos financieros, con los que puedes blindarte y te dejan
              con la tranquilidad de que tus compras y datos están resguardados
              por los mejores sistemas de seguridad.{" "}
              <span className="font-semibold">
                {" "}
                No necesitan plástico, pues, como su nombre indica, no tiene
                forma física{" "}
              </span>{" "}
              y pertenece a un procesador de pagos que solamente usas por este
              medio. Además, emplean la misma información que una tarjeta
              física, es decir, fecha de caducidad, numeración y código de
              verificación. También,{" "}
              <span className="font-semibold">
                {" "}
                pueden ser empleadas en varios comercios,{" "}
              </span>
              es un método que te permite adquirir productos y servicios online
              sin tener que asistir de manera presencial al establecimiento.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Tarjetas de crédito<br></br>
              </span>{" "}
              La tarjeta de crédito de un procesador de pago online es, como su
              nombre lo indica, una tarjeta física que te permite disfrutar de
              las virtudes de una virtual.{" "}
              <span className="font-semibold">
                {" "}
                Son más seguras, pues, las digitales pueden ser hackeadas por
                ciberdelincuentes y perder tus fondos.
              </span>{" "}
              En cambio, cuando la tienes físicamente, solamente corres el
              riesgo de perderla de tu bolso o billetera, además de que no
              necesitas de Internet para poder utilizarla. Solamente requieres
              de un comercio afiliado al procesador de pagos y que acepte
              tarjetas de crédito.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Tarjetas de débito<br></br>
              </span>{" "}
              <span className="font-semibold">
                {" "}
                Los procesadores de pago ofrecen tarjetas de débito a sus
                usuarios{" "}
              </span>{" "}
              para hacer sus compras en línea en su sitio web de preferencia.
              Sin embargo, este tipo de método difiere de las tarjetas de
              crédito en que cada vez que realizas un pago, la comisión de pago
              por operación es inferior, y el monto máximo mensual y coste de
              mantenimiento es diferente. Además, cuando pagas con una tarjeta
              de débito, el monto es retirado directamente de tu cuenta
              bancaria.
            </li>
          </ul>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 07, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>

        <div className="bg-gray py-6">
          <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
            <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; soporte
              </p>

              <h2 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                Preguntas sobre procesadores de pago internacionales
              </h2>
              <p class="mt-4 leading-relaxed text-gray-700">
                A continuación, te detallo las preguntas más usuales que los
                usuarios de los procesadores de pago internacionales suelen
                tener:
              </p>
            </div>
          </div>
          <div className="w-full mt-16 my-12 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
              <div className="space-y-4">
                <div>
                  <Item title="¿Un procesador de pago es una pasarela de pago?">
                    No,{" "}
                    <span className="font-semibold">
                      {" "}
                      los procesadores de pago internacionales son similares a
                      una terminal de tarjeta de crédito,{" "}
                    </span>{" "}
                    aunque específicamente diseñada para transacciones
                    digitales. Es decir, no usan una tarjeta física, además
                    tienes que autenticar la misma sin el empleo de un chip EMV.
                    Igualmente, la pasarela de pago cifra la información
                    bancaria del cliente para que permanezca segura. En cambio,
                    un procesador de pagos{" "}
                    <span className="font-semibold">
                      sirve para ventas online como físicas,
                    </span>{" "}
                    en el caso de las compras presenciales, transmite la
                    información entre el banco emisor, cliente y banco receptor.
                    El procesador es el encargado de proveer al negocio un punto
                    de venta o un terminal de pago, los cuales deben poder leer
                    tarjetas físicas con chip EMV.
                  </Item>
                </div>
                <div>
                  <Item title="¿Cuáles son los pasos para emplear el procesador de pagos de Invu POS en mi negocio?">
                    Lo primero que tienes que hacer{" "}
                    <span className="font-semibold">
                      {" "}
                      es registrarte rellenando un típico formulario de Invu
                      POS,
                    </span>{" "}
                    luego vas a recibir un email de confirmación para que te
                    envíen un PIN o clave de seguridad. Debes colocar tus datos
                    reales para evitar problemas de verificación con el
                    proveedor del servicio.{" "}
                    <span className="font-semibold">
                      {" "}
                      Es una plataforma accesible y fácil de escalar,{" "}
                    </span>{" "}
                    solamente necesitas de un dispositivo móvil con conexión a
                    Internet y con tus credenciales puedes comenzar a vender en
                    tu negocio.
                  </Item>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <Item title="¿Puedo añadir el procesador de pagos a mi tienda online?">
                    Sí,{" "}
                    <span className="font-semibold">
                      es posible conectar un procesador de pagos en la sección
                      de configuración de pagos de la tienda online.
                    </span>{" "}
                    Una vez que lo enlaces al sitio web, tus clientes pueden ver
                    la nueva alternativa para finalizar la compra.
                  </Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_invu_processor;
