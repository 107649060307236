import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_loyalty_invu = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("Sistema Loyalty con INVU POS | Invu POS");
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Sistema Loyalty con Invu POS; Sistema de fidelizacion de clientes, un paso mas con tu sistema POS, registra tus clientes y aplica campañas de marketing."
        />
        <meta name="keywords" content="Loyalty con INVU POS" />
      </Helmet>

      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%206/Cover%20blog%206.jpg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 29, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Loyalty con Invu POS
            </h1>

            <p className="text-gray-500 max-w-xl">
              Integrar un sistema Loyalty con Invu POS es un paso importante
              para cualquier negocio, ya que mejora la manera de hacer los pagos
              y brinda mayor control sobre las ventas.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            En general, es sinónimo de simplicidad, optimización del flujo de la
            información y efectividad operacional. Por lo tanto, conoce más
            detalles sobre las ventajas que puede ofrecerte la integración de
            software Loyalty con Invu POS.
          </p>
          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />
          <h2 className="font-semibold text-xl mt-6">
            Construya relaciones más sólidas con los clientes usando Loyalty con
            Invu POS
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Crea un programa de fidelización de clientes directamente desde tu
            panel de datos o sitio web de ventas online al integrar Loyalty con
            Invu POS:
          </p>
          <h3 className="font-bold text-[#000] mt-4">
            Recompensas<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Mantén informado a tu audiencia sobre las recompensas:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Ofrece múltiples niveles de recompensa.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Deja que los clientes obtengan puntos por cada artículo comprado,
              visitas o monto invertido.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Elige el término que tenga sentido para ti: estrellas o puntos.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Puedes automatizar los mensajes de textos para confirmar la
              inscripción y mantenerlos actualizados sobre las recompensas.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Comercializa con clientes afiliados por medio de campañas
              automáticas de mensajes o correo electrónico gracias a la
              integración de Loyalty con Invu POS.
            </li>
          </ul>
          <h3 className="font-bold text-[#000] mt-4">
            Promociones<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Elabora promociones por medio de herramientas diseñadas para ello
            que solamente la integración Loyalty con Invu POS te puede ofrecer:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Puedes reemplazar las tarjetas de papel por una nueva presentación
              digital para aumentar la lealtad de los clientes.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Cuenta con un panel que coloca toda la información importante de
              tu negocio en un solo lugar.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-semibold">
                Destaca los productos que ofreces y vuelvelos más atractivos
                para tus clientes.
              </span>
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Imprime anuncios gratuitos en la tienda para anunciar tus
              programas de recompensas.
            </li>
          </ul>
          <h3 className="font-bold text-[#000] mt-4">
            Campañas de marketing<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Con el panel de control de Loyalty integrado con Invu POS puedes:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Hacer un seguimiento de la información como la cantidad total de
              clientes, las recompensas y los principales compradores.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Puedes evaluar el gasto promedio y las visitas de los clientes
              fidelizados frente a los que no lo son.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Gestiona la información de la lealtad de tus clientes
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Facilita la toma de decisiones comerciales y crea un plan a
              futuro, todo desde un único sitio.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Administra la información de la lealtad de tus clientes durante
              las campañas de marketing con POS.
            </li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Convierte las visitas en negocios recurrentes
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Los negocios que{" "}
            <span className="font-semibold">
              {" "}
              prueban la integración Loyalty con Invu POS indican que sus
              clientes inscritos gastan un 33% más.
            </span>{" "}
            Lo que representa un incremento en la cantidad de ventas de tu
            E-Commerce y mayor cantidad de visitas.
          </p>
          <h2 className="font-semibold text-xl mt-6">
            Características del sistema Loyalty de fidelización de clientes
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            <span className="font-semibold">
              {" "}
              Son muchas las características que te ofrece la integración de
              Loyalty con Invu POS,
            </span>{" "}
            y te las muestro a continuación:
          </p>
          <h3 className="mt-2 text-gray-700 leading-7 font-semibold">
            Intuitivo
          </h3>
          <p className="mt-1 text-gray-700 leading-7">
            Pasa tu tarjeta de crédito, inscríbete en el programa, gana puntos,
            de manera sencilla y sin pasos adicionales. Para ello, solamente
            basta con ingresar un correo electrónico al pagar para formalizar el
            registro. También, cada una de las personas fidelizadas puede
            ingresar al sistema y con su perfil verificar la evolución de la
            lealtad en cualquier instante.{" "}
          </p>
          <h3 className="mt-2 text-gray-700 leading-7 font-semibold">
            Flexible
          </h3>
          <p className="mt-1 text-gray-700 leading-7">
            <span className="font-semibold">
              Puedes trabajar rápidamente con el programa de recompensas de
              Loyalty con Invu POS fácil de implementar.
            </span>{" "}
            Personaliza bonos de registro, tasas de canje y acumulación y otras
            recompensas como cumpleaños. Además, la tarifa del programa de
            fidelización es plana, por lo que no debes sorprenderte con el
            valor.
          </p>
          <h3 className="mt-2 text-gray-700 leading-7 font-semibold">
            Compatible
          </h3>
          <p className="mt-1 text-gray-700 leading-7">
            Cada vez que un cliente regresa,
            <span className="font-semibold">
              el programa Loyalty con Invu POS muestra su historial de pedidos
              para que conozcas exactamente lo que quiere.
            </span>{" "}
            Cada uno de los huéspedes acumula puntos cada vez que usa su tarjeta
            de crédito. Además, no necesita de una tarjeta de fidelización o
            iniciar sesión cada vez que un cliente visita la tienda. También,
            cuentas con informes de lealtad para conocer la imagen del
            consumidor en el programa de fidelización.
          </p>
          <h2 className="font-semibold text-xl mt-6">
            Beneficios de contar con el sistema Loyalty de Invu POS
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            A continuación, te muestro una lista resumen de las ventajas que te
            ofrece la integración Loyalty con Invu POS:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">
                Sin software o hardware adicional:
              </span>{" "}
              No necesitas de dispositivos adicionales para recompensar a los
              clientes que compran directamente desde tu POS.{" "}
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Paga más cuando ganes más:
              </span>{" "}
              solamente vas a pagar cuando comiences a generar ingresos, cuyo
              precio va desde los 45$ y es ajustado en función de la cantidad de
              visitas por ubicación al mes. .{" "}
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Incrementa las visitas en tu negocio:
              </span>{" "}
              los comercios que emplean el software Loyalty con Invu POS
              experimentan un incremento en la cantidad de visitas de clientes
              de hasta un 26%.
            </li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Contacta a nuestro equipo para más información sobre el sistema
            Loyalty de Invu POS
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Invu POS es una empresa líder en el sector de desarrollo de software
            de facturación que vende su producto integrado con Loyalty para
            ofrecerte nuevas características. De esta manera, puedes aumentar la
            cantidad de visitas y registrar un incremento en las ventas sin
            importar a qué te dedicas. Si quieres conocer más información,
            contacta al correo electrónico info@invupos.com o al número +507
            201-8490.
          </p>
          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 07, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_loyalty_invu;
