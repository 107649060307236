import React, { useState } from "react";
import Modal_started from "../Home/Modal_started";

const PageNotFound = () => {
  const [isOpen, setIsOpen] = useState(false);

  window.setTimeout(function () {
    window.location.href = "/";
  }, 1000);

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section class="px-4 py-24 mx-auto max-w-7xl">
        <div class="grid items-center w-full grid-cols-1 gap-10 mx-auto md:w-4/5 lg:grid-cols-2 xl:gap-32">
          <div>
            <p class="mb-2 text-xs font-semibold tracking-wide text-indigo-600 uppercase">
              Error 404
            </p>
            <h1 class="mb-4 text-2xl font-extrabold leading-tight tracking-tight text-left text-gray-900 md:text-4xl">
              Oops! The page you're looking for isn't here.
            </h1>
            <p class="mb-5 text-base text-left text-gray-700 md:text-xl">
              You might have the wrong address, or the page may have moved.
            </p>
            {/* <a
              href="#"
              class="w-full mb-2 btn btn-lg btn-light sm:w-auto sm:mb-0"
            >
              Back to homepage
            </a>
            <a
              href="#"
              class="w-full mb-2 btn btn-lg btn-white sm:w-auto sm:mb-0"
            >
              Contact us
            </a> */}
            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <a
                href="/"
                class="block w-full px-6 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
              >
                Back to homepage
              </a>

              <button
                onClick={() => setIsOpen(true)}
                class="block w-full px-8 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
              >
                Contact us
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src="https://images.pexels.com/photos/7709263/pexels-photo-7709263.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
