import React, { useState } from "react";
import Modal_started from "../Home/Modal_started";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import FAQ_model_one from "../modules/FAQ_model_one";
import Help_CTA from "../Home/Help_CTA";
import useDocumentTitle from "../../useDocumentTitle";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const features_one = [
  {
    name: "Find a retail store",
    description:
      "Need something today? Square hardware may be available at a store near you.",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20details/Group_21_qhawqz.svg",
    button: "See retail",
    href: "/venta-minorista/general",
  },
  {
    name: "Get gift cards for your business",
    description:
      "Offer free, digital eGift Cards or custom plastic gift cards online or in-store.",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20details/Group_22_sdl4mp.svg",
    button: "Resellers program",
    href: "/programas-de-revendedores",
  },
  {
    name: "Talk to our Sales team",
    description:
      "Get in touch with our sales team and we’ll help you find the right hardware setup for your business.",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20details/Group_23_dmsese.svg",
    button: "Contact sales",
    href: "/contacto-con-ventas",
  },
];

const features = [ 
  {
    id: "1",
    name: "Sunmi v3 Mix",
    description: "Detachable case lets you use your tablet around the store or use the built-in lock to keep it in place. Keeps your tablet charged and ready to use. 24/7 support is available for this product whenever you need it",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/SUNMI%20V3%20MIX.png",
  },
  {
    id: "2",
    name: "Swift 2 Series",
    description: "For restaurant operations, it offers the features of multiple online booking touchpoints, table status tracking, two-way guest communication, etc.",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/SWIFT%202%20SERIES.png",
  },
  {
    id: "3",
    name: "D4 Pro",
    description: "Managing cash flow. Retailers with a high-volume sales system can integrate cash drawers to track the amount of money in every single real-time transaction.",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/D4%20PRO.png",
  },
  {
    id: "4",
    name: "Swan 1 Pro",
    description: "Its most important features are printing speed, multiple interfaces and ease of integration with various operating systems.",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/SWAN%201%20PRO.png",
  },
  {
    id: "5",
    name: "Ipad 8th/9th generation",
    description: "Security - Transactions and sensitive data must be strictly protected from attacks.Easy installation & maintenance - Quick and easy installation with existing IP infrastructure",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/9th%20generation.png",
  },
  {
    id: "6",
    name: "Router",
    description: "After accepting payment, POS terminals can offer customers the option of choosing whether to receive a digital receipt (either via text or email), a printed receipt or no receipt.",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/Router.png",
  },
  {
    id: "7",
    name: "Cash Drawer",
    description: "After accepting payment, POS terminals can offer customers the option of choosing whether to receive a digital receipt (either via text or email), a printed receipt or no receipt.",
    imgSrc: "https://img.invupos.com/webinvu/Hardware/New/Cash%20Drawer.png",
  },
];

const Hardware_details = () => {
  const [isOpen, setIsOpen] = useState(false);
  useDocumentTitle("Invu POS | Hardware Solutions");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();
  const features = t("hardwareequipment", { returnObjects: true });
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      {/* <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://img.invupos.com/webinvu/Hardware%20details/bg.png"
          alt=""
        />

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div className="relative px-4 lg:-mt-12 xl:-mt-12  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="mb-10 lg:mt-0 xl:mt-0 md:mt-12 mt-12 lg:max-w-lg lg:pr-5 lg:mb-0">
              <div className="max-w-xl mb-6">
                <div>
                  <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                    &mdash; Hardware solutions
                  </p>
                </div>
                <h2 className="max-w-lg space-y-2 mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                  Payment processing for Professional Services
                </h2>
                <p className="text-gray-500 leading-loose">
                  From restaurants to soccer stadiums, shoe stores, fast
                  services, Big enterprises, Health and Fitness, Boutiques,
                  Jewerly and more.
                </p>
              </div>
              <div className="flex flex-col items-center md:flex-row">
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-indigo-600 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  <span className="mr-3">Get started</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="4,4 22,4 19,14 4,14 "
                    />
                    <circle
                      cx="4"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <circle
                      cx="20"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="1,1 4,4 4,14 2,18 23,18 "
                    />
                  </svg>
                </button>
                <Link
                  to={`${prefix}` + "/productos/punto-de-venta"}
                  onClick={() => window.scrollTo(0, 0)}
                  className="inline-flex items-center font-semibold text-white ransition-colors duration-200 hover:text-deep-purple-accent-700"
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className="relative lg:w-1/2 ">
              <Carousel
                autoPlay={true}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                interval={5000}
                swipeable={true}
              >
                <div>
                  <img
                    className="object-cover w-full h-5/6 sm:h-5/6"
                    src="https://img.invupos.com/webinvu/Hardware%20details/Group_8_3_cgqnks.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="object-cover w-full h-5/6 sm:h-5/6"
                    src="https://img.invupos.com/webinvu/Hardware%20details/Group_13_ecjmih.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="object-cover w-full h-5/6 sm:h-5/6"
                    src="https://img.invupos.com/webinvu/Hardware%20details/Group_9_1_foujkt.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="object-cover w-full h-5/6 sm:h-5/6"
                    src="https://img.invupos.com/webinvu/Hardware%20details/Group_10_1_uuph9h.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="object-cover w-full h-5/6 sm:h-5/6"
                    src="https://img.invupos.com/webinvu/Hardware%20details/Group_12_1_nftqdw.svg"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </section> */}
<section className="bg-gray-100">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
          <div className="max-w-xl mb-6">
            <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; {t("hardwareequipmentuppercase")}
            </p>
            <h2 className="font-bold xl:text-5xl lg:text-6xl md:text-6xl text-5xl text-[#002D33]">
              {t("hardwareequipmenttitle")}
            </h2>
            <p className="mt-5 text-base text-gray-700 md:text-lg">
            {t("hardwareequipmentdescription")}
            </p>
          </div>
          <div>
            <button
              onClick={() => {
              setIsOpen(true);
            }}
              class="relative inline-flex items-center bg-indigo-600 hover:opacity-95 px-8 py-3.5 overflow-hidden text-white rounded-full group "

            >
              <span class="font-medium">{t("hardwareequipmentbutton")}</span>
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              style={{ objectPosition: '80% 100%' }}
              src="https://images.pexels.com/photos/12935074/pexels-photo-12935074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
            <img
              className="object-cover object-center w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              style={{ objectPosition: '80% 90%' }}
              src="https://images.pexels.com/photos/2988232/pexels-photo-2988232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src="https://images.pexels.com/photos/9810169/pexels-photo-9810169.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
</section>

      <section className="">
        <div class="px-4 py-24 mx-auto max-w-screen-lg px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
            {features.map((feature) => (
              <a class="block p-4 rounded-lg shadow-gray-300 bg-gray-50 shadow-xl border">
                <img
                  alt=""
                  src={feature.imgSrc}
                  class="object-cover w-full h-70 rounded-md"
                />
                <div class="mt-4 p-2">
                  <h5 class="text-2xl font-bold">{feature.name}</h5>

                  <p class="mt-3 mb-3 text-gray-500">
                    {feature.description}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div class="max-w-screen-xl px-4 mt-2 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 mb-16 lg:mb-0 lg:pt-20 lg:max-w-xl lg:pr-5">
        <div className="max-w-2xl mb-6">
          <h2 className="max-w-2xl mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("hardwareequipmentctatitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
         {t("hardwareequipmentctadescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("hardwareequipmentctabutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div>
        <img
          src="https://img.invupos.com/webinvu/Hardware%20details/Cover%20woman%20pos.png"
          className="h-[400px] mx-auto xl:mr-32 md:max-w-sm lg:-mt-0 -mt-12"
        />
      </div>
    </div>
    </div>
    </section>

<div class="lg:max-w-screen-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-6">
    <div class="group flex flex-col justify-center bg-gray-50 rounded-xl p-4 md:p-7">
      <div class="flex justify-center items-center w-14 h-14 bg-indigo-600 rounded-xl">
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-devices" width={36} height={36} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M13 9a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-10z" />
        <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
        <path d="M16 9h2" />
      </svg>
      </div>
      <div class="mt-5">
        <h3 class="text-lg font-semibold text-gray-800">{t("hardwareequipmentitem1title")}</h3>
        <p class="mt-1 text-gray-600">{t("hardwareequipmentitem1description")}</p>
      </div>
    </div>

    <div class="group flex flex-col justify-center bg-gray-50 rounded-xl p-4 md:p-7">
      <div class="flex justify-center items-center w-14 h-14 bg-indigo-600 rounded-xl">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-headset" width={36} height={36} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 14v-3a8 8 0 1 1 16 0v3" />
          <path d="M18 19c0 1.657 -2.686 3 -6 3" />
          <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
          <path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
        </svg>
      </div>
      <div class="mt-5">
        <h3 class="text-lg font-semibold text-gray-800">{t("hardwareequipmentitem2title")}</h3>
        <p class="mt-1 text-gray-600">{t("hardwareequipmentitem2description")}</p>
      </div>
    </div>

    <div class="group flex flex-col justify-center bg-gray-50 rounded-xl p-4 md:p-7">
      <div class="flex justify-center items-center w-14 h-14 bg-indigo-600 rounded-xl">
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-gift" width={36} height={36} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
        <path d="M12 8l0 13" />
        <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
        <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
      </svg>
      </div>
      <div class="mt-5">
        <h3 class="text-lg font-semibold text-gray-800">{t("hardwareequipmentitem3title")}</h3>
        <p class="mt-1 text-gray-600">{t("hardwareequipmentitem3description")}</p>
      </div>
    </div>


  </div>
</div>

      {/* <FAQ_model_one />
      <Help_CTA /> */}
    </>
  );
};

export default Hardware_details;
