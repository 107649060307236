import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal_started from "../Home/Modal_started";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const services = [
  {
    id: 1,
    name: "Loyalty",
    description:
      "Build your customer base, grow your regular business, and increase your customers",
    imgSrc:
      "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/productos/lealtad",
  },
  {
    id: 2,
    name: "Marketing",
    description:
      "Point of sale marketing is a type of in-store marketing campaign that takes place",
    imgSrc:
      "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/productos/marketing",
  },
  {
    id: 3,
    name: "Gift cards",
    description:
      "Drive immediate cash flow and repeat business with physical and digital gift cards",
    imgSrc:
      "https://images.unsplash.com/photo-1521685468847-de0a1a3c41a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    href: "/expertos-de-invu",
  },
  {
    id: 4,
    name: "Kitchen display system",
    description:
      "Improve order accuracy, increase efficiency, and time meals and courses perfectly",
    imgSrc: "https://img.invupos.com/webinvu/About%20us%20module%20/KDS.jpg",
    href: "/productos/kds",
  },
  {
    id: 5,
    name: "Payments",
    description:
      "Get peace of mind with a payments solution that offers transparent pricing. ",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/payments.jpg",
    href: "/pagos/general",
  },
  {
    id: 6,
    name: "E-commerce",
    description:
      "Expand your business to the whole world, sell to anyone, anywhere, anytime",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/ecommerce.jpg",
    href: "/productos/comercio-electronico",
  },
  {
    id: 7,
    name: "Point of sale",
    description:
      "Enjoy having your accounting up to date with our Invu integrations",
    imgSrc:
      "https://img.invupos.com/webinvu/About%20us%20module%20/point%20of%20sale.jpg",
    href: "/productos/punto-de-venta",
  },
  {
    id: 8,
    name: "Plus",
    description:
      "Take contactless payments, streamline your ordering, and keep service hustling.",
    imgSrc: "https://img.invupos.com/webinvu/About%20us%20module%20/plus.jpg",
    href: "/productos/plus",
  },
];

const Why_invu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <div class="relative w-full bg-pirBlack">
        <div class="">
          <div class="max-w-screen-xl px-4 py-28 mx-auto sm:px-6 lg:px-16">
            <div class="flex items-center flex-wrap gap-12 px-2 md:px-0">
              <div class="lg:w-6/12 lg:-ml-7">
                <Carousel
                  className="z-10 relative"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={5000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20hero%201.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20hero%202.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                  <div class="relative">
                    <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-40"></div>
                    <img
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652453546/Branding%20Invu%20/Hero%20/Downloader.la_-627e7095ccbcb_xqheyx.jpg"
                      alt=""
                      loading="lazy"
                      className="rounded-xl"
                    />
                  </div>
                </Carousel>
              </div>

              <div class="relative z-20 lg:w-6/12 lg:-ml-20">
                <h1 class="font-bold text-white text-5xl sm:text-6xl md:text-7xl xl:text-[76px]">
                  {t("heroaboutustitle")}{" "}
                  {/* <span class="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange">
                    mood
                  </span> */}
                  <span className="bg-gradient-clip">Invu</span>.
                </h1>
                <div class="mt-16 space-y-8 lg:ml-32">
                  <p class="text-gray-500">{t("heroaboutusdescription")}</p>
                  <div class="flex space-x-4 ">
                    {/* <button
                      type="button"
                      title="Start buying"
                      class="w-full py-3 px-6 text-center rounded-full transition duration-300 bg-gray-900 hover:bg-cyan-500 active:bg-cyan-600 focus:bg-cyan-800 sm:w-max"
                    >
                      <span class="block text-white text-sm">Shop now</span>
                    </button> */}
                    <button
                      class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
                      onClick={() => setIsOpen(true)}
                    >
                      {t("heroaboutusbutton")}
                    </button>
                    {/* <button
                      type="button"
                      title="Start buying"
                      class="w-full py-3 px-6 text-center rounded-full transition border border-gray-200 sm:w-max"
                    >
                      <span class="block text-gray-800 text-sm">
                        View store
                      </span>
                    </button> */}
                    {/* <Link
                      class="inline-block px-8 py-3 text-sm font-medium text-white border border-white rounded hover:bg-white hover:text-pirBlack active:bg-indigo-500 focus:outline-none focus:ring"
                      to={`${prefix}` + "/por-que-invu"}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Learn more
                    </Link> */}
                  </div>
                </div>
                '
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-pirDarkGray ">
        <div class="max-w-screen-xl  px-4 py-32 mx-auto  lg:px-8 ">
          <div class="flex flex-col lg:flex-row lg:space-x-12">
            <div class="order-last w-full  max-w-screen-sm m-auto mt-12 lg:w-1/4 lg:order-first">
              <div class="p-4 transition bg-pirDarkGray duration-500 ease-in-out transform bg-white border rounded-lg">
                <div class="flex py-2 mb-4 ">
                  <img
                    src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Icon%20Invu%20Circle.svg"
                    class="w-12 h-12 rounded-full"
                  />
                  <div class="ml-3 mt-2 content-center">
                    <p class="text-sm font-medium text-gray-900">Invu POS</p>
                    <p class="text-sm text-gray-500">Point of sale system</p>
                  </div>
                </div>
                <div>
                  {/* <a
                    href="#"
                    class="flex items-center px-6 py-2 mt-auto text-lg text-white transition duration-500 ease-in-out transform bg-blue-600 border border-current rounded hover:bg-blue-700 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"
                  >
                    Follow us on Twitter
                  </a> */}
                  <div class="relative mt-auto text-center w-full inline-block px-8 py-3 text-white bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange rounded group">
                    <span class="text-sm font-medium transition-opacity group-hover:opacity-0">
                      Follow On Social
                    </span>

                    <ul class="absolute inset-0 flex items-center justify-center space-x-3 transition-opacity opacity-0 group-hover:opacity-100">
                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          href="https://twitter.com/invupos?lang=es"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Twitter </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          href="https://www.instagram.com/invupos/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Instagram </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a
                          class="block transition-opacity rounded-full hover:opacity-90 focus:outline-none focus:opacity-75"
                          href="https://es-la.facebook.com/invuposofficial/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span class="sr-only"> Facebook </span>

                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full px-4 mt-12 prose lg:px-0 lg:w-3/3">
              <h1 className="text-2xl text-white font-bold md:text-5xl">
                {t("historytitle1")}
              </h1>
              <p className="mt-6 text-gray-500">{t("historyunionsub1")}</p>
              <p className="mt-2 text-gray-500">{t("historyunionsub2")}</p>
              <p className="mt-2 text-gray-500">{t("historyunionsub3")}</p>
              <p className="mt-2 text-gray-500">{t("historyunionsub4")}</p>
              <p className="mt-2 text-gray-500">{t("historyunionsub5")}</p>
              <h1 className="text-2xl mt-8 text-white font-bold md:text-5xl">
                {t("historytitle2")}
              </h1>
              <p className="mt-6 text-gray-500">{t("historysub1")}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack py-16">
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="max-w-xl mb-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="mt-16 inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                {t("featuresaboutusuppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="#fff"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-white lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="d0d83814-78b6-480f-9a5f-7f637616b267"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>{" "}
              {t("featuresaboutustitle")}
            </h2>
          </div>
        </div>
        <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-pirBlack md:px-24 lg:px-8 ">
          <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
            <div className="lg:py-6 lg:pr-16">
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border  bg-gradient-to-r  from-pirLightBlue  to-pirPurple  rounded-full">
                      {/* <svg
                        className="w-4 text-white"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                        <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    {t("feature1title")}
                  </p>
                  <p className="text-gray-500">{t("feature1description")}</p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                        <path
                          fill-rule="evenodd"
                          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    {" "}
                    {t("feature2title")}
                  </p>
                  <p className="text-gray-500">{t("feature2description")}</p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    {t("feature3title")}
                  </p>
                  <p className="text-gray-500">{t("feature3description")}</p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13 7H7v6h6V7z" />
                        <path
                          fill-rule="evenodd"
                          d="M7 2a1 1 0 012 0v1h2V2a1 1 0 112 0v1h2a2 2 0 012 2v2h1a1 1 0 110 2h-1v2h1a1 1 0 110 2h-1v2a2 2 0 01-2 2h-2v1a1 1 0 11-2 0v-1H9v1a1 1 0 11-2 0v-1H5a2 2 0 01-2-2v-2H2a1 1 0 110-2h1V9H2a1 1 0 010-2h1V5a2 2 0 012-2h2V2zM5 5h10v10H5V5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-white" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold text-white">
                    {t("feature4title")}
                  </p>
                  <p className="text-gray-500">{t("feature4description")}</p>
                </div>
              </div>
              <div className="flex ">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border bg-gradient-to-r from-pirLightBlue  to-pirPurple  rounded-full">
                      <svg
                        className="w-6 text-white"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <polyline
                          fill="none"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          points="6,12 10,16 18,8"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="pt-1">
                  <p className="mb-2 text-lg font-bold text-white">
                    {t("feature5title")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <img
                className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
                src="https://img.invupos.com/webinvu/About%20us%20module%20/about%20us%20section%201.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div class="py-16 bg-pirDarkGray">
        <div class="container m-auto px-6 space-y-8 text-gray-500 md:px-12 lg:px-20">
          <div class="flex items-center justify-center -space-x-2">
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.pexels.com/photos/3760953/pexels-photo-3760953.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="member photo"
              class="w-8 h-8 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt="member photo"
              class="w-12 h-12 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
              alt="member photo"
              class="z-10 w-16 h-16 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt="member photo"
              class="relative w-12 h-12 object-cover rounded-full"
            />
            <img
              loading="lazy"
              width="220"
              height="220"
              src="https://images.pexels.com/photos/3760953/pexels-photo-3760953.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="member photo"
              class="w-8 h-8 object-cover rounded-full"
            />
          </div>
          <div class="m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <h1 class="text-4xl text-white font-bold text-center md:text-5xl">
              {t("aboutusctatitle")}
            </h1>
            <p class="text-xl text-center">{t("aboutusctadescription")}</p>
            <div class="flex flex-wrap justify-center gap-6">
              <button
                class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                {t("aboutusctabutton")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="py-16 bg-pirBlack">
        <div class="max-w-screen-xl px-4 py-28 mx-auto sm:px-6 lg:px-16">
          {/* <div class="mb-12 space-y-2 text-center">
            <span class="block w-max mx-auto px-3 py-1.5 border border-yellow-200 rounded-full bg-yellow-100 text-yellow-700">
              Blog
            </span>
            <h2 class="text-2xl text-yellow-900 font-bold md:text-4xl">
              Sharing is Caring
            </h2>
            <p class="lg:w-6/12 lg:mx-auto">
              Quam hic dolore cumque voluptate rerum beatae et quae, tempore
              sunt, debitis dolorum officia aliquid explicabo? Excepturi,
              voluptate?
            </p>
          </div> */}

          <div className="max-w-xl mb-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="mt-16 inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                {t("aboutusproductsuppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="#fff"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-white lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="d0d83814-78b6-480f-9a5f-7f637616b267"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>{" "}
              {t("aboutusproductstitle")}
            </h2>
          </div>

          <div class="grid gap-6  sm:grid-cols-2 lg:grid-cols-4 ">
            {/* {services.map((service) => (
              <Link
                to={`${prefix}` + service.href}
                className="mt-6"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
                  <div class="relative">
                    <div
                      aria-hidden="true"
                      class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                    ></div>
                    <img
                      src={service.imgSrc}
                      alt="art cover"
                      loading="lazy"
                      width="1000"
                      height="667"
                      class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                    />
                  </div>
                  <div class="relative space-y-4 -mt-20 p-4">
                    <h4 class="text-xl font-semibold text-white">
                      {service.name}
                    </h4>
                    <p class="text-gray-400">{service.description}</p>
                  </div>
                </div>
              </Link>
            ))} */}
            {/* Card */}
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://images.pexels.com/photos/6612709/pexels-photo-6612709.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct1title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct1description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct2title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct2description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://images.unsplash.com/photo-1521685468847-de0a1a3c41a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct3title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct3description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://img.invupos.com/webinvu/About%20us%20module%20/KDS.jpg"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct4title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct4description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://img.invupos.com/webinvu/About%20us%20module%20/payments.jpg"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct5title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct5description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://img.invupos.com/webinvu/About%20us%20module%20/ecommerce.jpg"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct6title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct6description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://img.invupos.com/webinvu/About%20us%20module%20/point%20of%20sale.jpg"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct7title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct7description")}</p>
              </div>
            </div>
            <div class="relative p-1 rounded-xl  border border-gray-700 group bg-pirDarkGray  shadow-xl hover:rounded-2xl">
              <div class="relative">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-pirDarkGray"
                ></div>
                <img
                  src="https://img.invupos.com/webinvu/About%20us%20module%20/plus.jpg"
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  class="h-64 w-full object-cover object-top rounded-t-lg transition duration-500 group-hover:rounded-t-xl"
                />
              </div>
              <div class="relative space-y-4 -mt-20 p-4">
                <h4 class="text-xl font-semibold text-white">
                  {t("aboutusproduct8title")}
                </h4>
                <p class="text-gray-400">{t("aboutusproduct8description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-pirBlack">
        <div class="">
          <div class="container m-auto px-8 py-20 md:px-12 lg:px-20">
            <div class="mt-12 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
              <div class="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"
                ></div>
                <div class="relative p-6 space-y-6 lg:p-8">
                  <h3 class="text-3xl text-gray-700 font-semibold text-center">
                    {t("aboutusspecialoffertitle")}
                  </h3>
                  <div>
                    <div class="relative flex justify-around">
                      <div class="flex items-end">
                        <span class="text-8xl text-gray-800 font-bold leading-0">
                          25
                        </span>
                        <div class="pb-2">
                          <span class="block text-2xl text-gray-700 font-bold">
                            %
                          </span>
                          <span class="block text-xl text-indigo-600 font-bold">
                            {t("aboutusspecialofferoff")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex  justify-center">
                      <span class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                        {t("aboutusspecialofferoffspan1")}
                      </span>
                    </div>
                  </div>
                  <ul
                    role="list"
                    class="w-max space-y-4 py-6 m-auto text-gray-600"
                  >
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem1")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem2")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem3")}</span>
                    </li>
                  </ul>
                  <p class="flex items-center justify-center space-x-4 text-lg text-gray-600 text-center">
                    <span>{t("aboutusspecialofferspancontact")}</span>
                    <a
                      href="tel:+24300"
                      class="flex space-x-2 items-center text-indigo-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="w-6"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                      <span class="font-semibold">+507 201-8490</span>
                    </a>
                    <span>{t("aboutusspecialofferspancontact1")}</span>
                  </p>
                  <button
                    onClick={() => setIsOpen(true)}
                    class="block w-full py-3 px-6 text-center rounded transition bg-indigo-600 hover:bg-indigo-600 active:bg-purple-800 focus:bg-indigo-600"
                  >
                    <span class="text-white font-semibold">
                      {t("aboutusspecialoffercontactbutton")}
                    </span>
                  </button>
                </div>
              </div>

              <div class="relative group md:w-6/12 lg:w-7/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-lg transition duration-500 group-hover:scale-105"
                ></div>
                <div class="relative p-6 pt-16 md:p-8 md:pl-12 md:rounded-r-2xl lg:pl-20 lg:p-16">
                  <ul role="list" class="space-y-4 py-6 text-gray-600">
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature1")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature2")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature3")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-indigo-600 font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature4")}</span>
                    </li>
                  </ul>
                  <p class="text-gray-600 text-sm">
                    {t("aboutusspecialofferdescription")}
                  </p>
                  <div class="mt-6 flex justify-between gap-6">
                    <img
                      class="w-16 lg:w-24"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/Little_caesars_e0f0gu.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-6 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/cinnabon_1_fqrx8o.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-8 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/KrispyKreme_1_fqcaqz.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-20 lg:w-28"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/YogenFruz_1_1_tph8r7.svg"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-20 mx-auto sm:px-6 lg:px-20 ">
          <div className="lg:flex items-center justify-between">
            <div className=" lg:w-1/2 w-full">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
                {t("aboutuspricinguppercase")}
              </h2>
              <h1
                role="heading"
                className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-white"
              >
                {t("aboutuspricingtitle")}
              </h1>
              <p
                role="contentinfo"
                className="text-base leading-5 mt-5 text-gray-500"
              >
                {t("aboutuspricingdescription")}
              </p>
              <div className="mt-6">
                <Link
                  class="inline-block px-8 py-3 text-sm font-medium text-pirBlack bg-white border border-white rounded active:text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring"
                  to={`${prefix}` + "/recursos/precios"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("aboutuspricingbutton")}
                </Link>
              </div>
            </div>
            <div
              className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8"
              role="list"
            >
              <img
                src="https://i.ibb.co/0n6DSS3/bgimg.png"
                className="absolute w-full -ml-12 mt-24"
                alt="background circle images"
              />
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30"
              >
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    {t("aboutuspricing1title")}
                  </h2>
                  <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800">
                    $89 {""}
                    <span className="font-normal text-base">
                      / {t("pricingmodulemonth")}
                    </span>
                  </p>
                </div>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  {t("aboutuspricing1description")}
                </p>
              </div>
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30"
              >
                <div className="w-2.5  h-auto bg-indigo-700 rounded-tl-md rounded-bl-md" />
                <div className="w-full p-8">
                  <div className="md:flex items-center justify-between">
                    <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                      {t("aboutuspricing2title")}
                    </h2>
                    <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-indigo-600">
                      $49 {""}
                      <span className="font-normal text-base">
                        / {t("pricingmodulemonth")}
                      </span>
                    </p>
                  </div>
                  <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                    {t("aboutuspricing2description")}
                  </p>
                </div>
              </div>
              <div
                role="listitem"
                className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7"
              >
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    {t("aboutuspricing3title")}
                  </h2>
                  <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800">
                    $49 {""}
                    <span className="font-normal text-base">
                      / {t("pricingmodulemonth")}
                    </span>
                  </p>
                </div>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  {t("aboutuspricing3description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-pirDarkGray">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
              <a className="mb-6 sm:mx-auto">
                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-teal-accent-400">
                  <svg
                    class="text-white w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    ></path>
                  </svg>
                </div>
              </a>
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                  <span className="relative inline-block">
                    <svg
                      viewBox="0 0 52 24"
                      fill="currentColor"
                      className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                    >
                      <defs>
                        <pattern
                          id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                          x="0"
                          y="0"
                          width=".135"
                          height=".30"
                        >
                          <circle cx="1" cy="1" r=".7" />
                        </pattern>
                      </defs>
                      <rect
                        fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                        width="52"
                        height="24"
                      />
                    </svg>
                  </span>{" "}
                  {t("aboutuscta2title")}
                </h2>
                <p className="text-base text-indigo-100 md:text-lg">
                  {t("aboutuscta2description")}
                </p>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center h-12 px-6 font-semibold tracking-wide text-black transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-white hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none"
                >
                  {t("aboutuscta2button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Why_invu;
