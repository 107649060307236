import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useLocalePrefix from "../utils/useLocalePrefix";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";

const Demo_confirmed = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const locationState = location.state || {};

  const {
    firstName,
    lastName,
    email,
    phone,
    name,
    message,
    leadStatus,
    registrationId,
    hutkValue,
  } = locationState;

  const [isOptionOther, setIsOptionOther] = useState(false);
  const [isOptionOtherSelected, setIsOptionOtherSelected] = useState(false);

  const [estoybuscando, setEstoyBuscando] = useState("");
  const [tipoindustria, setTipoIndustria] = useState("");

  const [tiporestaurante, setTipoRestaurante] = useState("");
  const [tipominorista, setTipoMinorista] = useState("");
  const [tipoentretenimiento, setTipoEntretenimiento] = useState("");
  const [tipohospitalidadturismo, setTipoHospitalidadTurismo] = useState("");
  const [tiposerviciosprofesionales, setTipoServiciosProfesionales] = useState("");

  const [lastSelectedOption, setLastSelectedOption] = useState("");
const [lastSelectedOptionName, setLastSelectedOptionName] = useState("")


  const [cantidadcolaboradores, setCantidadColaboradores] = useState("");
  const [estoyinteresadoen, setEstoyInteresadoEn] = useState("");

  const [ocupacion, setOcupacion] = useState("");

  const prefix = useLocalePrefix();

  // Step 1 Function Data
  const handleOptionChangeOne = (value) => {
    setEstoyBuscando(value);
  };

  // Step 2 Function Data
  const handleOptionChangeTwo = (value) => {
    setIsOptionOtherSelected(value === "Otros");
    setTipoIndustria(value);

    // Verificar si la opción seleccionada es "Otros"
    setIsOptionOther(value === "Otros");
  };


// Agregar el useEffect para manejar los cambios en isOptionOtherSelected
useEffect(() => {
  if (isOptionOtherSelected) {
    setLastSelectedOption("");
    setLastSelectedOptionName("");
  }
}, [isOptionOtherSelected]);

  const handleOptionChange = (value, name) => {
    if (isOptionOtherSelected) {
      setLastSelectedOption("");
      setLastSelectedOptionName("");
    } else {
      setLastSelectedOption(value);
      setLastSelectedOptionName(name);
    }
  };

   // Step 4 Function Data
  const handleOptionChangeEight = (value) => {
    setCantidadColaboradores(value);
  };

  // Step 5 Function Data
  const handleOptionChangeNine = (value) => {
    if (estoyinteresadoen.includes(value)) {
      // Si la opción ya está seleccionada, la eliminamos de la cadena
      const updatedInterested = estoyinteresadoen
        .split(" , ")
        .filter((option) => option !== value)
        .join(" , ");
      setEstoyInteresadoEn(updatedInterested);
    } else {
      // Si la opción no está seleccionada, la agregamos a la cadena separada por guiones
      setEstoyInteresadoEn(estoyinteresadoen + (estoyinteresadoen ? " , " : "") + value);
    }
  };

  // Step 6 Function Data
  const handleOptionChangeTen = (value) => {
    setOcupacion(value);
  };

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
      })
      .catch((error) => {
        console.log(error);
      })
  };
  
  useEffect(() => {
    getGeoInfo();
  }, []);

  const handleSecondFormSubmit = (evt) => {
    evt.preventDefault();

    if (!validateStep(step)) {
      return;
    }

    if (step === 6 && !validateAllFields()) {
      return;
    }

    const xhr = new XMLHttpRequest();
    const url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/be316fe8-ea4c-4aa2-ad2a-b5750f006e01";

    const data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "name",
          value: name,
          objectTypeId: "COMPANY",
        },
        {
          name: "message",
          value: message,
        },
        {
          name: "hs_lead_status",
          value: leadStatus,
          objectTypeId: "CONTACT",
        },
        {
          name: "estoy_buscando___",
          value: estoybuscando,
        },
        {
          name: "tipo_de_industria",
          value: tipoindustria,
          objectTypeId: "COMPANY",
        },
        {
          name: lastSelectedOptionName, // Usa el nombre de la última opción seleccionada
          value: lastSelectedOption, // Usa el valor de la última opción seleccionada
          objectTypeId: "COMPANY",
        },
        {
          name: "cantidad_de_colaboradores",
          value: cantidadcolaboradores,
          objectTypeId: "COMPANY",
        },
        {
          name: "estoy_interesado_en",
          value: estoyinteresadoen,
        },
        {
          name: "cual_es_su_ocupacion",
          value: ocupacion
        }
      ],
      context: {
        hutk: hutkValue,
        ipAddress: geoInfo.ip,
        pageUri: "www.invupos.com",
        pageName: "formulario de contacto invu pos"
      },
    };

    const final_data = JSON.stringify(data);

    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        history.push(`${prefix}/thanks-for-sending-message`);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };

    xhr.send(final_data);
  };

  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const nextStep = () => {
    if (validateStep(step)) {
      if (step === 2 && isOptionOther) {
        setStep(4); // Redirigir al "step 4" si la opción es "Otros"
      } else {
        setStep(step + 1);
      }
    }
  };


  const prevStep = () => {
    if (step === 4 && isOptionOther) {
      setStep(2); // Regresar al "step 2" si la opción es "Otros" en el "step 4"
    } else {
      setStep(step - 1);
    }
  };


  const validateStep = (step) => {
    switch (step) {
      case 1:
        if (!estoybuscando) {
          setErrors({ estoybuscando: "Este campo es obligatorio" });
          return false;
        }
        break;
      case 2:
        if (!tipoindustria) {
          setErrors({ tipoindustria: "Este campo es obligatorio" });
          return false;
        }
        break;
        case 3:
          if (tipoindustria !== "Otros") {
            // Validar lastSelectedOption o lastSelectedOptionName si es necesario
            if (!lastSelectedOption || !lastSelectedOptionName) {
              // Muestra un mensaje de error o realiza alguna acción apropiada
              setErrors({ lastSelectedOption: "Este campo es obligatorio" });
              return false;
            }
          }
          break;
      case 4:
        if (!cantidadcolaboradores) {
          setErrors({ cantidadcolaboradores: "Este campo es obligatorio" });
          return false;
        }
        break;
      case 5:
        if (!estoyinteresadoen) {
          setErrors({ estoyinteresadoen: "Este campo es obligatorio" });
          return false;
        }
        break;
      case 6:
        if (!ocupacion) {
          setErrors({ ocupacion: "Este campo es obligatorio" });
          return false;
        }
        break;
      default:
        return true;
    }
    setErrors({});
    return true;
  };
  

  const validateAllFields = () => {
    if (
      !estoybuscando ||
      !tipoindustria ||
      (!isOptionOtherSelected && (!lastSelectedOption || !lastSelectedOptionName)) || // Validar solo si no es "Otros"
      !cantidadcolaboradores ||
      !estoyinteresadoen ||
      !ocupacion
    ) {
      setErrors({
        estoybuscando: "Este campo es obligatorio",
        tipoindustria: "Este campo es obligatorio",
        lastSelectedOption: "Este campo es obligatorio",
        lastSelectedOptionName: "Este campo es obligatorio",
        cantidadcolaboradores: "Este campo es obligatorio",
        estoyinteresadoen: "Este campo es obligatorio",
        ocupacion: "Este campo es obligatorio",
      });
      return false;
    }
  
    setErrors({});
    return true;
  };

  return (
    <>
      <div
        class="relative z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <section class="min-h-screen  bg-white lg:flex">
            <div class="flex flex-col justify-end px-8 bg-gradient-to-b from-pirLightBlue to-pirPurple lg:px-12 xl:px-24 lg:w-1/2">
              <div className="hidden lg:block">
                <div>
                  <p className="inline-block px-3 py-1 mb-3 text-xs  mx-auto font-bold tracking-wider uppercase rounded-full bg-#2A72FF border-white border">
                    <span className="text-white">Invu POS</span>
                  </p>
                  <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-white sm:text-[43px] sm:leading-none">
                    {t("titledemoconfirmed")}
                  </h2>
                  <p className="pr-5 mb-2 text-md text-white opacity-90">
                    {t("descriptiondemoconfirmed")}{" "}
                    <span className="font-semibold italic">
                      {t("descriptiondemoconfirmedspan")}
                    </span>
                  </p>
                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="self-end "
                      src="https://img.invupos.com/webinvu/confirmed%20demo/Hero-confirmed-1.webp"
                      alt="Ecommerce Hero"
                      loading="lazy"
                      style={{ width: "540px", height: "auto" }}
                    />
                  </div>
                  <div>
                    <img
                      className="self-end "
                      src="https://img.invupos.com/webinvu/confirmed%20demo/Hero%20confirmed%204.png"
                      alt="Ecommerce Hero"
                      loading="lazy"
                      style={{ width: "540px", height: "auto" }}
                    />
                  </div>
                </Carousel>
              </div>
            </div>

            <div class="flex flex-col justify-center w-full p-5 pt-0 lg:w-1/2 lg:px-12 xl:px-12 bg-[url('https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Bg-abstract-form%281%29.png')]">
              <div className="mb-14 mt-10 lg:mt-0 ">
                <img
                  className="h-[65px] w-auto"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                  alt=""
                />
              </div>
              
              <form onSubmit={handleSecondFormSubmit}>
                {step === 1 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                      {t("questiondemoconfirmed1")}
                    </label>
                    <div className="mt-1">
                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                        <div>
                          <input
                            type="radio"
                            name="EstoyBuscandoOption"
                            value="Abrir mi primer negocio"
                            id="EstoyBuscandoOption1"
                            className="peer hidden"
                            checked={
                              estoybuscando === "Abrir mi primer negocio"
                            }
                            onChange={() =>
                              handleOptionChangeOne(
                                "Abrir mi primer negocio",
                              )
                            }
                          />

                          <label
                            htmlFor="EstoyBuscandoOption1"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Describe%201%20-%20Icon%281%29.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                             {t("answerlookingdemoconfirmedoption1")}
                            </p>
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            name="EstoyBuscandoOption"
                            value="Abrir una nueva sucursal"
                            id="EstoyBuscandoOption2"
                            className="peer hidden"
                            checked={estoybuscando === "Abrir una nueva sucursal"}
                            onChange={() =>
                              handleOptionChangeOne("Abrir una nueva sucursal")
                            }
                          />
                          <label
                            htmlFor="EstoyBuscandoOption2"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/step%20one%20icons/Describe%203%20-%20Icon.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerlookingdemoconfirmedoption2")}
                            </p>
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            name="EstoyBuscandoOption"
                            value="Crecer mi negocio"
                            id="EstoyBuscandoOption3"
                            className="peer hidden"
                            checked={
                              estoybuscando === "Crecer mi negocio"
                            }
                            onChange={() =>
                              handleOptionChangeOne(
                                "Crecer mi negocio",
                              )
                            }
                          />
                          <label
                            htmlFor="EstoyBuscandoOption3"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/growth%20icon.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerlookingdemoconfirmedoption3")}
                            </p>
                          </label>
                        </div>
                      </div>

                      {errors.estoybuscando && (
                        <p className="text-red-500 text-sm">{errors.estoybuscando}</p>
                      )}
                      
                    </div>
                    <button
                      type="button"
                      onClick={nextStep}
                      className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    >
                     {t("buttonnextdemoconfirmed")}
                    </button>
                  </div>
                )}





                {/* STEP 2- TIPOS DE INDUSTRIAS */}
                {/*  
                    Alimentos y Bebidas
                    Minoristas
                    Entretenimiento
                    Hospitalidad y Turismo
                    Servicios Profesionales
                    Otros
                */}

                {step === 2 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                      {t("questiondemoconfirmed2")}
                    </label>
                    <div className="mt-1">
                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                        {/* Alimentos y Bebidas */}
                        <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Alimentos y bebidas"
                            id="TipoIndustriaOption1"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Alimentos y bebidas"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Alimentos y bebidas",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption1"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Alimentos%20y%20bebidas%20Icon.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption1")}
                            </p>
                          </label>
                        </div>

                         {/* Minoristas */}
                         <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Minoristas"
                            id="TipoIndustriaOption2"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Minoristas"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Minoristas",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption2"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Minoristas%20icon.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption2")}
                            </p>
                          </label>
                        </div>

                          {/* Entretenimiento */}
                          <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Entretenimiento"
                            id="TipoIndustriaOption3"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Entretenimiento"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Entretenimiento",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption3"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Entretenimiento.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption3")}
                            </p>
                          </label>
                        </div>

                        {/* Hospitalidad y Turismo */}
                        <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Hospitalidad y turismo"
                            id="TipoIndustriaOption4"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Hospitalidad y turismo"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Hospitalidad y turismo",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption4"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Hospitalidad.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption4")}
                            </p>
                          </label>
                        </div>

                        {/* Servicios Profesionales */}
                        <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Servicios profesionales"
                            id="TipoIndustriaOption5"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Servicios profesionales"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Servicios profesionales",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption5"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Servicios%20profesionales.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption5")}
                            </p>
                          </label>
                        </div>

                         {/* Otros */}
                         <div>
                          <input
                            type="radio"
                            name="TipoIndustriaOption"
                            value="Otros"
                            id="TipoIndustriaOption6"
                            className="peer hidden"
                            checked={
                              tipoindustria === "Otros"
                            }
                            onChange={() =>
                              handleOptionChangeTwo(
                                "Otros",
                              )
                            }
                          />

                          <label
                            htmlFor="TipoIndustriaOption6"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answertypeindustrydemoconfirmedoption6")}
                            </p>
                          </label>
                        </div>
                      </div>
                      {errors.tipoindustria && (
                        <p className="text-red-500 text-sm">{errors.tipoindustria}</p>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <button
                        type="button"
                        onClick={prevStep}
                        className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonbackdemoconfirmed")}
                      </button>
                      <button
                        type="button"
                        onClick={nextStep}
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                      {t("buttonnextdemoconfirmed")}
                      </button>
                    </div>
                  </div>
                )}




                
                 {/* STEP 3- SELECT ANINADO CON TIPOS DE INDUSTRIAS  */}

                {step === 3 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                      {tipoindustria}
                    </label>

                    {tipoindustria === "Alimentos y bebidas" && 
                      <div className="mt-1">
                        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-6">
                          {/* Alta Cocina */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Alta cocina"
                              id="TipoRestauranteOption1"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Alta cocina"
                              }
                              onChange={() => handleOptionChange("Alta cocina", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption1"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Alta%20cocina%281%29.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption1")}
                              </p>
                            </label>
                          </div>
                          {/* Servicio completo informal */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Servicio completo informal"
                              id="TipoRestauranteOption2"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Servicio completo informal"
                              }
                              onChange={() => handleOptionChange("Servicio completo informal", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption2"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20Completo%20Informal.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption2")}
                              </p>
                            </label>
                          </div>
                          {/* Servicio rápido informal */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Servicio rápido informal"
                              id="TipoRestauranteOption3"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Servicio rápido informal"
                              }
                              onChange={() => handleOptionChange("Servicio rápido informal", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption3"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20rapido%20informal.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption3")}
                              </p>
                            </label>
                          </div>
                          {/* Un camión de comida */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Un camión de comida"
                              id="TipoRestauranteOption4"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Un camión de comida"
                              }
                              onChange={() => handleOptionChange("Un camión de comida", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption4"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Food%20truckk.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption4")}
                              </p>
                            </label>
                          </div>
                          {/* Una cafetería o panadería */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Una cafetería o panadería"
                              id="TipoRestauranteOption5"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Una cafetería o panadería"
                              }
                              onChange={() => handleOptionChange("Una cafetería o panadería", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption5"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Bakeryy.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption5")}
                              </p>
                            </label>
                          </div>
                          {/* Un bar o discoteca */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Un bar o discoteca"
                              id="TipoRestauranteOption6"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Un bar o discoteca"
                              }
                              onChange={() => handleOptionChange("Un bar o discoteca", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption6"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Bar%20pubs.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption6")}
                              </p>
                            </label>
                          </div>
                          {/* Algo más */}
                          <div>
                            <input
                              type="radio"
                              name="TipoRestauranteOption"
                              value="Algo más... "
                              id="TipoRestauranteOption7"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Algo más... "
                              }
                              onChange={() => handleOptionChange("Algo más... ", "tipo_de_restaurante")}
                            />

                            <label
                              htmlFor="TipoRestauranteOption7"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answeraybdemoconfirmedoption7")}
                              </p>
                            </label>
                          </div>
                        </div>
                      {errors.lastSelectedOption && (
                        <p className="text-red-500 text-sm">{errors.lastSelectedOption}</p>
                      )}
                      {errors.lastSelectedOptionName && (
                        <p className="text-red-500 text-sm">{errors.lastSelectedOptionName}</p>
                      )}
                      </div>
                    }

                    {tipoindustria === "Minoristas" && 
                      <div className="mt-1">
                        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-6">
                          {/* Tiendas de conveniencia */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Tiendas de conveniencia"
                              id="TipoMinoristaOption1"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Tiendas de conveniencia"
                              }
                              onChange={() => handleOptionChange("Tiendas de conveniencia", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption1"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Tienda%20de%20conveniencia.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption1")}
                              </p>
                            </label>
                          </div>
                          {/* Grandes Almacenes */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Grandes almacenes"
                              id="TipoMinoristaOption2"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Grandes almacenes"
                              }
                              onChange={() => handleOptionChange("Grandes almacenes", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption2"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Grandes%20almacenes.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption2")}
                              </p>
                            </label>
                          </div>
                          {/* Tiendas de Descuento */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Tiendas de descuento"
                              id="TipoMinoristaOption3"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Tiendas de descuento"
                              }
                              onChange={() => handleOptionChange("Tiendas de descuento", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption3"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Tiendas%20de%20descuento.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption3")}
                              </p>
                            </label>
                          </div>
                          {/* Supermercados y Tiendas de Comestibles */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Supermercados y tiendas de comestibles"
                              id="TipoMinoristaOption4"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Supermercados y tiendas de comestibles"
                              }
                              onChange={() => handleOptionChange("Supermercados y tiendas de comestibles", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption4"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Supermercados.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption4")}
                              </p>
                            </label>
                          </div>
                          {/* Tiendas Especializadas o de Nicho */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Tiendas especializadas o de Nicho"
                              id="TipoMinoristaOption5"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Tiendas especializadas o de Nicho"
                              }
                              onChange={() => handleOptionChange("Tiendas especializadas o de Nicho", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption5"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Tiendas%20de%20nicho.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption5")}
                              </p>
                            </label>
                          </div>
                          {/* Tiendas en Línea o E-commerce */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Tiendas en línea o e-commerce"
                              id="TipoMinoristaOption6"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Tiendas en línea o e-commerce"
                              }
                              onChange={() => handleOptionChange("Tiendas en línea o e-commerce", "tipo_de_minorista")}
                            />

                            <label
                              htmlFor="TipoMinoristaOption6"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Ecommerce.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption6")}
                              </p>
                            </label>
                          </div>
                          {/* Algo más */}
                          <div>
                            <input
                              type="radio"
                              name="TipoMinoristaOption"
                              value="Algo más...  "
                              id="TipoMinoristaOption7"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Algo más...  "
                              }
                              onChange={() => handleOptionChange("Algo más...  ", "tipo_de_minorista")}
                            />
                            <label
                              htmlFor="TipoMinoristaOption7"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerretailerdemoconfirmedoption7")}
                              </p>
                            </label>
                          </div>
                        </div>
                        {errors.lastSelectedOption && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOption}</p>
                        )}
                        {errors.lastSelectedOptionName && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOptionName}</p>
                        )}
                      </div>
                    }

                    {/* Tipo de industria - ENTRETENIMIENTO */}
                    {tipoindustria === "Entretenimiento" && 
                      <div className="mt-1">
                        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-6">
                          {/* Cine */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Cine"
                              id="TipoEntretenimientoOption1"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Cine"
                              }
                              onChange={() => handleOptionChange("Cine", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption1"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Cine.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption1")}
                              </p>
                            </label>
                          </div>
                          {/* Parques de atracciones */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Parques de atracciones"
                              id="TipoEntretenimientoOption2"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Parques de atracciones"
                              }
                              onChange={() => handleOptionChange("Parques de atracciones", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption2"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Parque%20de%20atracciones.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption2")}
                              </p>
                            </label>
                          </div>
                          {/* Clubes Nocturnos */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Clubes nocturnos"
                              id="TipoEntretenimientoOption3"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Clubes nocturnos"
                              }
                              onChange={() => handleOptionChange("Clubes nocturnos", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption3"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Clubes%20nocturnos.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption3")}
                              </p>
                            </label>
                          </div>
                          {/* Teatros */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Teatros"
                              id="TipoEntretenimientoOption4"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Teatros"
                              }
                              onChange={() => handleOptionChange("Teatros", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption4"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Teatros.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption4")}
                              </p>
                            </label>
                          </div>
                          {/* Casinos */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Casinos"
                              id="TipoEntretenimientoOption5"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Casinos"
                              }
                              onChange={() => handleOptionChange("Casinos", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption5"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Casinos.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption5")}
                              </p>
                            </label>
                          </div>
                          {/* Canchas Deportivas */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Canchas deportivas"
                              id="TipoEntretenimientoOption6"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Canchas deportivas"
                              }
                              onChange={() => handleOptionChange("Canchas deportivas", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption6"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Canchas.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption6")}
                              </p>
                            </label>
                          </div>
                          {/* Algo más */}
                          <div>
                            <input
                              type="radio"
                              name="TipoEntretenimientoOption"
                              value="Algo más...   "
                              id="TipoEntretenimientoOption7"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Algo más...   "
                              }
                              onChange={() => handleOptionChange("Algo más...   ", "tipo_entretenimiento")}
                            />

                            <label
                              htmlFor="TipoEntretenimientoOption7"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerentertaindemoconfirmedoption7")}
                              </p>
                            </label>
                          </div>
                        </div>
                        {errors.lastSelectedOption && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOption}</p>
                        )}
                        {errors.lastSelectedOptionName && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOptionName}</p>
                        )}
                      </div>
                    } 


                    {/* Tipo de industria - HOSPITALIDAD Y TURISMO  */}
                    {tipoindustria === "Hospitalidad y turismo" && 
                      <div className="mt-1">
                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                          {/* Hoteles y Moteles */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Hoteles y moteles"
                              id="TipoHospitalidadOption1"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Hoteles y moteles"
                              }
                              onChange={() => handleOptionChange("Hoteles y moteles", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption1"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Hoteles%20y%20moteles.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption1")}
                              </p>
                            </label>
                          </div>
                          {/* Restaurantes y Cafeterías */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Restaurantes y cafeterías"
                              id="TipoHospitalidadOption2"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Restaurantes y cafeterías"
                              }
                              onChange={() => handleOptionChange("Restaurantes y cafeterías", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption2"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Restaurantes%20y%20cafeterias.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption2")}
                              </p>
                            </label>
                          </div>

                          {/* Agencias de Viajes */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Agencias de viajes"
                              id="TipoHospitalidadOption3"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Agencias de viajes"
                              }
                              onChange={() => handleOptionChange("Agencias de viajes", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption3"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Agencias%20de%20viajes.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption3")}
                              </p>
                            </label>
                          </div>

                          {/* Operadores de Tours */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Operadores de tours"
                              id="TipoHospitalidadOption4"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Operadores de tours"
                              }
                              onChange={() => handleOptionChange("Operadores de tours", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption4"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Operadores%20de%20tours.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption4")}
                              </p>
                            </label>
                          </div>

                          {/* Líneas de Cruceros */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Líneas de cruceros"
                              id="TipoHospitalidadOption5"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Líneas de cruceros"
                              }
                              onChange={() => handleOptionChange("Líneas de cruceros", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption5"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Lineas%20de%20crucero.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption5")}
                              </p>
                            </label>
                          </div>

                          {/* Algo más */}
                          <div>
                            <input
                              type="radio"
                              name="TipoHospitalidadOption"
                              value="Algo más...    "
                              id="TipoHospitalidadOption6"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Algo más...    "
                              }
                              onChange={() => handleOptionChange("Algo más...    ", "tipo_de_hospitalidad_y_turismo")}
                            />

                            <label
                              htmlFor="TipoHospitalidadOption6"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerhytdemoconfirmedoption6")}
                              </p>
                            </label>
                          </div>

                        </div>
                        {errors.lastSelectedOption && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOption}</p>
                        )}
                        {errors.lastSelectedOptionName && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOptionName}</p>
                        )}
                      </div>
                    }

                    {/* servicios profesionales */}
                    {tipoindustria === "Servicios profesionales" && 
                      <div className="mt-1">
                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                          {/* Salones de Belleza y Barberías */}
                          <div>
                            <input
                              type="radio"
                              name="TipoServiciosProfesionalesOption"
                              value="Salones de belleza y barberías"
                              id="TipoServiciosProfesionalesOption1"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Salones de belleza y barberías"
                              }
                              onChange={() => handleOptionChange("Salones de belleza y barberías", "tipo_de_servicios_profesionales")}
                            />

                            <label
                              htmlFor="TipoServiciosProfesionalesOption1"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Salones%20de%20belleza.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerspdemoconfirmedoption1")}
                              </p>
                            </label>
                          </div>

                           {/* Spas */}
                           <div>
                            <input
                              type="radio"
                              name="TipoServiciosProfesionalesOption"
                              value="Spas"
                              id="TipoServiciosProfesionalesOption2"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Spas"
                              }
                              onChange={() => handleOptionChange("Spas", "tipo_de_servicios_profesionales")}
                            />

                            <label
                              htmlFor="TipoServiciosProfesionalesOption2"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Spas.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerspdemoconfirmedoption2")}
                              </p>
                            </label>
                          </div>

                          {/* Agencias de Consultoría */}
                          <div>
                            <input
                              type="radio"
                              name="TipoServiciosProfesionalesOption"
                              value="Agencias de consultoría"
                              id="TipoServiciosProfesionalesOption3"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Agencias de consultoría"
                              }
                              onChange={() => handleOptionChange("Agencias de consultoría", "tipo_de_servicios_profesionales")}
                            />

                            <label
                              htmlFor="TipoServiciosProfesionalesOption3"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Agencia%20de%20consultoria.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerspdemoconfirmedoption3")}
                              </p>
                            </label>
                          </div>

                          {/* Servicios Médicos Especializados */}
                          <div>
                            <input
                              type="radio"
                              name="TipoServiciosProfesionalesOption"
                              value="Servicios médicos especializados"
                              id="TipoServiciosProfesionalesOption4"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Servicios médicos especializados"
                              }
                              onChange={() => handleOptionChange("Servicios médicos especializados", "tipo_de_servicios_profesionales")}
                            />

                            <label
                              htmlFor="TipoServiciosProfesionalesOption4"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Servicio%20medico.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerspdemoconfirmedoption4")}
                              </p>
                            </label>
                          </div>

                          {/* Algo más */}
                          <div>
                            <input
                              type="radio"
                              name="TipoServiciosProfesionalesOption"
                              value="Algo más...     "
                              id="TipoServiciosProfesionalesOption5"
                              className="peer hidden"
                              checked={
                                lastSelectedOption === "Algo más...     "
                              }
                              onChange={() => handleOptionChange("Algo más...     ", "tipo_de_servicios_profesionales")}
                            />

                            <label
                              htmlFor="TipoServiciosProfesionalesOption5"
                              className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                            >
                              <div class="flex justify-center">
                                <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                  <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                                </div>
                              </div>
                              <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                              {t("answerspdemoconfirmedoption5")}
                              </p>
                            </label>
                          </div>

                        </div>
                        {errors.lastSelectedOption && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOption}</p>
                        )}
                        {errors.lastSelectedOptionName && (
                          <p className="text-red-500 text-sm">{errors.lastSelectedOptionName}</p>
                        )}
                      </div>
                    }



                     <div className="flex justify-between">
                     <button
                        type="button"
                        onClick={prevStep}
                        className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonbackdemoconfirmed")}
                      </button>
                      <button
                        type="button"
                        onClick={nextStep}
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                      {t("buttonnextdemoconfirmed")}
                      </button>
                    </div>
                  </div>
                )}  

                {/* STEP 4 - CANTIDAD DE COLABORADORES */}
                {/*  
                  MENOS DE 10
                  ENTRE 11 Y 50
                  MAS DE 50
                */}

                {step === 4 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                     {t("questiondemoconfirmed3")}
                    </label>

                    <div className="mt-1">
                        <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 mb-6">
                        {/* Menos de 10 */}
                        <div>
                          <input
                            type="radio"
                            name="CantidadColaboradoresOption"
                            value="Menos de 10"
                            id="CantidadColaboradoresOption1"
                            className="peer hidden"
                            checked={
                              cantidadcolaboradores === "Menos de 10"
                            }
                            onChange={() =>
                              handleOptionChangeEight(
                                "Menos de 10",
                              )
                            }
                          />

                          <label
                            htmlFor="CantidadColaboradoresOption1"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%201.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                             {t("answeremployeesdemoconfirmedoption1")}
                            </p>
                          </label>
                        </div>
                        {/* Entre 11 y 50 */}
                        <div>
                          <input
                            type="radio"
                            name="CantidadColaboradoresOption"
                            value="Entre 11 y 50"
                            id="CantidadColaboradoresOption2"
                            className="peer hidden"
                            checked={
                              cantidadcolaboradores === "Entre 11 y 50"
                            }
                            onChange={() =>
                              handleOptionChangeEight(
                                "Entre 11 y 50",
                              )
                            }
                          />

                          <label
                            htmlFor="CantidadColaboradoresOption2"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%202.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeremployeesdemoconfirmedoption2")}
                            </p>
                          </label>
                        </div>
                         {/* Más de 50 */}
                         <div>
                          <input
                            type="radio"
                            name="CantidadColaboradoresOption"
                            value="Más de 50"
                            id="CantidadColaboradoresOption3"
                            className="peer hidden"
                            checked={
                              cantidadcolaboradores === "Más de 50"
                            }
                            onChange={() =>
                              handleOptionChangeEight(
                                "Más de 50",
                              )
                            }
                          />

                          <label
                            htmlFor="CantidadColaboradoresOption3"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Empleados%203.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeremployeesdemoconfirmedoption3")}
                            </p>
                          </label>
                        </div>
                      </div>
                      {errors.cantidadcolaboradores && (
                        <p className="text-red-500 text-sm">{errors.cantidadcolaboradores}</p>
                      )}
                    </div>

                    <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={prevStep}
                        className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonbackdemoconfirmed")}
                      </button>
                      <button
                        type="button"
                        onClick={nextStep}
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                      {t("buttonnextdemoconfirmed")}
                      </button>
                    </div>
                  </div>
                )}





                {/* STEP 5 - ESTOY INTERESADO EN */}
                {/*  
                  Recibir Pagos
                  Kioscos de Autoservicio
                  Pedidos en línea y entrega a domicilio
                  Marketing por correo electrónico
                  Gestión de inventario
                  Lealtad y Fidelización de Clientes
                  Algo más
                */}

                {step === 5 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                     {t("questiondemoconfirmed4")}
                    </label>

                    <div className="mt-1">
                        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-6">
                        {/* Recibir Pagos */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Recibir pagos"
                            id="EstoyInteresadoEnOption1"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Recibir pagos")}
                            onChange={() => handleOptionChangeNine("Recibir pagos")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption1"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Recibir%20pagos.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                             {t("answerinterestedindemoconfirmedoption1")}
                            </p>
                          </label>
                        </div>

                        {/* Kioscos de Autoservicio */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Kioscos de autoservicio"
                            id="EstoyInteresadoEnOption2"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Kioscos de autoservicio")}
                            onChange={() => handleOptionChangeNine("Kioscos de autoservicio")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption2"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Kiosko%20de%20auto%20servicio.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption2")}
                            </p>
                          </label>
                        </div>

                        {/* Pedidos en línea y entrega a domicilio */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Pedidos en línea y entrega a domicilio"
                            id="EstoyInteresadoEnOption3"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Pedidos en línea y entrega a domicilio")}
                            onChange={() => handleOptionChangeNine("Pedidos en línea y entrega a domicilio")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption3"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Auto%20servicio.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption3")}
                            </p>
                          </label>
                        </div>

                        {/* Marketing por correo electrónico */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Marketing por correo electrónico"
                            id="EstoyInteresadoEnOption4"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Marketing por correo electrónico")}
                            onChange={() => handleOptionChangeNine("Marketing por correo electrónico")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption4"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Correo%20marketing.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption4")}
                            </p>
                          </label>
                        </div>

                        {/* Gestión de inventario */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Gestión de inventario"
                            id="EstoyInteresadoEnOption5"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Gestión de inventario")}
                            onChange={() => handleOptionChangeNine("Gestión de inventario")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption5"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Gestion%20de%20inventario.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption5")}
                            </p>
                          </label>
                        </div>

                        {/* Lealtad y fidelización de clientes */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Lealtad y fidelización de cliente"
                            id="EstoyInteresadoEnOption6"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Lealtad y fidelización de cliente")}
                            onChange={() => handleOptionChangeNine("Lealtad y fidelización de cliente")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption6"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Lealtad.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption6")}
                            </p>
                          </label>
                        </div>

                        {/* Algo más */}
                        <div>
                          <input
                            type="checkbox"
                            name="EstoyInteresadoEnOption"
                            value="Algo más"
                            id="EstoyInteresadoEnOption7"
                            className="peer hidden"
                            checked={estoyinteresadoen.includes("Algo más")}
                            onChange={() => handleOptionChangeNine("Algo más")}
                          />

                          <label
                            htmlFor="EstoyInteresadoEnOption7"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answerinterestedindemoconfirmedoption7")}
                            </p>
                          </label>
                        </div>

                      </div>
                      {errors.estoyinteresadoen && (
                        <p className="text-red-500 text-sm">{errors.estoyinteresadoen}</p>
                      )}
                    </div>

                    <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={prevStep}
                        className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonbackdemoconfirmed")}
                      </button>
                      <button
                        type="button"
                        onClick={nextStep}
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                      {t("buttonnextdemoconfirmed")}
                      </button>
                    </div>
                  </div>
                )}





                {/* STEP 6 - CUAL ES SU OCUPACION */}
                {/*  
                  Propietario
                  Director General
                  Consultor de "Restaurantes"
                  Operaciones
                  IT
                  Colaborador de "Restaurante"
                  Otra
                */}

                {step === 6 && (
                  <div>
                    <label className="block text-3xl font-medium text-gray-700 mb-8">
                    {t("questiondemoconfirmed5")}
                    </label>

                    <div className="mt-1">
                        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-6">
                        {/* Propietario */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Propietario"
                            id="OcupacionOption1"
                            className="peer hidden"
                            checked={
                              ocupacion === "Propietario"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Propietario",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption1"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Propietario.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                             {t("answeroccupationdemoconfirmedoption1")}
                            </p>
                          </label>
                        </div>

                        {/* Director General */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Director general"
                            id="OcupacionOption2"
                            className="peer hidden"
                            checked={
                              ocupacion === "Director general"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Director general",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption2"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Director%20general.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption2")}
                            </p>
                          </label>
                        </div>

                        {/* Consultor */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Consultor"
                            id="OcupacionOption3"
                            className="peer hidden"
                            checked={
                              ocupacion === "Consultor"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Consultor",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption3"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Consultor.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption3")}
                            </p>
                          </label>
                        </div>

                        {/* Operaciones */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Operaciones"
                            id="OcupacionOption4"
                            className="peer hidden"
                            checked={
                              ocupacion === "Operaciones"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Operaciones",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption4"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Operaciones.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption4")}
                            </p>
                          </label>
                        </div>

                        {/* IT */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="IT"
                            id="OcupacionOption5"
                            className="peer hidden"
                            checked={
                              ocupacion === "IT"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "IT",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption5"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/IT.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption5")}
                            </p>
                          </label>
                        </div>

                        {/* Colaborador */}
                        <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Colaborador"
                            id="OcupacionOption6"
                            className="peer hidden"
                            checked={
                              ocupacion === "Colaborador"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Colaborador",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption6"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Colaborador.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption6")}
                            </p>
                          </label>
                        </div>

                         {/* Otra */}
                         <div>
                          <input
                            type="radio"
                            name="OcupacionOption"
                            value="Otra"
                            id="OcupacionOption7"
                            className="peer hidden"
                            checked={
                              ocupacion === "Otra"
                            }
                            onChange={() =>
                              handleOptionChangeTen(
                                "Otra",
                              )
                            }
                          />

                          <label
                            htmlFor="OcupacionOption7"
                            className="block h-56 cursor-pointer rounded-lg border border-gray-100 bg-white p-7 text-sm font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500 hover:scale-105 transform transition hover:shadow-xl"
                          >
                            <div class="flex justify-center">
                              <div class="w-20 mb-5 h-20 rounded-full cursor-pointer flex justify-center items-center bg-sky-50">
                                <img src="https://img.invupos.com/webinvu/confirmed%20demo/Others.svg" className="w-14"/>
                              </div>
                            </div>
                            <p className="text-gray-800 text-base font-normal text-[#081131] text-center">
                            {t("answeroccupationdemoconfirmedoption7")}
                            </p>
                          </label>
                        </div>

                      </div>
                      {errors.ocupacion && (
                        <p className="text-red-500 text-sm">{errors.ocupacion}</p>
                      )}
                    </div>

                    <div className="flex justify-between">
                    <button
                        type="button"
                        onClick={prevStep}
                        className="w-full px-6 py-3 mt-4 mr-4 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonbackdemoconfirmed")}
                      </button>
                      <button
                        type="submit"
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {t("buttonsenddemoconfirmed")}
                      </button>
                    </div>
                  </div>
                )}  

                
              </form>
              <Toaster position="bottom-center" reverseOrder={false} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Demo_confirmed;