import React, { useState } from "react";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import Help_CTA from "../Home/Help_CTA";

const features = [
  {
    description:
      "Request payments over email, SMS, or via link, and track funds in real time. We secure our hardware and network end-to-end to protect payments as well as your and your clients' sensitive data",
    button: "Learn more about Invu Invoices",
    imgSrc:
      "https://images.pexels.com/photos/5810700/pexels-photo-5810700.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
    href: "/payments/risk-manage",
  },
  {
    description:
      "Collect and record remote payments on your computer. Improve your payments and your operations. Take payment in person or remotely. Invu integrates with QuickBooks and Xero.",
    button: "Learn more about Virtual Terminal  ",
    imgSrc:
      "https://images.pexels.com/photos/5239806/pexels-photo-5239806.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
    href: "/hardware-details",
  },
  {
    description:
      "Take in-person payments on your customizable POS. Our cloud-based platform is designed to scale with your business, today and tomorrow.",
    button: "Learn more about our Point of sale",
    imgSrc:
      "https://images.pexels.com/photos/6248536/pexels-photo-6248536.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
    href: "/payments/in-person-payments",
  },
  {
    description:
      "Accept payments everywhere with flexible hadware solutions. Our business consultants listen and learn your needs, then help tailor a system that will help you thrive, grow, and serve your customers.",
    button: "Learn more about Invu Payments",
    imgSrc:
      "https://images.unsplash.com/photo-1586864030223-a918b07d357d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cG9zfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    altImg: "",
    href: "/payments-overview",
  },
];

const Professional_services = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Professional Services");
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://images.unsplash.com/photo-1533777419517-3e4017e2e15a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                Professional services
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              Give your clients{""}
              <strong class="font-extrabold text-white sm:block">
                quick, easy, and secure
              </strong>
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              Invu has built a suite of integrated tools to help save you time,
              so you can focus on growing revenue and delivering exceptional
              service to your clients.
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/invu-experts"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h2 className="max-w-md mb-6 font-sans text-3xl leading-loose space-y-2 font-bold tracking-tight text-black sm:text-4xl sm:leading-none xl:max-w-lg">
            Get paid faster.
          </h2>
          <p class="mb-20 mt-2  max-w-lg text-gray-500 text-base md:text-medium">
            Give your clients quick, easy, and secure payment options in person
            and online.
          </p>
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            {features.map((feature) => (
              <div className="rounded border">
                <a className="" href={feature.href}>
                  <img
                    src={feature.imgSrc}
                    class="object-cover w-full h-72  bg-center rounded-t-lg"
                    alt=""
                  />
                </a>
                <div className="p-8">
                  <p class="mb-3 text-sm max-w-md  font-normal text-gray-500">
                    {feature.description}
                  </p>
                  <a
                    href={feature.href}
                    class="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer"
                  >
                    {feature.button} {""}
                    <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-white">
                &mdash; Services
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Payment solutions for professional services
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Whether you need a POS device or want to accept all your
                  payments virtually, Invu has easy-to-use and flexible
                  professional service credit card processing tools that let you
                  email an invoice, set up recurring payments, or accept
                  payments online.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/customer-loyalty"
              >
                <span class="text-sm font-medium"> Customer loyalty </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/3865901/pexels-photo-3865901.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font ">
                &mdash; Services
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Schedule appointments easily.
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Manage your availability, let customers book online, and send
                  automated reminders. Send an invoice or a payment request for
                  completed services by email and let your clients pay you
                  directly online.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> Contact sales </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/3928263/pexels-photo-3928263.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-white">
                &mdash; Services
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Offer your customers the best point of sale
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Automatically track customer details and analyze purchase data
                  with powerful customer management software. Accept all payment
                  types and future-proof your accounting practice. With one
                  powerful point of sale.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/point-of-sale"
              >
                <span class="text-sm font-medium">Point of sale </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/9050558/pexels-photo-9050558.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="flex flex-col mt-16 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:flex-row">
          <div className="mb-5 lg:w-1/3 lg:mb-0 lg:mr-20">
            <h2 className="relative mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              We handle different types of businesses.
            </h2>
            <p className="mb-4 text-gray-700 lg:mb-6">
              Explore how Invu POS can grow your business. We offer point of
              sale solutions for a variety of industries.
            </p>
          </div>
          <div className="flex-grow pt-1">
            <div className="flex items-center mb-3">
              <span className="font-bold tracking-wide text-gray-900">
                Categories
              </span>
              <span className="ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="#681BFE"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
            <div className="grid grid-cols-2 row-gap-6 sm:grid-cols-4">
              <ul className="space-y-2">
                <li>
                  <a className="text-gray-700">Big Enterprises</a>
                </li>
                <li>
                  <a className="text-gray-700">Coffee Shops</a>
                </li>
                <li>
                  <a className="text-gray-700">Fast Service</a>
                </li>
                <li>
                  <a className="text-gray-700">Pubs</a>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <a className="text-gray-700">Stadiums</a>
                </li>
                <li>
                  <a className="text-gray-700">Table service</a>
                </li>
                <li>
                  <a className="text-gray-700">Health & Fitness</a>
                </li>
                <li>
                  <a className="text-gray-700">Retail</a>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <a className="text-gray-700">Pets shop</a>
                </li>
                <li>
                  <a className="text-gray-700">Restaurants</a>
                </li>
                <li>
                  <a className="text-gray-700">Boutiques</a>
                </li>
                <li>
                  <a className="text-gray-700">Home</a>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <a className="text-gray-700">Beauty</a>
                </li>
                <li>
                  <a className="text-gray-700">Sports</a>
                </li>
                <li>
                  <a className="text-gray-700">Jewerly</a>
                </li>
                <li>
                  <a className="text-gray-700">Fast services</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="relative">
          <img
            className="object-cover w-full h-56 sm:h-96"
            src="https://images.pexels.com/photos/3183159/pexels-photo-3183159.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            alt=""
          />
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50" />
        </div>
      </div>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Professional_services;
