import React, { useState } from "react";
import Modal_started from "../../../components/Home/Modal_started";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";

const Loyalty_tab = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            {/* <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
            </div> */}
            <div className="max-w-xl mb-6">
              <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("loyaltytittle")}
              </h3>
              <p className="text-gray-600 leading-loose">
                {t("loyaltydescription")}
              </p>
              <div class="flex flex-col mt-3 flex-grow ">
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#FFCE00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("loyaltyitem1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#FFCE00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("loyaltyitem2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#FFCE00"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose">
                    {t("loyaltyitem3")}
                  </p>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap gap-4 text-center">
              <button
                class="inline-flex items-center justify-center  block w-full px-8 py-3 text-sm font-medium text-white rounded shadow bg-[#001489] sm:w-auto hover:bg-opacity-95  focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span className="mr-3">{t("loyaltybutton1")}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4"
                >
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    points="4,4 22,4 19,14 4,14 "
                  />
                  <circle
                    cx="4"
                    cy="22"
                    r="2"
                    strokeLinejoin="miter"
                    strokeLinecap="square"
                    stroke="none"
                    fill="currentColor"
                  />
                  <circle
                    cx="20"
                    cy="22"
                    r="2"
                    strokeLinejoin="miter"
                    strokeLinecap="square"
                    stroke="none"
                    fill="currentColor"
                  />
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    points="1,1 4,4 4,14 2,18 23,18 "
                  />
                </svg>
              </button>

              <Link
                class="block w-full px-2  py-3 text-sm font-medium bg-white rounded text-[#001489] hover:text-opacity-80 sm:w-auto active:text-indigo-500 focus:outline-none focus:ring"
                to={`${prefix}` + "/productos/lealtad"}
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("loyaltybutton2")}
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <img
              className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
              src="https://img.invupos.com/webinvu/Invu%20Products/Loyalty/Loyalty%201.jpeg"
              alt=""
              loading="lazy"
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src="https://img.invupos.com/webinvu/Invu%20Products/Loyalty/Loyalty%202.jpeg"
              alt=""
              loading="lazy"
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src="https://img.invupos.com/webinvu/Invu%20Products/Loyalty/Loyalty%203.jpeg"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loyalty_tab;
