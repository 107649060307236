import React, { useState } from "react";
import Modal_started from "../../../components/Home/Modal_started";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";

const Retail_tab = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {/* {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl mb-6">
                <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font">
                  &mdash; {t("uppercaseindustriesfocusedin")}
                </p>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                  {t("industriesretailtitle")}
                </h2>
                <p className="mt-2 max-w-lg text-sm lg:text-base text-gray-700 leading-loose">
                  {t("industriesretaildescription")}
                </p>
              </div>
              <div className="-mt-1">
                <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                  <div className="mr-2">
                    <svg
                      className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#4338CA"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                    {t("industriesretailitem1")}
                  </span>
                </div>
                <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                  <div className="mr-2">
                    <svg
                      className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#4338CA"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                    {t("industriesretailitem2")}
                  </span>
                </div>
                <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                  <div className="mr-2">
                    <svg
                      className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#4338CA"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                    {t("industriesretailitem3")}
                  </span>
                </div>
                <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                  <div className="mr-2">
                    <svg
                      className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#4338CA"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                    {t("industriesretailitem4")}
                  </span>
                </div>
                <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                  <div className="mr-2">
                    <svg
                      className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#4338CA"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-gray-600 text-sm transition-colors duration-200 group-hover:text-indigo-500">
                    {t("industriesretailitem5")}
                  </span>
                </div>
              </div>
            </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div>
              <p className="text-center mb-6 text-xs font-semibold tracking-widest text-black uppercase title-font">
                  {t("industriesourclients")}
                </p>
              <img
              className="object-cover w-full h-62 rounded shadow-lg sm:h-[500px]"
              src="https://img.invupos.com/webinvu/Industries/Clients%20cover.png"
              alt=""
              loading="lazy"
            />
            </div>
          </div>
        </div>
      </div> */}
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
        <div class="grid grid-cols-12 gap-4 mt-10">

        <div class="col-span-12 lg:col-span-4 md:col-span-5 sm:col-span-12">

        <a class="group relative flex flex-col w-full min-h-[15rem] bg-center bg-cover rounded-xl hover:shadow-xl transition bg-[#F5FCFF] border">
          
          <div class="flex-auto p-4 md:p-6">
            <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-[#ffcc00] to-[#d19500] flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-store" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 21l18 0" />
                <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                <path d="M5 21l0 -10.15" />
                <path d="M19 21l0 -10.15" />
                <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
              </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold">{t("industriesretailtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4">{t("industriesretaildescription")}</p>
          </div>
          <div class="pt-0 p-4 md:p-6">
            <Link to={`${prefix}` + "/venta-minorista/general"} onClick={() => window.scrollTo(0, 0)}>
              <div class="inline-flex items-center gap-2 font-semibold text-[#00247D] hover:text-opacity-80">
                {t("industriesbuttonexternal")}
                <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
              </div>
            </Link>
          </div>
        </a>
        </div>

        <div class="col-span-12 lg:col-span-8 md:col-span-7 sm:col-span-12">
          <img class="w-full rounded-xl h-[357px] object-cover" src="https://images.pexels.com/photos/7667441/pexels-photo-7667441.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Image Description"/>
        </div>
        </div>
    </>
  );
};

export default Retail_tab;
