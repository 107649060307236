import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const features = [
  {
    name: "Developer API",
    description:
      "Integrate Invu with your custom ecommerce website or other business systems using our extensive library of developer resources.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%201.webp",
    altImg: "",
    href: "/developers",
  },
  {
    name: "Find an agency",
    description:
      "If you need help setting up your Invu ecommerce experience, let us connect you with an agency partner from our trusted network.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%202%20.webp",
    altImg: "",
    href: "/purchase",
  },
  {
    name: "Reach out",
    description:
      "Not sure how to start? Reach out to us today and we can help you find the perfect eCommerce tools for your business needs. Get started.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%203.webp",
    altImg: "",
    href: "/ecommerce",
  },
];

const Products_Plus = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("INVU PLUS | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };


  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("productsgeneralinvuplusuppercase")}
                </p>
              </div>
              <h1 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("productsgeneralinvuplustitle")}
              </h1>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("productsgeneralinvuplusdescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Plus%20module/Header%20Invu%20Plus.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font">
                &mdash; {t("productsinvuplussection2uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight text-white md:leading-tight sm:text-left md:text-4xl">
                {t("productsinvuplussection2title")}
              </h2>
              <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-500 leading-loose">
                {t("productsinvuplussection2description")}
              </p>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5  font-semibold leading-5 text-white underline">
                    {t("productsinvuplussection2item1title")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("productsinvuplussection2item1description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("productsinvuplussection2item2title")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("productsinvuplussection2item2description")}
                  </p>
                </div>
              </div>
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center px-8 mt-5 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium"> {t("productsinvuplussection2button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Plus%20module/Invu%20Plus%20section%201.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div className="max-w-screen-xl mt-20 px-4 mb-12 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
          <div className="p-8 border-b sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard1title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard1description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard2title")}{" "}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard2description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-r lg:border-r-0">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
                  <path
                    fill-rule="evenodd"
                    d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard3title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard3description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-b-0 lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full  cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-7"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard4title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard4description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-b-0 sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                  <path
                    fill-rule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard5title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard5description")}
              </p>
            </div>
          </div>
          <div className="p-8">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productsinvupluscard6title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productsinvupluscard6description")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="bg-pirBlack">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold xl:block hidden leading-tight text-white">
            It’s eCommerce made easy
          </h2>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple"></span>

          <h2 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-white">
            It’s eCommerce made easy
          </h2>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple"></span>
          <div class="grid grid-cols-1 mt-8 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {features.map((feature) => (
              <div>
                <a
                  class="block overflow-hidden rounded-lg shadow-sm"
                  // href={feature.href}
                >
                  <img
                    class="object-cover w-full h-56"
                    src={feature.imgSrc}
                    alt=""
                  />

                  <div class="p-6 bg-pirDarkGray">
                    <h3 class="text-xl font-bold text-white underline">
                      {feature.name}
                    </h3>

                    <p class="mt-4 text-sm text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section>
        <div class="max-w-screen-xl mt-10 px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <aside class="relative overflow-hidden text-gray-300 bg-gray-50 lg:flex shadow-xl rounded-2xl">
            <div class="w-full p-12 text-center lg:w-1/2 sm:p-16 lg:p-24 lg:text-left ">
              <div class="max-w-xl mx-auto md:text-left md:-ml-8  lg:-ml-12 text-left -ml-7 rounded-lg">
                <h2 class="mb-4 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                  {t("productsinvuplussection5card1title")}
                </h2>
                <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-700 leading-loose">
                  {t("productsinvuplussection5card1description")}
                </p>
                <a>
                  <div class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer">
                    {t("productsinvuplussection5card1button")} {""}
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="relative w-full h-64  sm:h-96 lg:w-1/2 lg:h-auto md:-mt-12">
              <img
                src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Online%20payments.svg"
                alt="Women smiling at college"
                class="absolute inset-0 object-cover w-full h-full md:object-contain "
              />
            </div>
          </aside>

          {/* <div class="grid mt-8 grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            <div>
              <a class="block overflow-hidden rounded-2xl shadow-xl">
                <div class="p-4 bg-gray-50">
                  <div className="lg:ml-8 mb-6 md:ml-3">
                    <div class="flex">
                      <h2 class="mt-6 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                        Manage risk and prevent loss online.
                      </h2>
                    </div>
                    <p className="mt-5 max-w-md text-sm  lg:text-base text-gray-700 leading-loose">
                      Uses sophisticated machine learning to spot and block
                      payment fraud and secure businesses of all sizes. Get
                      insight into payment fraud patterns, and set customrules
                      and alerts to manage risk.
                    </p>
                    <a href="/payments/risk-manage">
                      <div class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer">
                        Start now {""}
                        <span aria-hidden="true">&rarr;</span>
                      </div>
                    </a>
                  </div>
                </div>

                <img
                  class="object-cover w-full h-56 "
                  src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Manage%20risk.avif"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a class="block overflow-hidden rounded-2xl shadow-xl">
                <div class="p-4 bg-gray-50">
                  <div className="lg:ml-8 mb-6 md:ml-3">
                    <div class="flex ">
                      <h2 class="mt-6 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                        Seamless syncing across Invu.
                      </h2>
                    </div>
                    <p className="mt-5 max-w-md text-sm lg:text-base text-gray-700 leading-loose">
                      Integration with Invu POS systems keeps your online and
                      in-store orders, items, and inventory in sync. Use Order
                      Manager to see all orders in one place—no matter where
                      they originate.
                    </p>
                    <button
                      onClick={() => setIsOpen(true)}
                      class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer"
                    >
                      Start now {""}
                      <span aria-hidden="true">&rarr;</span>
                    </button>
                  </div>
                </div>

                <img
                  class="object-cover w-full h-56 "
                  src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Seamless.avif"
                  alt=""
                />
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="mb-12 lg:max-w-lg lg:pr-5 lg:mb-0">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("resourcespartnersloyaltysection6ctatitle")}
              </h2>
              <p className="text-gray-600 leading-loose">
                {t("resourcespartnersloyaltysection6ctadescription")}
              </p>
            </div>
            <hr className="mb-6 border-gray-300" />
            <div className="flex">
              <a href="/" aria-label="Play Song" className="mr-3">
                <div className="flex items-center justify-center w-10 h-10 text-white transition duration-300 transform rounded-full shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 hover:scale-110">
                  <img
                    className="rounded-full w-10 h-10"
                    src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Icon%20Invu%20Circle.svg"
                  />
                </div>
              </a>
              <div className="flex flex-col">
                <div className="text-sm font-semibold">Invu POS</div>
                <div className="text-xs text-gray-700">
                  Point of sale system
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 pt-6 pb-5 text-center border border-gray-300 rounded lg:w-2/5">
            <div className="mb-5 font-semibold">{t("resourcespartnersloyaltysection6ctasubtitlecard")}</div>
            <div className="flex justify-center w-full mb-3">
              <a
                href="https://admin.invupos.com/invuPos/index.php/site/login"
                target="_blank"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-indigo-600 hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
              >
                <div className="flex items-center">
                  <div className="mr-3 font-semibold text-white">
                    {t("resourcespartnersloyaltysection6ctabuttoncard")}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                    />
                  </svg>
                </div>
              </a>
            </div>
            <div className="inline-flex items-center justify-center">
              <p className="max-w-md px-5 mb-3 text-xs text-gray-600 sm:text-sm md:mb-5 ">
                {t("resourcespartnersloyaltysection6ctacarddescription")}{" "}
                <Link to={`${prefix}` + "/centro-de-ayuda"}>
                  <span className="underline font-semibold cursor-pointer">
                    {t("resourcespartnersloyaltysection6ctacardspan")}
                  </span>
                </Link>
              </p>
            </div>
            <div className="flex items-center w-full mb-5">
              <hr className="flex-1 border-gray-300" />
              <div className="px-3 text-xs text-gray-500 sm:text-sm">{t("resourcespartnersloyaltysection6ctacardor")}</div>
              <hr className="flex-1 border-gray-300" />
            </div>
            <a
              href="https://admin.invupos.com/invuPos/index.php/site/login"
              target="_blank"
              className="inline-flex items-center justify-center w-full h-12 px-6 font-semibold transition duration-200 bg-white border border-gray-300 rounded md:w-auto hover:bg-gray-100 focus:shadow-outline focus:outline-none"
            >
              {t("resourcespartnersloyaltysection6ctasignin")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products_Plus;
