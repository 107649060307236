import React from 'react'
import HeroVN from './HeroVN'
import Products_main_tabs from "../landingVN/Products_tabs/Products_main_tabs"
import Customers_carousel from "../../components/modules/Customers_carousel";
import CTA_home from './CTA_home';
import Industries_tabs_main from "../landingVN/Industries/Industries_tabs_main"
import Hardware_equipment from './Hardware_equipment';
import Support from "./Support"
import Latest_blog from '../../components/Home/Latest_blog';
import Members from '../../components/Home/Members';
import Industries_carousel_vn from '../../components/modules/Industries_carousel_vn';
import Contact_vn from '../../components/Home/Contact_vn';

const LandingVN = () => {
  return (
    <>
        <HeroVN/>
        <Products_main_tabs/>
        {/* <Customers_carousel/> */}
        <Industries_carousel_vn/>
        <CTA_home/>
        <Industries_tabs_main/>
        <Hardware_equipment/>
        <Support/>
        <Latest_blog/>
        <Members/>
        <Contact_vn/>
    </>
  )
}

export default LandingVN