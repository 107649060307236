import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import { useTranslation } from "react-i18next";


import FAQ from "./FAQ";
import Industries_carousel_pa from "./Industries_carousel_pa";
import CTA_home from "../Home/CTA_home";
import Delivery_integrations_cta from "./Delivery_integrations_cta";
import Ecommerce_integrations_cta from "./Ecommerce_integrations_cta";
import Banks_integrations_invu from "./Banks_integrations_invu";

const features_one = [
  {
    name: "Convenient & Contactless",
    description:
      "Multi-channel campaigns, email, SMS, postal mail, calls. Do not stop reaching your customers, you have all the means.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%201.svg",
  },
  {
    name: "Fully-Integrated",
    description:
      "Because each client is a world, perform segmented campaigns to match the interests of their customers.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%202.svg",
  },
  {
    name: "Efficient",
    description:
      "Always easy, fast and intuitive. 5 simple steps allow you to adjust your campaign to the maximum to achieve your goals.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%203.svg",
  },
];

const Processor_payment = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Procesador de pago | Invu POS");
  const { t } = useTranslation();

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      {/* <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("paymentsgeneralprocessoruppercase")}
                </p>
              </div>
              <h1 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("paymentsgeneralprocessortitle")}
              </h1>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("paymentsgeneralprocessordescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg "
                src="https://img.invupos.com/webinvu/Payments%20Processor%20module/Header%20processor%20.jpg"
                alt="payments overview"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="bg-gray-50">
        <div class="px-4  mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {features_one.map((feature) => (
              <div className="bg-white border rounded p-5">
                <img
                  class="inset-0 object-cover w-14 h-14 rounded mb-4"
                  src={feature.imgSrc}
                  alt=""
                />
                <p class="mb-2 mt-3 text-sm font-normal text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("paymentsgeneralprocessorsection2uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("paymentsgeneralprocessorsection2title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection2item1title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection2item1description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection2item2title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection2item2description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection2item3title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection2item3description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    4
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection2item4title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection2item4description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%201.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; Invu PLUS
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Technology that allows your team to focus on delivering amazing
                guest experiences.
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5">
                    The POS that fits in the palm of your hand
                  </h3>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    Weighs just over a pound so you can stay light on your feet
                  </h3>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5">
                    6.4 inch touchscreen makes it easy to view indoors and out
                  </h3>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%202.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
          <div className="lg:w-1/2 ">
            <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

            <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
              &mdash; Kitchen display system
            </p>
            <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
              Point of sale that drives the most successful restaurants
            </h2>
          </div>
          <div className="flex items-end lg:w-1/2">
            <p className="text-base text-gray-700 md:text-medium">
              Payment processing to power your restaurant with insights.
              Hardwired terminals and offline mode so nothing slows you down
            </p>
          </div>
        </div>
        <div className="group relative">
          <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
            <img
              className="object-cover w-full h-86 sm:h-96 rounded-lg"
              src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%203.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="grid mt-12 gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon1.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              A flower in my garden, a mystery in my panties. Heart attack never
              stopped old Big Bear. I didn't even know we were calling him Big
              Bear.
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon2.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Easy to set up in minutes, with no installation costs. Spend less
              time training your staff, and more time getting orders out.
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16  rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon3.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Speed up operations with reports that give you insights on prep
              times and more.Designed for flexibility, Invu works with your
              kitchen setup and processes
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon4.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Whether you take orders through POS, Square Online site, or
              delivery apps like Postmates, everything is sent to and fulfilled
              directly from the kitchen.
            </p>
          </div>
        </div>
      </div> */}

      {/* <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; {t("paymentsgeneralprocessorsection3uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("paymentsgeneralprocessorsection3title")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("paymentsgeneralprocessorsection3description")}
                </p>
              </div>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection3subtitle1")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection3subdescription1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 font-semibold leading-5 ">
                    {t("paymentsgeneralprocessorsection3subtitle2")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralprocessorsection3subdescription2")}
                  </p>
                </div>
              </div>
              <button
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span class="text-sm font-medium"> {t("paymentsgeneralprocessorsection3button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Pay%20section%205.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section> */}

<section className="bg-gray-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
        <div className="grid gap-16 lg:grid-cols-2">
          <div className="lg:pr-10 content-center">
            <h1 class="font-bold xl:text-[47px] lg:text-5xl md:text-5xl text-4xl text-[#002D33]">
            {t("pagostitlesection1")}
            </h1>
            <p class="mt-5 text-gray-700 text-lg leading-loose max-w-2xl font-light">
            {t("pagosdescriptionsection1")}
            </p>
            <div class="mt-7 grid gap-3 w-full sm:inline-flex">
              <button 
              onClick={() => setIsOpen(true)}
              class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                {t("pagosbuttonsection1")}
              </button>
            </div>
            <div class="mt-6 lg:mt-10 mb-6">
              <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
              <div class="mt-4 flex gap-x-8">

              <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>

              </div>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-96 rounded-2xl shadow-lg sm:h-[520px]"
              src="https://img.invupos.com/webinvu/Payments%20Processor%20module/Header%20processor%20.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("pagostitlesection2")}
        </h2>
      </div>

      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://images.pexels.com/photos/12935093/pexels-photo-12935093.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("pagoscard1title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("pagoscard1description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("pagoscard3button")}
              </span>
            </button>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://img.invupos.com/webinvu/Payments%20Processor%20module/payment%20processors.png"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("pagoscard2title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("pagoscard2description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("pagoscard3button")}
              </span>
            </button>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded ">
          <img
            src="https://images.pexels.com/photos/2988232/pexels-photo-2988232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("pagoscard3title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("pagoscard3description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("pagoscard3button")}
              </span>
            </button>
          </div>
        </div>
        
      </div>
    </div>

    <Banks_integrations_invu/>
 

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("pagostitlesection4")}
        </h2>
      </div>
      <div className="flex flex-col max-w-screen-xl overflow-hidden bg-white border rounded shadow-sm lg:flex-row sm:mx-auto">
        <div className="relative lg:w-1/2">
          <img
            src="https://images.pexels.com/photos/12935069/pexels-photo-12935069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
            className="object-cover w-full lg:absolute h-80 lg:h-full"
          />
          <svg
            className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
            viewBox="0 0 20 104"
            fill="currentColor"
          >
            <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104" />
          </svg>
        </div>
        <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
          <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
            {t("pagosmaincardtitle")}
          </h5>
          <p className="mb-5 text-gray-800 text-md">
            {t("pagosmaincarddescription")}
          </p>
          <div className="flex items-center ">
          <button 
              onClick={() => setIsOpen(true)}
              class="py-4 px-6 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                {t("pagosmaincardbutton")}
              </button>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-headset">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 14v-3a8 8 0 1 1 16 0v3" />
              <path d="M18 19c0 1.657 -2.686 3 -6 3" />
              <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
              <path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("pagostitlecard1")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("pagosdescriptioncard1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
              <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
              <path d="M12 12l0 .01" />
              <path d="M3 13a20 20 0 0 0 18 0" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("pagostitlecard2")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("pagosdescriptioncard2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-analytics">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
              <path d="M7 20l10 0" />
              <path d="M9 16l0 4" />
              <path d="M15 16l0 4" />
              <path d="M8 12l3 -3l2 2l3 -3" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("pagostitlecard3")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("pagosdescriptioncard3")}
            </p>
          </div>
        </div>
      </div>
    </div>

      <Industries_carousel_pa/>
      <CTA_home/>
      <FAQ/>
    </>
  );
};

export default Processor_payment;
