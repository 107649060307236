import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";
import Modal_started from "../Home/Modal_started";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Partners from "../Home/Partners";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import useDocumentTitle from "../../useDocumentTitle";



import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

const partners = [
  {
    id: 1,
    name: "Clau.io",
    href: "https://www.clau.io/",
    commentary:
      '"The point of sale (POS) management software that we’ve integrated in our lottery kiosks was designed specifically with our needs in mind"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650291927/Branding%20Invu%20/Partners/Logo_Clau_1_5_e87sfb.svg",
    logoAlt: "",
  },
  {
    id: 2,
    name: "Shopify",
    href: "https://www.shopify.com/",
    commentary:
      '"The team’s greatest strengths are their willingness to work with partners to better the solution; an excellent ability to listen"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649261443/Branding%20Invu%20/Partners/Shopify_logo_mzdhli.svg",
    logoAlt: "",
  },
  {
    id: 3,
    name: "Binance",
    href: "https://www.binance.com/es",
    commentary:
      '"Invu POS offers excellent service, constant and quick development, a simple platform multiple workflow tools, a fast system”',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650290562/Branding%20Invu%20/Partners/800px-Binance_logo.svg_fk1zi8.png",
    logoAlt: "",
  },
  {
    id: 4,
    name: "Market Man",
    href: "https://www.marketman.com/",
    commentary:
      'It is faster than my other POS. it’s cloud-based and my other POS database was in my oﬃce. The system is great."',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649270927/Branding%20Invu%20/Partners/MarketMan-side-by-side-logo_1_gilkv9.svg",
    logoAlt: "",
  },
  {
    id: 5,
    name: "Banco General",
    href: "https://www.bgeneral.com/",
    commentary:
      '"Is a point of sale software designed for our reality. The company listens to our needs and we are building a long-lasting partnership with the team."',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649270128/Branding%20Invu%20/Partners/Banco_general_logo_ttvnar.svg",
    logoAlt: "",
  },
  {
    id: 6,
    name: "Woo commerce",
    href: "https://www.woocommerce.com/",
    commentary:
      '"We were looking for a secure POS system that would meet our needs. With invu we find all the functionalities"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649261595/Branding%20Invu%20/Partners/woocommerce_logo_1_wxr8mz.svg",
    logoAlt: "",
  },
];

const features_col_one = [
  {
    name: "Employee management and permissions",
  },
  {
    name: "Inventory tracking and alerts",
  },
  {
    name: "Products with variations & modifiers",
  },
  {
    name: "Online ordering",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Appointment booking",
  },
  {
    name: "Full payment terminal integrations",
  },
  {
    name: "Full and partial voids and refunds",
  },
  {
    name: "Table management",
  },
  {
    name: "Fixed or inclusive taxes ",
  },
  {
    name: "Custom order types (e.g. Uber eats)",
  },
  {
    name: "Integrations with printers and cash drawers",
  },
  {
    name: "Guest mode (kiosk mode)",
  },
  {
    name: "Contactless, chip-and-pin, and swipe payments ",
  },
];

const Integrations = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("INTEGRACIONES | INVU POS ");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="mt-8 relative rounded-lg bg-black container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
          <img
            className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg"
            alt="bg"
          />
          <img
            className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg"
            alt="bg"
          />
          <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
            <h2 className="mb-3 text-xs text-center font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
              &mdash; {t("resourcesmoretoolsintegrationsuppercase")}
            </h2>
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">
              {t("resourcesmoretoolsintegrationstitle")}
            </h1>
          </div>
          <div className="flex justify-center items-center mb-10 sm:mb-20">
            <button
              onClick={() => setIsOpen(true)}
              className="hover:text-white hover:bg-transparent lg:text-xl hover:border-white border bg-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700	focus:ring-white rounded text-black px-4 sm:px-8 py-1 sm:py-3 text-sm"
            >
              {t("resourcesmoretoolsintegrationsbuttoncontact")}
            </button>
          </div>
        </div>
        <div className="container mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
          <Carousel
            className="object-cover z-10 relative sm:w-2/3 w-11/12"
            autoPlay={true}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
            interval={5000}
            swipeable={false}
            showStatus={false}
            showIndicators={false}
            swipeAnimationHandler={false}
            animationHandler="fade"
            handleTransition="fade"
          >
            <div className="relative">
              <div className="z-20 absolute cursor-pointer rounded-lg inset-0 bg-black bg-opacity-70"></div>
              <img
                className="rounded-lg  hover:opacity-50"
                src="https://img.invupos.com/webinvu/Invu%20integrations%20module/Invu%20integrations%201.jpeg"
                alt="Sample Page"
                role="img"
              />
            </div>
            <div className="relative">
              <div className="absolute rounded-lg inset-0 bg-black bg-opacity-70"></div>
              <img
                className="rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20integrations%20module/Invu%20integrations%202.jpeg"
                alt="Sample Page"
                role="img"
              />
            </div>
          </Carousel>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              {t("resourcesmoretoolsintegrationssection2uppercase")}
            </p>
          </div>
          <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                  width="52"
                  height="24"
                />
              </svg>
            </span>{" "}
            {t("resourcesmoretoolsintegrationssection2title")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            {t("resourcesmoretoolsintegrationssection2description")}
          </p>
        </div>
        <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Inventory.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title1")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description1")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Electronic.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title2")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description2")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Payment.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title3")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description3")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Data.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title4")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description4")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Marketing.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title5")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description5")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm  shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
            <div>
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                <img
                  src="https://img.invupos.com/webinvu/Integrations/Icons/Delivery.svg"
                  className="w-16 h-16 rounded-full"
                />
              </div>
              <h6 className="mb-2 font-semibold leading-5">
                {t("resourcesmoretoolsintegrationssection2title6")}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t("resourcesmoretoolsintegrationssection2description6")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <section class="text-white">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-2xl">
            <h2 class="text-3xl text-gray-900 font-bold sm:text-4xl">
              {t("resourcesmoretoolsintegrationssection3title")}
            </h2>

            <p class="mt-4 text-gray-500">
              {t("resourcesmoretoolsintegrationssection3description")}
            </p>
          </div>

          <div class="mt-8 py-16 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
            {/* <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm">
              <img
                alt="Office"
                src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                class="h-56 w-full object-cover"
              />

              <div class="p-4 sm:p-6">
                <a href="#">
                  <h3 class="text-lg font-medium text-gray-900">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </h3>
                </a>

                <p class="mt-2 text-sm leading-relaxed text-gray-500 line-clamp-3">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p>

                <a
                  href="#"
                  class="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
                >
                  Find out more
                  <span
                    aria-hidden="true"
                    class="block transition group-hover:translate-x-0.5"
                  >
                    &rarr;
                  </span>
                </a>
              </div>
            </article> */}
            <article className="group">
              <div class="bg-white relative shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                <div className="bg-[#DA2529] rounded-xl">
                  <div class="flex justify-center ">
                    <img
                      src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Staples.svg"
                      alt=""
                      class="rounded-full object-cover mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white "
                    />
                  </div>

                  <div class="mt-16">
                    <p class="text-center mb-7 text-sm text-white">
                      {t("resourcesmoretoolsintegrationssection3card1preferred")}
                    </p>
                    <p class="text-center mt-2 text-xs text-white font-bold tracking-wide text-white uppercase">
                      {t("resourcesmoretoolsintegrationssection3card1uppercase")}
                    </p>
                    <h1 class="font-bold text-center text-3xl text-white">
                      Staples Connect
                    </h1>
                    <p>
                      <span></span>
                    </p>
                    <div class="my-5 px-6">
                      <a
                        href="https://www.staplesconnect.com/"
                        target="_blank"
                        class="text-gray-900 block rounded-lg text-center font-medium leading-6 px-6 py-3 bg-white hover:bg-black hover:text-white"
                      >
                        {t("resourcesmoretoolsintegrationssection3card1button1")}{" "}
                        <span class="font-bold">Staples Connect</span>
                      </a>
                    </div>
                  </div>
                  <div className="bg-white py-10 rounded-b-xl">
                    <div class="w-full">
                      <p class="font-medium text-sm text-gray-600 text-left px-6">
                        {t("resourcesmoretoolsintegrationssection3card1description1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="group">
              <div class="bg-white relative shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                <div className="bg-[#2d4884] rounded-xl">
                  <div class="flex justify-center ">
                    <img
                      src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Davo.svg"
                      alt=""
                      class="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110"
                    />
                  </div>

                  <div class="mt-16">
                    <p class="text-center mb-7 text-sm text-white">
                      {t("resourcesmoretoolsintegrationssection3card2preferred")}
                    </p>
                    <p class="text-center mt-2 text-xs text-white font-bold tracking-wide text-white uppercase">
                      {t("resourcesmoretoolsintegrationssection3card2uppercase")}
                    </p>
                    <h1 class="font-bold text-center text-3xl text-white">
                      DAVO Sales Tax
                    </h1>
                    <p>
                      <span></span>
                    </p>
                    <div class="my-5 px-6">
                      <a
                        href="https://www.davosalestax.com/"
                        target="_blank"
                        class="text-gray-900 block rounded-lg text-center font-medium leading-6 px-6 py-3 bg-white hover:bg-black hover:text-white"
                      >
                        {t("resourcesmoretoolsintegrationssection3card2button2")}{" "}
                        <span class="font-bold">Davo Sales Tax</span>
                      </a>
                    </div>
                  </div>
                  <div className="bg-white py-10 rounded-b-xl">
                    <div class="w-full">
                      <p class="font-medium text-sm text-gray-600 text-left px-6">
                        {t("resourcesmoretoolsintegrationssection3card2description2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="group">
              <div class="bg-white relative shadow rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                <div className="bg-[#D8262A] rounded-xl">
                  <div class="flex justify-center ">
                    <img
                      src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Rooam.svg"
                      alt=""
                      class="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110"
                    />
                  </div>

                  <div class="mt-16">
                    <p class="text-center mb-7 text-sm text-white">
                      {t("resourcesmoretoolsintegrationssection3card3preferred")}
                    </p>
                    <p class="text-center mt-2 text-xs text-white font-bold tracking-wide text-white uppercase">
                      {t("resourcesmoretoolsintegrationssection3card3uppercase")}
                    </p>
                    <h1 class="font-bold text-center text-3xl text-white">
                      Rooam
                    </h1>
                    <p>
                      <span></span>
                    </p>
                    <div class="my-5 px-6 ">
                      <a
                        href="https://rooam.co/"
                        target="_blank"
                        class="text-gray-900 block rounded-lg text-center font-medium leading-6 px-6 py-3 bg-white hover:bg-black hover:text-white"
                      >
                        {t("resourcesmoretoolsintegrationssection3card3button3")} <span class="font-bold">Rooam</span>
                      </a>
                    </div>
                  </div>
                  <div className="bg-white py-10 rounded-b-xl h-[210px]">
                    <div class="w-full">
                      <p class="font-medium text-sm text-gray-600 text-left px-6">
                        {t("resourcesmoretoolsintegrationssection3card3description3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section class="text-white">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-2xl">
            <h2 class="text-3xl text-gray-900 font-bold sm:text-4xl">
              {t("resourcesmoretoolsintegrationssection4title")}
            </h2>

            <p class="mt-4 text-gray-500">
              {t("resourcesmoretoolsintegrationssection4description")}
            </p>
          </div>

          <div class="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Shogo.io"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Shogo.io.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Quickbooks"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Quickbooks_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Xero"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Xero_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="ASAP"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Asap_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Marketman"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Marketman_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Clau.io"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Clau.io_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Uber eats"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Uber%20eats_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Woocommerce"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Woocommerce_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
            <article class="overflow-hidden rounded-lg border border-gray-100 shadow-sm shadow transition hover:shadow-xl transition duration-200 transform hover:scale-105">
              <img
                alt="Shopify"
                src="https://img.invupos.com/webinvu/Integrations/Test%20logos/Shopify_logo.png"
                class="h-56 w-full object-cover"
              />
            </article>
          </div>
        </div>
      </section>
      <section class="text-white">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-xl">
            <h2 class="text-3xl text-gray-900 font-bold sm:text-4xl">
              {t("resourcesmoretoolsintegrationssection5title")}
            </h2>
          </div>
        </div>
      </section>
      <div className="max-w-screen-xl px-4 -mt-12 mx-auto sm:px-6 lg:px-8">
        <div className="relative md:flex justify-center items-center ">
          {/* <!-- Medium and large Slider --> */}
          <div className="hidden md:block relative lg:w-full md:w-8/12 w-full overflow-x-hidden md:mt-0 mt-10">
            <div
              id="slider"
              className="flex justify-start items-start transition ease-out duration-700 gap-4"
            >
              <Swiper
                slidesPerView={4}
                spaceBetween={10}
                navigation={
                  (true,
                  {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  })
                }
                className="mySwiper"
                style={{
                  padding: "4% 1.2%",
                }}
                speed={700}
              >
                <SwiperSlide
                  style={{
                    width: "100% !important",
                  }}
                >
                  <div className="w-72 border border-b-4 border-[#EEF2FF] rounded-md flex-none mr-3 rounded-xl shadow transition hover:shadow-lg transition duration-200 transform hover:scale-110">
                    <div className="w-full p-5">
                      <div className="md:w-full h-full relative ">
                        <strong class="rounded border  bg-[#F6DDF0] px-3 py-1.5 text-[13px] font-bold text-[#D75CB8]">
                          {t("resourcesmoretoolsintegrationssection5card1badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card1title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium ">{t("resourcesmoretoolsintegrationssection5card1item1")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card1item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card1item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "100% !important",
                  }}
                >
                  <div className="w-72 border border-b-4 border-[#EEF2FF] rounded-md flex-none mr-3 rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                    <div className="w-full p-5">
                      <div className="md:w-full h-full relative ">
                        <strong class="rounded border  bg-[#FFEDE5] px-3 py-1.5 text-[13px] font-bold text-[#FF4C00]">
                          {t("resourcesmoretoolsintegrationssection5card2badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card2title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                              {t("resourcesmoretoolsintegrationssection5card2item1")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card2item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card2item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "100% !important",
                  }}
                >
                  <div className="w-72 border border-b-4 border-[#EEF2FF] rounded-md flex-none mr-3 rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                    <div className="w-full p-5">
                      <div className="md:w-full h-full relative ">
                        <strong class="rounded border  bg-[#FDD6DD] px-3 py-1.5 text-[13px] font-bold text-[#F53655]">
                          {t("resourcesmoretoolsintegrationssection5card3badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card3title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card3item1")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card3item2")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card3item3")}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "100% !important",
                  }}
                >
                  <div className="w-72 border border-b-4 border-[#EEF2FF] rounded-md flex-none mr-3 rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                    <div className="w-full p-5">
                      <div className="md:w-full h-full relative ">
                        <strong class="rounded border  bg-[#DBF5F9] px-3 py-1.5 text-[13px] font-bold text-[#3ec9d8]">
                          {t("resourcesmoretoolsintegrationssection5card4badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card4title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#3ec9d8"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                              {t("resourcesmoretoolsintegrationssection5card4item1")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#3ec9d8"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card4item2")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#3ec9d8"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card4item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "100% !important",
                  }}
                >
                  <div className="w-72 border border-b-4 border-[#EEF2FF] rounded-md flex-none mr-3 rounded-xl shadow transition hover:shadow-xl transition duration-200 transform hover:scale-110">
                    <div className="w-full p-5">
                      <div className="md:w-full h-full relative ">
                        <strong class="rounded border  bg-[#E9F3DF] px-3 py-1.5 text-[13px] font-bold text-[#9ACA68]">
                          {t("resourcesmoretoolsintegrationssection5card5badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card5title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#9ACA68"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card5item1")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#9ACA68"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card5item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#9ACA68"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card5item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="flex justify-end items-end mt-10 pr-2 pb-1">
              <div className="flex items-center space-x-3">
                <button
                  id="prev"
                  aria-label="slide backward"
                  className="swiper-button-prev focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M31.666 20H8.33268"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 26.6667L8.33333 20"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 13.3335L8.33333 20.0002"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  id="next"
                  aria-label="slide forward"
                  className="swiper-button-next focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33398 20H31.6673"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25 26.6667L31.6667 20"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25 13.3335L31.6667 20.0002"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* ********************************************************* */}

          {/* <!-- Small Slider --> */}
          <div className="block md:hidden relative md:w-8/12 w-full overflow-x-hidden md:mt-0 mt-10 ">
            <div
              id="slider2"
              className="flex justify-start items-start transition ease-out duration-700 gap-0 space-x-2"
            >
              <Swiper
                slidesPerView={1}
                navigation={
                  (true,
                  {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  })
                }
                className="mySwiper"
                speed={700}
              >
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div className="md:w-full h-full relative">
                        <strong class="rounded border  bg-[#F6DDF0] px-3 py-1.5 text-[13px] font-bold text-[#D75CB8]">
                          {t("resourcesmoretoolsintegrationssection5card1badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card1title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card1item1")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card1item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#D75CB8"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card1item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div className="md:w-full h-full relative">
                        <strong class="rounded border  bg-[#FFEDE5] px-3 py-1.5 text-[13px] font-bold text-[#FF4C00]">
                          {t("resourcesmoretoolsintegrationssection5card2badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card2title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                              {" "}
                              {t("resourcesmoretoolsintegrationssection5card2item1")}{" "}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card2item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#FF4C00"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card2item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div className="md:w-full h-full relative">
                        <strong class="rounded border  bg-[#FDD6DD] px-3 py-1.5 text-[13px] font-bold text-[#F53655]">
                          {t("resourcesmoretoolsintegrationssection5card3badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card3title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium"> {t("resourcesmoretoolsintegrationssection5card3item1")} </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card3item2")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#F53655"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card3item3")}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div className="md:w-full h-full relative">
                        <strong class="rounded border  bg-[#DBF5F9] px-3 py-1.5 text-[13px] font-bold text-[#5ED2DF]">
                          {t("resourcesmoretoolsintegrationssection5card4badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card4title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#5ED2DF"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                              {" "}
                              {t("resourcesmoretoolsintegrationssection5card4item1")}{" "}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#5ED2DF"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card4item2")}
                            </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#5ED2DF"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card4item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div className="md:w-full h-full relative">
                        <strong class="rounded border  bg-[#E9F3DF] px-3 py-1.5 text-[13px] font-bold text-[#A3CF76]">
                          {t("resourcesmoretoolsintegrationssection5card5badge")}
                        </strong>

                        <h3 class="mt-4 text-lg font-bold sm:text-md">
                          <a href="" class="hover:underline text-gray-600">
                            {" "}
                            {t("resourcesmoretoolsintegrationssection5card5title")}{" "}
                          </a>
                        </h3>
                      </div>
                      <a target="_blank">
                        <div class="mt-4 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#A3CF76"
                              class="bi bi-cart-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            <p class="ml-2 text-md font-medium"> {t("resourcesmoretoolsintegrationssection5card5item1")} </p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#A3CF76"
                              class="bi bi-journal-text"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">{t("resourcesmoretoolsintegrationssection5card5item2")}</p>
                          </div>
                        </div>
                        <div class="mt-2 sm:flex sm:items-center sm:gap-2">
                          <div class="flex items-center text-gray-500 mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#A3CF76"
                              class="bi bi-box2-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982Z" />
                              <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6l2.25-3ZM8.5 4V1h3.75l2.25 3h-6ZM15 5v10H1V5h14Z" />
                            </svg>
                            <p class="ml-2 text-md font-medium">
                            {t("resourcesmoretoolsintegrationssection5card5item3")}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className=" flex justify-end items-end mt-10 pr-2 pb-1">
              <div className="flex items-center space-x-3">
                <button
                  id="prev2"
                  aria-label="slide backward"
                  className="swiper-button-prev focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M31.666 20H8.33268"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 26.6667L8.33333 20"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 13.3335L8.33333 20.0002"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  id="next2"
                  aria-label="slide forward"
                  className="swiper-button-next focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33398 20H31.6673"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25 26.6667L31.6667 20"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25 13.3335L31.6667 20.0002"
                      stroke="#2C3E50"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partners />
      {/* <section>
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 lg:grid-cols-2 lg:h-screen">
            <div class="relative z-10 lg:py-16">
              <div class="relative h-64 sm:h-80 lg:h-full">
                <img
                  class="absolute inset-0 object-cover w-full h-full"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652823883/Branding%20Invu%20/Integrations/Group_2_2_dqmobn.png"
                  alt=""
                />
              </div>
            </div>
            <div class="relative flex items-center bg-gray-100">
              <span class="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span>

              <div class="p-8 sm:p-16 lg:p-24">
                <div>
                  <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    Integrations
                  </p>
                </div>
                <h2 class="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                  Integrations tools
                </h2>

                <p class="mt-4 text-gray-600 mb-4">
                  Integrations can also track general sales trends over both
                  channels, making it easier to monitor the performance of your
                  business.
                </p>

                <div class="flex flex-col mt-3 flex-grow space-y-3">
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      By integrating payment options, you are synchronizing the
                      systems and platforms that facilitate the payment side.
                    </p>
                  </div>
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      By integrating your loyalty management systems with your
                      POS terminal, you can award points and make rewards
                      redemption a breeze.
                    </p>
                  </div>
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      POS integrations allow you to track employee performance
                      and sales goals to see who sells the most, and which
                      employees are putting in the most hours.
                    </p>
                  </div>
                </div>

                <button
                  onClick={() => setIsOpen(true)}
                  class="inline-block px-12 py-3 mt-8 text-sm font-medium text-white bg-indigo-600 border border-indigo-600 rounded active:text-indigo-500 hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring"
                >
                  Get in touch
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-pirBlack">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("resourcesmoretoolsintegrationssection6uppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcesmoretoolsintegrationssection6title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsintegrationssection6subtitle1")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsintegrationssection6description1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsintegrationssection6subtitle2")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsintegrationssection6description2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsintegrationssection6subtitle3")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                   {t("resourcesmoretoolsintegrationssection6description3")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20integrations%20module/Invu%20integrations%203.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("resourcesmoretoolsintegrationssection7uppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcesmoretoolsintegrationssection7title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("resourcesmoretoolsintegrationssection7subtitle1")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("resourcesmoretoolsintegrationssection7description1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                  {t("resourcesmoretoolsintegrationssection7subtitle2")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                  {t("resourcesmoretoolsintegrationssection7description2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                  {t("resourcesmoretoolsintegrationssection7subtitle3")}
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                  {t("resourcesmoretoolsintegrationssection7description3")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                  {t("resourcesmoretoolsintegrationssection7subtitle4")}
                    
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                  {t("resourcesmoretoolsintegrationssection7description4")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20integrations%20module/Invu%20integrations%204.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-1">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("resourcesmoretoolsintegrationssection8uppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {t("resourcesmoretoolsintegrationssection8title")}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {t("resourcesmoretoolsintegrationssection8description")}
            </p>
          </div>
        </div>
        <div className=" max-w-screen-lg mx-auto">
          <div className="grid grid-cols-1 gap-4 row-gap-5 lg:grid-cols-2">

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item1")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item2")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item3")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item4")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item5")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item6")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item7")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item8")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item9")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item10")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="flex items-center p-2 transition-colors duration-200 border rounded group hover:bg-indigo-600 hover:border-indigo-600 cursor-pointer transform transition duration-500 hover:scale-105">
                    <div className="mr-2">
                      <svg
                        className="w-6 h-6 transition-colors duration-200 hover:text-white text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7E8CF7"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        stroke="#7E8CF7"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>

                    <span className="text-gray-800 transition-colors duration-200 group-hover:text-white">
                      {t("resourcesmoretoolsintegrationssection8item11")}
                    </span>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      <FAQ_model_one />
      <style>
        {`
  @media (min-width: 768px){
    .swiper-slide-active {
      width: 100% !important;
    }
    .swiper-slide, .swiper-slider-next {
      width: 100% !important;
      flex-shrink: inherit;
    }
  }
  .swiper-button-next {
    position: relative;
  }
  .swiper-button-prev {
    position: relative;
    margin-right: 15px;
  }
  .swiper-button-prev::after, .swiper-button-next::after{
    content: " ";
  }
  .swiper-button-prev, .swiper-button-next {
    width: auto;
    height: auto;
  }
  .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity:1
  }	
`}
      </style>
      ;

    </>
  );
};

export default Integrations;
