import React, { useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useTranslation } from "react-i18next";

export default function Testimonials() {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <section className="mt-20">
      {/* <div className="xl:px-20 px-8 py-20 2xl:mx-auto 2xl:container relative z-40"> */}
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
        <CarouselProvider
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={4}
        >
          <h2 className="text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            {t("testimonialstittle")}
          </h2>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h2 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            {t("testimonialstittle")}
          </h2>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <Slider>
            <Slide index={0} tabIndex="null">
              <div className="flex">
                <div className="mt-14 md:flex">
                  <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                    <img
                      src="https://img.invupos.com/webinvu/Testimonials/Mario%20Castrellon.png"
                      alt="Mario Castrellón"
                      loading="lazy"
                      className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                    />
                    <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                      <img
                        className="w-14 h-14"
                        loading="lazy"
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                    <div>
                      {/* <h3 className="text-xl font-semibold  text-gray-800">
                        {t("testimonialcustomertittle1")}
                      </h3> */}
                      <p className="text-base leading-6 mt-4 text-gray-600">
                        {t("testimonialcustomerdescription1")}
                      </p>
                    </div>
                    <div className="md:mt-10 mt-10 ">
                      <p className="mt-2 text-base font-medium leading-4 text-gray-800">
                        Mario Castrellón
                      </p>
                      <p className="text-base leading-4 mt-2 mb-4 text-gray-600">
                        Grupo Maito
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={1}>
              <div
                className="flex relative"
                style={{ transform: "translateX(0%)" }}
              >
                <div className="mt-14 md:flex">
                  <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                    <img
                      src="https://img.invupos.com/webinvu/Testimonials/foto%20testimonials.jpeg"
                      alt="image of profile"
                      loading="lazy"
                      className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                    />
                    <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                      <img
                        className="w-14 h-14"
                        loading="lazy"
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                        alt="commas"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                    <div>
                      {/* <h3 className="text-xl font-semibold  text-gray-800">
                        {t("testimonialcustomertittle2")}
                      </h3> */}
                      <p className="text-base leading-6 mt-4 text-gray-600">
                        {t("testimonialcustomerdescription2")}
                      </p>
                    </div>
                    <div className="md:mt-10 mt-10">
                      {/* <a href="#">
                        <img
                          className="h-6 w-auto"
                          src="https://res.cloudinary.com/dslkvd23s/image/upload/v1649457369/Branding%20Invu%20/Logo%20testimonials/Company_Airwallex_nfpqsr.svg"
                          alt=""
                        />
                      </a> */}
                      <p className="mt-2 text-base font-medium leading-4 text-gray-800">
                        {t("testimonialcustomerwork2")}
                      </p>
                      {/* <p className="text-base leading-4 mt-2 mb-4 text-gray-600">
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={2}>
              <div
                className="flex relative"
                style={{ transform: "translateX(0%)" }}
              >
                <div className="mt-14 md:flex">
                  <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                    <img
                      src="https://img.invupos.com/webinvu/Testimonials/Sushi%20Express%20Testimonial.jpg"
                      alt="image of profile"
                      loading="lazy"
                      className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                    />
                    <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                      <img
                        className="w-14 h-14"
                        loading="lazy"
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                        alt="commas"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                    <div>
                      {/* <h3 className="text-xl font-semibold  text-gray-800">
                        {t("testimonialcustomertittle2")}
                      </h3> */}
                      <p className="text-base leading-6 mt-4 text-gray-600">
                        {t("testimonialcustomerdescription3")}
                      </p>
                    </div>
                    <div className="md:mt-10 mt-10">
                      {/* <a href="#">
                        <img
                          className="h-6 w-auto"
                          src="https://res.cloudinary.com/dslkvd23s/image/upload/v1649457369/Branding%20Invu%20/Logo%20testimonials/Company_Airwallex_nfpqsr.svg"
                          alt=""
                        />
                      </a> */}
                      <p className="mt-2 text-base font-medium leading-4 text-gray-800">
                        Edmir Facey
                      </p>
                      <p className="text-base leading-4 mt-2 mb-4 text-gray-600">
                        Sushi Express
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={2}>
              <div
                className="flex relative"
                style={{ transform: "translateX(0%)" }}
              >
                <div className="mt-14 md:flex">
                  <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                    <img
                      src="https://img.invupos.com/webinvu/Testimonials/Moy%20Corkidid.jpeg"
                      alt="image of profile"
                      loading="lazy"
                      className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                    />
                    <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                      <img
                        className="w-14 h-14"
                        loading="lazy"
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                        alt="commas"
                      />
                    </div>
                  </div>
                  <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                    <div>
                      {/* <h3 className="text-xl font-semibold  text-gray-800">
                        {t("testimonialcustomertittle2")}
                      </h3> */}
                      <p className="text-base leading-6 mt-4 text-gray-600">
                        {t("testimonialcustomerdescription4")}
                      </p>
                    </div>
                    <div className="md:mt-10 mt-10">
                      {/* <a href="#">
                        <img
                          className="h-6 w-auto"
                          src="https://res.cloudinary.com/dslkvd23s/image/upload/v1649457369/Branding%20Invu%20/Logo%20testimonials/Company_Airwallex_nfpqsr.svg"
                          alt=""
                        />
                      </a> */}
                      <p className="mt-2 text-base font-medium leading-4 text-gray-800">
                        Moy Corkidi
                      </p>
                      <p className="text-base leading-4 mt-2 mb-4 text-gray-600">
                        Ay Mi Negra
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </Slider>
          <div className="flex items-center mt-8">
            <ButtonBack
              className="cursor-pointer "
              role="button"
              aria-label="previous slide"
            >
              <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg"
                alt="previous"
              />
            </ButtonBack>

            <ButtonNext
              role="button"
              aria-label="next slide"
              className="cursor-pointer ml-2"
            >
              <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg"
                loading="lazy"
                alt="next"
              />
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </section>
  );
}
