import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../useDocumentTitle";

const SignUp = () => {
  const history = useHistory();
  useDocumentTitle("Invu | Sign In");
  return (
    <>
      <div class="flex min-h-screen overflow-hidden">
        <div
          class="
            flex flex-col
            justify-center
            flex-1
            px-4
            py-12
            sm:px-6
            lg:flex-none lg:px-20
            xl:px-24
          "
        >
          <div class="w-full max-w-xl mx-auto lg:w-96">
            <div className="">
              <button onClick={() => history.push("/")}>
                <a href="">
                  <img
                    className="h-12 w-auto"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                    alt=""
                  />
                </a>
              </button>
            </div>
            <div class="mt-8">
              <div class="mt-6">
                <form action="#" method="POST" class="space-y-6">
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-neutral-600"
                    >
                      {" "}
                      Full Name{" "}
                    </label>
                    <div class="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required=""
                        placeholder="Your complete name"
                        class="
                          block
                          w-full
                          px-5
                          py-3
                          text-base
                          placeholder-gray-300
                          transition
                          duration-500
                          ease-in-out
                          transform
                          border border-transparent
                          rounded-lg
                          text-neutral-600
                          bg-gray-50
                          focus:outline-none
                          focus:border-transparent
                          focus:ring-2
                          focus:ring-white
                          focus:ring-offset-2
                          focus:ring-offset-gray-300
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-neutral-600"
                    >
                      {" "}
                      Email address{" "}
                    </label>
                    <div class="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required=""
                        placeholder="Your Email"
                        class="
                          block
                          w-full
                          px-5
                          py-3
                          text-base
                          placeholder-gray-300
                          transition
                          duration-500
                          ease-in-out
                          transform
                          border border-transparent
                          rounded-lg
                          text-neutral-600
                          bg-gray-50
                          focus:outline-none
                          focus:border-transparent
                          focus:ring-2
                          focus:ring-white
                          focus:ring-offset-2
                          focus:ring-offset-gray-300
                        "
                      />
                    </div>
                  </div>
                  <div class="space-y-1">
                    <label
                      for="password"
                      class="block text-sm font-medium text-neutral-600"
                    >
                      {" "}
                      Password{" "}
                    </label>
                    <div class="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        required=""
                        placeholder="Your Password"
                        class="
                          block
                          w-full
                          px-5
                          py-3
                          text-base
                          placeholder-gray-300
                          transition
                          duration-500
                          ease-in-out
                          transform
                          border border-transparent
                          rounded-lg
                          text-neutral-600
                          bg-gray-50
                          focus:outline-none
                          focus:border-transparent
                          focus:ring-2
                          focus:ring-white
                          focus:ring-offset-2
                          focus:ring-offset-gray-300
                        "
                      />
                    </div>
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        placeholder="Your password"
                        class="
                          -mt-3
                          w-4
                          h-4
                          text-blue-600
                          border-gray-200
                          rounded
                          focus:ring-blue-500
                        "
                      />
                      <label
                        for="remember-me"
                        class="block ml-2 text-xs text-neutral-600"
                      >
                        {" "}
                        I've read and agree with{" "}
                        <span className="text-indigo-600 font-semibold underline">
                          Terms of Service {""}
                        </span>
                        and our <br></br>
                        <span className="text-indigo-600 font-semibold underline">
                          Privacy Policy
                        </span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      class="
                        flex
                        items-center
                        justify-center
                        w-full
                        px-10
                        py-4
                        text-base
                        font-medium
                        text-center text-white
                        transition
                        duration-500
                        ease-in-out
                        transform
                        bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring
                      "
                    >
                      {" "}
                      Create an account{" "}
                    </button>
                  </div>
                </form>
                <div class="relative my-4">
                  <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300"></div>
                  </div>
                  <div class="relative flex justify-center text-sm">
                    <h3 class="px-2 bg-white text-neutral-600">
                      {" "}
                      Al ready have an account?{" "}
                      <Link to={"/auth/login"}>
                        <span className="text-indigo-600 font-semibold">
                          Log In
                        </span>
                      </Link>
                    </h3>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="relative w-full h-64 sm:h-96 lg:w-1/2 lg:h-auto flex-1 hidden w-0 overflow-hidden lg:block">
          <img
            src="https://res.cloudinary.com/dslkvd23s/image/upload/v1649519808/Branding%20Invu%20/Log%20In/New_Sign_Up_cover_hbikga.svg"
            alt=""
            class="absolute inset-0 object-cover w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

export default SignUp;
