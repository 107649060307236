import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const hardware = [
  {
    id: 1,
    name: "Ipad",
    description:
      "Invu uses iPads as checkouts or sales stations. Enjoy a solid, reliable and accessible team",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20business%20module%20/Hardware%20business%201.png",
  },

  {
    id: 2,
    name: "Printers",
    description:
      "Featuring multi-interface connectivity, our printer meets current and future needs.",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20business%20module%20/Hardware%20business%202.png",
  },

  {
    id: 3,
    name: "Terminal",
    description:
      "Invu Terminal is an all-in-one credit card machine for your business",
    imgSrc:
      "https://img.invupos.com/webinvu/Hardware%20business%20module%20/Hadware%20business%203.svg",
  },
];

const Hardware_business = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  const equipmenthardware = t("equipmenthardware", { returnObjects: true });

  return (
    <>
      <section></section>
      <section className="bg-pirBlack">
        <div className="px-4 py-4 mt-12 mx-auto max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <div className="lg:w-1/2 mt-12">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
                {t("businesstypesgeneralsectionequipmentuppercase")}
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none xl:max-w-lg">
                {t("businesstypesgeneralsectionequipmenttitle")}
              </h2>
            </div>
            <div className="flex items-end lg:w-1/2">
              <p className="text-base text-gray-500 md:text-medium">
                {t("businesstypesgeneralsectionequipmentdescription")}
              </p>
            </div>
          </div>
        </div>
        <div class="px-4 py-8 mx-auto max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3">
            {equipmenthardware.map((equipment) => (
              <Link
                to={`${prefix}` + "/detalles-de-equipos"}
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className="overflow-hidden mb-10 transition-shadow duration-300 bg-pirBlack">
                  <Link
                    className="rounded-md"
                    to={`${prefix}` + "/detalles-de-equipos"}
                    onClick={() => window.scrollTo(0, 0)}
                    aria-label="Article"
                  >
                    <img
                      src={equipment.imgSrc}
                      className="object-cover w-full h-96 rounded-md"
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                  <div className="py-5">
                    <Link
                      to={`${prefix}` + "/detalles-de-equipo"}
                      onClick={() => window.scrollTo(0, 0)}
                      className=" text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      <div class=" pb-1 text-lg font-semibold text-gray-100 hover:text-gray-300 cursor-pointer">
                        {equipment.name} {""}
                        <span aria-hidden="true">&rarr;</span>
                      </div>
                    </Link>
                    <p className="mt-2.5 text-gray-500">
                      {equipment.description}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hardware_business;
