import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SelectorPais } from "../../App";
import useLocalePrefix from "../utils/useLocalePrefix";

const Footer = ({country, handleCountryChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <footer class="bg-pirBlack ">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <a>
                <img
                  className="h-16 w-auto"
                  src="https://img.invupos.com/webinvu/Footer/Logo%20/Logo_blanco_invu_lgzbkn.svg"
                  alt=""
                />
              </a>
              <p class="max-w-xs mt-4 text-sm text-gray-500">
                {t("footerdescription")}
              </p>

              {/* <div class="flex mt-8 space-x-6 text-gray-500">
                <a
                  class="hover:opacity-75"
                  href="https://www.instagram.com/invupos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Instagram </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://twitter.com/invupos?lang=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Twitter </span>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://es-la.facebook.com/invuposofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Facebook </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div> */}

              <div className="mt-10 mb-2 flex">
                <a
                  class="hover:opacity-75 w-36"
                  href="https://ciudaddelsaber.org/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-[135px] w-auto"
                    src="https://img.invupos.com/webinvu/Footer/Cuidad%20del%20saber%20afiliado.avif"
                    alt="Cuidad del saber afiliado"
                  />
                </a>

                <a
                  class="hover:opacity-75 w-36"
                  href="https://www.nvidia.com/en-us/startups/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-[135px] w-auto rounded-lg"
                    src="https://img.invupos.com/webinvu/Footer/nvidiainception.png"
                    alt="Nvidia Inception Program"
                  />
                </a>
                </div>

                <div class="mt-6 lg:mt-6 mb-6">
                    <div class="mt-4 flex gap-x-3">
                      <a
                      class="hover:opacity-90"
                      href="https://www.capterra.com.pa/software/1049125/invu-pos"
                      target="_blank"
                      rel="noreferrer"
                      >
                        <img src="https://img.invupos.com/webinvu/Footer/BESTEASEOFUSE2024.png" className="h-[85px]"/>
                      </a>

                      <a
                      class="hover:opacity-90"
                      href="https://www.softwareadvice.com/inventory-management/invu-pos-profile/"
                      target="_blank"
                      rel="noreferrer"
                      >
                      <img src="https://img.invupos.com/webinvu/Footer/CUSTOMERSUPPORT2024.png" className="h-[85px]"/>
                      </a>

                      <a
                       class="hover:opacity-90"
                       href="https://www.getapp.com/retail-consumer-services-software/a/invu-pos/"
                       target="_blank"
                       rel="noreferrer"
                      >
                      <img src="https://img.invupos.com/webinvu/Footer/ga-features_and_functionality-2023.png" className="h-[80px]"/>
                      </a>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-8 lg:col-span-2 sm:grid-cols-3 lg:grid-cols-4">
              <div>
                <p class="font-semibold text-white">
                  {t("footermodulepayments")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/pagos-en-persona"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem2")}{" "}
                  </Link>
                  {/* <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/payments/on-your-computer"}
                  >
                    {" "}
                    {t("footermodulepaymentsitem3")}{" "}
                  </Link> */}
                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/pagos-en-su-sitio-web"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem4")}{" "}
                  </Link>

                  {/* <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/payments/risk-manage"}
                  >
                    {" "}
                    {t("footermodulepaymentsitem5")}{" "}
                  </Link> */}
                  {/* <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/payments/platform"}
                  >
                    {" "}
                    {t("footermodulepaymentsitem6")}{" "}
                  </Link> */}

                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/procesadores"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem7")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/facturacion-electronica"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem8")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${prefix}` + "/pagos/impresoras-fiscales"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulepaymentsitem9")}{" "}
                  </Link>
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">
                  {t("footermoduleproducts")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/punto-de-venta"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/comercio-electronico"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem2")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/lealtad"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem3")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/plus"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem4")}{" "}
                  </Link>

                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/kds"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem5")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/quioscos"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem6")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/marketing"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleproductsitem7")}{" "}
                  </Link>
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">
                  {t("footermodulesolutions")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/venta-minorista/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/ayb/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem2")}{" "}
                  </Link>
                  {/* <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/professional-services"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem3")}{" "}
                  </Link> */}
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/empresas-grandes/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem4")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={
                      `${[prefix]}` + "/mas-herramientas/gestion-de-inventarios"
                    }
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem5")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/tipos-de-negocios/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulesolutionsitem6")}{" "}
                  </Link>
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">
                  {t("footermodulecustomers")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/contacto-con-ventas"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulecustomersitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/mas-herramientas/contabilidad-erps"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulecustomersitem2")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/mas-herramientas/compras"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulecustomersitem3")}{" "}
                  </Link>
                  {/* <Link
                      class="hover:opacity-75"
                      to={`${[prefix]}` + "/resources/guides"}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {" "}
                      {t("footermodulecustomersitem4")}{" "}
                    </Link> */}
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/programas-de-revendedores"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulecustomersitem5")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/recursos/blog"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulecustomersitem6")}{" "}
                  </Link>
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">
                  {t("footermoduleprofessional")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/tipos-de-negocios/salud-gimnasios"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("footermoduleprofessionalitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/ayb/cafeterias"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleprofessionalitem2")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={
                      `${[prefix]}` +
                      "/tipos-de-negocios/profesionales-de-la-belleza"
                    }
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleprofessionalitem3")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/productos/punto-de-venta"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduleprofessionalitem4")}{" "}
                  </Link>
                  {/* <Link
                    class="hover:opacity-75"
                    to={
                      `${[prefix]}` + "/business-types/home-and-repair-services"
                    }
                  >
                    {" "}
                    {t("footermoduleprofessionalitem5")}{" "}
                  </Link> */}
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">
                  {t("footermoduledevelopers")}
                </p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/socios/desarrolladores"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduledevelopersitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/recursos/blog"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermoduledevelopersitem2")}{" "}
                  </Link>
                </nav>
              </div>

              <div>
                <p class="font-semibold text-white">{t("footermodulehelp")}</p>

                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/recursos/general"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulehelpitem1")}{" "}
                  </Link>
                  <Link
                    class="hover:opacity-75"
                    to={`${[prefix]}` + "/centro-de-ayuda"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    {t("footermodulehelpitem2")}{" "}
                  </Link>
                </nav>
              </div>
            </div>
          </div>


          <div class="mt-8 -mb-4">
            <div class="mx-auto">
              <div class="flex justify-between">
                <SelectorPais value={country.toLowerCase()} onChange={handleCountryChange} />
                <div class="flex items-center">
                <div class="flex space-x-6 text-gray-500">
                <a
                  class="hover:opacity-75"
                  href="https://www.instagram.com/invupos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Instagram </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://twitter.com/invupos?lang=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Twitter </span>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>

                <a
                  class="hover:opacity-75"
                  href="https://es-la.facebook.com/invuposofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="sr-only"> Facebook </span>

                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>

                </div>
              </div>
            </div>
          </div>
             

          <div class="pt-8 mt-12 border-t border-white/10">
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
              <p class="text-sm text-center text-gray-400 lg:text-left">
                {t("footercopy")}
              </p>

              <nav class="flex justify-center space-x-4 text-sm text-gray-400 lg:justify-end">
                <Link
                    class="underline"
                    to={`${[prefix]}` + "/politicas-de-privacidad"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                {t("footerprivacy")}
              </Link>
              </nav>
            </div>
          </div>
        </div>

        <div class="px-4 py-2 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"></div>
      </footer>
    </>
  );
};

export default Footer;
