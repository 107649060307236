import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import Help_CTA from "../Home/Help_CTA";

const features = [
  {
    id: "1",
    name: "Collect a deposit up front",
    description:
      "Request a certain amount up front and collect the remaining balance when the job is done.trust us. Our system is tailored to your needs",
    imgSrc:
      "https://images.pexels.com/photos/7821702/pexels-photo-7821702.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    id: "2",
    name: "Request payment after a job",
    description:
      "Send an invoice for the field services you provide and your client can pay online with a couple clicks Our system is tailored to your needs",
    imgSrc:
      "https://images.pexels.com/photos/7821899/pexels-photo-7821899.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    name: "Set up recurring billing",
    description:
      "Set up recurring invoices to automatically bill repeat clients weekly, monthly, or quarterly. Our system is tailored to your needs",
    imgSrc:
      "https://images.pexels.com/photos/4484078/pexels-photo-4484078.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    name: "Get paid on time",
    description:
      "Send auto-reminders to clients so you don’t have to chase down payments. Our system is tailored to your needs",
    imgSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650313495/Branding%20Invu%20/Payments%20on%20your%20computer/Downloader.la_-625dc88a68986_bjayhp.jpg",
  },
];

const Payment_on_computer = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | On Your Computer Payment");

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://images.pexels.com/photos/8636625/pexels-photo-8636625.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-70"></div>
        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                Pay on your computer
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              Accept payments {""}
              <strong class="font-extrabold text-white sm:block">
                on your computer.
              </strong>
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              A virtual terminal turns your computer into a credit card
              terminal. It’s perfect for remote billing or taking credit cards
              over the phone.
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments-overview"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div class="px-4 py-16 mx-auto max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            {features.map((feature) => (
              <a class="block p-4 rounded-lg shadow-sm shadow-indigo-100 ">
                <img
                  alt="123 Wallaby Avenue, Park Road"
                  src={feature.imgSrc}
                  class="object-cover w-full h-70 rounded-md"
                />

                <div class="mt-4 p-2">
                  <h5 class="text-xl font-bold">{feature.name}</h5>

                  <p class="mt-3 mb-3 text-sm text-gray-500">
                    {feature.description}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-pirBlack mt-12">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; On your computer
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Manage and run your business more easily.
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Keep track of your business
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">Stay up to date</span> using
                    the real-time reporting tools in your Invu Dashboard.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Create better customer relationships
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Create customer profiles, add notes, and view purchase
                      histories
                    </span>{" "}
                    using Invu Customer Directory
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Access your payment processing funds quickly
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Get paid as fast as the next business day free, instantly
                      for a small fee,
                    </span>{" "}
                    or with the free Invu Checking as soon as a payment is
                    charged.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7793138/pexels-photo-7793138.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; On your computer
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Take in-person payments, too.
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Set up promotions
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      To entice your customers
                    </span>{" "}
                    to shop online again or visit you again
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Organize your customers by categories
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Analyze their online behavior, you can even differentiate
                      between
                    </span>{" "}
                    those who bought online and those who bought in store.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Collect personal data
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      To segment and send personalized promotions
                    </span>{" "}
                    such as a gift discount.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    Create mailing campaigns
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      With the drag and drop editor
                    </span>{" "}
                    or choose from the available templates
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/8939564/pexels-photo-8939564.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Payment_on_computer;
