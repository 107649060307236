import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_sales_offline_online = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "Ventajas de unificar, con el Sistema POS, las ventas on line y off line de un negocio | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Conozca el aspecto que tiene una integración de sistema POS de comercio electrónico, venden en mas lugares al mismo tiempo, mejora la experiencia al cliente. "
        />
        <meta name="keywords" content="Paginas web para restaurantes" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2013/Cover%20blog%2013.png"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 07, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Ventajas de unificar, con el Sistema POS, las ventas online y
              offline de un negocio
            </h1>

            <p className="text-gray-500 max-w-xl">
              Es evidente: tener tienda on line es, a día de hoy, fundamental
              para fomentar el éxito de un negocio off line. No en vano, el
              mercado del comercio electrónico es enorme. A principios de 2021,
              las ventas de comercio electrónico alcanzaron los $4.891 mil
              millones.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-2  leading-7 mb-8">
            En 2024, los expertos dicen que habremos aumentado a alrededor de
            $6.388 mil millones por año. Sin embargo, las ventas offline siguen
            representando una parte muy importante de las ventas globales. Para
            cumplir con las expectativas del cliente, tanto en la tienda física
            como en la tienda on line, resulta absolutamente fundamental tener
            integradas ambas partes del negocio.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <p className="mt-2 text-gray-700 leading-7">
            Un reciente estudio, realizado por la prestigiosa publicación
            Harvard Business Review, demuestra que, el cliente que hace una
            investigación on line de lo que pretende comprar luego en la tienda
            física, acaba gastando un 13% más, de lo que tenía pensado
            previamente, en su visita al comercio off line de la marca
            investigado. Pero esto sólo ocurre si, previamente, ha visto
            cubiertas sus expectativas como cliente.
          </p>

          <p className="mt-2 text-gray-700 leading-7 mb-6">
            El problema es que, si cuando una empresa hacía, exclusivamente,
            ventas off line, no tenía una correcta gestión de su inventario, la
            suma de una ventana de ventas on line puede ser todo un quebradero
            de cabeza. Sin embargo, como para casi todo en la vida, también aquí
            hay una solución para este problema: conectar el sistema POS (punto
            de venta) off line a la plataforma de comercio electrónico on line.
          </p>

          <h2 className="font-semibold text-xl mt-8">
            Una integración imprescindible
          </h2>
          <p className="mt-2 text-gray-700 leading-7 ">
            Así es. La integración del punto de venta físico con la tienda de
            comercio electrónico resulta imprescindible para cualquier negocio
            minorista que opere off y on line. Los dos canales deben hablar
            entre ellos para que ventas, inventario y datos de clientes fluyan,
            sin problemas, entre uno y otro. De este modo, se ahorra tiempo y se
            reduce la posibilidad de un error humano.
          </p>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Pero, ¿qué es exactamente un sistema POS que integre tienda física y
            comercio electrónico? En pocas palabras, es una solución digital que
            permite procesar pedidos o transacciones a través de varios canales.
            Esto es posible gracias a que un POS puede manejar pedidos y
            transacciones tanto offline como online.
          </p>

          <h2 className="font-semibold text-xl mt-8">
            6 beneficios fundamentales de una integración POS de comercio
            electrónico:
          </h2>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Posibilidad de vender en más lugares al mismo tiempo <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Un negocio físico no puede estar en varios sitios a la vez, pero
              una tienda on line, sí. Al integrar canales, el propietario de una
              empresa tendrá una visión holística de su negocio y de sus
              clientes sin necesidad de tener que hacer una suma manual de
              números (ventas, clientes, material que queda en stock…).
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Ver el inventario a tiempo real y evitar vender lo que no se tiene{" "}
              <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Cualquier dueño de negocio o gerente de comercio electrónico sabe
              lo importante que es optimizar la gestión de su inventario.
              Ofrecer algo que ya se ha vendido o tener mercancía no registrada
              en su almacén es algo a evitar. Esto es especialmente importante
              si desea utilizar un modelo mixto de compra on line y recogida en
              tienda. O sea, un modelo de hacer clic y recoger. Los clientes
              necesitan saber que el artículo que ordenaron online, para
              recogerlo en la tienda física, estará allí.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              No tener que inventariar de manera manual <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              La integración de un sistema POS de comercio electrónico libera al
              propietario de un negocio de tener que hacer inventario a mano.
              Invu POS permite inventariar desde un solo lugar, dejando que el
              sistema se encargue de actualizar el estado del inventario. El
              ahorro de tiempo y esfuerzo se puede dedicar a desarrollar otros
              aspectos del negocio.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Ofrecer promociones y descuentos a través de varios canales{" "}
              <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Muchas plataformas de comercio electrónico facilitan la opción de
              poder ofrecer promociones, ofertas y descuentos a sus clientes.
              Sin embargo, la integración del sistema POS permite ir un paso más
              allá. Y es que ya no hay que aplicar, manualmente, las campañas de
              promoción según vayan dirigidas al canal online y offline. El
              sistema lo hace de forma automática. Simplemente, hay que agregar
              una capa de personalización, habilitando promociones.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Obtener más información de los clientes… y aprovecharla para
              vender más <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Al integrar POS, se tendrá una visión clara del comportamiento de
              compra de los clientes. Esto es posible porque, al tener todos los
              datos de clientes en un solo lugar, se puede comprobar el
              comportamiento de estos tanto en la tienda física como en la
              tienda on line. A su vez, permite hacer recomendaciones de forma
              más personal y, también, planear estrategias de venta mucho más
              eficaces.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Mejorar la experiencia de cliente <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Al conectar el sistema POS a una tienda online, se crea una
              experiencia perfecta para los clientes. Esto sucede porque se les
              está permitiendo pagar de varias formas gracias al software POS,
              realizando pedidos online sin problemas. Incluso, desde el lado
              del propietario, se podrán integrar iniciativas de marketing como
              programas de fidelización.
            </p>
          </ul>

          {/* <h2 className="font-semibold text-xl mt-8">
            Anticipar el flujo de caja
          </h2>
          <p className="mt-2 text-gray-700 leading-7 ">
            Más allá del desarrollo de análisis de situación, anticipar el flujo
            de caja futuro permitirá adelantarse a futuras situaciones, ya sean
            estas positivas o negativas. El primer paso para hacer esta
            previsión de flujo de caja consistirá en identificar cobros y pagos.
            Servirá una simple hoja Excel, colocando a un lado los pagos y al
            otro los cobros. La previsión se puede hacer siguiendo diferentes
            periodos de tiempo, ya sean mensuales, trimestrales o anuales. Para
            ello es necesario tener en cuenta las fechas en las que el dinero
            sale de la caja, no la fecha que aparece en las facturas.
          </p>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Cuando se tengan clasificados gastos e ingresos se podrá establecer
            una previsión de flujo de caja a partir de los resultados que se
            obtengan de esta clasificación. Es posible hacer una anticipación de
            gastos recurrentes, como también hacerse una idea de los ingresos
            que se pueden tener en el futuro según diversos escenarios.
          </p> */}

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_sales_offline_online;
