import React, { useState } from 'react';
import Modal from 'react-modal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FaArrowLeft, FaArrowRight, FaDownload } from 'react-icons/fa';
import { useTranslation } from "react-i18next";


// Imágenes de ejemplo con el formato especificado
const images = [
  {
    url: 'https://img.invupos.com/webinvu/logotypes%20resources/logo-INVU-POS-rgb%284%29.png',
    title: 'Terminal-de-punto-de-venta.jpeg',
    format: '.png'
  },
  {
    url: 'https://img.invupos.com/webinvu/logotypes%20resources/logo-INVU-POS-negro.png',
    title: 'Impresion-factura.jpeg',
    format: '.png'
  },
  {
    url: 'https://img.invupos.com/webinvu/logotypes%20resources/logo-INVU-POS-negro.jpg',
    title: 'Impresion-factura.jpeg',
    format: '.jpeg'
  },
  {
    url: 'https://img.invupos.com/webinvu/logotypes%20resources/logo-INVU-POS-rgb-blanco.jpg',
    title: 'Impresion-factura.jpeg',
    format: '.jpeg'
  },
];

const Logos = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [filter, setFilter] = useState('all');

  const openModal = (index) => {
    setCurrentImage(index);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  const prevImage = () => setCurrentImage((currentImage - 1 + filteredImages.length) % filteredImages.length);
  const nextImage = () => setCurrentImage((currentImage + 1) % filteredImages.length);

  const downloadImage = async (url, format) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `image${format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAllImages = async () => {
    const zip = new JSZip();

    await Promise.all(
      images
        .filter(image => filter === 'all' || image.format === filter)
        .map(async (image, index) => {
          const response = await fetch(image.url);
          const blob = await response.blob();
          zip.file(`image${index + 1}${image.format}`, blob);
        })
    );

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'images.zip');
    });
  };

  const filteredImages = images.filter(image => filter === 'all' || image.format === filter);

  // Reiniciar currentImage cuando cambia el filtro
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentImage(0);
  };

  return (
    <>

<div class="px-4 mt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
  <div class="bg-gradient-to-r from-purple-600 to-blue-400 bg-center p-4 rounded-lg text-center">
   
  <div class="grid justify-center sm:grid-cols-2 sm:items-center gap-4">
      <div class="flex items-center gap-x-3 md:gap-x-5">
        <div class="grow lg:text-left sm:text-left">
          <p class="md:text-xl text-xl text-gray-800 font-semibold dark:text-neutral-200">
            {t("saladeprensafiltertext")}
          </p>
          <p class="text-sm md:text-base text-gray-800 dark:text-neutral-200">
          {t("saladeprensafilterdescription")}
          </p>
        </div>
      </div>

      <div class="text-center sm:text-start flex sm:justify-end sm:items-center gap-x-3 md:gap-x-4">
        <button
          onClick={downloadAllImages}
          className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        >
          Descargar todas
        </button>
        <select
          value={filter}
          onChange={handleFilterChange}
          className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border bg-transparent text-white"
        >
          <option value="all">Todos</option>
          <option value=".png">PNG</option>
          <option value=".jpeg">JPEG</option>
        </select>
      </div>
    </div>

  </div>
</div>
  
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16 mb-32">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {filteredImages.map((image, index) => (
          <div key={index} className="relative cursor-pointer group">
            <img 
              src={image.url} 
              alt={image.title} 
              className="w-full h-auto object-cover" 
              onClick={() => openModal(index)}
            />
            {/* <div className="mt-2 text-left text-xs text-gray-700">
              {image.title + image.format}
            </div> */}
            <button 
              onClick={() => downloadImage(image.url, image.format)} 
              className="absolute top-2 right-2 bg-white p-1 rounded-full text-black opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <FaDownload />
            </button>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          onClick={closeModal}
        >
          <div className="relative p-8 rounded" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 m-2 text-black text-2xl"
            >
              &times;
            </button>
            <img
              src={filteredImages[currentImage].url}
              alt={filteredImages[currentImage].title}
              className="lg:max-w-2xl lg:min-w-2xl max-w-full min-h-full border-2"
            />
            <div
              className="absolute top-1/2 left-2 transform -translate-y-1/2 text-black text-3xl cursor-pointer bg-white rounded-full shadow-lg p-2"
              onClick={prevImage}
            >
              <FaArrowLeft />
            </div>
            <div
              className="absolute top-1/2 right-2 transform -translate-y-1/2 text-black text-3xl cursor-pointer bg-white rounded-full shadow-lg p-2"
              onClick={nextImage}
            >
              <FaArrowRight />
            </div>
            <button
              onClick={() => downloadImage(filteredImages[currentImage].url, filteredImages[currentImage].format)}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-full flex gap-2 items-center"
            >
              <FaDownload /> Descargar Imagen
            </button>
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default Logos;
