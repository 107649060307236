import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";

const features = [
  {
    name: "Payments solutions, at any scale",
    description:
      "Our platform processes millions of payments every day and we are the utmost experts in payments. We work directly with multiple networks and acquirers on your behalf so you can rely on your cash flow coming in.",
    imgSrc:
      "https://images.pexels.com/photos/5239812/pexels-photo-5239812.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
  },
  {
    name: "Compliance is on us with Invu POS",
    description:
      "As the merchant of record, our reputation is at stake as much as yours. We’re dedicated to protecting both sellers and customers.",
    imgSrc:
      "https://images.pexels.com/photos/3182774/pexels-photo-3182774.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
  },
  {
    name: "Data Security is taken care of",
    description:
      "With end-to-end encryption, customer’s payment data never touches your servers or devices. Our system is battle-tested every day, 24/7",
    imgSrc:
      "https://images.pexels.com/photos/7868972/pexels-photo-7868972.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
  },
  {
    name: "We minimize your payment risk",
    description:
      "we keep you ahead of fraud. No extra fees, it’s all included in your rate. With B ecosystem of payments and a dedicated in-house team of data scientists and risk analysts.",
    imgSrc:
      "https://images.unsplash.com/photo-1586864030223-a918b07d357d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cG9zfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    altImg: "",
  },
];

const Payment_platform = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Platform Payment");
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://images.pexels.com/photos/5711939/pexels-photo-5711939.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                Payment platform
              </p>
            </div>
            <h1 class="text-3xl text-white font-extrabold sm:text-5xl">
              The payment platform {""}
              <strong class="font-extrabold text-white sm:block">
                designed to save you time.
              </strong>
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              You can now leverage the payment platform millions of businesses
              depend on to meet your customers’ unique needs. It’s flexible,
              secure, and reliable—so you can focus on your business, not on
              your payments.
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments-overview"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            What our customers are saying
          </h1>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h1 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            What our customers are saying
          </h1>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <div class="grid grid-cols-1 mt-10 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            {features.map((feature) => (
              <div className="rounded border bg-white">
                <a>
                  <img
                    src={feature.imgSrc}
                    class="object-cover w-full h-72  bg-center rounded-t-lg"
                    alt=""
                  />
                </a>
                <div className="p-8">
                  <h5 class="text-xl font-bold mb-4">{feature.name}</h5>
                  <p class="mb-3 text-sm max-w-md  font-normal text-gray-500">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section class="mt-16">
        <div class="max-w-screen-xl px-4 py-16 mx-auto lg:items-center lg:flex">
          <div class=" mx-auto text-center">
            <h1 class="text-3xl font-extrabold sm:text-5xl">
              Solutions for every
              <strong class="font-extrabold  sm:block">
                way you want to sell.
              </strong>
            </h1>

            <p class="mt-4  text-sm lg:text-base text-gray-500 leading-loose">
              There are no setup fees or monthly fees. Only pay when you take a
              payment.
            </p>

            <div class="flex flex-wrap justify-center gap-4 mt-8">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white bg-indigo-600 rounded shadow sm:w-auto 0 hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium text-indigo-600 rounded shadow sm:w-auto hover:text-indigo-700  focus:outline-none focus:ring"
                href="/point-of-sale"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Take payments on your website
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Accept payments securely from your eCommerce site with our
                  online payments APIs (payment gateway) and customizable
                  payment form.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments/on-your-website"
              >
                <span class="text-sm font-medium"> Pay on website </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/6207699/pexels-photo-6207699.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Build your software solution, or use ours
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Use Invu hardware to take in-person payments. Or build a
                  custom point of sale, self-service kiosk, or any other
                  in-person payment solution with our Terminal API
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments/in-person-payments"
              >
                <span class="text-sm font-medium"> Pay in person </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7679466/pexels-photo-7679466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Take payments in your mobile app.
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Now you can easily integrate Invu payment gateway into your
                  app, allowing customers to make purchases directly.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> Contact sales </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7821899/pexels-photo-7821899.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <Help_CTA />
      <FAQ_model_one />
    </>
  );
};

export default Payment_platform;
