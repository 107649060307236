import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useTranslation } from "react-i18next";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Form_Contact = () => {
  
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");
  const [registrationId, setRegistrationId] = useState(null);

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const leadStatus = "Nuevo"
  

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const history = useHistory();

  const location = useLocation();
  const prefix = useLocalePrefix();

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
      })
      .catch((error) => {
        console.log(error);
      })
  };
  
  useEffect(() => {
    getGeoInfo();
  }, []);

  function generateUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  var hutkValue = generateUUID();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/be316fe8-ea4c-4aa2-ad2a-b5750f006e01";

    var data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },

        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "name",
          value: name,
          objectTypeId: "COMPANY",
        },
        {
          name: "message",
          value: message,
        },
        {
          name: "hs_lead_status",
          value: leadStatus,
          objectTypeId: "CONTACT",
        }
      ],
      context: {
        hutk: hutkValue,
        ipAddress: geoInfo.ip,
        pageUri: "www.invupos.com",
        pageName: "formulario de contacto invu pos"
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const responseData = JSON.parse(xhr.responseText);
        const registrationId = responseData.inlineMessage;

        history.push("/request-demo-confirmed-external", {
          firstName,
          lastName,
          email,
          phone,
          name,
          message,
          leadStatus,
          registrationId,
          hutkValue,
        });
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        //TODO @YannickAlexander poner el toster bonito si responde un arreglo xhr.data.errors
        // alert(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setName("");
    setIsChecked(false); // Limpia el checkbox
  };

return (
    <>

<div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity  bg-gradient-to-bl from-blue-100"></div>

<div class="fixed inset-0 z-10 overflow-y-auto bg-white bg-gradient-to-bl from-[#efeeff]">
  <div class="max-w-[80rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    <div class="grid items-center md:grid-cols-2 gap-8 lg:gap-12">
        
      <div>
        <div style={{ maxWidth: 'fit-content', textAlign: 'right' }}>
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img
              className="h-16 w-auto cursor-pointer"
              src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
              alt=""
            />
          </Link>
        </div>  
        <p class="inline-block mt-12 text-sm font-medium bg-clip-text uppercase font-semibold tracking-widest bg-gradient-to-l from-blue-600 to-violet-500 text-transparent dark:from-blue-400 dark:to-violet-400">
          {t("conctacexternaluppercase")}
        </p>

        <div class="mt-4 md:mb-12 max-w-2xl">
          <h1 class="mb-4 font-semibold text-gray-800 text-4xl lg:text-5xl">
            {t("conctacexternaltitle")}
          </h1>
          <p class="text-gray-500 max-w-lg">
          {t("contactexternaldescription")} <span className="font-semibold">{t("contactexternaldescriptionspan")}</span>
          </p>
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div class="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto">
            <div class="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg">
              <div>

                <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6">{t("contactexternalformuppercase")}</div>
                
                <div className="space-y-4">
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-3">
                        <div class="">
                            <input
                            placeholder={t("contactexternalformfirstname")}
                            class="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            required
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div class="">
                            <input
                            placeholder={t("contactexternalformlastname")}
                            class="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            required
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div class="">
                            <input
                            placeholder={t("contactexternalformemail")}
                            class="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            required
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div class="">
                            <PhoneInput
                            placeholder={t("contactexternalformphone")}
                            className="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            defaultCountry="PA"
                            required
                            value={phone}
                            onChange={(value) => setPhone(value)}
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-1">
                        <div class="">
                            <input
                            placeholder={t("contactexternalformnamecompany")}
                            class="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-1">
                        <div class="">
                            <textarea
                            rows="3"
                            placeholder={t("contactexternalformmessage")}
                            class="w-full p-4 text-sm border border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:ring-[#4f46e5] focus:shadow-outline rounded-lg"
                            required
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>


                <div class="mt-3 flex items-start">
                    <div class="flex">
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} placeholder="Privacy Policy" required class="shrink-0 mt-1.5 text-blue-600 border-gray-200 rounded focus:ring-blue-500"/>
                    </div>
                    <div class="ms-3 ml-2">
                    <label for="remember-me" class="text-sm text-gray-500">{t("contactexternalformpolicy1")}  <Link to={"/politicas-de-privacidad"} class="text-indigo-600 decoration-2 underline font-medium">{t("contactexternalformpolicyspan")}</Link> {t("contactexternalformpolicy2")}</label>
                    
                    </div>
                </div>
                <div class="mt-5">
                  <button  type="submit" value="Submit" class="w-full inline-block rounded bg-indigo-600 px-8 py-3 text-sm text-white font-bold transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-indigo-500">{t("contactexternalformbutton")}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div style={{ zIndex: 1000 }}>
          <Toaster position="bottom-center" reverseOrder={false} />
          </div>
      </div>
    </div>

    <div class="mt-6 md:mt-20 flex uppercase opacity-75 text-[#1F2937] tracking-wider items-center font-semibold text-sm  gap-x-1.5 after:flex-[1_1_0%] after:border-t">
      {t("contactexternaltextfooter")}
    </div>

    <div class="">
    <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3 sm:grid-cols-2">
            <div class="p-4 rounded-lg bg-[#F8F8FF] md:p-5">
                <span class="inline-block p-3 text-blue-500 rounded-lg bg-[#d9d6fe]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="#693aed" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                    </svg>
                </span>

                <h2 class="mt-4 text-lg font-semibold">{t("contactexternaltextcardphone")}</h2>
                <p class="mt-2 text-base text-gray-700">+507 201-8490</p>
            </div>

            <div class="p-4 rounded-lg bg-[#F8F8FF] md:p-5">
                <span class="inline-block p-3 text-blue-500 rounded-lg bg-[#d9d6fe]">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="#693aed" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 9l9 6l9 -6l-9 -6l-9 6" />
                    <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                    <path d="M3 19l6 -6" />
                    <path d="M15 13l6 6" />
                </svg>
                </span>

                <h2 class="mt-4 text-lg font-semibold">{t("contactexternaltextcardemail")}</h2>
                <p class="mt-2 text-base text-gray-700">info@invupos.com</p>
            </div>

            <div class="p-4 rounded-lg bg-[#F8F8FF] md:p-5">
                <span class="inline-block p-3 text-blue-500 rounded-lg bg-[#d9d6fe]">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="#693aed" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                </svg>
                </span>

                <h2 class="mt-4 text-lg font-semibold">{t("contactexternaltextcardlocation")}</h2>
                <p class="mt-2 text-base text-gray-700">{t("contactexternaltextcardlocationvalue")}</p>
            </div>
        </div>
    </div>
  </div>
</div>
</div>
    </>
  )
}

export default Form_Contact