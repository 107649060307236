import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_online_ordering = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "4 beneficios de los pedidos en línea para propietarios de restaurantes | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Los pedidos en línea para restaurantes recibieron un fuerte
          impulso durante el confinamiento. Posteriormente, y durante los
          meses transcurridos de 2022, esta modalidad de consumir comida se
          ha consolidado como una de las principales fuentes de ingresos
          para este tipo de negocios."
        />
        <meta name="keywords" content="Control de sucursales" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2018/Cover%20blog%2018.jpeg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                August 12, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              4 beneficios de los pedidos en línea para propietarios de
              restaurantes
            </h1>

            <p className="text-gray-500 max-w-2xl">
              Los pedidos en línea para restaurantes recibieron un fuerte
              impulso durante el confinamiento. Posteriormente, y durante los
              meses transcurridos de 2022, esta modalidad de consumir comida se
              ha consolidado como una de las principales fuentes de ingresos
              para este tipo de negocios.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-2  leading-7 mb-8">
            Las estadísticas demuestran que las generaciones Z y Millennials
            están detrás de una gran parte de este desarrollo. Hablamos de
            sistemas de pedidos que permiten a los clientes hacer sus encargos
            directamente con los restaurantes, sin la intervención de ningún
            otro intermediario y que se están mostrando beneficiosas tanto para
            restaurantes como para clientes. Unos y otros dicen preferir este
            trato directo.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <p className="mt-2 text-gray-700 leading-7">
            Existen tres maneras de implementar un sistema de pedidos en un
            restaurante:
          </p>

          <ul class="list-decimal  list-inside mt-5 px-4">
            <li className="text-xl leading-7 font-bold">
              A través del punto de venta (POS)
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              En este caso, el POS toma nota del pedido, lo reenvía a la cocina
              del local y avisa a la persona responsable cuando el cliente o el
              repartidor han recogido su demanda.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              POS integrado en una aplicación de pedidos de terceros
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              Algunos POS no tienen un software nativo de pedidos integrado. En
              este supuesto, lo que hace el restaurante es sincronizar su POS
              con una aplicación externa pagando un coste adicional. La ventaja
              de este método es que la configuración es sencilla, pudiendo estar
              activo el sistema de pedidos en poco tiempo. Además, podrá
              almacenar información de los pedidos de los clientes, combinando
              los datos obtenidos de sus encargos en línea con las compras en el
              local.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Integración de los pedidos en aplicaciones externas
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              La gestión en la reserva es 100% responsabilidad de una app
              externa. Específicamente diseñadas para la industria del
              restaurante, estas aplicaciones son muy intuitivas para
              implementarlas y comenzar a operar. Las desventajas son las altas
              comisiones (entre un 10 y un 30% de cada pedido) y la posibilidad
              de que surjan problemas de compatibilidad.
            </p>
          </ul>

          <p className="mt-2 text-gray-700 leading-7">
            Existen tres maneras de implementar un sistema de pedidos en un
            restaurante:
          </p>

          <ul class="list-disc  list-inside mt-5 px-4">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold">Costo:</span> las aplicaciones de
              pedidos de terceros y las aplicaciones de entrega en el mercado, a
              veces, cobran una suscripción anual o reciben un porcentaje de
              cada venta.
            </li>
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold">Visibilidad:</span> las aplicaciones
              de terceros pueden costar más, pero ofrecen una forma de llegar a
              más clientes, que tal vez no conozcan el restaurante.
            </li>
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold">Facilidad de uso: </span> hay que
              asegurarse de que cualquier plataforma que se implemente sea
              intuitiva para el software utilizado por el restaurante. No debe
              generar cuellos de botella internos.
            </li>
            <li className="text-gray-700 leading-7 ">
              Experiencia del cliente: Hay que comprobar que el usuario puede
              navegar con facilidad por el menú; hacer una selección; solicitar
              cambios y pagar su consumo.
            </li>
          </ul>

          <p className="mt-2 text-gray-700 leading-7">
            Según diversas estimaciones, los encargos de comida en línea en
            restaurantes aumentarán, a nivel mundial, un promedio anual de un
            20% hasta el año 2030.
          </p>
          <p className="mt-6 italic leading-7">
            Aquí tenemos 4 beneficios que pueden aprovechar los dueños de
            negocios:
          </p>

          <ul class="list-decimal  list-inside mt-5 px-4">
            <li className="text-xl leading-7 font-bold">
              Gestión eficiente de pedidos y clientes
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              A estas alturas ya nadie duda de que un sistema de pedidos on line
              para restaurantes refuerza la experiencia de cliente… y por lo
              tanto la fidelización de éste con el restaurante. La experiencia
              de cliente impulsa el crecimiento del negocio.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Conveniencia de pedidos móviles
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              Una de las principales fortalezas del sistema de pedidos para
              restaurantes es que integra los pedidos que llegan desde los
              teléfonos móviles de los clientes –y, también, a través de la
              página web del restaurante- con el POS. Todas las demandas
              recibidas en línea se insertan, directamente, en el sistema de
              pedidos. No hay posibilidad alguna de que se pierda algún encargo.
              Cualquier pedido recibido en línea también se refleja, en tiempo
              real, en el POS. De igual modo, cualquier cambio realizado en el
              menú se refleja automáticamente en el sitio web y en la aplicación
              móvil del restaurante.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Programa de fidelización integrado
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              La integración de un programa de fidelización con el sistema de
              reservas en línea también facilita la retención de clientes. Esto
              es así gracias a que el CRM actualiza, de forma automática, los
              datos de cliente que se recopilan tanto desde la app como desde la
              página web. En función de los patrones de consumo que se vayan
              desarrollando, los propietarios de los negocios pueden crear
              ofertas personalizadas y, también, enviar notificaciones push
              directas a través de la aplicación. Incluso para los clientes, la
              integración del programa de fidelización les facilita el
              seguimiento de su propio historial de pedidos.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Monitoreo de su flujo de efectivo
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              Un sistema de punto de venta (PDV) es una terminal de
              transacciones computarizada que consiste en una computadora con un
              monitor de pantalla táctil, una caja registradora, un lector de
              tarjetas de crédito, una impresora de recibos y a menudo un
              escáner de código de barras. Esta es la base del sistema de
              gestión de flujo de efectivo, cuyo monitoreo resulta clave para el
              mantenimiento de la buena salud financiera de un restaurante.
              Tener monitorizado el flujo de caja permite tener una visión clara
              del estado de tesorería del negocio. También, estimar la capacidad
              financiera del mismo y sus necesidades futuras de fondos. Los
              propietarios de restaurantes saben que, monitoreando el flujo de
              caja, mejoran, exponencialmente, las posibilidades de hacer crecer
              sus negocios.
            </p>
          </ul>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_online_ordering;
