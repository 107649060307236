import React from "react";
const view_all_jobs = [
  {
    category: "Human Resources",
    name: "Tech Talent Acquisition",
    description_job:
      "The Customer Success Operations team manages the day-to-day operations of the CS organization Workforce Management Vendor Management, Tools/Infrastructure.",
    imgSrc:
      "https://images.pexels.com/photos/7652178/pexels-photo-7652178.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    altImg: "",
    qualification_item1: "Bachelor's degree or equivalent practical experience",
    qualification_item2:
      "4 years of experience in SaaS, IaaS, and PaaS sales and/or consulting",
    qualification_item3:
      "5 years of experience with SAP applications and positioning, including S/4, ERP",
  },
];

const Careers_human_resources = () => {
  return (
    <>
      <section className="">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            {view_all_jobs.map((job) => (
              <article class="bg-white border-2 border-gray-100 rounded-xl">
                <div class="flex justify-end">
                  <strong class="-mr-[2px] -mb-[10px] inline-flex items-center gap-1 rounded-tl-xl rounded-br-xl bg-green-600 py-1.5 px-3 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      />
                    </svg>

                    <span class="text-[10px] font-medium sm:text-xs">
                      verified
                    </span>
                  </strong>
                </div>
                <div class="flex items-start p-6">
                  <a
                    href=""
                    class="hidden sm:grid bg-gray-100 shadow rounded-lg  sm:shrink-0 sm:place-content-center"
                  >
                    <img
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                      alt="User Avatar"
                      class="object-cover rounded-lg p-2 h-14 w-14"
                    />
                  </a>

                  <div class="ml-4">
                    <strong class="rounded   bg-indigo-200 px-3 py-1.5 text-[10px] font-medium text-indigo-700">
                      {job.category}
                    </strong>

                    <h2 class="mt-4 text-lg font-medium sm:text-xl">
                      <a href="" class="hover:underline">
                        {" "}
                        {job.name}{" "}
                      </a>
                    </h2>

                    <p class="mt-1 text-sm text-gray-700">
                      {job.description_job}
                    </p>

                    <ul class="list-inside list-disc mt-4">
                      <li className="text-sm text-gray-500">
                        {job.qualification_item1}
                      </li>
                      <li className="text-sm text-gray-500">
                        {job.qualification_item2}
                      </li>
                      <li className="text-sm text-gray-500">
                        {job.qualification_item3}
                      </li>
                    </ul>
                    <dl class="flex items-center mt-6 space-x-8 text-xs">
                      <div class="sm:inline-flex sm:items-center sm:shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-indigo-700"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>

                        <div class="sm:ml-3 mt-1.5 sm:mt-0">
                          <dt class="text-gray-500">Location</dt>

                          <dd class="font-medium">
                            San Francisco, Panama City
                          </dd>
                        </div>
                      </div>

                      <div class="sm:inline-flex sm:items-center sm:shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-indigo-700"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>

                        <div class="sm:ml-3 mt-1.5 sm:mt-0">
                          <dt class="text-gray-500">Contract</dt>

                          <dd class="font-medium">Full time</dd>
                        </div>
                      </div>
                    </dl>
                    <dl class="flex items-center  mt-6 space-x-8 text-xs">
                      <div class="sm:inline-flex sm:items-center sm:shrink-0">
                        <a
                          class="flex items-center justify-between px-4 py-2 transition-colors bg-indigo-600 border border-indigo-600 rounded-md hover:bg-transparent group focus:outline-none focus:ring"
                          href="/download"
                        >
                          <span class="font-medium text-white transition-colors group-active:text-indigo-500 group-hover:text-indigo-600">
                            Apply now
                          </span>

                          <span class="flex-shrink-0 ml-4 text-indigo-600 bg-white border border-current rounded-full group-active:text-indigo-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5 p-0.5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                              />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </dl>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers_human_resources;
