import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import Customers_saying_CTA from "../Home/Customers_saying_CTA";
import Partners from "../Home/Partners";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import FAQ from "./FAQ";
import Industries_carousel_pa from "./Industries_carousel_pa";
import CTA_home from "../Home/CTA_home";

const Dealers = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("DISTRIBUIDORES | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      {/* <div> 
        <section>
          <div className="max-w-screen-xl -mt-1 px-4 py-16 mx-auto sm:px-6 lg:px-8 mt-10">
            <div className="grid lg:gap-24 gap-8 row-gap-8 lg:grid-cols-2">
              <div className="flex flex-col justify-center">
                <div className="max-w-xl mb-6">
                  <h2 className="mb-3 text-xs font-semibold tracking-widest text-gray-700 uppercase title-font text-pirGray-100">
                    &mdash; {t("resourcespartnersdistributorssection3uppercase")}
                  </h2>
                  <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                    {t("resourcespartnersdistributorstitle")}
                  </h2>
                  <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-700 leading-loose">
                    {t("resourcespartnersdistributorsdescription")}
                  </p>
                </div>
                <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                  <div className="bg-white border-l-4 shadow-sm border-indigo-600">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        {t("resourcespartnersdistributorscard1title")}
                      </h6>
                      <p className="text-sm text-gray-900">
                        {t("resourcespartnersdistributorscard1description")}
                      </p>
                      <Link
                        to={`${prefix}` + "/venta-minorista/general"}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <div class="inline-block pb-1 mt-6 font-semibold text-indigo-600 border-b border-indigo-600 ">
                          {t("resourcespartnersdistributorscard1button")}{""}
                          <span aria-hidden="true"> &rarr;</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="bg-white border-l-4 shadow-sm border-indigo-600">
                    <div className="h-full p-5 border border-l-0 rounded-r">
                      <h6 className="mb-2 font-semibold leading-5">
                        {t("resourcespartnersdistributorscard2title")}
                      </h6>
                      <p className="text-sm text-gray-900">
                        {t("resourcespartnersdistributorscard2description")}
                      </p>
                      <Link
                        to={`${prefix}` + "/mas-herramientas/integraciones"}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <div class="inline-block pb-1 mt-6 font-semibold text-indigo-600 border-b border-indigo-600 ">
                          {t("resourcespartnersdistributorscard2button")}{""}
                          <span aria-hidden="true"> &rarr;</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img
                  className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                  src="https://img.invupos.com/webinvu/Distributors/Distributors%201.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 py-24 mt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
            <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
              <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  {t("resourcespartnersdistributorssection2title")}
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                  {t("resourcespartnersdistributorssection2description")}
                </p>
              </div>
              <div>
                <button
                  onClick={() => setIsOpen(true)}
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-indigo-600 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  {t("resourcespartnersdistributorssection2button")}
                </button>
              </div>
            </div>
          </div>
        </section>
        <Partners />
        <section className="bg-pirBlack">
          <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  {t("resourcespartnersdistributorssection3title")}
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection3item1subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection3item1description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection3item2subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection3item2description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection3item3subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection3item3description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection3item4subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection3item4description")}
                    </p>
                  </div>
                </div>

                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection3item5subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection3item5description")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirDarkGray">
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div class="order-none lg:order-2 md:order-none">
                <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                  &mdash; {t("resourcespartnersdistributorssection4uppercase")}
                </h2>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  {t("resourcespartnersdistributorssection4title")}
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection4item1subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection4item1description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection4item2subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection4item2description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection4item3subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection4item3description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection4item4subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection4item4description")}
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      {t("resourcespartnersdistributorssection4item5subtitle")}
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      {t("resourcespartnersdistributorssection4item5description")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://img.invupos.com/webinvu/Distributors/Distributors%203.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <FAQ_model_one />
      <Help_CTA /> */}


    <section className="bg-gray-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
        <div className="grid gap-16 lg:grid-cols-2">
          <div className="lg:pr-10 content-center">
            <h1 class="font-bold xl:text-[47px] lg:text-5xl md:text-5xl text-4xl text-[#002D33]">
            {t("dealertitlesection1")}
            </h1>
            <p class="mt-5 text-gray-700 text-lg leading-loose max-w-2xl font-light">
            {t("dealerdescriptionsection1")}
            </p>
            <div class="mt-7 grid gap-3 w-full sm:inline-flex">
              <button 
              onClick={() => setIsOpen(true)}
              class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                {t("dealerbuttonsection1")}
              </button>
            </div>
            <div class="mt-6 lg:mt-10 mb-6">
              <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
              <div class="mt-4 flex gap-x-8">

              <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>

              </div>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-96 rounded-2xl shadow-lg sm:h-[520px]"
              src="https://images.pexels.com/photos/3182778/pexels-photo-3182778.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("dealertitlesection2")}
        </h2>
      </div>

      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://images.pexels.com/photos/4475527/pexels-photo-4475527.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("dealercard1title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("dealercard1description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("dealercard3button")}
              </span>
            </button>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
          <img
            src="https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("dealercard2title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("dealercard2description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("dealercard3button")}
              </span>
            </button>
          </div>
        </div>

        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded ">
          <img
            src="https://images.pexels.com/photos/7821702/pexels-photo-7821702.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
            className="object-cover w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <a
              aria-label="Category"
              className="inline-block mb-4 mt-4 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              {t("dealercard3title")}
            </a>
            <p className="mb-2 text-gray-700">
              {t("dealercard3description")}
            </p>
            <button
             onClick={() => setIsOpen(true)}
              class="group mt-4 relative inline-block overflow-hidden border border-indigo-600 px-8 py-2 focus:outline-none focus:ring" 
            >
              <span
                class="absolute inset-y-0 left-0 w-[2px] bg-indigo-600 transition-all group-hover:w-full group-active:bg-indigo-500"
              ></span>

              <span
                class="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
              >
                {t("dealercard3button")}
              </span>
            </button>
          </div>
        </div>
        
      </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-6 lg:px-8 lg:py-20">
      <div className="p-8 rounded-2xl sm:p-20 bg-[#121212] md:px-12 sm:px-12">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-2/5 content-center">
            <h2 className="font-sans text-3xl font-bold tracking-tight text-white sm:text-[50px] sm:leading-none mb-4">
              {t("dealerctatitlesection3")}
            </h2>
            <p className="inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-white uppercase rounded-full">
            {t("dealerctaspansection3")}
            </p>
          </div>
          <div className="lg:w-11/12 content-center">

          <div class="grid xl:grid-cols-5 lg:grid-cols-5 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-5 md:gap-5">
            {/* Card 1 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofuse2k24.png"/>
              </div>
            </div>
            {/* Card 2 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofvalue2k24.png"/>
              </div>
            </div>

             {/* Card 3 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/csupport2k24.png"/>
              </div>
            </div>

             {/* Card 4 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/Getapp.png"/>
              </div>
            </div>

            {/* Card 5 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/nvidia.png"/>
              </div>
            </div>

             {/* Card 6 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofuse2k23.png"/>
              </div>
            </div>
            
              {/* Card 7 */}
              <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/bestofvalue2k23.png"/>
              </div>
            </div>

                {/* Card 8 */}
                <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/csupport2k23.png"/>
              </div>
            </div>

                {/* Card 9 */}
                <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Insignias%20/mrecommended2k23.png"/>
              </div>
            </div>
            
          </div>

          </div>
        </div>
      </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("dealertitlesection4")}
        </h2>
      </div>
      <div className="flex flex-col max-w-screen-xl overflow-hidden bg-white border rounded shadow-sm lg:flex-row sm:mx-auto">
        <div className="relative lg:w-1/2">
          <img
            src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
            className="object-cover w-full lg:absolute h-80 lg:h-full"
          />
          <svg
            className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
            viewBox="0 0 20 104"
            fill="currentColor"
          >
            <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104" />
          </svg>
        </div>
        <div className="flex flex-col justify-center p-8 bg-white lg:p-16 lg:pl-10 lg:w-1/2">
          <h5 className="mb-3 text-3xl font-extrabold leading-none sm:text-4xl">
            {t("dealermaincardtitle")}
          </h5>
          <p className="mb-5 text-gray-800 text-md">
            {t("dealermaincarddescription")}
          </p>
          <div className="flex items-center ">
          <button 
              onClick={() => setIsOpen(true)}
              class="py-4 px-6 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                {t("dealermaincardbutton")}
              </button>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-headset">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 14v-3a8 8 0 1 1 16 0v3" />
              <path d="M18 19c0 1.657 -2.686 3 -6 3" />
              <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
              <path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("dealertitlecard1")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("dealerdescriptioncard1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
              <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
              <path d="M12 12l0 .01" />
              <path d="M3 13a20 20 0 0 0 18 0" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("dealertitlecard2")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("dealerdescriptioncard2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 text-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-analytics">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
              <path d="M7 20l10 0" />
              <path d="M9 16l0 4" />
              <path d="M15 16l0 4" />
              <path d="M8 12l3 -3l2 2l3 -3" />
            </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold ">{t("dealertitlecard3")}</h3>
            <p className="mb-3 text-base text-gray-900">
              {t("dealerdescriptioncard3")}
            </p>
          </div>
        </div>
      </div>
    </div>

      <Industries_carousel_pa/>
      <CTA_home/>
      <FAQ/>

    </>
  );
};

export default Dealers;
