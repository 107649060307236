import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import Modal_started from '../../components/Home/Modal_started';
import useLocalePrefix from '../../components/utils/useLocalePrefix';


const Pricing = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const prefix = useLocalePrefix();


  return (
    <>
    {isOpen && <Modal_started setIsOpen={setIsOpen} />}
<section className="bg-gray-50 mt-16">
        <div class="">
          <div class="container m-auto px-8 py-20 ">
            <div class="mt-12 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
              <div class="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-xl transition duration-500 group-hover:scale-105 lg:group-hover:scale-110"
                ></div>
                <div class="relative p-6 space-y-6 lg:p-8">
                  <h3 class="text-3xl text-[#002D33] font-semibold text-center">
                    {t("aboutusspecialoffertitle")}
                  </h3>
                  <div>
                    <div class="relative flex justify-around">
                      <div class="flex items-end">
                        <span class="text-8xl text-[#002D33] font-bold leading-0">
                          35
                        </span>
                        <div class="pb-2">
                          <span class="block text-2xl text-gray-700 font-bold">
                            %
                          </span>
                          <span class="block text-xl text-[#C81026] font-bold">
                            {t("aboutusspecialofferoff")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    role="list"
                    class="w-max space-y-4 py-6 m-auto text-gray-600"
                  >
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem1")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem2")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferitem3")}</span>
                    </li>
                  </ul>
                  <p class="lg:flex items-center justify-center space-x-4 text-lg text-gray-600 text-center">
                    <span>{t("aboutusspecialofferspancontact")}</span>
                    <a
                      href="tel:+24300"
                      class="flex space-x-2 items-center justify-center text-[#006847]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="w-6"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                      <span class="font-semibold">+507 201-8490</span>
                    </a>
                    <span>{t("aboutusspecialofferspancontact1")}</span>
                  </p>
                  <button class="block w-full py-3 px-6 text-center rounded-full transition  bg-[#006847] hover:bg-[#006847] focus:bg-[#006847]">
                    <button
                     onClick={() => {
                      setIsOpen(true);
                    }}
                      class="text-white font-semibold"
                    >
                      {t("aboutusspecialoffercontactbutton")}
                    </button>
                  </button>
                </div>
              </div>

              <div class="relative group md:w-6/12 lg:w-7/12">
                <div
                  aria-hidden="true"
                  class="absolute top-0 w-full h-full rounded-2xl bg-white shadow-lg transition duration-500 group-hover:scale-105"
                ></div>
                <div class="relative p-6 pt-16 md:p-8 md:pl-12 md:rounded-r-2xl lg:pl-20 lg:p-16">
                  <ul role="list" class="space-y-4 py-6 text-gray-600">
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature1")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature2")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>{t("aboutusspecialofferfeature3")}</span>
                    </li>
                    <li class="space-x-2 flex">
                      <span class="text-[#006847] font-semibold">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                          />
                        </svg>
                      </span>
                      <span>
                      <span>{t("aboutusspecialofferfeature4")}</span>
                      </span>
                    </li>
                  </ul>
                  <p class="text-gray-500 text-left text-sm">
                    {t("aboutusspecialofferdescription")}.
                  </p>
                  <div class="mt-6 flex justify-between gap-6">
                    <img
                      class="w-16 lg:w-24"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/Little_caesars_e0f0gu.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-6 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/cinnabon_1_fqrx8o.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-8 lg:w-20"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/KrispyKreme_1_fqcaqz.svg"
                      loading="lazy"
                    />
                    <img
                      class="w-20 lg:w-28"
                      src="https://img.invupos.com/webinvu/Our%20Customers/Customers%20logos%20/YogenFruz_1_1_tph8r7.svg"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
     <div class="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 mx-auto lg:py-16">
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8">

    <div class="hover:shadow-2xl hover:scale-105 transition ease-in-out duration-300 delay-400 divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm">
      <div class="p-6 sm:px-8 mt-2">
        <div class="flex items-start text-left">
          <span class="flex-shrink-0 rounded-[16px] rounded-lg">
            <img
              class="rounded-lg w-14"
              alt="pricing icon"
              src="https://img.invupos.com/invu%20mexico/Ecommerce%20price%20icon.svg"
            />
          </span>

          <div class="ml-4">
            <p class="mt-1 text-base text-gray-600">
              {t("pricemodulecard1plan1")}
            </p>
            <h2 class="text-2xl font-semibold text-gray-900">
              {t("pricemodulecard1title")}
            </h2>
          </div>
        </div>

        <p class="text-left text-[15.5px] text-gray-500 mt-4">{t("pricemodulecard1description")}</p>

        <p class="mt-2 sm:mt-8 text-left">
          <strong class="text-3xl font-bold text-gray-900 sm:text-5xl"> 49$ </strong>
          <span class="text-[16px] font-medium text-gray-700">/ {t("pricemodulecard1month")}</span>
        </p>
        <a
          class="mt-4 block rounded-full border border-[#006847] text-[#006847] hover:text-[#ffffff] bg-[#fff] hover:bg-[#006847] px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-[#006847] focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("pricemodulecard1buttom")}
        </a>
      </div>

      <div class="p-6 sm:px-8 text-left">
        <p class="text-lg font-medium text-gray-900 sm:text-xl mt-3">{t("pricemodulecard1whatincluded")}</p>

        <ul class="mt-2 space-y-2 sm:mt-4 mb-3">
          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item1")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item2")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item3")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item4")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item5")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item6")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard1item7")}</span>
          </li>
        </ul>
      </div>
    </div>

     <div class="hover:shadow-2xl hover:scale-105 transition ease-in-out duration-300 delay-400 relative divide-y divide-gray-200 rounded-2xl border-2 border-[#006847] shadow-sm">
      
      <div class="p-6 sm:px-8">
      <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
        <div className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded bg-[#006847]">
          {t("pricemostpopular")}
        </div>
      </div>
      
      <div class="flex items-start text-left mt-2">
          <span class="flex-shrink-0 rounded-[16px] rounded-lg">
            <img
              class="rounded-lg w-14"
              alt="pricing icon"
              src="https://img.invupos.com/invu%20mexico/Invu%20pos%20price%20icon.svg"
            />
          </span>

          <div class="ml-4">
            <p class="mt-1 text-base text-gray-600">
              {t("pricemodulecard2plan1")}
            </p>
            <h2 class="text-2xl font-semibold text-gray-900">
              {t("pricemodulecard2title")}
            </h2>
          </div>
        </div>

        <p class="text-left text-[15.5px] text-gray-500 mt-4">{t("pricemodulecard2description")}</p>

        <p class="mt-2 sm:mt-8 text-left">
          <strong class="text-3xl font-bold text-gray-900 sm:text-5xl"> 75$ </strong>
          <span class="text-[16px] font-medium text-gray-700">/ {t("pricemodulecard2month")}</span>
        </p>
        <a
          class="mt-4 block  rounded-full border border-[#006847] bg-[#006847] px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-[#006847] focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("pricemodulecard2buttom")}
        </a>
      </div>

      <div class="p-6 sm:px-8 text-left">
        <p class="text-lg font-medium text-gray-900 sm:text-xl mt-3">{t("pricemodulecard2whatincluded")}</p>

        <ul class="mt-2 space-y-2 sm:mt-4 mb-3">
          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item1")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item2")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item3")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item4")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item5")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item6")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard2item7")}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="hover:shadow-2xl hover:scale-105 transition ease-in-out duration-300 delay-400 divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm">
    <div class="p-6 sm:px-8 mt-2">
        <div class="flex items-start text-left">
          <span class="flex-shrink-0 rounded-[16px] rounded-lg">
            <img
              class="rounded-lg w-14"
              alt="pricing icon"
              src="https://img.invupos.com/invu%20mexico/Loyalty%20price%20icon.svg"
            />
          </span>

          <div class="ml-4">
            <p class="mt-1 text-base text-gray-600">
              {t("pricemodulecard3plan1")}
            </p>
            <h2 class="text-2xl font-semibold text-gray-900">
              {t("pricemodulecard3title")}
            </h2>
          </div>
        </div>

        <p class="text-left text-[15.5px] text-gray-500 mt-4">{t("pricemodulecard3description")}</p>

        <p class="mt-2 sm:mt-8 text-left">
          <strong class="text-3xl font-bold text-gray-900 sm:text-5xl"> 49$ </strong>
          <span class="text-[16px] font-medium text-gray-700">/ {t("pricemodulecard3month")}</span>
        </p>
        <a
          class="mt-4 block  rounded-full border border-[#006847] text-[#006847] hover:text-[#ffffff] bg-[#fff] hover:bg-[#006847] px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-[#006847] focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("pricemodulecard3buttom")}
        </a>
      </div>

      <div class="p-6 sm:px-8 text-left">
        <p class="text-lg font-medium text-gray-900 sm:text-xl mt-3">{t("pricemodulecard3whatincluded")}</p>

        <ul class="mt-2 space-y-2 sm:mt-4 mb-3">
          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item1")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item2")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item3")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item4")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item5")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item6")}</span>
          </li>

          <li class="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-5 w-5 text-indigo-700"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>

            <span class="text-gray-700">{t("pricemodulecard3item7")}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Pricing