import React, { useState } from "react";
import FAQ_model_one from "../modules/FAQ_model_one";
import Partners_carousel from "./Partners_carousel";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import FAQ from "../Home/FAQ";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";

import Products_main_tabs from "../../components/Products_tabs/Products_main_tabs";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const About_us = () => {
  const [isOpen, setIsOpen] = useState(false);
  useDocumentTitle("Invu | About Us");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  let [transition, setTransition] = useState("width-carousel");

  const handleTransition = () => {
    setTransition("width-carousel fade-in-image");

    setTimeout(() => {
      setTransition("width-carousel");
    }, 2000);
  };

  console.log(language);

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="bg-black">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                <svg className="text-white w-6 h-6" viewBox="0 0 24 24">
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points=" 8,5 8,1 16,1 16,5"
                    strokeLinejoin="round"
                  />
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="9,15 1,15 1,5 23,5 23,15 15,15"
                    strokeLinejoin="round"
                  />
                  <polyline
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="22,18 22,23 2,23 2,18"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="9"
                    y="13"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    width="6"
                    height="4"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="max-w-xl mb-6">
                <p className="inline-block  py-1.5 text-white  mb-4 text-xs font-semibold tracking-wider uppercase rounded-full ">
                  {t("aboutusuppercase")}
                </p>
                <h1 className="max-w-xs mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                  {t("aboutustittle")}
                </h1>
                <p className="text-base text-gray-600 md:text-lg">
                  {t("aboutustittledescription")}
                </p>
              </div>
              <div>
                {/* <a
                  href="/"
                  aria-label=""
                  className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                >
                  Learn more
                  <svg
                    className="inline-block w-3 ml-2"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                  </svg>
                </a> */}
                <button
                  onClick={() => setIsOpen(true)}
                  class="inline-flex items-center px-6 py-3 text-black bg-white border border-white rounded hover:bg-transparent hover:text-white focus:outline-none focus:ring-none"
                >
                  <span class="text-sm font-medium">{t("aboutusbutton")}</span>

                  <svg
                    class="w-5 h-5 ml-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
              <div className="flex flex-col items-end px-3">
                <div>
                  <Carousel
                    className="object-cover z-10 relative mb-6 rounded shadow-lg h-48 sm:h-48 xl:h-56 w-40 sm:w-48 xl:w-56"
                    autoPlay={true}
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    interval={5000}
                    swipeable={false}
                    showStatus={false}
                    showIndicators={false}
                    swipeAnimationHandler={false}
                    animationHandler="fade"
                    handleTransition="fade"
                  >
                    <div>
                      <img
                        className="object-cover mb-6 rounded shadow-lg h-48 sm:h-48 xl:h-56 w-40 sm:w-48 xl:w-56"
                        src="https://img.invupos.com/webinvu/Why%20Invu/Hero/Hero%20why%20invu%201.jpg"
                        alt=""
                      />
                    </div>
                    {/* <div>
                      <img
                        className="object-cover mb-6 rounded shadow-lg h-48 sm:h-48 xl:h-56 w-40 sm:w-48 xl:w-56"
                        src="https://res.cloudinary.com/dslkvd23s/image/upload/v1653601633/smartmockups_l3nj9as1_1_ludvjf.png"
                        alt=""
                      />
                    </div> */}
                  </Carousel>
                </div>
                <div>
                  <Carousel
                    className="object-cover z-10 relative w-28 h-28 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                    autoPlay={true}
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    interval={6000}
                    swipeable={false}
                    showStatus={false}
                    showIndicators={false}
                    swipeAnimationHandler={false}
                    animationHandler="fade"
                    handleTransition="fade"
                  >
                    <div>
                      <img
                        className="object-cover w-28 h-28 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                        src="https://img.invupos.com/webinvu/Why%20Invu/Hero/Hero%20why%20invu%202.jpg"
                        alt=""
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
              <div className="px-3">
                <Carousel
                  className="object-cover z-10 relative w-40 h-48 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={7000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="object-cover w-40 h-48 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                      src="https://img.invupos.com/webinvu/Why%20Invu/Hero/Hero%20why%20invu%203.jpg"
                      alt=""
                    />
                  </div>
                  {/* <div>
                    <img
                      className="object-cover w-40 h-48 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1653594537/smartmockups_l3nf7ar5_1_yekozh.png"
                      alt=""
                    />
                  </div> */}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div class="max-w-screen-xl px-4 mt-16 py-10 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <div className="grid grid-cols-2 gap-5 lg:order-last">
              <img
                className="object-cover w-full h-70 col-span-2 rounded shadow-lg"
                src="https://images.pexels.com/photos/3184436/pexels-photo-3184436.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt=""
              />
              <img
                className="object-cover w-full h-48 rounded shadow-lg"
                src="https://images.pexels.com/photos/3184285/pexels-photo-3184285.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt=""
              />
              <img
                className="object-cover w-full h-48 rounded shadow-lg"
                src="https://images.pexels.com/photos/3861958/pexels-photo-3861958.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt=""
              />
            </div>

            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; {t("aboutusuppercase")}
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                {t("innovatestittle")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem3")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    4
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem4")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    5
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem5")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    6
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("innovatesitem6")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 row-gap-8 lg:grid-cols-4">
          <a class="relative block p-8 overflow-hidden border border-gray-100 rounded-lg  shadow-xl">
            <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-500 to-purple-600"></span>
            <div class="justify-between sm:flex">
              <div className="flex">
                <p className="mr-2 text-4xl font-bold md:text-5xl text-indigo-600">
                  <CountUp prefix="+" start={0} end={900} duration={5} />
                </p>
                <div className="flex items-center justify-center rounded-full bg-indigo-200 w-7 h-7">
                  <svg
                    class="text-indigo-600 w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div class="mt-4 ">
              <h3 className="mb-2 font-bold  md:text-lg">
                {" "}
                {t("statscardtittle1")}
              </h3>
              <p class="text-sm text-gray-500">{t("statscarddescription1")}</p>
            </div>
          </a>
          <a class="relative block p-8 overflow-hidden border border-gray-100 rounded-lg  shadow-xl">
            <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-500 to-purple-600"></span>
            <div class="justify-between sm:flex">
              <div className="flex">
                <p className="mr-2 text-4xl font-bold md:text-5xl text-indigo-600">
                  <CountUp prefix="+" start={0} end={25} duration={5} />
                </p>
                <div className="flex items-center justify-center rounded-full bg-indigo-200 w-7 h-7">
                  <svg
                    class="text-indigo-600 w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div class="mt-4 ">
              <h3 className="mb-2 font-bold  md:text-lg">
                {t("statscardtittle2")}
              </h3>
              <p class="text-sm text-gray-500">{t("statscarddescription2")}</p>
            </div>
          </a>
          <a class="relative block p-8 overflow-hidden border border-gray-100 rounded-lg  shadow-xl">
            <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-500 to-purple-600"></span>
            <div class="justify-between sm:flex">
              <div className="flex">
                <p className="mr-2 text-4xl font-bold md:text-5xl text-indigo-600">
                  <CountUp prefix="+" start={0} end={20000} duration={5} />
                </p>
                <div className="flex items-center justify-center rounded-full bg-indigo-200 w-7 h-7">
                  <svg
                    class="text-indigo-600 w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div class="mt-4 ">
              <h3 className="mb-2 font-bold  md:text-lg">
                {t("statscardtittle3")}
              </h3>
              <p class="text-sm text-gray-500">{t("statscarddescription3")}</p>
            </div>
          </a>
          <a class="relative block p-8 overflow-hidden border border-gray-100 rounded-lg  shadow-xl">
            <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-500 to-purple-600"></span>
            <div class="justify-between sm:flex">
              <div className="flex">
                <p className="mr-2 text-4xl font-bold md:text-5xl text-indigo-600">
                  <CountUp prefix="+" start={0} end={10} duration={5} />
                </p>
                <div className="flex items-center justify-center rounded-full bg-indigo-200 w-7 h-7">
                  <svg
                    class="text-indigo-600 w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div class="mt-4 ">
              <h3 className="mb-2 font-bold  md:text-lg">
                {t("statscardtittle4")}
              </h3>
              <p class="text-sm text-gray-500">{t("statscarddescription4")}</p>
            </div>
          </a>
        </div>
      </div>

      <section class="text-white bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-xl">
            <h2 class="text-4xl font-bold sm:text-6xl">
              {t("whatisinvutitle")}
            </h2>

            <p class="mt-4 sm:text-lg text-gray-500">
              {t("whatisinvudescription")}
            </p>
          </div>

          <ul class="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3">
            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <h3 class="text-2xl font-extrabold mt-1.5">
                  {t("whatisitemtittle1")}
                </h3>
              </li>
              <p class="mt-3 text-xl text-gray-400 font-medium">
                {t("whatisitemdescription1")}
              </p>
            </li>

            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <h3 class="text-2xl font-extrabold mt-1.5">
                  {t("whatisitemtittle2")}
                </h3>
              </li>
              <p class="mt-3 text-xl text-gray-400 font-medium">
                {t("whatisitemdescription2")}
              </p>
            </li>

            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <h3 class="text-2xl font-extrabold mt-1.5">
                  {t("whatisitemtittle3")}
                </h3>
              </li>
              <p class="mt-3 text-gray-400 text-xl font-medium">
                {t("whatisitemdescription3")}
              </p>
            </li>

            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                  </svg>
                </div>
                <h3 class="text-2xl font-extrabold mt-1.5">
                  {" "}
                  {t("whatisitemtittle4")}
                </h3>
              </li>
              <p class="mt-3 text-gray-400 text-xl font-medium">
                {t("whatisitemdescription4")}
              </p>
            </li>

            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                </div>
                <h3 class="text-2xl font-extrabold mt-1.5">
                  {" "}
                  {t("whatisitemtittle5")}
                </h3>
              </li>
              <p class="mt-4 text-gray-400 text-xl font-medium">
                {t("whatisitemdescription5")}
              </p>
            </li>

            <li class="p-8 shadow-xl rounded-xl bg-pirBlack border border-gray-700">
              <li class="flex">
                <div
                  class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                >
                  <li class="flex">
                    <div
                      class="
                        inline-flex
                        items-center
                        w-10
                        h-10
                        cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple
                        rounded-full mr-3 
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="flex-shrink-0 w-6 h-6 mx-auto text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                      </svg>
                    </div>
                    <h3 class="text-2xl font-extrabold mt-1.5">
                      {t("whatisitemtittle6")}
                    </h3>
                  </li>
                </div>
              </li>
              <p class="mt-3 text-gray-400 text-xl font-medium">
                {t("whatisitemdescription6")}
              </p>
            </li>
          </ul>
        </div>
      </section>

      <Products_main_tabs />

      {/* <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              {t("aboutusuppercase")}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {t("personalitytittle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            {t("personalitydescription")}
          </p>
        </div>
        <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-indigo-50">
                <img
                  className="rounded-full h-14 w-14"
                  src="https://img.invupos.com/webinvu/Why%20Invu/About%20us%20icons/Icon%201.svg"
                />
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("personalityitemtittle1")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("personalityitemdescription1")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-indigo-50">
                <img
                  className="rounded-full h-14 w-14"
                  src="https://img.invupos.com/webinvu/Why%20Invu/About%20us%20icons/Icon%202.svg"
                />
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("personalityitemtittle2")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("personalityitemdescription2")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-indigo-50">
                <img
                  className="rounded-full h-14 w-14"
                  src="https://img.invupos.com/webinvu/Why%20Invu/About%20us%20icons/Icon%203.svg"
                />
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("personalityitemtittle3")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("personalityitemdescription3")}
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
            <div>
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-indigo-50">
                <img
                  className="rounded-full h-14 w-14"
                  src="https://img.invupos.com/webinvu/Why%20Invu/About%20us%20icons/Icon%204.svg"
                />
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("personalityitemtittle4")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("personalityitemdescription4")}
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <section className="bg-pirBlack mt-24">
        <div className="px-4 py-16 mx-auto sm:max-w-xl  md:max-w-full lg:max-w-screen-xl md:px-6 lg:px-8 ">
          <div className="flex flex-col max-w-screen-xl  mx-auto overflow-hidden bg-pirDarkGray rounded shadow-sm lg:flex-row sm:mx-auto">
            <div className="relative lg:w-1/2">
              <img
                src="https://img.invupos.com/webinvu/Why%20Invu/Our%20History/History%201.avif"
                alt=""
                className="object-cover w-full lg:absolute h-80 lg:h-full"
              />
              <svg
                className="absolute top-0 right-0 hidden h-full text-white lg:inline-block"
                viewBox="0 0 19 104"
                fill="#131313"
              >
                <polygon
                  className="bg-pirDarkGray"
                  points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"
                />
              </svg>
            </div>
            <div className="flex flex-col justify-center p-8 bg-pirDarkGray lg:p-16 lg:pl-10 lg:w-1/2">
              <div>
                <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                  &mdash; {t("storyuppercase")}
                </p>
              </div>
              <h2 className="mb-3 text-3xl font-extrabold text-white leading-none sm:text-4xl">
                {t("storytittle")}
              </h2>
              <p className="mb-5 text-base text-gray-500 md:text-lg">
                {t("storydescription")}
              </p>
              <div className="flex items-center">
                <button
                  class="inline-flex items-center mr-6 px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span class="font-semibold">{t("storybuttonstarted")}</span>
                </button>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`${prefix}` + "/recursos/acerca-de-invu"}
                  aria-label=""
                  className="inline-flex items-center font-semibold transition-colors duration-200 text-white hover:text-deep-purple-800"
                >
                  {t("storylearnmore")}
                  <svg
                    className="inline-block w-3 ml-2"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="px-4 mx-auto -mt-16 max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            <div>
              <a class="block p-8 bg-pirDarkGray rounded-lg">
                <img
                  src="https://img.invupos.com/webinvu/Why%20Invu/Our%20History/history%202.webp"
                  class="object-cover w-full h-64 rounded-md"
                />
                <div>
                  <p className="inline-block  py-px mt-8 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                    &mdash; {t("integrationsuppercase")}
                  </p>
                </div>
                <h2 class="mb-2 mt-3 text-2xl font-semibold text-gray-900">
                  <a class="text-gray-100 hover:text-gray-300">
                    {t("integrationstittle")}
                  </a>
                </h2>
                <p className=" text-base mb-4  text-gray-500 md:text-lg sm:mb-8 ">
                  {t("integrationsdescription")}
                </p>
                <button onClick={() => setIsOpen(true)}>
                  <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                    {t("integrationsbutton")} {""}
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </button>
              </a>
            </div>
            <div>
              <a class="block p-8 bg-pirDarkGray rounded-lg ">
                <img
                  src="https://img.invupos.com/webinvu/Why%20Invu/Our%20History/History%203.jpeg"
                  class="object-cover w-full h-64 rounded-md"
                />
                <div>
                  <p className="inline-block  py-px mt-8 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                    &mdash; {t("serveruppercase")}
                  </p>
                </div>
                <h2 class="mb-2 mt-3 text-2xl font-semibold text-gray-900">
                  <a class="text-gray-100 hover:text-gray-300">
                    {t("servertittle")}
                  </a>
                </h2>
                <p className=" text-base mb-4 text-gray-500 md:text-lg sm:mb-8">
                  {t("serverdescription")}
                </p>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`${prefix}` + "/centro-de-ayuda"}
                >
                  <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                    {t("serverbutton")} <span aria-hidden="true">&rarr;</span>
                  </div>
                </Link>
              </a>
            </div>
          </div>
        </div> */}
      </section>

      <div className="px-4 py-16 mt-20 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col lg:flex-row">
          <div className="max-w-xl pr-16 mx-auto mb-10">
            <h2 className="mb-6 text-3xl font-extrabold leading-none">
              {t("voicetittle")}
            </h2>
            <p className="mb-6 text-gray-900">{t("voicedescription")}</p>
            <div className="flex items-center">
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center px-8 py-3 mr-6 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">
                  {" "}
                  {t("voicebuttonstarted")}{" "}
                </span>
              </button>
              {/* <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/programas-de-revendedores"}
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                {t("voicebuttonlearnmore")}
              </Link> */}
            </div>
          </div>
          <div className="grid gap-5 row-gap-5 sm:grid-cols-2 space-y-2">
            <div className="max-w-md border p-4 rounded-lg">
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("voiceitemtitle1")}
              </h3>
              <p className="text-sm text-gray-700">
                {t("voiceitemdescription1")}
              </p>
            </div>
            <div className="max-w-md border p-4 rounded-lg">
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {" "}
                {t("voiceitemtitle2")}
              </h3>
              <p className="text-sm text-gray-700">
                {t("voiceitemdescription2")}
              </p>
            </div>
            <div className="max-w-md border p-4 rounded-lg">
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("voiceitemtitle3")}
              </h3>
              <p className="text-sm text-gray-700">
                {t("voiceitemdescription3")}
              </p>
            </div>
            <div className="max-w-md border p-4 rounded-lg">
              <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("voiceitemtitle4")}
              </h3>
              <p className="text-sm text-gray-700">
                {t("voiceitemdescription4")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 lg:grid-cols-2 lg:h-screen">
            <div class="relative z-10 lg:py-16">
              <div class="relative h-64 sm:h-80 lg:h-full">
                <img
                  class="absolute inset-0 object-cover w-full h-full"
                  src="https://img.invupos.com/webinvu/Why%20Invu/Culture/Culture.webp"
                  alt=""
                />
              </div>
            </div>

            <div class="relative flex items-center bg-gray-100">
              <span class="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span>

              <div class="p-8 sm:p-16 lg:p-24">
                <div>
                  <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    {t("cultureuppercasetitle1")}
                  </p>
                </div>
                <h2 class="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                  {t("culturetittle")}
                </h2>

                <p class="mt-4 text-gray-600 mb-4">{t("culturedescription")}</p>

                <div class="flex flex-col mt-3 flex-grow space-y-3">
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      {t("cultureitem1")}
                    </p>
                  </div>
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      {t("cultureitem2")}
                    </p>
                  </div>
                  <div class="flex items-start">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <p class="text-gray-600 text-sm leading-loose">
                      {t("cultureitem3")}
                    </p>
                  </div>
                </div>

                <button
                  class="inline-block px-12 py-3 mt-8 text-sm font-medium text-white bg-indigo-600 border border-indigo-600 rounded active:text-indigo-500 hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  {t("culturebutton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              {t("helpuppercase")}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {t("helptittle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            {t("helpdescription")}
          </p>
        </div>
        <div className="relative mx-auto  w-full p-px mx-auto mb-4 overflow-hidden transition-shadow duration-300 border rounded lg:mb-8 lg:max-w-screen-xl  group hover:shadow-xl">
          <div className="absolute  bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute  bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative flex flex-col items-center h-full py-10 duration-300 bg-white rounded-sm transition-color sm:items-stretch lg:flex-row">
            <div className="px-12 py-8 text-center">
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              </div>
              <p className="text-center mb-6  md:text-base">{t("helpitem1")}</p>
              <button onClick={() => setIsOpen(true)}>
                <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                  {t("helpbutton1")} {""}
                  <span aria-hidden="true">&rarr;</span>
                </div>
              </button>
            </div>
            <div className="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:h-auto sm:w-1" />
            <div className="px-12 py-8 text-center">
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                </div>
              </div>
              <p className="text-center mb-6  md:text-base">{t("helpitem1")}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/centro-de-ayuda"}
              >
                <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                  {t("helpbutton2")}
                  {""}
                  <span aria-hidden="true">&rarr;</span>
                </div>
              </Link>
            </div>
            <div className="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:h-auto sm:w-1" />
            <div className="px-12 py-8 text-center">
              <div className="text-center">
                <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer sm:w-16 sm:h-16">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#fff"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </div>
              </div>
              <p className="text-center mb-6 md:text-base">{t("helpitem3")}</p>
              <button onClick={() => setIsOpen(true)}>
                <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                  {t("helpbutton3")} {""}
                  <span aria-hidden="true">&rarr;</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <p className="mx-auto text-sm mb-4 text-gray-600 sm:text-center lg:max-w-xl lg:mb-6 md:px-16 leading-loose">
          {t("helpinfo")}{" "}
          <button
            onClick={() => setIsOpen(true)}
            className="font-semibold text-indigo-600 cursor-pointer"
          >
            {t("helpspan")}
          </button>
        </p>
      </div> */}
      {/* <Help_CTA /> */}
      <Partners_carousel />
    </>
  );
};

export default About_us;
