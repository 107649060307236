import React from 'react'
import { useTranslation } from "react-i18next";

const Ecommerce_integrations_cta = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
     <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-6 lg:px-8 lg:py-20">
      <div className="p-8 rounded-2xl sm:p-20 bg-[#121212] md:px-12 sm:px-12">
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-2/5 lg:pr-10 content-center">
            <h2 className="font-sans text-3xl font-bold tracking-tight text-white sm:text-[40px] sm:leading-none mb-6">
              {t("e-commerceinvuctatitle")}
            </h2>
          </div>
          <div className="lg:w-3/5 content-center">

          <div class="grid xl:grid-cols-5 lg:grid-cols-5 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-5 md:gap-5">
            {/* Card 1 */}
            <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Ecommerce%20module/Clau.png"/>
              </div>
              <div className="text-center mt-2">
                <p className="text-gray-50 text-sm">Clau.io</p>
              </div>
            </div>
             {/* Card 2 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Ecommerce%20module/Deliverect.png"/>
              </div>
              <div className="text-center mt-2">
                <p className="text-gray-50 text-sm">Deliverect</p>
              </div>
            </div>
             {/* Card 3 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Ecommerce%20module/Shopify.png"/>
              </div>
              <div className="text-center mt-2">
                <p className="text-gray-50 text-sm">Shopify</p>
              </div>
            </div>

             {/* Card 4 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Ecommerce%20module/Marketman.png"/>
              </div>
              <div className="text-center mt-2">
                <p className="text-gray-50 text-sm">Marketman</p>
              </div>
            </div>

             {/* Card 5 */}
             <div class="rounded-2xl">
              <div>
                <img className="rounded-2xl" src="https://img.invupos.com/webinvu/Ecommerce%20module/Woocommerce.png"/>
              </div>
              <div className="text-center mt-2">
                <p className="text-gray-50 text-sm">WooCommerce</p>
              </div>
            </div>
            
          </div>

          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Ecommerce_integrations_cta