import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-sm lg:text-base text-left justify-start md:text-sm text-gray-700 font-semibold">
          {title}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-700">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const Article_DGI = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("Facturación electrónica DGI 2022 | Invu POS");

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="[Facturación Electrónica  DGI 2022] Requisitos para emitir facturas electrónicas en Panamá, cumple la ley y emite facturas electrónicas en Panamá en el 2022"
        />
        <meta name="keywords" content="Facturacion electronica dgi" />
        <meta name="keywords" content="emitir facturas electronicas" />
      </Helmet>

      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2022/Detail%20blog%2022.jpeg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 08, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Facturación electrónica en Panamá: requisitos y novedades
            </h1>
            <p className="text-gray-500 max-w-xl">
              En Panamá, la factura electrónica es un modelo de facturación que,
              además de reemplazar el uso de impresoras fiscales, acelera la
              transformación digital de las empresas e impulsa el ingreso de los
              emprendedores en la economía formal.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 leading-7">
            Según el artículo 1 del decreto 147 del 26 de mayo del 2021, la
            reglamentación que legisla el uso de la factura electrónica en
            Panamá, se dictamina lo siguiente: “están obligados a implementar y
            cumplir las disposiciones establecidas en el presente Decreto, toda
            persona natural y jurídica que solicite a la Dirección General de
            Ingresos el uso de la Factura Electrónica para documentar sus
            operaciones de transferencias, venta de bienes y prestación de
            servicios.
          </p>
          <p className="text-gray-700 mt-2 leading-7">
            De igual forma, aquellas personas, naturales o jurídicas, que estén
            obligadas al uso de equipos fiscales, también podrán utilizar el
            modelo de Facturación Electrónica en Panamá como medio para
            documentar sus operaciones financieras, siempre y cuando esto se
            haga de forma expresa y voluntaria.”.
          </p>
          <p className="text-gray-700 mt-2 leading-7 mb-8">
            Con respecto a los tiempos de entrada en vigor de esta normativa, es
            obligatorio hacer uso del sistema, a partir del 1 de enero de 2022,
            para todo nuevo Registro Único de Contribuyente (RUC); el 30 de
            julio de 2022 para todo proveedor del Estado; y por último, en el
            caso de las empresas del Plan Piloto que deban migrar al SFEP bajo
            la modalidad PAC o facturador gratuito, a partir del primer día
            hábil del año 2023.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <h2 className="font-semibold text-xl mt-6">
            Requisitos para el de la factura electrónica en Panamá
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            En el artículo 3 de este mismo decreto se alude a los requisitos a
            cumplir para poder hacer uso de la facturación electrónica en
            Panamá. Son los siguientes:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Disponer de la correspondiente excepción de uso de equipos
              fiscales.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Estar en posesión del certificado electrónico correspondiente
              expedido por un prestador de servicios de facturación ante la
              Dirección Nacional de Firma Electrónica del Registro Público de
              Panamá.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Haberse registrado, ante la Dirección General Impositiva (DGI), en
              el Sistema de Factura Electrónica de Panamá
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Emitir todos los documentos electrónicos, según las
              especificaciones de la ficha técnica.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Contar con los servicios de un Proveedor de Autorización
              Calificado (PAC), para obtener la autorización de uso de
              documentos electrónicos.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Reportar, correctamente, los eventos posteriores relacionados con
              una factura electrónica.
            </li>
          </ul>

          <p className="text-gray-700 mt-2 leading-7 mb-8">
            Hay que tener en cuenta que, en caso de incumplimiento de alguno de
            estos requisitos, se podría derivar en sanciones o, como mínimo, en
            la imposibilidad de no poder emitir el documento en cuestión.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Bajo qué condiciones se puede emitir una factura electrónica en
            Panamá
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Para emitir, legalmente, una factura electrónica en Panamá, la
            persona o sociedad tiene que:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Disponer de un sistema de facturación electrónica como el de Invu
              POS.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Haberse inscrito en el DGI.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Disponer de Registro Único de Contribuyentes (RUC) y Número de
              Identificación Tributaria (NIT).
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Tener actualizada la información económica de la sociedad o de la
              persona física usuaria del sistema de facturación electrónica.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Registrarse, a través del portal de la DGI, en el sistema de
              facturación electrónica.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Validar que el sistema de facturación electrónica utilizado cumple
              con el estándar del Sistema de Factura Electrónica de Panamá
              (SFEP) para generar y transmitir los comprobantes a la DGI.
            </li>
          </ul>

          <p className="text-gray-700 mt-2 leading-7 ">
            Además, de lo dicho, los usuarios que reciban una factura
            electrónica tienen que comprobar, ante la DGI, que dicha factura ha
            sido registrada. También deberán contar con la debida autorización
            de uso. De este modo, el documento podrá ser utilizado como soporte
            de crédito fiscal.
          </p>
          <p className="text-gray-700 mt-2 leading-7 ">
            También, todo contribuyente afiliado al SFEP tendrá que conservar
            sus facturas electrónicas –tanto las emitidas como las recibidas-
            hasta cumplirse el plazo de prescripción de los tributos. Es por
            este motivo por el que, desde Invu POS, se recomienda operar desde
            la nube. De este modo, la documentación almacenada se podrá
            mantener, siempre, en un modo seguro y accesible.
          </p>
          <p className="text-gray-700 mt-2 leading-7">
            En cualquier caso, y para usuarios que no estén afiliados al SFEP,
            tendrá que conservarse o bien un ejemplar del Comprobante Auxiliar
            de Factura Electrónica (CAFE) o bien un archivo contenedor de una
            imagen representativa del contenido de la factura electrónica
            emitida.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Cuál es el proceso de emisión de una factura electrónica en Panamá
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            A día de hoy tan sólo existen dos vías, legalmente admitidas, de
            emisión de facturas electrónicas en Panamá. Estas son:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Herramienta gratuita de la DGI. Este organismo dispone de una
              aplicación para emitir facturas electrónicas sin coste alguno. El
              problema es que, al no ser esta herramienta un software contable,
              no se tiene la opción de poder tener, centralizada, toda la
              información contable de la empresa. Este método es apto para
              emprendedores que emitan hasta 200 facturas mensuales.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Una herramienta facilitada por Proveedores Autorizados Calificados
              (PAC) como Invu POS. Este software, además de permitir la emisión
              de facturas electrónicas, posibilita la gestión de otras muchas
              operaciones financieras, como control de operaciones bancarias,
              gestión de inventarios…
            </li>
          </ul>

          <p className="mt-2 text-gray-700 leading-7">
            Una vez solucionado el trámite de elegir la herramienta de
            facturación electrónica con la que se va a trabajar, toca conocer en
            qué consiste el proceso de facturación electrónica en Panamá. Consta
            de los siguientes pasos:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              El comercio envía la factura a la DGI
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              La DGI procede a la validación del documento enviado
            </li>
          </ul>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>

        <div className="bg-gray py-6">
          <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
            <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; soporte
              </p>

              <h2 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                Preguntas frecuentes sobre facturación electrónica en Panamá
              </h2>
              <p class="mt-4 leading-relaxed text-gray-700">
                A continuación, te muestro cuáles son las preguntas que los
                usuarios tienen en cuanto a los procesos de facturación
                electrónica DGI:
              </p>
            </div>
          </div>
          <div className="w-full mt-16 my-12 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
              <div className="space-y-4">
                <div>
                  <Item title="¿Es obligatoria la facturación electrónica?">
                    Sí, la ley 256 establece que las personas deben entregar de
                    manera obligatoria el soporte de todas las actividades
                    financieras llevadas a cabo en sus negocios. A excepto de
                    las que emplean equipos fiscales y son comprobantes
                    electrónicos que tengan relación de compra y venta de bienes
                    y servicios.
                  </Item>
                </div>
                <div>
                  <Item title="¿Cuál ley rige la facturación electrónica?">
                    La ley 256 es la encargada de establecer las normas
                    relativas a la adopción del proceso de facturación
                    electrónica para las empresas.
                  </Item>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <Item title="¿Puedo ver la información desde cualquier dispositivo con internet?">
                    Sí, las personas naturales o jurídicas que están obligadas a
                    utilizar equipos fiscales pueden utilizar el modelo de
                    Facturación Electrónica en Panamá para dejar un registro de
                    sus operaciones, siempre que lo hagan de manera voluntaria.
                  </Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_DGI;
