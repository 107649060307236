import React, { useState } from "react";
import FAQ_model_one from "./FAQ_model_one";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

const Resources = () => {
  const [isOpen, setIsOpen] = useState(false);
  useDocumentTitle("Invu POS | Resources Overview");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources.jpeg"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-70"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                {t("invuresourcesuppercase")}
              </p>
            </div>
            <h1 class="text-3xl max-w-lg text-white font-extrabold sm:text-5xl">
              {t("invuresourcestittle")}
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              {t("invuresourcesdescription")}
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                onClick={() => setIsOpen(true)}
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
              >
                {t("invuresourcesbutton1")}
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/resources/blog"
              >
                {t("invuresourcesbutton2")}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-black">
                &mdash; {t("resourceuppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("resourcessectiontittle1")}
              </h2>
              <p class="text-gray-500 leading-loose">
                {t("resourcessectiondescription1")}
              </p>

              <div class="flex flex-col mt-3 flex-grow space-y-1">
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessectionitem1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessectionitem2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessectionitem3")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessectionitem4")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessectionitem5")}
                  </p>
                </div>
              </div>

              <a
                class="inline-flex items-center mt-6 px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/resellers-program"
              >
                <span class="text-sm font-medium">
                  {" "}
                  {t("resourcessectionbutton1")}{" "}
                </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%201.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; {t("resourceuppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("resourcessectiontittle2")}
              </h2>
              <div class="flex flex-col mt-3 flex-grow space-y-1">
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessection2item1")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessection2item2")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessection2item3")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessection2item4")}
                  </p>
                </div>
                <div class="flex items-start">
                  <svg
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                    class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="text-gray-600 text-sm leading-loose max-w-md">
                    {t("resourcessection2item5")}
                  </p>
                </div>
              </div>
              <a
                href="/point-of-sale"
                class="inline-flex mt-6 items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">
                  {t("resourcessection2button1")}
                </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%202.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>

      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
          <div class="grid gap-6 md:grid-cols-2 md:col-span-2 lg:col-span-3">
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  1
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle1")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription1")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  2
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle2")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription2")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  3
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle3")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription3")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  4
                </span>
                {t("featureresourcetittle4")}
                <p class="text-lg font-semibold sm:text-base"></p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription4")}
              </p>
            </div>
          </div>
          <div class="relative md:col-span-2 lg:col-span-2">
            <img
              class="inset-0 object-cover object-bottom w-full h-56 rounded shadow-lg lg:absolute lg:h-full"
              src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%203.jpeg"
              alt=""
            />
          </div>
        </div>
      </div>

      <section class="px-4 py-24 mx-auto max-w-7xl bg-pirDarkGray">
        <div class="w-full mx-auto text-center md:w-4/5 lg:w-3/5">
          <h1 class="mb-10 text-2xl font-extrabold text-gray-300 md:leading-tight md:text-3xl">
            {t("resourcecta1")}{" "}
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-pirOrange to-pirPurple">
              {t("resourcectaspan")}
            </span>{" "}
            {t("resourcecta2")}
          </h1>
          <div class="mx-auto mb-3 avatar flex justify-center">
            <a aria-label="Author" className="mr-3 mx-0 md:mx-auto">
              <img
                alt="avatar"
                src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%204.jpeg"
                className="object-cover w-16 h-16 rounded-full shadow-sm mx-0 md:mx-auto"
              />
            </a>
          </div>
          <p class="text-base font-bold text-white">Rafael Turgman</p>
          <p class="text-sm font-medium text-gray-300">CEO, Invu POS</p>
        </div>
      </section>

      <FAQ_model_one />

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-white">
                &mdash; {t("resourceuppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcessectiontittle3")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcesectiondescription3")}
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments/platform"
              >
                <span class="text-sm font-medium">
                  {t("resourcesection3button1")}
                </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%205.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font ">
                &mdash; {t("resourceuppercase")}
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("resourcessectiontittle4")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcesectiondescription4")}
                </p>
              </div>
              <a
                href="/help-center"
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">
                  {" "}
                  {t("resourcesection4button1")}{" "}
                </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/General%20resources%20/General%20resources%206.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <Help_CTA />
    </>
  );
};

export default Resources;
