import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import FAQ_model_one from "./FAQ_model_one";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Invu_expert = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("EXPERTOS | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };


  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("resourcespartnersexpertsuppercase")}
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("resourcespartnersexpertstitle")}
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("resourcespartnersexpertsdescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://images.pexels.com/photos/7526590/pexels-photo-7526590.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
              {t("resourcespartnersexpertssection2title")}
                </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcespartnersexpertssection2description")}
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> {t("resourcespartnersexpertssection2button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex h-96 lg:h-[350px] md:h-[450px] w-full">
              <img
                className="object-center object-cover h-96 lg:h-[380px] md:h-[450px]  w-full rounded-md"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652406362/Branding%20Invu%20/Invu%20images%20/invu4_fieqek.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("resourcespartnersexpertssection3title")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcespartnersexpertssection3description")}
                </p>
              </div>
              <button
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span class="text-sm font-medium"> {t("resourcespartnersexpertssection3button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/5668885/pexels-photo-5668885.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("resourcespartnersexpertssection4title")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcespartnersexpertssection4description")}
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/resources/guides"
              >
                <span class="text-sm font-medium"> {t("resourcespartnersexpertssection4button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7988661/pexels-photo-7988661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("resourcespartnersexpertssection5title")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("resourcespartnersexpertssection5description")}
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/resellers-program"
              >
                <span class="text-sm font-medium"> {t("resourcespartnersexpertssection5button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/3779628/pexels-photo-3779628.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Invu_expert;
