import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Help_CTA = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <>
      <section className="bg-gray-50">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
            <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
              {t("ctahelptittle")}
            </h2>
          </div>
          <div
            class="grid grid-cols-1 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3"
          >
            <div>
              <a aria-label="View Item">
                <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                  <img
                    className="object-cover w-full h-56 md:h-64 xl:h-80"
                    loading="lazy"
                    src="https://img.invupos.com/webinvu/Help%20Cards/Help%201.webp"
                    alt=""
                  />
                  <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-black bg-opacity-75">
                    <p className="text-sm font-medium tracking-wide text-white">
                      {t("ctahelpitem1")}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a  aria-label="View Item">
                <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                  <img
                    className="object-cover w-full h-56 md:h-64 xl:h-80"
                    loading="lazy"
                    src="https://img.invupos.com/webinvu/Help%20Cards/Help%202.webp"
                    alt=""
                  />
                  <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-black bg-opacity-75">
                    <p className="text-sm font-medium e text-white">
                      {t("ctahelpitem2")}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a  aria-label="View Item">
                <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                  <img
                    className="object-cover w-full h-56 md:h-64 xl:h-80"
                    loading="lazy"
                    src="https://img.invupos.com/webinvu/Help%20Cards/Help%203.webp"
                    alt=""
                  />
                  <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-black bg-opacity-75">
                    <p className="text-sm font-medium text-white">
                      {t("ctahelpitem3")}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Help_CTA;
