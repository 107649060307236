import React from "react";
import { t } from 'i18next';
import { Link } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Latest_blog = () => {

  const data = [
    {
      id: 18,
      name: "4 beneficios de los pedidos en línea para propietarios de restaurantes",
      description:
        "Los pedidos en línea para restaurantes recibieron un fuerte impulso durante el confinamiento. Posteriormente, y durante los meses transcurridos de 2022, esta modalidad de consumir comida se ha consolidado para este tipo de negocio.",
      date: "August 12, 2022",
      views: "2.3k",
      comments: "27",
      href: "/blog/cuatro-beneficios-de-los-pedidos-en-linea-para-propietarios-de-restaurantes",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Blog%2018/Cover%20blog%2018.jpeg",
      imageAlt: "",
      category: "Payments",
      typecontent: "Articles",
      country: "Panama",
    },
    {
      id: 19,
      name: "Cómo utilizar el video marketing para promocionar su restaurante",
      description:
        "El video marketing es una herramienta de lo más eficaz para promocionar cualquier negocio en general… Y con los restaurantes funciona a la perfección.  Y es que una imagen impacta, y es capaz de transmitir contenidos en un segundo y, además, no requiere prácticamente ni de atención ni de esfuerzo mental por parte del espectador. ",
      date: "August 15, 2022",
      views: "2.3k",
      comments: "27",
      href: "/blog/como-utilizar-el-video-marketing-para-promocionar-su-restaurante",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Blog%2019/Cover%20blog%2019.jpeg",
      imageAlt: "",
      category: "Marketing",
      typecontent: "Tools",
      country: "Panama",
    },
    {
      id: 20,
      name: "10 industrias donde los programas de lealtad son extremadamente efectivos",
      description:
        "Restaurantes de comida rápida y cafeterías; salones de belleza y cosmética; gimnasios y comercios especializados en supplements & vitamins; negocios relacionados con productos de farmacia; viajes y turismo; electrónica y gaming; cines y salas de teatro; tiendas de suministros para mascotas y empresas de productos agrícolas; supermercados y minimarkets; consultorios médicos y clínicas. ",
      date: "August 19, 2022",
      views: "2.3k",
      comments: "27",
      href: "/blog/diez-industrias-donde-los-programas-de-lealtad-son-extremadamente-efectivos",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Blog%2020/Cover%20blog%2020.avif",
      imageAlt: "",
      category: "Customers",
      typecontent: "Articles",
      country: "Panama",
    },
    {
      id: 21,
      name: "Los 7 Beneficios Irresistibles de Integrar INVU POS con la Facturación Electrónica",
      description:
        "Descubre los beneficios de integrar INVU POS con la facturación electrónica en el escenario empresarial panameño. En el dinámico escenario empresarial panameño, la adopción de la facturación electrónica con la incorporación estratégica de INVU POS emerge como una jugada maestra para las empresas que buscan no solo cumplir con las normativas fiscales sino también potenciar sus operaciones comerciales.",
      date: "December 12, 2023",
      views: "2.3k",
      comments: "27",
      href: "/blog/siete-beneficios-irresistibles-de-integrar-invupos-con-la-facturacion-electronica",
      imageSrc:
        "https://img.invupos.com/webinvu/Ecommerce%20module/Header%20Ecommerce.webp",
      imageAlt: "",
      category: "Finances",
      typecontent: "Articles",
      country: "Panama",
    },
    {
      id: 22,
      name: "Facturador Gratuito de la DGI en Panamá: Simplificando la Facturación para Emprendedores y Pequeñas Empresas",
      description:
        "En el dinámico mundo de los negocios, la digitalización y la simplificación de procesos son clave para el crecimiento de emprendedores y pequeñas empresas. En Panamá, la Dirección General de Ingresos (DGI) ha dado un paso significativo hacia la modernización con la introducción del Facturador Gratuito. En este artículo, exploraremos cómo esta herramienta beneficia a los profesionales emprendedores y pequeñas empresas, y por qué INVUPOS se destaca como la mejor solución para aprovechar al máximo esta nueva iniciativa de la DGI.",
      date: "December 21, 2023",
      views: "2.3k",
      comments: "27",
      href: "/blog/facturador-gratuito-de-la-DGI-en-Panamá",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Facturador%20Gratuito%20de%20la%20DGI.jpg",
      imageAlt: "",
      category: "Finances",
      typecontent: "Articles",
      country: "Panama",
    },
    {
      id: 23,
      name: "INVU POS eleva su potencial al unirse al programa NVIDIA Inception",
      description:
        "Ciudad de Panamá, Panamá— 8 Enero, 2024—INVU POS anunció hoy que se ha unido a NVIDIA Inception, un programa diseñado para apoyar a startups que están revolucionando industrias con avances tecnológicos. INVU POS se especializa en proporcionar soluciones integrales de software, hardware y servicios para el procesamiento de pagos, atendiendo las necesidades de comerciantes, empresas tecnológicas y organizaciones. ",
      date: "January 8, 2024",
      views: "2.3k",
      comments: "27",
      href: "/blog/invupos-eleva-su-potencial-al-unirse-al-programa-nvidia-inception",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Cover%20nvidia.png",
      imageAlt: "",
      category: "Press release",
      typecontent: "Articles",
      countries: ["Panama", "Venezuela", "Mexico"], 
    },
    {
      id: 24,
      name: "Gastronomía con Sabor a Tecnología: Transformando Restaurantes en México con InvuPos",
      description:
        "En el cautivador y diverso mercado gastronómico de México, donde la calidad de la experiencia del cliente es un componente esencial del éxito culinario, la integración de un Sistema Punto de Venta (POS) se convierte en la clave para alcanzar nuevas alturas. En este análisis enfocado en el mercado mexicano, exploraremos cómo InvuPos emerge como la opción predilecta para redefinir la interacción en restaurantes, optimizando cada aspecto de la experiencia culinaria.",
      date: "January 12, 2024",
      views: "2.3k",
      comments: "27",
      href: "/blog/gastronomia-con-sabor-a-tecnologia-transformando-restaurantes-en-mexico-con-invupos",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/Transformando_restaurantes_en_mexico.jpg",
      imageAlt: "",
      category: "Informative",
      typecontent: "Articles",
      country: "Mexico", 
    },
    {
      id: 25,
      name: "Maximizando la fidelización de clientes con InvuPos",
      description:
        "En el competitivo mundo de la gastronomía, la fidelización de clientes es esencial para el éxito continuo de cualquier negocio. En este contexto, la tecnología desempeña un papel crucial, y es aquí donde InvuPos se destaca como una herramienta indispensable para elevar la lealtad de los comensales. Conociendo a InvuPos: Más allá de un sistema de punto de venta",
      date: "January 17, 2024",
      views: "2.3k",
      comments: "27",
      href: "/blog/maximizando-la-fidelizacion-de-los-clientes-con-invupos",
      imageSrc:
        "https://img.invupos.com/webinvu/Blog%20invu/alianza%20invupos%20cloudbeds.jpg",
      imageAlt: "",
      category: "Marketing",
      typecontent: "Articles",
      country: "Panama", 
    },
  ];


  const prefix = useLocalePrefix();
  
  const sortedData = [...data].sort((a, b) => new Date(b.date) - new Date(a.date));

  const lastThreeItems = sortedData.slice(0, 3);

  return (
    <>
       <section id="results-section" className="bg-gray-100">
        <div className="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mt-12">
            <h2 className="-mt-8 text-4xl font-bold leading-tight text-gray-900">{t("lastblogstitle")}</h2>
            <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/recursos/blog"}
              >
            <p className="-mt-8 text-lg font-bold leading-tight text-gray-900 underline">{t("lastblogsviewall")}</p>
            </Link>
          </div>

          <div className="grid gap-6  grid-cols-1 mt-10 grid grid-cols-1 gap-12 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3">

          <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/blog/el-mejor-sistema-pos-para-spas-y-salones-de-belleza"}
              >
                <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                  <a aria-label="Article">
                    <img
                      src="https://img.invupos.com/webinvu/Blog%20invu/el-mejor-sistema-pos-para-spas-y-salones-de-belleza.jpeg"
                      className="object-cover w-full h-64 rounded-t-lg"
                      alt=""
                    />
                  </a>
                  <div className="py-5 p-6">
                    <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                      Articles
                    </span>
                    <div className="flex justify-between items-end mt-3">
                      <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                      July 5, 2024
                      </p>
                    </div>
                    <a
                      aria-label="Article"
                      className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      <p className="text-2xl font-bold leading-7">
                      El mejor sistema POS para Spas y salones de belleza
                      </p>
                    </a>
                    <p className="mb-4 text-gray-700">
                    La gestión de inventarios es un aspecto crítico para el éxito de cualquier spa. Mantener un equilibrio adecuado entre la oferta y la demanda de productos de belleza puede ser un desafío constante. Un sistema POS (Point of Sale) especializado puede ser la solución ideal para optimizar este proceso, asegurando que los productos necesarios estén siempre disponibles sin exceder en inventario. 
                    </p>
                    <div className="mt-1.5 sm:mt-0">
                      <p className="text-gray-500 italic text-sm">Management</p>
                    </div>
                  </div>
                </div>
              </Link>

          <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/blog/invupos-anuncia-nueva-integracion-con-hero-para-mejorar-la-contabilidad-y-gestion-financiera-de-sus-clientes"}
              >
                <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                  <a aria-label="Article">
                    <img
                      src="https://img.invupos.com/webinvu/Blog%20invu/hero-integrations.webp"
                      className="object-cover w-full h-64 rounded-t-lg"
                      alt=""
                    />
                  </a>
                  <div className="py-5 p-6">
                    <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                      Articles
                    </span>
                    <div className="flex justify-between items-end mt-3">
                      <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                      June 27, 2024
                      </p>
                    </div>
                    <a
                      aria-label="Article"
                      className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      <p className="text-2xl font-bold leading-7">
                      INVU POS anuncia nueva integración con HERO para mejorar la contabilidad y gestión financiera...
                      </p>
                    </a>
                    <p className="mb-4 text-gray-700">
                    Ciudad de Panamá,– Invu POS, líder en soluciones de punto de venta, se complace en anunciar su reciente integración con Hero, una innovadora plataforma de contabilidad y gestión financiera. Esta colaboración tiene como objetivo simplificar y automatizar los procesos contables...
                    </p>
                    <div className="mt-1.5 sm:mt-0">
                      <p className="text-gray-500 italic text-sm">Management</p>
                    </div>
                  </div>
                </div>
              </Link>

          <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/blog/como-elegir-el-mejor-sistema-pos-para-tu-estudio-de-tatuajes-una-guia-completa"}
              >
                <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                  <a aria-label="Article">
                    <img
                      src="https://img.invupos.com/webinvu/Blog%20invu/como-elegir-el-mejor-sistema-pos-para-tu-estudio-de-tatuajes-una-guia-completa.webp"
                      className="object-cover w-full h-64 rounded-t-lg"
                      alt=""
                    />
                  </a>
                  <div className="py-5 p-6">
                    <span className="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700">
                      Articles
                    </span>
                    <div className="flex justify-between items-end mt-3">
                      <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                      June 17, 2024
                      </p>
                    </div>
                    <a
                      aria-label="Article"
                      className="inline-block mt-2.5 mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      <p className="text-2xl font-bold leading-7">
                      Cómo elegir el mejor sistema POS para tu estudio de tatuajes: Una guía completa
                      </p>
                    </a>
                    <p className="mb-4 text-gray-700">
                    Dirigir un estudio de tatuajes es una combinación única de arte, creatividad y gestión empresarial. En el corazón de esta operación se encuentra un sistema POS (Punto de Venta) eficiente y especializado. ¿Pero cómo elegir el mejor sistema POS para tu estudio de tatuajes? En esta guía exhaustiva, exploraremos los aspectos clave...
                    </p>
                    <div className="mt-1.5 sm:mt-0">
                      <p className="text-gray-500 italic text-sm">Management</p>
                    </div>
                  </div>
                </div>
              </Link>

          </div>
        </div>
      </section>

    </>
  );
};

export default Latest_blog;
