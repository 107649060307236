import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import Modal_started from "../Home/Modal_started";

const Plus_tab = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            {/* <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </div> */}
            <div className="max-w-xl mb-6">
              <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("plustittle")}
              </h3>
              <p className="text-gray-600 leading-loose">
                {t("plusdescription")} {""}
                <Link
                  to={`${prefix}` + "/productos/plus"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span className="font-semibold underline">
                    {t("plusspan")}
                  </span>
                </Link>
              </p>
            </div>
            <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <ul className="space-y-1">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem1")}
                  </p>
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem2")}
                  </p>
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem3")}
                  </p>
                </li>
              </ul>
              <ul className="space-y-1">
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem4")}
                  </p>
                </li>
                <li className="flex">
                  <span className="mr-1 ">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem5")}
                  </p>
                </li>
                <li className="flex">
                  <span className="mr-1">
                    <svg
                      viewBox="0 0 20 20"
                      fill="#4F46E5"
                      class="flex-none w-5 h-5 mt-1 mr-2 text-primary"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p className="text-gray-600 text-sm leading-loose">
                    {t("plusitem6")}
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <div class="flex flex-wrap gap-4 text-center mt-6">
                <button
                  class="inline-flex items-center justify-center  block w-full px-8 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="mr-3">{t("pointofsalebutton1")}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="4,4 22,4 19,14 4,14 "
                    />
                    <circle
                      cx="4"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <circle
                      cx="20"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="1,1 4,4 4,14 2,18 23,18 "
                    />
                  </svg>
                </button>

                <Link
                  class="block w-full px-2  py-3 text-sm font-medium bg-white rounded  text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                  to={`${prefix}` + "/productos/plus"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("pointofsalebutton2")}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://img.invupos.com/webinvu/Invu%20Products/Plus/Plus%201.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://img.invupos.com/webinvu/Invu%20Products/Plus/Plus%202.webp"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="px-3">
              <img
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://img.invupos.com/webinvu/Invu%20Products/Plus/Plus%203.webp"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plus_tab;
