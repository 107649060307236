import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_marketing_restaurant = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    " Cómo utilizar el video marketing para promocionar su restaurante | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="  El video marketing es una herramienta de lo más eficaz para
          promocionar cualquier negocio en general… Y con los restaurantes
          funciona a la perfección."
        />
        <meta name="keywords" content="video marketing para restaurante" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2019/Cover%20blog%2019.jpeg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                August 15, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Cómo utilizar el video marketing para promocionar su restaurante
            </h1>

            <p className="text-gray-500 max-w-2xl">
              El video marketing es una herramienta de lo más eficaz para
              promocionar cualquier negocio en general… Y con los restaurantes
              funciona a la perfección.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-2  leading-7 mb-8">
            Y es que una imagen impacta, y es capaz de transmitir contenidos en
            un segundo y, además, no requiere prácticamente ni de atención ni de
            esfuerzo mental por parte del espectador. Sabiendo utilizar el video
            marketing, va a provocar siempre, efectos positivos en la persona
            que lo ve… ya sea éste cliente o potencial cliente. Suma de
            creatividad y psicología, el video marketing capta el interés del
            usuario despertando emociones, motivando la experiencia de compra,
            de consumo.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <p className="mt-2 text-gray-700 leading-7">
            En el caso particular de los restaurantes, la comida provoca deseo.
            Y esto lleva a que, la imagen de un sabroso plato de comida, siempre
            va a valer más que mil palabras.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            4 Formas de usar el video en la estrategia de marketing de tu
            restaurante
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Ya está grabado el video. Ahora toca aprovechar su potencial. Parece
            evidente que el primer uso va a ser colgarlo en la página web del
            restaurante. De este modo, y aplicando una buena estrategia SEO,
            conseguirás mejorar el posicionamiento de la web. Sin embargo, el
            aprovechamiento de esa pieza de video no debe quedar ahí.
          </p>

          <ul class="list-decimal  list-inside mt-5 px-4">
            <li className="text-xl leading-7 font-bold">Redes sociales</li>

            <p className="mt-2 text-gray-700 leading-7">
              A la hora de difundir un video en redes sociales, el restaurante
              habrá de tener en cuenta que la gestión de la pieza deberá estar
              basada en una estrategia clara y bien definida. De otro modo, se
              podría obtener lo contrario de lo que se busca. Es fácil imaginar
              como una instantánea en Instagram de un plato mal presentado, o
              una mala respuesta a una opinión irrespetuosa, pueden hacer tirar
              por la borda todo el trabajo realizado.
            </p>
            <p className="mt-2 text-gray-700 leading-7">
              Algunas de las oportunidades que ofrece la difusión de video
              marketing, a través de redes sociales para un restaurante son:
            </p>

            <ul class="list-disc  list-inside mt-5 px-4">
              <li className="text-gray-700 leading-7 ">
                Dar a conocer el restaurante a posibles clientes de la localidad
                o turistas interesados en un tipo de cocina determinada y
                trabajada por el local.
              </li>
              <li className="text-gray-700 leading-7 ">
                Despertar el interés por la oferta gastronómica o por las
                actividades que ofrece el restaurante.
              </li>
              <li className="text-gray-700 leading-7 ">
                Ofrecer un nuevo canal de reservas y de atención al cliente.
              </li>
              <li className="text-gray-700 leading-7 ">
                Mostrar cómo son los platos que se elaboran y cómo se preparan.
              </li>
              <li className="text-gray-700 leading-7 ">
                Comunicar eventos, menús especiales o la nueva carta del
                restaurante.
              </li>
              <li className="text-gray-700 leading-7 ">
                Transmitir unos valores qué hacen único al restaurante y que lo
                convierten en mejor opción que otros.
              </li>

              <p className="mt-4 text-gray-700 leading-7">
                Y antes de volcar el video en redes, algo muy importante: hay
                que saber adaptarse a cada una de las plataformas. No es lo
                mismo publicar en Instagram, que en Youtube, Facebook, Twitter o
                TikTok.
              </p>
              <p className="mt-2 text-gray-700 leading-7">
                Por este motivo, resulta fundamental tener en cuenta tanto los
                algoritmos de cada red social como su forma de funcionar. De
                este modo, se podrá obtener un mayor rendimiento de cada acción
                que se haga.
              </p>
            </ul>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Transmisión en vivo
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              La moda de la transmisión de videos en directo es algo
              absolutamente pujante. Por ejemplo, muchos restaurantes lo emplean
              en determinadas fechas del calendario para transmitir diferentes
              actividades.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Video marketing por E-Mail
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              Siempre que el video haya sido grabado en el formato adecuado, es
              posible adjuntar la pieza en un correo y difundirlo.
            </p>

            <li className=" mt-8 text-xl leading-7 font-bold">
              Realidad virtual
            </li>

            <p className="mt-2 text-gray-700 leading-7">
              Una vía de difusión con un gran potencial, al combinar mundo real
              y mundo virtual. Vídeos 360 y vídeos storytelling modernizan los
              recursos de un restaurante para motivar a los clientes.
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-6">
            Crea entusiasmo en torno a los próximos eventos
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Emplear el video como herramienta promocional de próximos eventos
            que vaya a organizar un restaurante incrementará, de manera
            exponencial, el entusiasmo de quienes ya habían pensado ir, animará
            a asistir a aquellos que se lo están pensando y, gracias a la
            viralidad, multiplicará la visibilidad y conocimiento del evento.
          </p>
          <p className="mt-4 text-gray-700 italic leading-7">
            Algunas maneras de conseguir estos propósitos son:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className=" leading-7 font-semibold text-lg">
              Reserva a eventos
              <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              O si se prefiere, reserva de fecha, una invitación empleada para
              anunciar el día, el objetivo es anunciar la fecha de un evento a
              través de un formato visual, y dinámico. Sería bueno acabar la
              pieza con una llamada a la acción, invitando a la gente a
              registrarse para obtener más información, invitándoles a
              suscribirse a la newsletter, a seguir al restaurante en redes
              sociales o a comprar tickets para un evento.
            </p>

            <li className="leading-7 font-semibold text-lg mt-8 ">
              Anuncios cortos <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              También llamado vídeo de bocado o snack vídeo. Se trata de vídeos
              publicitarios pensados para condensar la información y el
              contenido gracias a su corta duración. La aparición de los “Bumper
              Ads” ha supuesto una ventaja competitiva para las empresas en
              general y los restaurantes en particular, ya que permite lanzar
              mensajes más directos y atractivos.
            </p>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Promociona tus platos más vendidos
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Todo restaurante tiene –o debería tener- sus especialidades de la
            casa, una creación culinaria (o varias) que hace que la gente vaya a
            ese local y no a otro. A la hora de presentar un plato, el objetivo
            será crear una experiencia culinaria que guste a todos los sentidos,
            no sólo al paladar. Cuando la comida se ve apetitosa, en realidad el
            organismo produce más fluidos que ayudan a absorber los nutrientes,
            así que podríamos decir que la comida que se ve hermosa es más
            nutritiva que la comida que no se ve apetitosa.
          </p>
          <p className="mt-2 text-gray-700 leading-7">
            Será importante presentar el plato por capas, además de jugar con
            colores y texturas contrastantes, aumentando, así, el atractivo
            visual del plato mostrado. Por último, decorando el plato con
            hierbas o especias aromáticas se ganarán muchos puntos frente al
            usuario, goloso, que mira el plato desde la pantalla de su móvil.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            El video marketing y las tendencias de redes sociales
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            A día de hoy, muchos restaurantes –y, en general, la mayoría de las
            empresas- emplean las redes sociales para atraer, interactuar y
            fidelizar con nuevos clientes. Este modo de promocionarse facilita
            la generación de engagement, consiguiendo que el usuario no vea al
            restaurante como una simple empresa que quiere venderle comida. El
            objetivo será convertir al negocio en lo más parecido posible a un
            amigo que aconseje una experiencia gastronómica.
          </p>

          <p className="mt-4 text-gray-700 italic leading-7">
            Las principales tendencias que, en 2022, relacionan video marketing
            y redes sociales son:
          </p>
          <ul class="list-disc  list-inside mt-5 px-4">
            <li className="text-gray-700 leading-7 ">
              El video tiene que generar interacción. El usuario tiene que
              sentirse interpelado para compartir la pieza.
            </li>
            <li className="text-gray-700 leading-7 ">
              Una historia creativa incrementa las posibilidades de compartirla
            </li>
            <li className="text-gray-700 leading-7 ">
              El usuario debe sentirse el centro del storytelling.
            </li>
            <li className="text-gray-700 leading-7 ">
              Apelando a los sentimientos se relaciona la marca con aquello que
              hace sentir.
            </li>
            <li className="text-gray-700 leading-7 ">
              Nunca hay que dejar de lado el SEM
            </li>
            <li className="text-gray-700 leading-7 ">
              Cuanto más breve se sea la pieza, más fácilmente llegará al
              cliente potencial
            </li>
            <li className="text-gray-700 leading-7 ">
              Hay que captar la atención de los usuarios en los primeros
              segundos de video
            </li>
          </ul>

          <h2 className="font-semibold text-xl mt-6">
            Conclusión del video marketing para restaurantes
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Hoy por hoy, el video marketing es la manera perfecta de conseguir
            engagement con el cliente de un restaurante. Hay que tener en cuenta
            que la incorporación de Tablet y Smartphone a la vida diaria, tanto
            de profesionales como de usuarios, ha incrementado exponencialmente
            la posibilidad y facilidad para crear vídeos y acceder a ellos,
            dando como resultado una herramienta que los restaurantes no pueden
            desestimar ni subestimar.
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_marketing_restaurant;
