import React from 'react'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Hardware_equipment = () => {
  const { t, i18n } = useTranslation();
  const prefix = useLocalePrefix();
  return (
    <>
   {/* <section>
  <div class="mx-auto max-w-screen-xl px-4 mt-24 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-none order-last lg:h-full">
        <img
          alt="Party"
          src="https://images.unsplash.com/photo-1527529482837-4698179dc6ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
          class="absolute inset-0 h-full w-full object-cover"
        />
      </div>

      <div class="lg:py-24">
        <h2 class="text-3xl font-bold sm:text-4xl">{t("hardwarequipments")}</h2>

        <a
          href="#"
          class="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          {t("hardwarequipmentsbutton")}
        </a>
      </div>
    </div>
  </div>
</section> */}

<section>
        <div class="max-w-screen-xl px-4 mt-20 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#cad0fb] to-[#eff1fe] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 lg:pb-16 mb-16 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5 lg:order-last">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("hardwarequipments")}
          </h2>
        </div>
        <div className="flex items-center">
        <Link to={`${prefix}` + "/detalles-de-equipos"} onClick={() => window.scrollTo(0, 0)}>
        <button
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("hardwarequipmentsbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
          </Link>
        </div>
      </div>
      <div>
        <img
          src="https://img.invupos.com/webinvu/CTA%20Home/PACTAIMG.webp"
          className="mx-auto xl:mr-32 lg:ml-8 md:max-w-sm lg:mt-0 -mt-12"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>

    </>
  )
}

export default Hardware_equipment