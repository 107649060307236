import React, { useState } from 'react'
import Modal_started from "../../components/Home/Modal_started";
import { useTranslation } from "react-i18next";

const CTA_home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
     {isOpen && <Modal_started setIsOpen={setIsOpen} />} 
         <section>
        <div class="max-w-screen-xl px-4 mt-24 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#e5ecff] to-[#e5ecff] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 mb-16 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5">
        <div className="max-w-xl mb-6">
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          &mdash; {t("ctacontactuppercase")}
          </p>
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("ctacontacttitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          {t("ctacontactdescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-[#001489] bg-[#001489] px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-[#001489] group-active:text-[#001489]"
            >
              {t("ctacontactbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-[#001489] group-active:text-[#001489]"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div>
        <img
          src="https://img.invupos.com/invu%20costa%20rica/Image%20cta%20contact%20cr.png"
          className="h-[415px] mx-auto xl:mr-32 md:max-w-sm lg:-mt-0 -mt-12"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>
    </>
  )
}

export default CTA_home