import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import i18n from "i18next";

function useLocalePrefix() {
  const params = useParams();

  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    setPrefix(genLanguageParam());
  }, [params]);

  function genLanguageParam() {
    const lang = i18n.language;

    if (lang === "es") {
      return "";
    }
    return `/${lang}`;
  }

  return prefix;
}

export default useLocalePrefix;
