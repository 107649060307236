import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal_started from "../../components/Home/Modal_started";
import Modal_started_two from "../../components/Home/Modal_started_two";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../components/utils/useLocalePrefix";

const Support = () => {
  const [isOpenOne, setIsOpenOne] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpenOne && <Modal_started setIsOpen={setIsOpenOne} />}
      {isOpenTwo && <Modal_started_two setIsOpen={setIsOpenTwo} />}


<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        {t("supportinvupostitle")}
        </h2>
      </div>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm text-center">
          <a href="https://pineappletc.zendesk.com/hc/es" target="_blank">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-[#d1efff] mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-headset" width={40} height={40} viewBox="0 0 24 24" strokeWidth={2} stroke="#0049d7" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 14v-3a8 8 0 1 1 16 0v3" />
                  <path d="M18 19c0 1.657 -2.686 3 -6 3" />
                  <path d="M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
                  <path d="M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z" />
                </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("supportinvuposcard1title")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("supportinvuposcard1description")}
            </p>
          </div>
          </a>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm">
        <Link
               to={`${prefix}` + "/socios/distribuidores"}
               onClick={() => window.scrollTo(0, 0)}
              >
          <div className="text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-[#d1efff] mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-progress-check" width={45} height={45} viewBox="0 0 24 24" strokeWidth={2} stroke="#0049d7" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969" />
                <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554" />
                <path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592" />
                <path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305" />
                <path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356" />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("supportinvuposcard2title")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("supportinvuposcard2description")}
            </p>
          </div>
          </Link>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm">
        <Link
               to={`${prefix}` + "/mas-herramientas/integraciones"}
               onClick={() => window.scrollTo(0, 0)}
              >
          <div className="text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-[#d1efff] mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-binary-tree" width={40} height={40} viewBox="0 0 24 24" strokeWidth={2} stroke="#0049d7" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
                <path d="M16 4a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
                <path d="M16 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
                <path d="M11 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
                <path d="M21 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z" />
                <path d="M5.058 18.306l2.88 -4.606" />
                <path d="M10.061 10.303l2.877 -4.604" />
                <path d="M10.065 13.705l2.876 4.6" />
                <path d="M15.063 5.7l2.881 4.61" />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("supportinvuposcard3title")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("supportinvuposcard3description")}
            </p>
          </div>
          </Link>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded-2xl shadow-sm">
        <Link 
               to={`${prefix}` + "/preguntas-frecuentes"}
               onClick={() => window.scrollTo(0, 0)}
              >
          <div className="text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-[#d1efff] mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help-hexagon" width={42} height={42} viewBox="0 0 24 24" strokeWidth={2} stroke="#0049d7" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
                <path d="M12 16v.01" />
                <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
              </svg>
            </div>
            <h6 className="mb-2 font-semibold leading-5">{t("supportinvuposcard4title")}</h6>
            <p className="mb-3 text-sm text-gray-900">
              {t("supportinvuposcard4description")}
            </p>
          </div>
          </Link>
        </div>
      </div>
    </div>
    </>
  );
};

export default Support;
