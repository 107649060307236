import React from "react";
const Careers_details = () => {
  return (
    <>
      <section id="login">
        <div className="bg-white">
          <div className=" max-w-screen-xl  mx-auto bg-white  rounded">
            <div className="mx-auto">
              <div className="">
                <div className="rounded relative h-80">
                  <img
                    src="https://images.pexels.com/photos/1181676/pexels-photo-1181676.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    alt
                    className="w-full h-full object-cover rounded absolute shadow"
                  />
                  <div className="absolute bg-black opacity-50 top-0 right-0 bottom-0 left-0 rounded" />

                  <div className="w-40 h-40  bg-cover bg-center bg-no-repeat absolute bottom-0 -mb-20 ml-8 shadow flex items-center justify-center">
                    <img
                      src="https://res.cloudinary.com/dslkvd23s/image/upload/v1652393821/Branding%20Invu%20/Developers/Group_1_3_dd4nro.svg"
                      alt
                      className="absolute z-0 h-full w-full object-cover shadow top-0 left-0 bottom-0 right-0"
                    />
                    <div className="absolute bg-black opacity-50 top-0 right-0 bottom-0 left-0  z-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="relative  mt-20 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div>
            <h1 class="text-2xl font-bold lg:text-3xl">
              Data Engineering Manager (Remote)
            </h1>

            <ul className="flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3">
              <li className="flex items-center mr-6 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </span>
                <span>San Francisco, Panama City</span>
              </li>
              <li className="flex items-center mr-6 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span> Full Time</span>
              </li>
              <li className="flex items-center mt-6 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </span>
                <span>Engineering</span>
              </li>
            </ul>
          </div>

          <div class="grid gap-8 lg:items-start lg:grid-cols-4">
            <div class="lg:col-span-2">
              <div class="prose max-w-none mt-10">
                <h2 className="font-semibold text-xl mt-6">
                  Company Description
                </h2>
                <p className="mt-4 text-gray-600">
                  In 2013, with the idea of ​​offering complete solutions, in
                  the cloud and with omnipresence, INVU was born in Panama City.
                  Our passion is to provide our customers with solutions that
                  are practical for every day and easy to use at an affordable
                  price. We make sure to listen to our customers, they are the
                  ones who help us grow and evolve to offer a better solution.
                </p>

                <h2 className="font-semibold text-xl mt-6">
                  Minimum Qualifications
                </h2>
                <ul class="list-disc px-6 text-indigo-600 mt-2">
                  <li className="text-gray-600 leading-7">
                    7+ years of analytical experience in data engineering, data
                    science.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Bachelor's degree required, with major in analytical or
                    technical field.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Experience managing and developing a team of at least 2 data
                  </li>
                  <li className="text-gray-600 leading-7">
                    Strong technical intuition and ability to understand complex
                    business
                  </li>
                  <li className="text-gray-600 leading-7">
                    Expert knowledge in data modeling concepts and
                    implementation.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Strong technical accomplishments in SQL, ETLs and data
                    analysis skills
                  </li>
                  <li className="text-gray-600 leading-7">
                    MySQL, Snowflake, Redshift, or similar data handling
                    experience.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Hands on experience in processing extremely large data sets.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Work experience with Python.
                  </li>
                  <li className="text-gray-600 leading-7">
                    Experience with Linux/OSX command line, version control
                    software (git)
                  </li>
                  <li className="text-gray-600 leading-7">
                    Expertise in visualization technologies including Looker,
                    Tableau.
                  </li>
                </ul>

                <h2 className="font-semibold text-xl mt-6">
                  Additional Information
                </h2>

                <p className="mt-4 text-gray-600">
                  We’re working to build a more inclusive economy where our
                  customers have equal access to opportunity, and we strive to
                  live by these same values in building our workplace. Block is
                  a proud equal opportunity employer.
                </p>
              </div>
            </div>

            <div class="lg:top-0 lg:sticky lg:col-span-2">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-4 sm:col-span-3">
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full name
                          </label>
                          <div className="mb-3">
                            <label class="sr-only" for="firstname">
                              Full name
                            </label>
                            <input
                              class="w-full p-2 w-full mt-3 bg-white border border-gray-200 rounded shadow-sm"
                              required
                              type="text"
                            />
                          </div>
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address
                          </label>
                          <div className="mb-3">
                            <label class="sr-only" for="firstname">
                              Email Address
                            </label>
                            <input
                              class="w-full p-2 w-full mt-3 bg-white border border-gray-200 rounded shadow-sm"
                              required
                              type="text"
                            />
                          </div>
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone number
                          </label>
                          <div className="mb-3">
                            <label class="sr-only" for="firstname">
                              Phone number
                            </label>
                            <input
                              class="w-full p-2 w-full mt-3 bg-white border border-gray-200 rounded shadow-sm"
                              required
                              type="text"
                            />
                          </div>
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Subject
                          </label>
                          <div className="mb-3">
                            <label class="sr-only" for="firstname">
                              Subject
                            </label>
                            <input
                              class="w-full p-2 w-full mt-3 bg-white border border-gray-200 rounded shadow-sm"
                              required
                              type="text"
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Resume
                        </label>
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PDF, PNG, JPG, up to 25MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Apply now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Careers_details;
