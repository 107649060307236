import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Redirect, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const ContactMx = () => {

    const { t, i18n } = useTranslation();
    const [language, setLenguage] = useState("es");
    const [registrationId, setRegistrationId] = useState(null);

    const onChangeLanguaje = () => {
        i18n.changeLanguage(language);
        if (language === "es") {
        setLenguage("en");
        } else {
        setLenguage("es");
        }
    };


  return (
    <>
<section class="bg-gray-100">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-2">
      <div class="lg:relative lg:flex lg:items-start">
        <div class="lg:sticky lg:top-24 lg:w-96 lg:mr-8">
          <div class="mt-8">
            <div class="flex -space-x-1 overflow-hidden">
              <img class="inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover" src="https://img.invupos.com/invu%20mexico/flag-mx.png" alt="flag-mx"/>
            </div>
            <h1 class="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
            Invu POS México
            </h1>

              <p className="mt-4 text-lg text-gray-500 md:text-medium">
                {t("contactdescription")}
              </p>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {" "}
                    {t("contactcelltittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-[#006847] to-[#00a067]"></span>
                  <p class="max-w-xs mt-4 text-lg text-gray-500">
                  +52 553727744
                  </p>
                </div>
              </div>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {t("contactemailtittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-[#006847] to-[#00a067]"></span>
                  <p class="max-w-xs mt-4 text-lg text-gray-500">
                    info@invupos.com
                  </p>
                </div>
              </div>
              <div class="mt-8">
                <div>
                  <h3 class="text-lg font-semibold text-black">
                    {" "}
                    {t("contactlocationtittle")}
                  </h3>
                  <span class="block w-10 h-1 mt-1  bg-gradient-to-r from-[#006847] to-[#00a067]"></span>
                  <p class="max-w-xs mt-4  text-lg text-gray-500">
                  Av. Patriotismo 229 Piso 7 Oficina 205, San Pedro de los Pinos, Benito Juárez, Ciudad de México CP 03800
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div className="rounded-2xl">
        <div class="rounded-2xl bg-white shadow-lg lg:col-span-3 lg:px-10">
          <form action="#" class="space-y-4 rounded-xl">
            <iframe
              src="https://forms.monday.com/forms/embed/3057840758763ef17fb0d887a1b6a4d3?r=use1"
              width="100%"
              height="1450"
              title="Monday Form"
              style={{ border: 'none', outline: 'none' }}
            ></iframe>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>



    </>
  );
};

export default ContactMx;