import React from 'react'
import HeroCL from './HeroCL'
import Products_main_tabs from "../landingCL/Products_tabs/Products_main_tabs";
import Customers_carousel from "../../components/modules/Customers_carousel";
import CTA_home from './CTA_home';
import Industries_tabs_main from './Industries/Industries_tabs_main';
import Hardware_equipment from './Hardware_equipment';
import Support from './Support';
import Latest_blog from '../../components/Home/Latest_blog';
import Members from '../../components/Home/Members';
import Industries_carousel from '../../components/modules/Industries_carousel';
import Industries_carousel_cl from '../../components/modules/Industries_carousel_cl';
import Contact_cl from '../../components/Home/Contact_cl';

const LandingCL = () => {
  return (
    <>
    <HeroCL/>
    <Products_main_tabs/>
    {/* <Customers_carousel/> */}
    <Industries_carousel_cl/>
    <CTA_home/>
    <Industries_tabs_main/>
    <Hardware_equipment/>
    <Support/>
    <Latest_blog/>
    <Members/>
    <Contact_cl  />
    </>
  )
}

export default LandingCL