import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_receive_payments = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("Cómo recibir pagos en línea en Venezuela | Invu POS");

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Como recibir pagos en línea en Venezuela, conoce los métodos de pago disponibles en línea y como implementarlos en tu negocio para montar un E-Commerce."
        />
        <meta name="keywords" content="como recibir pagos en linea" />
        <meta
          name="keywords"
          content="sistemas de pago online, aceptar pagos por internet, recibir pagos en linea, recibir pagos online, plataforma pagos online, aceptar pagos en linea"
        />
      </Helmet>

      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%202/Image%20details%20blog%202.jpg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 18, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              ¿Cómo recibir pagos en línea en Venezuela?
            </h1>

            <p className="text-gray-500 max-w-xl">
              Con el paso del tiempo, los métodos de pago han experimentado
              muchos cambios en Venezuela hasta el punto que debes preguntarte
              cómo recibir pagos en línea.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            Desde un panorama de únicamente efectivo a una serie de alternativas
            de pago, que muestran diferentes beneficios a sus usuarios por
            implementarlas en distintos ciclos de venta. Por lo tanto, te
            muestro cómo recibir pagos en línea y los aspectos que debes tomar
            en cuenta al momento de hacerlo.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />

          <h2 className="font-semibold text-xl ">
            ¿Cuáles son los métodos de pago disponibles en línea en Venezuela?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-8">
            Actualmente, muchos negocios en Venezuela cuentan con más de una
            forma de pago para facilitar al cliente la posibilidad de elegir
            medios de pago como:
          </p>

          <ul class=" list-inside mt-5">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">
                Tarjetas de crédito/débito <br></br>
              </span>{" "}
              <span className="font-semibold">
                Es la forma de pago más tradicional que existe en el mundo
              </span>{" "}
              después del método en efectivo. De acuerdo con un estudio
              realizado en WorldsPay, ambas cubren alrededor del 40% de los
              métodos de pago de los consumidores. Un valor importante si
              consideramos la variedad de métodos que hay en la actualidad. Las
              tarjetas de crédito/débito de referencia son Visa, American
              Express, MasterCard, entre otros. <br></br> Debido a que{" "}
              <span>respaldan las transacciones del cliente</span> y la
              facilidad y confianza que brinda durante un proceso de pago
              simple. Además, si tomamos en cuenta un TPV virtual o integras en
              tu tienda online una pasarela de pago con tarjeta, puedes aceptar
              este tipo de transacciones. Aunque el pago móvil es una variación
              que está tomando un impulso distinto, las tarjetas van a continuar
              siendo la preferencia de los usuarios en Venezuela.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                PayPal<br></br>
              </span>{" "}
              Paypal{" "}
              <span className="font-semibold">
                {" "}
                es una de las formas de pago más utilizada por los clientes en
                Venezuela{" "}
              </span>{" "}
              debido a la protección al comprador y facilidad para hacer
              transacciones. Consiste en una plataforma especializada en la
              gestión de pagos online desde que entró al país. <br></br> Además,
              revolucionó las alternativas de pago y promovió la aceptación de
              transferencias entre personas particulares. Al ser los precursores
              de una nueva alternativa de pago, aunado a su capacidad de
              adaptación a distintas situaciones con el transcurso del tiempo.
              Ahora, PayPal ha logrado ser poco a poco un método muy popular
              entre los venezolanos.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Transferencia bancaria<br></br>
              </span>{" "}
              Es un método sencillo de pago que{" "}
              <span className="font-semibold">
                {" "}
                cuenta con todo el respaldo de las entidades bancarias
                tradicionales{" "}
              </span>{" "}
              al depender de ellas directamente. De acuerdo con estudios
              anteriores, las transferencias bancarias están ubicadas entre las
              3 primeras opciones de los consumidores. Un problema que puede
              estar presente en este método de pago, es que para ser usada por
              clientes es obligatorio tener una cuenta de banco. En sectores
              donde la bancarización no supera el 50%, es una opción con un
              rango de penetración moderado a nivel regional.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Monedas virtuales <br></br>
              </span>{" "}
              Siempre han existido criterios desiguales desde el momento en que
              las monedas virtuales surgieron, especialmente las criptomonedas
              como el Bitcoin. La cual,{" "}
              <span className="font-semibold">
                es uno de las que más presencia ha tenido en el mercado digital{" "}
              </span>
              (aunque existen otras como el Etherum, Dash, Litecoin que tienen
              popularidad). Desde el comienzo, el empleo de las criptomonedas
              implica darle la confianza a un código matemático, lo que
              representa una revolución desde su nacimiento. El tamaño del
              impacto que proponen las monedas virtuales es grande, por ejemplo,
              el Bitcoin, no tiene un emisor específico y no posee control
              centralizado, ya que está basado en una red compartida por el
              mundo. La seguridad de este proceso es posible por medio de
              algoritmos criptográficos que evitan que una transacción sea
              revertida de manera anónima. Sin embargo, a pesar de la presencia
              de las criptomonedas, aún no han logrado alcanzar el respaldo
              necesario de las entidades bancarias. Es una realidad que obliga a
              este método de pago a tener un largo camino que recorrer para ser
              la alternativa de preferencia de los clientes.
            </li>
          </ul>

          <h2 className="font-semibold text-xl mt-6">
            ¿Cómo usar Invu POS para recibir pagos en línea?
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Invu POS es un sistema de pagos que te permite convertir cualquier
            dispositivo móvil en un punto de venta avanzado. Para lograrlo,
            <span className="font-semibold">
              solamente tienes que descargar la app de invu POS{" "}
            </span>{" "}
            para hacer ventas en tiendas pequeñas desde cualquier teléfono o
            Tablet Android o iOS. Personaliza tu sistema POS para tu tienda,
            elige las aplicaciones, promociones, ofertas y productos que más
            empleamos. Así, puedes disponer de ellos y agilizar el proceso de
            ventas un poco, lo que resulta en un aumento de tu capacidad de
            atención al cliente y de fidelización.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Beneficios de implementar un sistema de cobro en línea
          </h2>

          <p className="mt-5 text-gray-700 leading-7">
            Mediante el sistema de pagos online puedes gestionar pedidos de
            compras y hacer transferencias de existencias de acuerdo al
            rendimiento y previsiones de inventario. Además,{" "}
            <span className="font-semibold">
              {" "}
              puedes establecer los permisos de control de personal para delegar
              responsabilidades con total tranquilidad.{" "}
            </span>{" "}
            También, te mantiene al tanto de las últimas tendencias con informes
            estadísticos que incluyen las ventas en línea y físicas.
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_receive_payments;
