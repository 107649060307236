import React, { useState } from "react";
import Ecommerce_tab from "./Ecommerce_tab";
import KDS_tab from "./KDS_tab";
import Kiosks_tab from "./Kiosks_tab";
import Loyalty_tab from "./Loyalty_tab";
import Marketing_tab from "./Marketing_tab";
import Plus_tab from "./Plus_tab";
import Point_of_sale_tab from "./Point_of_sale_tab";
import { useTranslation } from "react-i18next";

const Products_main_tabs = () => {
  const [openTab, setOpenTab] = React.useState(1);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <>
      <section>
        <div class="max-w-screen-xl px-4 mt-32 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("productsuppercase")}
                </p> */}
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("productstittle")}
              </h2>
              <p className="text-base text-gray-700 md:text-base">
                {t("productsdescription1")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="flex flex-wrap">
        <div className="container max-w-screen-xl px-4  mx-auto sm:px-6 lg:px-8  ">
          <ul
            className="flex pt-3 pb-4 mb-0 overflow-x-scroll bg-gray-50 shadow-xl rounded-xl p-1.5 flex hide-scrollbar"
            role="tablist"
          >
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 1
                    ? "bg-indigo-600 text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("tab1")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 2
                    ? "bg-indigo-600 text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-cog"></i> {t("tab2")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 3
                    ? "bg-indigo-600 text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tab3")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 4
                    ? "bg-indigo-600 text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tab4")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 5
                    ? "bg-indigo-600 text-white text-xs shadow-lg"
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(5);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tab5")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 6
                    ? "bg-indigo-600 text-white text-xs shadow-lg "
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(6);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tab6")}
              </a>
            </li>
            <li className="flex-1 flex-auto p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 7
                    ? "bg-indigo-600 text-white text-xs shadow-lg "
                    : "bg-pirGray-2000 text-pirGray-500  font-medium tracking-widest")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(7);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tab7")}
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col w-full mb-6 break-words rounded-b-lg ">
            <div className="flex-auto rounded-b-lg bg-pirGray-1800">
              <div className="tab-content tab-space ">
                <div
                  className={openTab === 1 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                  <Point_of_sale_tab />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <Ecommerce_tab />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <Loyalty_tab />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <Plus_tab />
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link4">
                  <KDS_tab />
                </div>
                <div className={openTab === 6 ? "block" : "hidden"} id="link4">
                  <Kiosks_tab />
                </div>
                <div className={openTab === 7 ? "block" : "hidden"} id="link4">
                  <Marketing_tab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Products_main_tabs color="pink" />
    </>
  );
}
