import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import { useTranslation } from "react-i18next";

import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

const partners = [
  {
    id: 1,
    name: "Clau.io",
    href: "https://www.clau.io/",
    commentary:
      '"The point of sale (POS) management software that we’ve integrated in our lottery kiosks was designed specifically with our needs in mind"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650291927/Branding%20Invu%20/Partners/Logo_Clau_1_5_e87sfb.svg",
    logoAlt: "",
  },
  {
    id: 2,
    name: "Shopify",
    href: "https://www.shopify.com/",
    commentary:
      '"The team’s greatest strengths are their willingness to work with partners to better the solution; an excellent ability to listen"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649261443/Branding%20Invu%20/Partners/Shopify_logo_mzdhli.svg",
    logoAlt: "",
  },
  {
    id: 3,
    name: "Binance",
    href: "https://www.binance.com/es",
    commentary:
      '"Invu POS offers excellent service, constant and quick development, a simple platform multiple workflow tools, a fast system”',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650290562/Branding%20Invu%20/Partners/800px-Binance_logo.svg_fk1zi8.png",
    logoAlt: "",
  },
  {
    id: 4,
    name: "Market Man",
    href: "https://www.marketman.com/",
    commentary:
      'It is faster than my other POS. it’s cloud-based and my other POS database was in my oﬃce. The system is great."',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649270927/Branding%20Invu%20/Partners/MarketMan-side-by-side-logo_1_gilkv9.svg",
    logoAlt: "",
  },
  {
    id: 5,
    name: "Banco General",
    href: "https://www.bgeneral.com/",
    commentary:
      '"Is a point of sale software designed for our reality. The company listens to our needs and we are building a long-lasting partnership with the team."',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649270128/Branding%20Invu%20/Partners/Banco_general_logo_ttvnar.svg",
    logoAlt: "",
  },
  {
    id: 6,
    name: "Woo commerce",
    href: "https://www.woocommerce.com/",
    commentary:
      '"We were looking for a secure POS system that would meet our needs. With invu we find all the functionalities"',
    logoSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1649261595/Branding%20Invu%20/Partners/woocommerce_logo_1_wxr8mz.svg",
    logoAlt: "",
  },
];

const Partners_carousel = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const partners = t("partners", { returnObjects: true });

  return (
    <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
      <div className=" relative md:flex justify-center items-center ">
        {/* <!-- Medium and large Slider --> */}
        <div className="hidden md:block relative lg:w-full md:w-8/12 w-full overflow-x-hidden md:mt-0 mt-10">
          <div
            id="slider"
            className="flex justify-start items-start transition ease-out duration-700 gap-4"
          >
            <Swiper
              slidesPerView={4}
              navigation={
                (true,
                {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                })
              }
              className="mySwiper"
              speed={700}
            >
              {partners.map((partner) => (
                <SwiperSlide className="" style={{ width: "100% !important" }}>
                  <div className="w-72 border rounded-md flex-none mr-3 transition-shadow duration-300 border group hover:shadow-xl">
                    <div className="w-full p-4">
                      <div class="mb-4 mt-2">
                        <img
                          src={partner.logoSrc}
                          alt=""
                          class="block object-contain h-8"
                        />
                      </div>
                      <div className="md:w-full h-full relative ">
                        <p className=" text-gray-600 text-sm leading-6">
                          {partner.commentary}
                        </p>
                      </div>
                      <a href={partner.href} target="_blank">
                        <p class="relative inline-block mt-6 mb-2 text-sm font-bold text-indigo-600 cursor-pointer">
                          <span class="absolute inset-x-0 bottom-0 transition-transform transform bg-indigo-100 h-2/3 group-hover:scale-110"></span>
                          <span class="relative">Visit partner</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flex justify-end items-end mt-10 pr-2 pb-1">
            <div className="flex items-center space-x-3">
              <button
                id="prev"
                aria-label="slide backward"
                className="swiper-button-prev focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.666 20H8.33268"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 26.6667L8.33333 20"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 13.3335L8.33333 20.0002"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                id="next"
                aria-label="slide forward"
                className="swiper-button-next focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33398 20H31.6673"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 26.6667L31.6667 20"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 13.3335L31.6667 20.0002"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* ********************************************************* */}

        {/* <!-- Small Slider --> */}
        <div className="block md:hidden relative md:w-8/12 w-full overflow-x-hidden md:mt-0 mt-10 ">
          <div
            id="slider2"
            className="flex justify-start items-start transition ease-out duration-700 gap-0 space-x-2"
          >
            <Swiper
              slidesPerView={1}
              navigation={
                (true,
                {
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                })
              }
              className="mySwiper"
              speed={700}
            >
              {partners.map((partner) => (
                <SwiperSlide style={{ width: "100%" }}>
                  <div
                    id="sizeDiv"
                    className="w-full border rounded-md  sm:w-6/12 flex-none  "
                  >
                    <div className=" w-full  p-4">
                      <div class="mb-4 mt-2">
                        <img
                          src={partner.logoSrc}
                          alt=""
                          class="block object-contain h-8"
                        />
                      </div>
                      <div className="md:w-full h-full relative">
                        <p className=" text-gray-600 text-sm leading-6">
                          {partner.commentary}
                        </p>
                      </div>
                      <a href={partner.href} target="_blank">
                        <p class="relative inline-block mt-6 mb-2 text-sm font-bold text-indigo-600 cursor-pointer">
                          <span class="absolute inset-x-0 bottom-0 transition-transform transform bg-indigo-100 h-2/3 group-hover:scale-110"></span>
                          <span class="relative">Visit partner</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className=" flex justify-end items-end mt-10 pr-2 pb-1">
            <div className="flex items-center space-x-3">
              <button
                id="prev2"
                aria-label="slide backward"
                className="swiper-button-prev focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.666 20H8.33268"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 26.6667L8.33333 20"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 13.3335L8.33333 20.0002"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                id="next2"
                aria-label="slide forward"
                className="swiper-button-next focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33398 20H31.6673"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 26.6667L31.6667 20"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 13.3335L31.6667 20.0002"
                    stroke="#2C3E50"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
					@media (min-width: 768px){
						.swiper-slide-active {
							width: 100% !important;
						}
						.swiper-slide, .swiper-slider-next {
							width: 100% !important;
							flex-shrink: inherit;
						}
					}
					.swiper-button-next {
						position: relative;
					}
					.swiper-button-prev {
						position: relative;
						margin-right: 15px;
					}
					.swiper-button-prev::after, .swiper-button-next::after{
						content: " ";
					}
					.swiper-button-prev, .swiper-button-next {
						width: auto;
						height: auto;
					}
					.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
						opacity:1
					}	
				`}
      </style>
    </div>
  );
};

export default Partners_carousel;
