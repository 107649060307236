import React, { useState } from "react";
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

const Article_nvidia_inception = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "INVU POS eleva su potencial al unirse al programa NVIDIA Inception | Invu POS"
  );

  return (
    <>
      {/* <Helmet>
        <meta
          name="description"
          content=" Ciudad de Panamá, Panamá— 8 Enero, 2024—INVU POS anunció hoy que se ha unido a NVIDIA Inception, un programa diseñado para apoyar a startups que están revolucionando industrias con avances tecnológicos."
        />
        <meta name="keywords" content="INVU POS eleva su potencial al unirse al programa NVIDIA Inception" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Cover%20nvidia.png"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                January 8, 2024
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
            INVU POS eleva su potencial al unirse al programa NVIDIA Inception
            </h1>

            <p className="text-gray-500 max-w-2xl">
            Ciudad de Panamá, Panamá— 8 Enero, 2024—INVU POS anunció hoy que se ha unido a NVIDIA Inception, un programa diseñado para apoyar a startups que están revolucionando industrias con avances tecnológicos.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-2  leading-7 mb-8">
          INVU POS se especializa en proporcionar soluciones integrales de software, hardware y servicios para el procesamiento de pagos, atendiendo las necesidades de comerciantes, empresas tecnológicas y organizaciones. Nuestro enfoque se centra en ofrecer una plataforma de extremo a extremo basada en la nube, que garantiza una experiencia eficiente y segura en todas las transacciones financieras. Nos dedicamos a proporcionar herramientas innovadoras que optimizan los procesos de pago, brindando a nuestros clientes la confianza y la eficacia necesarias en el entorno comercial actual.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Nvdia.png"
            class="object-cover mb-8 w-full h-80 bg-center rounded"
            alt=""
          />

          <p className="text-gray-700 mt-2  leading-7 mb-8">
          NVIDIA Inception permitirá a INVU POS avanzar significativamente en su crecimiento al brindarnos valioso respaldo a través de capacitaciones especializadas, soporte técnico integral y acceso a las últimas tecnologías. Este programa no solo nos proporcionará las herramientas necesarias para optimizar nuestros procesos, sino que también nos ofrecerá la oportunidad única de colaborar con expertos destacados en la industria y otras organizaciones impulsadas por la inteligencia artificial. Estamos entusiasmados con la posibilidad de recibir asistencia adicional por parte de NVIDIA, lo que fortalecerá aún más nuestra posición en el mercado y nos permitirá seguir ofreciendo soluciones innovadoras a nuestros clientes
          </p>

          <p className="text-gray-700 mt-2  leading-7 mb-8">
          <span className="font-semibold">RAFAEL TURGMAN, CEO de INVU POS </span> comentó sobre nuestra decisión de unirnos a NVIDIA Inception, destacando que esta colaboración estratégica representa una oportunidad única para impulsar el crecimiento de INVU POS. Planeamos beneficiarnos significativamente de las capacitaciones especializadas y el soporte técnico brindado por NVIDIA, lo que fortalecerá nuestra capacidad para ofrecer soluciones de procesamiento de pagos de vanguardia. Además, la posibilidad de colaborar con expertos líderes en la industria y otras organizaciones de inteligencia artificial nos permitirá estar a la vanguardia de la innovación.
          </p>

          <p className="text-gray-700 mt-2  leading-7 mb-8">
          NVIDIA Inception ayuda a las startups durante etapas críticas del desarrollo de productos, prototipado e implementación. Cada miembro de NVIDIA Inception recibe un conjunto personalizado de beneficios continuos, como créditos para el Instituto de Aprendizaje Profundo de NVIDIA, soporte de marketing y asistencia tecnológica, lo que proporciona a las startups las herramientas fundamentales para ayudarlas a crecer.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Sobre INVU POS
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
          INVU POS, fundada en 2013, se ha destacado como líder en el mercado de sistemas de facturación y tecnología. Especializados en software de gestión de inventario y punto de venta, ofrecemos soluciones integrales para ayudar a los comercios a administrar ventas, inventario y empleados con facilidad, atrayendo clientes y aumentando ingresos.
          </p>
          <p className="mt-2 text-gray-700 leading-7">
          Desde nuestras oficinas centrales en Ciudad de Panamá, desarrollamos y producimos innovadoras soluciones con un enfoque prioritario en calidad y funcionalidad. Con una trayectoria de más de seis años en el desarrollo de software, INVU POS se distingue por su compromiso con la excelencia y su capacidad para adaptarse a las necesidades específicas de Latinoamérica. Con un equipo de expertos y una red de distribución global, estamos comprometidos a ofrecer a nuestros clientes las herramientas necesarias para el éxito empresarial.
          </p> 

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
            January 8, 2024 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span> 
            </p>
          </div>
        </div>
      </article> */}
      <Helmet>
        <meta
          name="description"
          content=" Ciudad de Panamá, Panamá— 8 Enero, 2024—INVU POS anunció hoy que se ha unido a NVIDIA Inception, un programa diseñado para apoyar a startups que están revolucionando industrias con avances tecnológicos."
        />
        <meta name="keywords" content="INVU POS eleva su potencial al unirse al programa NVIDIA Inception" />
      </Helmet>
      <article class="px-4 py-24 mx-auto max-w-7xl -mt-14" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
  <div class="w-full mx-auto mb-12 text-left md:w-3/4 lg:w-1/2">
    <div className="flex space-x-3 mb-2">
      <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Artículo</p>
      <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Nota de prensa</p>
    </div>
    <h1 class="mb-6 text-3xl font-bold leading-tight text-gray-900 md:text-4xl" itemprop="headline">
      "INVU POS eleva su potencial al unirse al programa NVIDIA Inception"
    </h1>
    <p class="text-base text-gray-500 italic">Fecha de publicación —  <span className="font-semibold">January 8, 2024</span></p>
    <div class="pb-6 mb-6 border-b border-gray-200"></div>
    <p className="text-gray-700 leading-7 mb-6">
      <span className="font-semibold">Ciudad de Panamá, Panamá— 8 Enero, 2024 </span> — INVU POS anunció hoy que se ha unido a NVIDIA Inception, un programa diseñado para apoyar a startups que están revolucionando industrias con avances tecnológicos.
    </p>
    {/* <img src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Cover%20nvidia.png" class="object-cover w-full h-96 bg-center rounded-lg md:order-1 md:h-full" alt="Nvidia" /> */}
    <div class="order-2 h-72 md:order-1 md:h-full rounded-xl">
      <img
        src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Cover%20nvidia.png"
        class="w-full h-full bg-center object-cover rounded-xl"
        alt=""
      />
    </div>
    <p class="mt-6 mb-4 text-xs font-semibold tracking-wider uppercase text-primary">Escrito por:</p>

    <a class="flex items-center text-gray-700" href="#">
      <div class="avatar h-12 w-12 border rounded-full mr-3 object-cover"><img src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg" className="rounded-full" alt="CEO of Invu POS" /></div>
      <div class="ml-2">
        <p class="text-medium font-semibold text-gray-800">Rafael Turgman</p>
        <p class="text-sm text-gray-500">CEO, Invu POS</p>
      </div>
    </a>
    <div class="pb-6 mb-6 border-b border-gray-200"></div>

  </div>

  <div class="w-full mx-auto prose md:w-3/4 lg:w-1/2">
    <p className="text-gray-700 leading-7">
    <span className="font-semibold">INVU POS se especializa en proporcionar soluciones integrales de software, hardware y servicios para el procesamiento de pagos, atendiendo las necesidades de comerciantes, empresas tecnológicas y organizaciones. </span> 
    </p>
    <p className="mt-2 text-gray-700 leading-7">Nuestro enfoque se centra en ofrecer una plataforma de extremo a extremo basada en la nube, que garantiza una experiencia eficiente y segura en todas las transacciones financieras.</p>
    <p className="mt-2 text-gray-700 leading-7">Nos dedicamos a proporcionar herramientas innovadoras que optimizan los procesos de pago, brindando a nuestros clientes la confianza y la eficacia necesarias en el entorno comercial actual.</p>

    <p className="mt-8 text-gray-700 leading-7">
    <span className="font-semibold">NVIDIA Inception permitirá a INVU POS </span> avanzar significativamente en su crecimiento al brindarnos valioso respaldo a través de capacitaciones especializadas, soporte técnico integral y acceso a las últimas tecnologías. 
    </p>
    <p className="mt-2 text-gray-700 leading-7">Este programa no solo nos proporcionará las herramientas necesarias para optimizar nuestros procesos, sino que también nos ofrecerá la oportunidad única de colaborar con expertos destacados en la industria y otras organizaciones impulsadas por la inteligencia artificial. Estamos entusiasmados con la posibilidad de recibir asistencia adicional por parte de <span className="font-semibold">NVIDIA</span>, lo que fortalecerá aún más nuestra posición en el mercado y nos permitirá seguir ofreciendo soluciones innovadoras a nuestros clientes.</p>

    <p className="mt-8 text-gray-700 leading-7">
    <span className="font-semibold">RAFAEL TURGMAN, CEO de INVU POS</span> comentó sobre nuestra decisión de unirnos a NVIDIA Inception, destacando que esta colaboración estratégica representa una oportunidad única para impulsar el crecimiento de INVU POS.
    </p>
    <p className="mt-2 text-gray-700 leading-7">Planeamos beneficiarnos significativamente de las capacitaciones especializadas y el soporte técnico brindado por NVIDIA, lo que fortalecerá nuestra capacidad para ofrecer soluciones de procesamiento de pagos de vanguardia. Además, la posibilidad de colaborar con expertos líderes en la industria y otras organizaciones de inteligencia artificial nos permitirá estar a la vanguardia de la innovación.</p>
  
    <p className="mt-8 text-gray-700 leading-7">
    <span className="font-semibold">NVIDIA Inception ayuda a las startups durante etapas críticas del desarrollo de productos, prototipado e implementación.</span>
    </p>
    <p className="mt-2 text-gray-700 leading-7">Cada miembro de NVIDIA Inception recibe un conjunto personalizado de beneficios continuos, como créditos para el Instituto de Aprendizaje Profundo de NVIDIA, soporte de marketing y asistencia tecnológica, lo que proporciona a las startups las herramientas fundamentales para ayudarlas a crecer.</p>

    <img src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2023/Nvdia.png" class="object-cover w-full h-56 bg-center rounded-lg mt-8" alt="Nvidia" />

    <h2 className="font-semibold text-xl mt-12">
      Sobre INVU POS
    </h2>
    <p className="mt-4 text-gray-700 leading-7">
    <span className="font-semibold">INVU POS, fundada en 2013, se ha destacado como líder en el mercado de sistemas de facturación y tecnología. </span> Especializados en software de gestión de inventario y punto de venta, ofrecemos soluciones integrales para ayudar a los comercios a administrar ventas, inventario y empleados con facilidad, atrayendo clientes y aumentando ingresos.
    </p>
    <p className="mt-2 text-gray-700 leading-7">Desde nuestras oficinas centrales en Ciudad de Panamá, desarrollamos y producimos innovadoras soluciones con un enfoque prioritario en calidad y funcionalidad. Con una trayectoria de más de seis años en el desarrollo de software, INVU POS se distingue por su compromiso con la excelencia y su capacidad para adaptarse a las necesidades específicas de Latinoamérica. Con un equipo de expertos y una red de distribución global, estamos comprometidos a ofrecer a nuestros clientes las herramientas necesarias para el éxito empresarial.</p>


    <div class="pb-6 mb-6 border-b border-gray-200"></div>
    <div class="flex items-center mb-6 space-x-2">
      <p class="text-base text-gray-500">
      January 8, 2024 — Written by:{" "}
        <span className="underline font-regular">Rafael Turgman</span> 
      </p>
    </div>
  </div>
</article>
    </>
  );
};

export default Article_nvidia_inception;
