import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import Help_CTA from "../Home/Help_CTA";
import FAQ_model_one from "./FAQ_model_one";

const features = [
  {
    id: "1",
    name: "Transfer Merchandise",
    description:
      "Transfer inventory easily between stocking locations. Receive inventory at a central location, and then transferthe right quantities to your other locations.",
    imgSrc:
      "https://images.pexels.com/photos/4484078/pexels-photo-4484078.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    icon:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650480731/Branding%20Invu%20/Transfers/Group_46_xh7jdm.svg",
  },
  {
    id: "2",
    name: "Transfer Documents",
    description:
      "The transfer process begins with the creation of a transfer-out document, can be created manually or imported from a handheld data terminal or scanner.",
    imgSrc:
      "https://images.pexels.com/photos/7109288/pexels-photo-7109288.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    icon:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650480730/Branding%20Invu%20/Transfers/Group_44_wetwsu.svg",
  },
  {
    id: "3",
    name: "Invu Quick Transfers",
    description:
      "If you’re in a hurry, you can use Quick Transfers to transfer items between locations in a single step. Lets you specify a “from” location, a “to”location",
    imgSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1652542964/Branding%20Invu%20/Processors/Downloader.la_-627fcdd41ea69_z4hrz8.jpg",
    icon:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650480730/Branding%20Invu%20/Transfers/Group_45_w89sly.svg",
  },
];

const Transfers = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Tranfers Payment");
  return (
    <>
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  Transfers
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Transfer Options with Invu POS
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                With Invu POS, you can choose when you receive your funds. We
                offer a couple of options
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://images.pexels.com/photos/3738387/pexels-photo-3738387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-black">
                &mdash; Transfers
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Standard next business day transfer
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  When you activate your Invu Point of Sale account, link a bank
                  account, and start accepting card payments, we'll transfer
                  funds according to our standard schedule. All Square merchants
                  will start with our standard transfer schedule.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/point-of-sale"
              >
                <span class="text-sm font-medium"> Point of sale </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/3928266/pexels-photo-3928266.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; Transfers
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Invu POS Checking
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Invu Checking is a checking account that gives you instant
                  access to your sales with Invu. With Invu Checking, sales you
                  process with Invu are immediately available to spend on your
                  Invu Debit Card or with your routing and transit number and
                  account number.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments-overview"
              >
                <span class="text-sm font-medium"> Payments </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/8938735/pexels-photo-8938735.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-black">
                &mdash; Transfers
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Instant transfers
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  The fastest way to receive your money is to use Invu Instant
                  Transfers. Sometimes you need your money right away; that's
                  when instant transfer can help. If available in your account,
                  you can instantly send funds to your associated bank account
                  with a debit card 24 hours a day, 7 days a week,
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> Contact sales </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/6627894/pexels-photo-6627894.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; Transfers
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Same day transfer
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Same-day transfers are just as fast as instant transfers, but
                  with less paperwork. If you typically need to receive your
                  transfers in less than one or two business days, same-day
                  transfers can transfer your funds automatically at the close
                  of business .
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments-overview"
              >
                <span class="text-sm font-medium"> Payments </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/6353683/pexels-photo-6353683.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest  uppercase title-font text-black">
                &mdash; Transfers
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Manual transfer
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  By default, your funds will be transferred to your bank
                  account every night, Sunday through Thursday. Instead of using
                  automatic transfers, you can turn on manual transfers to
                  choose when to transfer money. The funds will be available in
                  your Square balance, and you can decide when to transfer them
                  to your associated bank account. The money will arrive in your
                  account the next business day, at no additional cost.
                </p>
              </div>
              <button
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span class="text-sm font-medium"> Get started </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/3907161/pexels-photo-3907161.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            Other transfers with Invu
          </h1>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h1 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            Other transfers with Invu
          </h1>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <div class="grid grid-cols-1 md:gap-4 lg:gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {features.map((feature) => (
              <div className="mt-10">
                <a class="relative block  overflow-hidden border border-gray-100 rounded-lg">
                  <img
                    class="object-cover w-full h-56"
                    src={feature.imgSrc}
                    alt=""
                  />
                  <div className="p-2 lg:p-6">
                    <span class="absolute inset-x-0 bottom-0 h-2  bg-gradient-to-r from-pirLightBlue to-pirPurple"></span>

                    <div class="justify-between sm:flex">
                      <div>
                        <h5 class="text-xl font-bold text-gray-900">
                          {feature.name}
                        </h5>
                        <p class="mt-1 text-xs font-medium text-gray-600">
                          By{" "}
                          <a href="/">
                            <span className="underline font-bold">
                              Invu POS
                            </span>
                          </a>
                        </p>
                      </div>

                      <div class="flex-shrink-0 hidden ml-3 sm:block">
                        <img
                          class="object-cover w-12 h-12 rounded-lg shadow-sm"
                          src={feature.icon}
                          alt=""
                        />
                      </div>
                    </div>

                    <div class="mt-6 sm:pr-8">
                      <p class="text-sm mb-4 text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Transfers;
