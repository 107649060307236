import React, { useEffect } from "react";
import Apps from "../components/Home/Apps";
import Contact from "../components/Home/Contact";
import CTA_Blog from "../components/Home/CTA_Blog";
import Customers from "../components/Home/Customers";
import Hardware from "../components/Home/Hardware";
import Hero from "../components/Home/Hero";
import Industries from "../components/Home/Industries";
import Managing from "../components/Home/Managing";
import Members from "../components/Home/Members";
import Newsletter from "../components/Home/Newsletter";
import Partners from "../components/Home/Partners";
import Reasons from "../components/Home/Reasons";
import Sell_eveywhere from "../components/Home/Sell_eveywhere";
import Solutions from "../components/Home/Solutions";
import Support from "../components/Home/Support";
import Testimonials from "../components/Home/Testimonials";
import Industries_tabs_main from "../components/Industries//Industries_tabs_main"
import Products_main_tabs from "../components/Products_tabs/Products_main_tabs";
import useDocumentTitle from "../useDocumentTitle";

import { Helmet } from "react-helmet";
import Cookie_policy from "../components/modules/Cookie_policy";
import Customers_carousel from "../components/modules/Customers_carousel";
import Latest_blog from "../components/Home/Latest_blog";
import CTA_home from "../components/Home/CTA_home";
import Hardware_equipment from "../components/Home/Hardware_equipment";
import Industries_carousel_pa from "../components/modules/Industries_carousel_pa";

const Home = () => {
  useDocumentTitle("Software de Punto de Venta Avanzado | POS");

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="El sistema POS ideal para comercios de todo tipo, tiendas minoristas, restaurantes, boutiques, hoteles, bares y más. Simplifica la gestión de ventas, inventarios, facturación y clientes con nuestra solución intuitiva y eficiente. Mejora la eficiencia de tu negocio hoy mismo."
        />
      </Helmet>
      <Hero />
      {/* <Industries /> */}
      <Products_main_tabs />
      {/* <Customers /> */}
      {/* <Customers_carousel/> */}
      <Industries_carousel_pa/>
      <CTA_home/>
      <Industries_tabs_main/>
      <Hardware_equipment/>
      {/* <Hardware /> */}
      {/* <Reasons /> */}
      {/* <Apps /> */}
      {/* <CTA_Blog /> */}
      <Partners />
      <Support />
      <Latest_blog/>
      {/* <Testimonials /> */}
      {/* <Newsletter /> */}
      <Members />
      <Contact />
    </>
  );
};

export default Home;
