import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";

const features = [
  {
    id: "1",
    name: "API Updates ",
    description:
      "Important updates to the API are announced in the Changelog and Release Notes. Follow these lists to be sure that future changes will not impact your integration.",
    imgSrc:
      "https://img.invupos.com/webinvu/Contact%20sales%20module/Icons%20features/icon1.svg",
  },
  {
    id: "2",
    name: "Community of API Developers",
    description:
      "The Invu community is a place for you to connect with other Retail API developers. Share your expertise and best practices. stay current with our latest Retail API announcements.",
    imgSrc:
      "https://img.invupos.com/webinvu/Contact%20sales%20module/Icons%20features/icon2.svg",
  },
  {
    id: "3",
    name: "About release notes ",
    description:
      "Visit the Release Notes section to review new features, enhancements, and fixes that can improve your Invu POS experienceand all the other products that we have integrated.",
    imgSrc:
      "https://img.invupos.com/webinvu/Contact%20sales%20module/Icons%20features/icon3.svg",
  },
  {
    id: "4",
    name: "Security and compliance",
    description:
      "Our payments APls include end-to-end encryption, disputemanagement, fast deposits for sellers, and fraud detection.Security payment platform. Trust us and start right now",
    imgSrc:
      "https://img.invupos.com/webinvu/Contact%20sales%20module/Icons%20features/icon4.svg",
  },
];

const Contact_sales = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Contact Sales");

  return (
    <>
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  CONTACT SALES
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Powering businesses of every sizes
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                Invu adapts and adjusts on the fly to the complexity of multiple
                stores and multiple employees.
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Contact%20sales%20module/Contact%20sales%20header%20.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-12">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; contact sales
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight text-black md:leading-tight sm:text-left md:text-4xl">
                Built for big businesses
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold  rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    Competitive, custom rates
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">Call or email</span> us to
                    get your rate and learn how to reduce your total cost of
                    ownership with Invu
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    API and partner integrations
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Connect your existing services
                    </span>{" "}
                    with Invu, our use Invu data to build custom apps.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    Process Payments
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">Process payments</span> with
                    industry-leading hardware and software.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    Understand your customers
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Analyze customer behavior
                    </span>{" "}
                    offer gift cards and loyalty programs.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Contact%20sales%20module/Contact%20sales%201.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirBlack mt-24">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <div className="lg:w-1/2 ">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 className="mb-3 text-xs font-semibold tracking-widest text-white  title-font text-pirGray-100">
                &mdash; APIs
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none xl:max-w-lg">
                Custom APIs and partner integrations.
              </h2>
            </div>
            <div className="flex items-end lg:w-1/2">
              <p className="text-base text-gray-500 md:text-medium">
                Invu existing tools are just the beginning. Connect your suite
                of existing services, or use Invu’s API to build custom apps
                with your payments data.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            <div>
              <a
                href="/developers"
                class="block p-8 bg-pirDarkGray rounded-lg "
              >
                <img
                  src="https://img.invupos.com/webinvu/Contact%20sales%20module/Contact%20sale%202.jpeg"
                  class="object-cover w-full h-64 rounded-md"
                />
                <h2 class="mb-2 mt-6 text-2xl font-semibold text-gray-900">
                  <a
                    href="/developers"
                    class="text-gray-100 hover:text-gray-300"
                  >
                    Make it your own
                  </a>
                </h2>
                <p className=" text-base mb-4  text-gray-500 md:text-lg sm:mb-8 ">
                  Your developers can pull Invu Data to build customized
                  reports, large item libraries, employee lists, and more.
                </p>
                <a href="/developers">
                  <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                    Learn more {""}
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </a>
              </a>
            </div>
            <div>
              <a
                href="/inventory-managment"
                class="block p-8 bg-pirDarkGray rounded-lg "
              >
                <img
                  src="https://img.invupos.com/webinvu/Contact%20sales%20module/Contact%20sales%203.jpeg"
                  class="object-cover w-full h-64 rounded-md"
                />
                <h2 class="mb-2 mt-6 text-2xl font-semibold text-gray-900">
                  <a
                    href="/inventory-managment"
                    class="text-gray-100 hover:text-gray-300"
                  >
                    Integrate your apps with a click
                  </a>
                </h2>
                <p className=" text-base mb-4 text-gray-500 md:text-lg sm:mb-8">
                  From accounting services to inventory management, Invu
                  integrates seamlessly with the apps you’re already using.
                </p>
                <a href="/inventory-managment">
                  <div class="inline-block pb-1 font-medium text-blue-600 border-b border-blue-500 cursor-pointer">
                    Learn more {""}
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </a>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            {features.map((feature) => (
              <div>
                <a class="block p-6 transition-shadow border rounded-lg bg-white sm:pr-12 group hover:shadow-sm">
                  <span class="inline-block text-white bg-indigo-600 rounded-lg">
                    <img src={feature.imgSrc} className="rounded-lg" alt="" />
                  </span>

                  <h2 class="mt-3 text-lg font-bold">{feature.name}</h2>

                  <p class="mt-3 text-sm text-gray-500">
                    {feature.description}
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Contact_sales;
