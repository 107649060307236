import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../../../components/utils/useLocalePrefix";
import Modal_started from "../../../components/Home/Modal_started";

const Ecommerce_tab = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="mt-4 max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            {/* <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                  clip-rule="evenodd"
                />
              </svg>
            </div> */}
            <div className="max-w-xl mb-6">
              <a>
                <h3 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  {t("ecommercetittle")}
                </h3>
              </a>
              <p className="text-gray-600 leading-loose">
                {t("ecommercedescription")}{" "}
                <Link
                  to={`${prefix}` + "/productos/comercio-electronico"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span className="underline font-semibold cursor-pointer">
                    {t("ecommercebutton")}
                  </span>
                </Link>
              </p>
            </div>
            <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
              <div className="bg-white border-l-4 shadow-sm">
                <div className="h-full p-5 border border-l-0 rounded-r">
                  <li class="flex">
                    <div
                      class="
                        inline-flex
                        items-center
                        w-6
                        h-6
                        cursor-pointer bg-[#ffcc00]
                        rounded-xl
                      "
                    >
                      <svg
                        class="flex-shrink-0 w-4 h-4 mx-auto text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                    <span class="mb-2 ml-2 font-semibold leading-">
                      {t("ecommercecardtittle1")}
                    </span>
                  </li>

                  <p className="text-sm mt-2 text-gray-500">
                    {t("ecommercecarddescription1")}
                  </p>
                </div>
              </div>
              <div className="bg-white border-l-4 shadow-sm ">
                <div className="h-full p-5 border border-l-0 rounded-r">
                  <li class="flex">
                    <div
                      class="
                        inline-flex
                        items-center
                        w-6
                        h-6
                        cursor-pointer bg-[#ffcc00]
                        rounded-xl
                      "
                    >
                      <svg
                        class="flex-shrink-0 w-4 h-4 mx-auto text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                    <span class="mb-2 ml-2 font-semibold leading-">
                      {t("ecommercecardtittle2")}
                    </span>
                  </li>
                  <p className="text-sm mt-2 text-gray-500">
                    {t("ecommercecarddescription2")}
                  </p>
                </div>
              </div>
            </div>
            
            <div>
              <div class="flex flex-wrap gap-4 text-center mt-6">
                <button
                  class="inline-flex items-center justify-center  block w-full px-8 py-3 text-sm font-medium text-white rounded shadow bg-[#00247D]  sm:w-auto hover:bg-opacity-95  focus:outline-none focus:ring"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="mr-3">{t("pointofsalebutton1")}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="4,4 22,4 19,14 4,14 "
                    />
                    <circle
                      cx="4"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <circle
                      cx="20"
                      cy="22"
                      r="2"
                      strokeLinejoin="miter"
                      strokeLinecap="square"
                      stroke="none"
                      fill="currentColor"
                    />
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      points="1,1 4,4 4,14 2,18 23,18 "
                    />
                  </svg>
                </button>

                <Link
                  class="block w-full px-2  py-3 text-sm font-medium bg-white rounded  text-[#00247D] hover:text-opacity-80  sm:w-auto active:text-indigo-500 focus:outline-none focus:ring"
                  to={`${prefix}` + "/productos/comercio-electronico"}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("pointofsalebutton2")}
                </Link>
              </div>
            </div>

          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src="https://img.invupos.com/webinvu/Invu%20Products/Ecommerce/Ecommerce%201.jpg"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecommerce_tab;
