import React from 'react'
import FAQ_model_one from './FAQ_model_one'

const FAQ = () => {
  return (
    <>
    <div className='mb-12'>
    <FAQ_model_one />
    </div>
    </>
  )
}

export default FAQ