import React, { useState } from "react";
import Modal_started_two from "./Modal_started_two";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Members = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <section className="bg-pirBlack ">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple flex justify-center items-center"></div>
          <h2 className="max-w-md mb-6 font-sans text-3xl leading-loose space-y-2 font-bold tracking-tight text-white sm:text-4xl sm:leading-none xl:max-w-lg">
            {t("membertittle")}
          </h2>
          <p class="mb-20 mt-2  max-w-lg text-gray-500 text-base md:text-medium">
            {t("memberdescription")}{" "}
            <a>
              <span className="underline cursor-pointer font-semibold">
                {t("memberspan")}
              </span>
            </a>
          </p>
          <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div>
              <a href="#">
                <img
                  src="https://img.invupos.com/webinvu/Member/Member%201.jpg"
                  class="object-cover w-full h-96 mb-5 bg-center rounded"
                  loading="lazy"
                  alt=""
                />
              </a>
              <h3 class="mb-2 text-lg font-semibold text-gray-900">
                <a href="#" class="text-gray-100 hover:text-gray-300">
                  {t("membercardtittle1")}
                </a>
              </h3>
              <p class="mb-3 text-sm max-w-lg  font-normal text-gray-500">
                {t("membercarddescription1")}
              </p>
              <a
                onClick={() => setIsOpen(true)}
                class="inline-block pb-1  mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer"
              >
                {t("membercardbutton1")} {""}
                <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
            <div>
              <a >
                <img
                  src="https://img.invupos.com/webinvu/Member/Member%202.avif"
                  class="object-cover w-full h-96 mb-5 bg-center rounded"
                  loading="lazy"
                  alt=""
                />
              </a>
              <h3 class="mb-2 text-lg font-semibold text-gray-900">
                <a  class="text-gray-100 hover:text-gray-300">
                  {t("membercardtittle2")}
                </a>
              </h3>
              <p class="mb-3 text-sm max-w-lg font-normal text-gray-500">
                {t("membercarddescription2")}
              </p>
            
              <Link
                to={`${prefix}` + "/contacto-con-ventas"}
                onClick={() => window.scrollTo(0, 0)}
                className="inline-block pb-1 mt-4 font-medium text-blue-600 border-b border-blue-500 cursor-pointer"
              >
                  <button>
                  {t("membercardbutton2")} {""}
                <span aria-hidden="true">&rarr;</span>
                  </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {isOpen && <Modal_started_two setIsOpen={setIsOpen} />}
    </>
  );
};

export default Members;
