import React from 'react'
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";


const Article_hero_integration = () => {
  useDocumentTitle(
    "INVU POS anuncia nueva integración con HERO para mejorar la contabilidad y gestión financiera de sus clientes. | Invu POS"  
  );  
  return (
    <>
    <Helmet>
    <meta
      name="description"
      content="Ciudad de Panamá,– Invu POS, líder en soluciones de punto de venta, se complace en anunciar su reciente integración con Hero, una innovadora plataforma de contabilidad y gestión financiera. Esta colaboración tiene como objetivo simplificar y automatizar los procesos contables para los usuarios de Invu POS, proporcionando una solución integral que conecta directamente las ventas con la contabilidad."
    />
    <meta name="keywords" content="INVU POS anuncia nueva integración con HERO para mejorar la contabilidad y gestión financiera de sus clientes." />
   </Helmet>
    <article class="px-4 py-24 mx-auto max-w-7xl -mt-14" itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="w-full mx-auto mb-12 text-left md:w-3/4 lg:w-1/2">
            <div className="flex space-x-3 mb-2">
            <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Nota de Prensa</p>
            <p class="mt-6 mb-4 py-2 px-5 rounded-full text-xs font-semibold tracking-widest uppercase text-[#6D28D9] bg-[#f8f3ff]">Alianza</p>
            </div>
            <h1 class="mb-6 text-3xl font-bold leading-tight text-gray-900 md:text-4xl" itemprop="headline">
            INVU POS anuncia nueva integración con HERO para mejorar la contabilidad y gestión financiera de sus clientes.
            </h1>
            <p class="text-base text-gray-500 italic">Fecha de publicación — <span className="font-semibold">June 27, 2024</span></p>
            <div class="pb-6 mb-6 border-b border-gray-200"></div>
            <p className="text-gray-700 leading-7 mb-6">
            Ciudad de Panamá,– Invu POS, líder en soluciones de punto de venta, se complace en anunciar su reciente integración con Hero, una innovadora plataforma de contabilidad y gestión financiera. Esta colaboración tiene como objetivo simplificar y automatizar los procesos contables para los usuarios de Invu POS, proporcionando una solución integral que conecta directamente las ventas con la contabilidad.
            </p>
            <div class="order-2 h-72 md:order-1 md:h-full rounded-xl">
            <img
                src="https://img.invupos.com/webinvu/Blog%20invu/hero-integrations.webp"
                class="w-full h-full bg-center object-cover rounded-xl"
                alt=""
            />
            </div>
   
            <a class="flex items-center text-gray-700 mt-6" href="#">
            <div class="gradient-div"></div>
            <div class="ml-2">
                <p class="text-medium font-semibold text-gray-800">De: Invu POS</p>
            </div>
            </a>
            <div class="pb-6 mb-6 border-b border-gray-200"></div>
   
        </div>
   
        <div class="w-full mx-auto prose md:w-3/4 lg:w-1/2">
   
   
           <h2 className="font-semibold text-xl mt-12">
           Tecnología de Hero para una contabilidad eficiente
           </h2>
           <p className="mt-4 text-gray-700 leading-7">
           Hero se destaca por utilizar tecnología avanzada para mantener la contabilidad de sus clientes siempre actualizada y en orden. La plataforma recoge y procesa información de diversas fuentes, incluyendo cuentas bancarias, tarjetas de Hero y de otros bancos, cuentas de e-commerce, facturadores, entre otros, para generar estados financieros mensuales precisos y detallados. 
           </p>

           <h2 className="font-semibold text-xl mt-12">
           Beneficios de la integración Invu POS y Hero
           </h2>
           <p className="mt-4 text-gray-700 leading-7">
           Gracias a esta nueva integración, los clientes de Invu POS podrán aprovechar al máximo los servicios de Hero. Mediante una API, los datos de ventas de Invu POS se transmitirán automáticamente a Hero, permitiendo que las ventas se reflejen en la contabilidad en tiempo real. Esta automatización no solo optimiza la precisión contable, sino que también ahorra tiempo y reduce el riesgo de errores humanos.
           </p>

           <h2 className="font-semibold text-xl mt-12">
           Ventajas Clave de la Integración:
           </h2>
            <ul class="list-disc mt-4  text-gray-700 leading-7">
                <li><span className='font-semibold'>Actualización inmediata:</span> Las ventas registradas en Invu POS se integran automáticamente en los sistemas contables de Hero, asegurando que los registros financieros estén siempre actualizados.</li>
                <li><span className='font-semibold'>Eficiencia mejorada: </span>  La automatización de la transferencia de datos elimina la necesidad de ingresar manualmente la información de ventas, reduciendo significativamente el trabajo administrativo.</li>
                <li><span className='font-semibold'>Gestión financiera integral: </span>Los usuarios pueden disfrutar de una visión completa de sus finanzas, desde la venta hasta la contabilidad y la gestión de impuestos, todo en una sola plataforma.</li>
            </ul>

           <img src="https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" class="object-cover  w-full h-64 bg-center rounded-lg mt-8" alt="" />

           <p className="mt-4 text-gray-700 leading-7">
           Estamos emocionados de asociarnos con Hero para ofrecer a nuestros clientes una solución que simplifique y mejore significativamente la gestión contable, comentó RAFEL TURGMAN CEO de Invu POS. Esta integración refleja nuestro compromiso de proporcionar herramientas que permitan a nuestros usuarios concentrarse en hacer crecer sus negocios, mientras Hero se encarga de mantener sus finanzas en orden.
           </p>

            <p className="mt-4 text-gray-700 leading-7">
                Para más información sobre la integración y cómo puede beneficiar a su negocio, visite <span className='font-semibold'><a href="https://www.invupos.com" target="_blank">www.invupos.com</a></span> o <span className='font-semibold'><a href="https://try.herofacturas.com" target="_blank">try.herofacturas.com</a></span>
            </p>

            <div class="pb-6 mb-6 border-b border-gray-200"></div>
            <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
            June 27, 2024 — Written by:{" "}
                <span className="underline font-regular">Andres Amaya</span> 
            </p>
            </div>
        </div>
    </article>
    </>
  )
}

export default Article_hero_integration;