import React, { useState } from "react";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import Help_CTA from "../Home/Help_CTA";

const industries = [
  {
    id: 1,
    name: "Invu Point of Sale",
    href: "/point-of-sale",
    imageSrc:
      "https://images.pexels.com/photos/9810169/pexels-photo-9810169.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 2,
    name: "Invu Restaurants",
    href: "/food-and-beverage",
    imageSrc:
      "https://images.pexels.com/photos/761854/pexels-photo-761854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 3,
    name: "Invu2GO E-ecommerce",
    href: "/ecommerce",
    imageSrc:
      "https://images.pexels.com/photos/7309322/pexels-photo-7309322.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 4,
    name: "Invu Retail",
    href: "/retail-overview",
    imageSrc:
      "https://images.pexels.com/photos/3735172/pexels-photo-3735172.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 5,
    name: "Invu loyalty",
    href: "/customer-loyalty",
    imageSrc:
      "https://images.pexels.com/photos/2988232/pexels-photo-2988232.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 6,
    name: "Invu PLUS",
    href: "/products/Plus",
    imageSrc:
      "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 7,
    name: "Invu KDS",
    href: "/products/KDS",
    imageSrc:
      "https://images.pexels.com/photos/6969755/pexels-photo-6969755.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 8,
    name: "Invu Kiosks",
    href: "/products/Kiosks",
    imageSrc:
      "https://images.pexels.com/photos/1015568/pexels-photo-1015568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
  {
    id: 9,
    name: "Invu Marketing",
    href: "/products/Marketing",
    imageSrc:
      "https://images.pexels.com/photos/265087/pexels-photo-265087.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    imageAlt: "",
  },
];

const Products = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Products Overview");
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  PRODUCTS
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Powerful inventory management software.
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                Totally dominate your inventory using innovative tools to
                increase the efficiency and visibility of your business.
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://images.pexels.com/photos/8475205/pexels-photo-8475205.jpeg?auto=compress&cs=tinysrgb&w=800"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 mt-16">
          <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
            <div className="lg:w-1/2 ">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; Products
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                Our products are key to
                <br></br>
                the success of your company.
              </h2>
            </div>
            <div className="flex items-end lg:w-1/2">
              <p className="text-base text-gray-700 md:text-medium">
                Choose an Invuque hardware package that works out of the box or
                select one of our recommended hardware to get started and
                continue, no problem.
              </p>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-3 xl:gap-x-8">
            {industries.map((industry) => (
              <div key={industry.id} className="group relative">
                <a
                  href={industry.href}
                  class="block overflow-hidden rounded-2xl"
                >
                  <img
                    class="object-cover w-full h-36 lg:h-44 md:h-40"
                    src={industry.imageSrc}
                    alt=""
                  />
                  <div class="p-4 bg-gray-900">
                    <h5 class="text-sm font-semibold text-white">
                      {industry.name}
                    </h5>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Products;
