import React, { useEffect } from 'react';
import useDocumentTitle from '../../useDocumentTitle';

const Privacy_policies = () => {
  useDocumentTitle('Invu POS | Políticas de privacidad');

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'termly-jssdk';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="px-4 py-24 mx-auto max-w-6xl">
      <div name="termly-embed" data-id="674c04af-0d4e-4c1a-a355-b1da79554434" />
    </section>
  );
};

export default Privacy_policies;
