import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import { useTranslation } from "react-i18next";

const features_one = [
  {
    name: "Convenient & Contactless",
    description:
      "Multi-channel campaigns, email, SMS, postal mail, calls. Do not stop reaching your customers, you have all the means.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%201.svg",
  },
  {
    name: "Fully-Integrated",
    description:
      "Because each client is a world, perform segmented campaigns to match the interests of their customers.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%202.svg",
  },
  {
    name: "Efficient",
    description:
      "Always easy, fast and intuitive. 5 simple steps allow you to adjust your campaign to the maximum to achieve your goals.",
    imgSrc:
      "https://img.invupos.com/webinvu/Invu%20Payments/features%20icon/feature%20pay%203.svg",
  },
];

const Payment_in_person = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useDocumentTitle("Pagos en persona | Invu POS");
  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}

      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("paymentsgeneralinpersonuppercase")}
                </p>
              </div>
              <h1 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("paymentsgeneralinpersontitle")}
              </h1>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("paymentsgeneralinpersondescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg "
                src="https://img.invupos.com/webinvu/Payments%20in%20person%20module/Header%20payments%20in%20person%20.jpg"
                alt="payments overview"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-gray-50">
        <div class="px-4  mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {features_one.map((feature) => (
              <div className="bg-white border rounded p-5">
                <img
                  class="inset-0 object-cover w-14 h-14 rounded mb-4"
                  src={feature.imgSrc}
                  alt=""
                />
                <p class="mb-2 mt-3 text-sm font-normal text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("paymentsgeneralinpersonsection2uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("paymentsgeneralinpersonsection2title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection2item1title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection2item1description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection2item2title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection2item2description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection2item3title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection2item3description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%201.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                &mdash; {t("paymentsgeneralinpersonsection3uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                {t("paymentsgeneralinpersonsection3title")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection3item1title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection3item1description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection3item2title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection3item2description")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 ">
                    {t("paymentsgeneralinpersonsection3item3title")}
                  </h3>
                  <p className="mt-2 text-sm lg:text-sm text-gray-600 leading-loose">
                    {t("paymentsgeneralinpersonsection3item3description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%202.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
          <div className="lg:w-1/2 ">
            <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

            <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
              &mdash; Kitchen display system
            </p>
            <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
              Point of sale that drives the most successful restaurants
            </h2>
          </div>
          <div className="flex items-end lg:w-1/2">
            <p className="text-base text-gray-700 md:text-medium">
              Payment processing to power your restaurant with insights.
              Hardwired terminals and offline mode so nothing slows you down
            </p>
          </div>
        </div>
        <div className="group relative">
          <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
            <img
              className="object-cover w-full h-86 sm:h-96 rounded-lg"
              src="https://img.invupos.com/webinvu/Invu%20Payments/Payments%20section%203.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="grid mt-12 gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon1.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              A flower in my garden, a mystery in my panties. Heart attack never
              stopped old Big Bear. I didn't even know we were calling him Big
              Bear.
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon2.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Easy to set up in minutes, with no installation costs. Spend less
              time training your staff, and more time getting orders out.
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16  rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon3.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Speed up operations with reports that give you insights on prep
              times and more.Designed for flexibility, Invu works with your
              kitchen setup and processes
            </p>
          </div>
          <div className="max-w-md">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-lg ">
              <img
                className="w-16 h-16 rounded-lg"
                src="https://img.invupos.com/webinvu/Invu%20Payments/payicon4.svg"
              />
            </div>
            <p className="mb-3 text-sm text-gray-700">
              Whether you take orders through POS, Square Online site, or
              delivery apps like Postmates, everything is sent to and fulfilled
              directly from the kitchen.
            </p>
          </div>
        </div>
      </div> */}

      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; {t("paymentsgeneralinpersonsection4uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                {t("paymentsgeneralinpersonsection4title")}
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  {t("paymentsgeneralinpersonsection4description")}
                </p>
              </div>
              <button
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span class="text-sm font-medium"> {t("paymentsgeneralinpersonsection4button")} </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Invu%20Payments/Pay%20section%205.jpg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payment_in_person;
