import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Ecommerce_tab from "../Products_tabs/Ecommerce_tab";
import Loyalty_tab from "../Products_tabs/Loyalty_tab";
import Point_of_sale_tab from "../Products_tabs/Point_of_sale_tab";
import Hardware_tab from "./Hardware_tab";
import Report_tab from "./Report_tab";
import Software_tab from "./Software_tab";

const Software_hardware_reports_tabs = () => {
  const [openTab, setOpenTab] = React.useState(1);

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };
  return (
    <>
    <section className="bg-gray-100 py-16 mb-28">
      <div className="flex flex-wrap">
        <div className="container max-w-screen-xl px-4  mx-auto sm:px-6 lg:px-8 ">
          <ul
            className="flex pt-3 pb-4 mb-0 overflow-x-scroll  xl:overflow-hidden p-1.5 flex hide-scrollbar"
            role="tablist"
          >
            <li className="flex-1  p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase  px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 1
                    ? "bg-[#121212] text-white text-xs shadow-lg"
                    : "bg-white text-[#121212] border-2 border-[#D3D3A] font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="mr-1 text-base w-full fas fa-space-shuttle"></i>
                {t("tabproductsoftware")}
              </a>
            </li>
            <li className="flex-1 p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 2
                    ? "bg-[#121212] text-white text-xs shadow-lg"
                    : "bg-white text-[#121212] border-2 border-[#D3D3A] font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-cog"></i> {t("tabproducthardware")}
              </a>
            </li>
            <li className="flex-1 p-2 mr-2 -mb-px text-center last:mr-0">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded-full block leading-normal " +
                  (openTab === 3
                    ? "bg-[#121212] text-white text-xs shadow-lg"
                    : "bg-white text-[#121212] border-2 border-[#D3D3A] font-medium tracking-widest ")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="mr-1 text-base fas fa-briefcase"></i> {t("tabproductinformes")}
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col w-full mb-6 break-words rounded-b-lg ">
            <div className="flex-auto rounded-b-lg bg-pirGray-1800">
              <div className="tab-content tab-space ">
                <div
                  className={openTab === 1 ? "block " : "hidden "}
                  bg-pink-100
                  id="link1"
                >
                  <Software_tab />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <Hardware_tab />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <Report_tab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default function TabsRender() { 
  return (
    <>
      <Software_hardware_reports_tabs color="pink" />
    </>
  );
}
