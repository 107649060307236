import React, { useState } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import Help_CTA from "../Home/Help_CTA";

const features = [
  {
    name: "Manage risk and prevent loss online",
    description:
      "Every payment is screened to detect suspicious online transactions with real-time results available in your Dashboard.",
    imgSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650239052/Branding%20Invu%20/Risk%20Manage/Group_41_mvox6y.svg",
  },
  {
    name: "Fraud protection",
    description:
      "Authenticate payments with Secure and shift the fraudulent chargeback liability from you to the card issuer.",
    imgSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650239052/Branding%20Invu%20/Risk%20Manage/Icon_1_uegkba.svg",
  },
  {
    name: "Custom flexibility",
    description:
      "Take control of fraud detection on online purchases. Set the level of risk that works best for you and your business.",
    imgSrc:
      "https://res.cloudinary.com/dslkvd23s/image/upload/v1650239052/Branding%20Invu%20/Risk%20Manage/Group_42_xluz7v.svg",
  },
];

const Payments_risk_manage = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Risk Manage Payment");

  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section class="relative">
        <img
          class="absolute inset-0 object-[75%] sm:object-[25%] object-cover w-full h-full opacity-85 sm:opacity-100"
          src="https://images.pexels.com/photos/4339732/pexels-photo-4339732.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          alt=""
        />
        <div className="absolute inset-0 bg-gray-900 bg-opacity-70"></div>

        <div class="hidden sm:block sm:inset-0 sm:absolute sm:bg-gradient-to-r sm:from-pirDarkGray sm:to-transparent"></div>

        <div class="relative max-w-screen-xl  px-4  sm:px-6 lg:px-8 py-32 mx-auto lg:h-4/6 lg:items-center lg:flex">
          <div class="max-w-xl text-center sm:text-left">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-white">
                Risk manage
              </p>
            </div>
            <h1 class="text-3xl max-w-xl text-white font-extrabold sm:text-5xl">
              Fight fraud.{""}
              <strong class="font-extrabold text-white sm:block">
                Invu is the perfect solution
              </strong>
            </h1>

            <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-200 leading-loose">
              Gain insight into payment fraud patterns and set custom rules and
              alerts to detect and manage risk. Invu is the best solution for
              your security and that of your company
            </p>

            <div class="flex flex-wrap gap-4 mt-8 text-center">
              <button
                class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600  sm:w-auto active:bg-indigo-600  hover:bg-indigo-700 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                Get Started
              </button>

              <a
                class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-indigo-600  sm:w-auto hover:text-indigo-700 active:text-indigo-500 focus:outline-none focus:ring"
                href="/payments-overview"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="max-w-screen-xl px-4 mt-32 py-10 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <div className="grid grid-cols-2 gap-5">
              <img
                className="object-cover w-full h-60 col-span-2 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1650244268/Branding%20Invu%20/Risk%20Manage/Downloader.la_-625cba72d4e54_hxhqig.jpg"
                alt=""
              />
              <img
                className="object-cover w-full h-60 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1650244506/Branding%20Invu%20/Risk%20Manage/Downloader.la_-625cbb8621542_qdsojg.jpg"
                alt=""
              />
              <img
                className="object-cover w-full h-60 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/v1650244113/Branding%20Invu%20/Risk%20Manage/risk_q90osr.jpg"
                alt=""
              />
            </div>

            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; RISK MANAGE
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                Manage risk with powerful, easy-to-use tools.
              </h2>

              <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-500 leading-loose">
                For businesses looking for fraud prevention solutions, Invu now
                offers Risk Manager, a customizable fraud prevention and risk
                management system.
              </p>

              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                    Assess risky payments
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Advanced analytics help spot online fraud activity.
                    </span>{" "}
                    Get alerts to review suspicious payments and take action to
                    prevent fraudulent disputes.
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <a>
                    <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                      Protect your business
                    </h6>
                  </a>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Stop fraud at the source by activating Secure.
                    </span>{" "}
                    Let the card issuer confirm the customer’s identity before
                    completing a purchase and take responsibility for any
                    related fraud disputes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; Risk Manage
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                Stay protected with advanced fraud monitoring.
              </h2>
              <div className="flex mt-10 my-8">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    Block repeat fraud
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Add suspicious cards, emails, and IP addresses to your
                      Block List,
                    </span>{" "}
                    or manually block flagged payments to help prevent repeat
                    fraudsters from making purchases on your website.
                  </p>
                </div>
              </div>
              <div className="flex my-8">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <h6 className="mb-2 mt-1.5 text-black font-semibold leading-5 underline">
                    Make your own rules
                  </h6>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      Noticing a trend? Advanced analytics
                    </span>{" "}
                    allow you to easily see fraud activity so you can create
                    alerts and set rules that automatically control when you
                    accept payments.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/7640782/pexels-photo-7640782.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font ">
                &mdash; Risk Manage
              </h2>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-4xl">
                There are many ways to sell online with Invu.
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Start your free eCommerce store using Invu Online and receive
                  fraud protection at no cost with Risk Manager added to your
                  account for free. You can also process payments using other
                  eCommerce solutions, including your preferred eCommerce
                  platform, or create a custom solution with Invu APIs.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> Contact sales </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://images.pexels.com/photos/5935744/pexels-photo-5935744.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {features.map((feature) => (
              <div className="bg-white border rounded p-5">
                <img
                  class="inset-0 object-cover w-14 h-14 rounded mb-4"
                  src={feature.imgSrc}
                  alt=""
                />

                <h2 class="mb-2 text-lg font-semibold text-gray-900">
                  <a class="text-black hover:text-gray-800 underline">
                    {feature.name}
                  </a>
                </h2>
                <p class="mb-2 mt-3 text-sm font-normal text-gray-500">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Payments_risk_manage;
