import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import TagManager from "react-gtm-module";
import useDocumentTitle from "../../useDocumentTitle";
import { Redirect, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import Cookies from 'js-cookie';

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const Modal_started = ({ setIsOpen }) => {
  
  useDocumentTitle("Invu POS | Get demo app ");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [language, setLenguage] = useState("es");
  const [registrationId, setRegistrationId] = useState(null);

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      path: useDocumentTitle,
    },
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const leadStatus = "Nuevo"

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const history = useHistory();

  const location = useLocation();
  const prefix = useLocalePrefix();
  const [countryRender, setCountryRender] = useState('');
  const [loading, setLoading] = useState(true); 
  const [country, setCountry] = useState(''); 

  const [geoInfo, setGeoInfo] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  }); 

  const getGeoInfo = (country) => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        setGeoInfo({
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });
        // setCountryRender(data.country_name);
        setCountry(country != null ? country : data.country_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
      
  };
  
  const compareCountry = (a, b) => a.toLowerCase() === b.toLowerCase();

  useEffect(() => {
    var country = null
    if (lang.toLowerCase() === 'mexico' || lang.toLowerCase() === 'venezuela' || lang.toLowerCase() === 'elsalvador' || lang.toLowerCase() === 'costarica' || lang.toLowerCase() === 'colombia') {
      country = lang;
    }
    getGeoInfo(country);
  }, []);

  if (loading) {
    return <div></div>;
  }

  function generateUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  var hutkValue = generateUUID();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/be316fe8-ea4c-4aa2-ad2a-b5750f006e01";


    var data = {
      fields: [
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "name",
          value: name,
          objectTypeId: "COMPANY",
        },
        {
          name: "message",
          value: message,
        },
        {
          name: "hs_lead_status",
          value: leadStatus,
          objectTypeId: "CONTACT",
        }
      ],
      context: {
        hutk: hutkValue,
        ipAddress: geoInfo.ip,
        pageUri: "www.invupos.com",
        pageName: "formulario de contacto invu pos"
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setIsOpen(false);
        const responseData = JSON.parse(xhr.responseText);
        const registrationId = responseData.inlineMessage;

        history.push(`${prefix}/request-demo-confirmed`, {
          firstName,
          lastName,
          email,
          phone,
          name,
          message,
          leadStatus,
          registrationId,
          hutkValue,
        });
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4"> 
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p> 
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-green-600">
                      Message sent.
                    </span>{" "}
                    We will communicate soon📭
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
        

        setTimeout(() => {
          toast.dismiss(); 
        }, 8000);
        // alert(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        console.log(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651769707/Branding%20Invu%20/Logo%20Apps/Layer_2_pxylmq.png"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    // Sends the request
    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setName("");
    setIsChecked(false); // Limpia el checkbox
  };

  return (
    <>
      {compareCountry(country, 'Mexico') || compareCountry(country, 'mexico') ? (
  <div className="flex justify-center flex overflow-x-hidden overflow-y-auto z-50 inset-0 fixed h-screen items-center antialiased bg-gray-500 bg-opacity-75 transition-opacity ">
    <div className="flex flex-col w-11/12 sm:w-5/6 xl:w-[500px] lg:w-2/5 md:w-2/4 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
      
       <form className="bg-white">
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-l">
              <p class="font-semibold text-gray-800">Contacto Mexico</p>
              <button onClick={() => setIsOpen(false)}>
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <iframe
              src="https://forms.monday.com/forms/embed/3057840758763ef17fb0d887a1b6a4d3?r=use1"
              width="100%"
              height="750"
              style={{
                border: '0',
                boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)',
                margin: '0px',
                padding: '0px',
              }}
              title="Monday Form"
            ></iframe> 
        </form>
    </div>
  </div>
) : (
      <div class="flex justify-center flex overflow-x-hidden overflow-y-auto z-50 inset-0 fixed h-screen items-center  antialiased bg-gray-500 bg-opacity-75 transition-opacity ">
        <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
          <form onSubmit={handleSubmit}>
            <div class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-gray-800">{t("modaltrydemo")}</p>
              <button onClick={() => setIsOpen(false)}>
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="flex flex-col px-6 py-5  bg-gray-50">
              <a href="">
                {countryRender === 'Mexico' && (
                  <img
                    className="h-12 w-auto"
                    src="https://img.invupos.com/invu%20mexico/Logo%20invu%20mx.svg"
                    alt=""
                  />
                )}
                {countryRender === 'Venezuela' && (
                  <img
                    className="h-12 w-auto"
                    src="https://img.invupos.com/invu%20venezuela/Logo%20invu%20vn.svg"
                    alt=""
                  />
                )}
                {(countryRender !== 'Mexico' && countryRender !== 'Venezuela') && (
                  <img
                    className="h-12 w-auto"
                    src="https://res.cloudinary.com/dslkvd23s/image/upload/v1648001932/Branding%20Invu%20/Logo%20Invu/logo-INVU-POS-rgb_nrg6ry.png"
                    alt=""
                  />
                )}
              </a>

              <div class="flex flex-col mt-6 sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      First name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("modalfirstname")}
                      required
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Last name
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("modallastname")}
                      required
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col sm:flex-row items-center mb-3 sm:space-x-5">
                <div class="w-full sm:w-1/2">
                  <div>
                    <label class="sr-only" for="firstname">
                      Email
                    </label>
                    <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("modalemail")}
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-1/2 mt-2 sm:mt-0">
                  <div>
                    <label class="sr-only" for="firstname">
                      Phone
                    </label>
                    {/* <input
                      class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("modalphone")}
                      required
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    /> */}
                    <PhoneInput
                      className="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                      placeholder={t("modalphone")}
                      defaultCountry={
                        compareCountry(country, 'Mexico') || compareCountry(country, 'mexico') ? "MX" : 
                        compareCountry(country, 'Venezuela') || compareCountry(country, 'venezuela') ? "VE" :
                        compareCountry(country, 'Elsalvador') || compareCountry(country, 'elsalvador') ? "SV" :
                        compareCountry(country, 'Costarica') || compareCountry(country, 'costarica') ? "CR" :
                        compareCountry(country, 'Colombia') || compareCountry(country, 'colombia') ? "CO" :
                        "PA"
                      }
                      required
                      value={phone}
                      onChange={(value) => setPhone(value)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label class="sr-only" for="firstname">
                  Company
                </label>
                <input
                  class="w-full p-4 bg-white border border-gray-200 rounded shadow-sm"
                  placeholder={t("modalcompany")}
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <textarea
                // type="message"
                placeholder={t("modalmessage")}
                class="p-4  bg-white border border-gray-200 rounded shadow-sm h-36"
                required
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>


              <div class="mt-6 mb-2 text-left text-gray-600 dark:text-gray-400 flex items-center justify-between">
                <div class="flex items-center">
                  <input  type="checkbox" checked={isChecked} onChange={handleCheckboxChange} placeholder="Privacy Policy" required class="w-5 h-5  text-blue-600 border-gray-200 rounded focus:ring-blue-500"/>
                  <label  class="block ml-2 text-[14px] text-neutral-600"> 
                  {t("privacypolicytext1")} {" "}
                  <Link
                    className={`underline font-bold ${countryRender === 'Mexico' ? 'text-green-700' : countryRender === 'Venezuela' ? 'text-[#001F7E]' : 'text-indigo-600'}`}
                    to={`${[prefix]}` + "/politicas-de-privacidad"}
                    onClick={() => {window.scrollTo(0, 0); setIsOpen(false);}}
                  >
                    {t("privacypolicytexthref2")}
                  </Link>
                    {" "}
                      {t("privacypolicytext3")}
                      </label>
                </div>
              </div>
            </div>


            <div class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
              <p
                onClick={() => setIsOpen(false)}
                class="font-semibold text-gray-600 cursor-pointer"
              >
                {t("modalformbuttoncancel")}
              </p>
              {/* <button
                type="submit"
                value="Submit"
                className="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">{t("modalformbutton")}</span>
              </button> */}
              <button
                type="submit"
                value="Submit"
                className={`inline-flex items-center px-8 py-3 ${countryRender === 'Mexico' ? 'bg-green-700 text-white' : countryRender === 'Venezuela' ? 'text-[#000000] bg-[#FED850]' : 'bg-indigo-600 text-white'} border rounded hover:opacity-90`}
              >
                <span className="text-sm font-medium">{t("modalformbutton")}</span>
              </button>
            </div>
          </form>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
      </div>
      )}
    </>
  );
};

export default Modal_started;

