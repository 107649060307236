import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_four_functions = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "4 funciones del sistema de Invu POS que te ayudarán a mejorar el control de tus ventas | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="  Un mundo en el que un empresario disfruta de la comodidad del
          hogar. Tal comodidad implica, entre otras cosas, poder coger el
          Smartphone, la Tablet o el ordenador y poder revisar inventario,
          catálogo de clientes y ventas realizadas."
        />
        <meta name="keywords" content="Control de ventas" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2010/Cover%20blog%2010.jpeg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 05, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              4 funciones del sistema de Invu POS que ayudan a mejorar el
              control de ventas
            </h1>

            <p className="text-gray-500 max-w-xl">
              Un mundo en el que un empresario disfruta de la comodidad del
              hogar. Tal comodidad implica, entre otras cosas, poder coger el
              Smartphone, la Tablet o el ordenador y poder revisar inventario,
              catálogo de clientes y ventas realizadas.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            Un escenario como éste no es ciencia ficción, sino algo
            absolutamente posible gracias a un software de punto de venta como
            Invu POS. Como gestor de punto de venta, Invu POS ofrece al usuario
            simplicidad, optimización del flujo de información y efectividad
            operacional.
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <h2 className="font-semibold text-xl ">
            ¿Cómo puede Invu POS ayudar a controlar las ventas?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-8">
            No existe una herramienta más eficaz, para que el propietario de un
            negocio pueda mantener el control total del mismo, que un punto de
            venta POS. Invu POS cumple con esta labor gracias a una tecnología
            que combina los mundos on line/off line con el almacenamiento de
            datos en la nube. La suma de las siguientes cuatro funciones lo
            hacen posible:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Control en punto de venta <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Cada venta cuenta, suma facturación a un negocio. Por este motivo,
              el modo más eficaz de llevar el control de un negocio es registrar
              cada producto que se vende a lo largo de una jornada. El problema
              es que realizar esta operación con lápiz y papel puede desembocar
              en errores, además de que resta tiempo a otras labores, como por
              ejemplo, la atención al público.
            </p>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Invu POS ofrece una función de control de ventas que permite
              realizar este trabajo de un modo rápido y sencillo. Con esta
              herramienta se pueden seleccionar los productos que se venden,
              registrar los pagos recibidos por estas ventas, agilizar la
              atención al cliente a través del escaneo de códigos de barras…
            </p>
            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Informes de ventas por días y por productos vendidos <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Los informes de venta permiten analizar la productividad de un
              negocio. También detectar qué productos se venden más y cuáles
              menos. O lo que es lo mismo, qué productos dejan beneficios y
              cuáles no. Invu POS genera estos informes, por días, semanas,
              meses y años. Y todo, en cualquier momento y desde cualquier
              ubicación.
            </p>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Otra funcionalidad de Invu POS, como punto de venta, es que
              permite hacer reportes por turnos. De este modo se puede saber
              quienes son los mejores vendedores y quienes necesitan mejorar.
            </p>
            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Control de inventarios <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              A menudo, en un negocio se pueden ver en la situación de que falta
              stock o que el que hay ha caducado – sin habérsele dado salida,
              por ejemplo, a través de una promoción- o, sencillamente, que no
              estaba registrado. Si esto sucede es que no se está inventariando
              de forma adecuada lo que, evidentemente, puede provocar pérdidas o
              merma de ingresos en un negocio.
            </p>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Con el uso de Invu POS como punto de venta este riesgo no existe.
              Al cerrarse cada turno, toda la información recopilada por el
              sistema se sincroniza de manera automática. Esta función, además
              de mantener el stock actualizado, permite hacer un análisis
              estratégico del mismo: focalizar inversiones en los productos más
              vendidos y recortar en los que menos.
            </p>
            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Catálogo de clientes <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Un catálogo o listado de clientes es un registro de cada persona
              que realiza compras en un negocio. La buena gestión de este
              listado puede propiciar nuevas ventas en el futuro. Un buen
              catálogo de clientes, como el que dota a Invu POS, incluye nombre
              del cliente, número de teléfono, email, dirección postal e
              historial de compras.
            </p>
            <p className="mt-2 text-gray-700 leading-7 px-6 mb-2">
              A partir de estos datos, y haciendo una buena gestión de los
              mismos, se puede:
            </p>
            <ul class="list-disc px-10">
              <li className="text-gray-700 leading-7">
                Hacer una detallada segmentación del mercado gracias a la
                información ofrecida por los clientes.
              </li>
              <li className="text-gray-700 leading-7">
                Reducir los costes que implica el desarrollo de una campaña de
                marketing tradicional, sustituyéndola por una estrategia de
                Email Marketing
              </li>
              <li className="text-gray-700 leading-7">
                Realizar un seguimiento, en el tiempo, de clientes, usuarios y
                proveedores.
              </li>
              <li className="text-gray-700 leading-7">
                Optimizar los gastos en conceptos como compras de producto o
                marketing, gracias a los datos obtenidos de la gestión de ese
                catálogo de clientes.
              </li>
            </ul>
          </ul>

          <h2 className="font-semibold text-xl mt-8">
            ¿Cómo puede Invu POS ayudar a controlar las ventas?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Una buena manera de aprovechar los datos obtenidos, a través de la
            gestión de Invu POS como punto de ventas, podría ser la creación de
            un programa de fidelización. Éste incluiría:
          </p>

          <ul>
            <li className="text-gray-700 leading-7 font-semibold text-lg  ">
              Recompensas <br></br>
            </li>
            <ul class="list-disc px-6 mt-2">
              <li className="text-gray-700 leading-7">
                Se mantendría informada a la audiencia sobre las recompensas
                ofrecidas.
              </li>
              <li className="text-gray-700 leading-7">
                Establecimiento de múltiples niveles de recompensa.
              </li>
              <li className="text-gray-700 leading-7">
                Los clientes obtendrán puntos por cada artículo comprado, visita
                realizada al establecimiento o monto invertido.
              </li>
              <li className="text-gray-700 leading-7">
                Opción, para el establecimiento, de elegir el término que tenga
                más sentido para cada empresa en particular: estrellas o puntos.
              </li>
              <li className="text-gray-700 leading-7">
                Automatización de los mensajes de textos que confirman las
                inscripciones de clientes.
              </li>
              <li className="text-gray-700 leading-7">
                Fidelización de clientes afiliados a través de campañas
                automáticas de sms o e-mail gracias a la integración de la
                empresa con Invu POS.
              </li>
            </ul>
            <li className="text-gray-700 leading-7 font-semibold text-lg mt-6">
              Promociones <br></br>
            </li>
            <ul class="list-disc px-6 mt-2">
              <li className="text-gray-700 leading-7">
                Desarrollo de promociones por medio de herramientas específicas
                que solamente la integración de la empresa con Invu POS puede
                ofrecer.
              </li>
              <li className="text-gray-700 leading-7">
                Sustitución de tarjetas de papel por presentaciones digitales
                diseñadas para aumentar la fidelidad de los clientes.
              </li>
              <li className="text-gray-700 leading-7">
                Visualización, en un solo lugar y a través de un panel, de toda
                la información importante del negocio.
              </li>
              <li className="text-gray-700 leading-7">
                Seguimiento de la información obtenida como la cantidad total de
                clientes, las recompensas y los principales compradores.
              </li>
              <li className="text-gray-700 leading-7">
                Evaluación del gasto promedio y las visitas de los clientes
                fidelizados frente a los que no lo son.
              </li>
              <li className="text-gray-700 leading-7">
                Administración de la información asociada a la fidelización de
                los clientes durante las campañas de marketing.
              </li>
              <li className="text-gray-700 leading-7">
                Conversión de las visitas en negocios recurrentes
              </li>
            </ul>
          </ul>

          <p className="mt-2 text-gray-700 leading-7  ">
            Los negocios que prueban su integración con Invu POS indican que los
            clientes inscritos gastan un 33% más. Este dato se traduce en un
            incremento en la cantidad de ventas de los E-Commerce, además de una
            mayor cantidad de visitas.
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_four_functions;
