import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import useDocumentTitle from "../../useDocumentTitle";
import FAQ_model_one from "./FAQ_model_one";

const Retail = () => {
  useDocumentTitle("Invu POS | Retail Overview");
  return (
    <>
      <>
        <section className="">
          <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
            <div class="flex flex-col w-full mb-2 text-center">
              <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                  <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    RETAIL
                  </p>
                </div>
                <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                  Reinvent your business with an POS for Retailers
                </h2>
                <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                  Expand your horizons with software built to protect your
                  business and allow you to grow through various sales channels
                  with an advanced system
                </p>
              </div>
            </div>
            <div className="group relative">
              <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
                <img
                  className="object-cover w-full h-86 sm:h-96 rounded-lg"
                  src="https://images.pexels.com/photos/3182759/pexels-photo-3182759.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirBlack mt-12">
          <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div>
                <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                  &mdash; Retail
                </h2>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  More customers online and in your physical store with Invu.
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Choose a theme from our catalog
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Templates are compatible
                      </span>{" "}
                      with mobile and desktop devices for greater operability
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Review, edit and organize
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Manage your pending shipments
                      </span>{" "}
                      from the Invu portal
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Boost your page by optimizing your SEO
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">With built-in tools</span>{" "}
                      to drive traffic to your online store
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Synchronize the stock
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        From your physical store
                      </span>{" "}
                      to your online store automatically
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://images.pexels.com/photos/7289710/pexels-photo-7289710.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirDarkGray">
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div class="order-none lg:order-2 md:order-none">
                <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                  &mdash; Retail
                </h2>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  Turn casual customers into repeat customers.
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Set up promotions
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        To entice your customers
                      </span>{" "}
                      to shop online again or visit you again.
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Organize your customers by categories
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Analyze their online behavior,
                      </span>{" "}
                      you can even differentiate between those who bought online
                      and those who bought in store
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Collect personal data
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        To segment and send personalized promotions
                      </span>{" "}
                      such as a gift discount
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Create mailing campaigns
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        With the drag and drop editor
                      </span>{" "}
                      or choose from the available templates
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://images.pexels.com/photos/7621012/pexels-photo-7621012.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirBlack">
          <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div>
                <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                  &mdash; Retail
                </h2>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  Take care and synchronize your stock.
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Know the real-time stock
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Of each store or the amount
                      </span>{" "}
                      added by all your stores
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Make purchase orders.
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Global or individual orders,
                      </span>{" "}
                      with different suppliers and stores
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Sell ​​items
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Sell ​​by serial numbers
                      </span>{" "}
                      and keep track of movements to keep track of your products
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Configure a control of the maximum and minimum
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Quantity of each product according to sales statistics
                      </span>{" "}
                      to always have the correct quantity in stock
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://images.pexels.com/photos/230544/pexels-photo-230544.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-pirDarkGray">
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
            <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
              <div class="order-none lg:order-2 md:order-none">
                <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-white">
                  &mdash; Retail
                </h2>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                  Offer multiple forms of payment to protect your customers.
                </h2>
                <div className="flex mt-10 my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      It offers agile and secure transactions.
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Make fast and secure transactions
                      </span>{" "}
                      while we protect your data.
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Protect your income
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">Protect yourself</span>{" "}
                      from fraud and human error with a comprehensive system
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Collect personal data
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        To segment and send personalized promotions
                      </span>{" "}
                      such as a gift discount.
                    </p>
                  </div>
                </div>
                <div className="flex my-6">
                  <div className="mr-4">
                    <div className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer"></div>
                  </div>
                  <div>
                    <h6 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                      Payments in cash
                    </h6>
                    <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                      <span className="font-semibold">
                        Pay at convenience stores,
                      </span>{" "}
                      we are affiliated with a wide variety
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full rounded-md"
                  src="https://images.pexels.com/photos/4473496/pexels-photo-4473496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <FAQ_model_one />
        <Help_CTA />
      </>
    </>
  );
};

export default Retail;
