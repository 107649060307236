import React, { useState } from "react";
import Partners from "../Home/Partners";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import FAQ_model_one from "./FAQ_model_one";

const features = [
  {
    id: "1",
    name: "Simple prices and competitive",
    description:
      "Contact us for an exclusive rate and discounts on bulk hardware purchases, plus learn how Square helps you lower your total cost of ownership.",
    imgSrc:
      "https://images.pexels.com/photos/5900231/pexels-photo-5900231.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    name: "Attention for comprehensive customer",
    description:
      "Larger businesses have 24/7 customer support, plus we'll help you prepare and train your staff.",
    imgSrc:
      "https://images.pexels.com/photos/4350037/pexels-photo-4350037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    name: "Leaders in security industry payments",
    description:
      "Invu software complies with PCI information security standards at no additional cost, and our hardware and readers are fully encrypted out of the box with no configuration required.",
    imgSrc:
      "https://images.pexels.com/photos/6818158/pexels-photo-6818158.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    name: "Technologies of advanced payments",
    description:
      "Accept EMV chip cards and other NFC payments, or use recurring billing and payment services to get your money quickly.",
    imgSrc:
      "https://images.pexels.com/photos/7621144/pexels-photo-7621144.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const Switch_invu = () => {
  const [isOpen, setIsOpen] = useState(false);
  useDocumentTitle("CAMBIAR A INVU | INVU POS");

  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("resourcesgeneralswitchinvuuppercase")}
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("resourcesgeneralswitchinvutitle")}
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("resourcesgeneralswitchinvudescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Switch%20invu%20module/Switch%20invu%201.webp"
                alt="payments overview"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="relative px-4 mt-12 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 z-0 w-full h-full  lg:w-3/4" />
        </div>
        <div className="relative">
          <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
            <div className="grid gap-12 row-gap-5 md:grid-cols-2">
              <div className="relative">
                <div className="relative">
                  <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </svg>
                  </div>
                  <h6 className="mb-2 font-semibold leading-5">
                   {t("resourcesgeneralswitchinvucard1title")}
                  </h6>
                  <p className="text-sm text-gray-900">
                    {t("resourcesgeneralswitchinvucard1description")}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  {t("resourcesgeneralswitchinvucard2title")}
                </h6>
                <p className="text-sm text-gray-900">
                  {t("resourcesgeneralswitchinvucard2description")}
                </p>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  {t("resourcesgeneralswitchinvucard3title")}
                </h6>
                <p className="text-sm text-gray-900">
                 {t("resourcesgeneralswitchinvucard3description")}
                </p>
              </div>
              <div>
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-indigo-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  {t("resourcesgeneralswitchinvucard4title")}
                </h6>
                <p className="text-sm text-gray-900">
                  {t("resourcesgeneralswitchinvucard4description")}
                </p>
              </div>
            </div>
            <div>
              <img
                className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                src="https://img.invupos.com/webinvu/Switch%20invu%20module/Switch%20Invu%202.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <Partners />

      <section className="px-4 py-24 mt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
               {t("resourcesgeneralswitchinvusection3title")}
              </h2>
              <p className="text-base text-gray-700 md:text-lg max-w-4xl">
                {t("resourcesgeneralswitchinvusection3description")}
              </p>
            </div>
            <div>
              <Link
                to={`${prefix}` + "/contacto-con-ventas"}
                onClick={() => window.scrollTo(0, 0)}
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-indigo-600 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                {t("resourcesgeneralswitchinvusection3button")}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
          <div class="grid gap-6 md:grid-cols-2 md:col-span-2 lg:col-span-3">
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  1
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle1")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription1")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  2
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle2")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription2")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  3
                </span>
                <p class="text-lg font-semibold sm:text-base">
                  {t("featureresourcetittle3")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription3")}
              </p>
            </div>
            <div class="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-indigo-50">
              <div class="flex items-center mb-1">
                <span class="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                  4
                </span>
                <p class="text-lg font-semibold sm:text-base">
                {t("featureresourcetittle4")}
                </p>
              </div>
              <p class="text-sm text-gray-900">
                {t("featureresourcedescription4")}
              </p>
            </div>
          </div>
          <div class="relative md:col-span-2 lg:col-span-2">
            <img
              class="inset-0 object-cover object-bottom w-full h-56 rounded shadow-lg lg:absolute lg:h-full"
              src="https://img.invupos.com/webinvu/Switch%20invu%20module/Switch%20invu%203.webp"
              alt=""
            />
          </div>
        </div>
      </div>

      <section class="px-4 py-24 mx-auto   bg-pirDarkGray">
        <div class="w-full mx-auto text-center md:w-4/5 lg:w-3/5">
          <h1 class="mb-10 text-2xl font-extrabold text-gray-300 md:leading-tight md:text-3xl">
            {t("resourcecta1")}{" "}
            <span class="text-transparent bg-clip-text bg-gradient-to-r from-pirOrange to-pirPurple">
              {t("resourcectaspan")}
            </span>{" "}
            {t("resourcecta2")}
          </h1>
          <div class="mx-auto mb-3 avatar flex justify-center">
            <a aria-label="Author" className="mr-3 mx-0 md:mx-auto">
              <img
                alt="avatar"
                src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Avatar%20blog%20author.jpg"
                className="object-cover w-16 h-16 rounded-full shadow-sm mx-0 md:mx-auto"
              />
            </a>
          </div>
          <p class="text-base font-bold text-white">Rafael Turgman</p>
          <p class="text-sm font-medium text-gray-300">CEO, Invu POS</p>
        </div>
      </section>
      <FAQ_model_one />
    </>
  );
};

export default Switch_invu;
