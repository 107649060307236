import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";

const features = [
  {
    tittle: "Apply the program",
    step: "1",
    description:
      "Fill out the INVU reseller application form. Trust us and startnow.",
    imgSrc:
      "https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%201.jpeg",
    altImg: "",
  },
  {
    tittle: "We will contact you",
    step: "2",
    description:
      "We will contact you to learn more about your business and thus help you achieve your goals.",
    imgSrc:
      "https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%202.jpeg",
    altImg: "",
  },
  {
    tittle: "Program entry",
    step: "3",
    description:
      "If you fit what we're looking for, we welcome you to the family.",
    imgSrc:
      "https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%203.jpeg",
    altImg: "",
  },
];

const Resellers_program = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Resellers program");
  return (
    <>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  Resellers
                </p>
              </div>
              <h2 className="max-w-4lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                Join the INVU Resellers program
              </h2>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                It is made for companies that distribute equipment or software,
                who know restaurant or retail customers.
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1 cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%204.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Add value to your company with Invu.
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Get trained at INVU so you can make presentations to your
                  clients and offer them special solutions for their business.
                  Sign Up Today for POS Invu's Reseller Program and Start
                  Generating Income
                </p>
              </div>
              <button
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                onClick={() => setIsOpen(true)}
              >
                <span class="text-sm font-medium"> Get a quotation </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%205.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirDarkGray">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div>
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Earn ongoing commissions with our system.
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  Generate monthly commissions for each client that is using our
                  point of sale system Invu POS. Trust us. Designed for
                  resellers interested in providing the payment processing
                  portion of a comprehensive POS system such as Invu.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/point-of-sale"
              >
                <span class="text-sm font-medium"> Point of sale </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%206.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <div class="w-10 mb-6 h-10 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center items-center"></div>

              <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-white md:leading-tight sm:text-left md:text-4xl">
                Take advantage <br></br> of our experience
              </h2>
              <div className="flex mt-6 my-6">
                <p class="text-gray-500 leading-loose">
                  It has our support for marketing, videos and user manuals, we
                  publish new versions every month, we have the ability to
                  develop tailored for each market.
                </p>
              </div>
              <a
                class="inline-flex items-center px-8 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
                href="/contact-sales"
              >
                <span class="text-sm font-medium"> Contact sales </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </a>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/Resellers%20module%20/Resellers%207.jpeg"
                alt="reasons invu"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-screen-xl mt-10 px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold xl:block hidden leading-tight text-gray-800">
          How do I become a member of INVU?
        </h1>
        <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

        <h1 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
          How do I become a member of INVU?
        </h1>
        <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
        <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
          {features.map((feature) => (
            <a class="block p-4 mt-6 rounded-lg shadow-xl">
              <img
                alt=""
                src={feature.imgSrc}
                class="object-cover w-full lg:h-56 md:h-96 rounded-md"
              />

              <div class="mt-4">
                <dl>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-lg font-bold leading-5 underline">
                      {feature.tittle}
                    </p>
                    <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-white bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange">
                      {feature.step}
                    </p>
                  </div>
                </dl>

                <dl class="flex items-center space-x-8 text-xs">
                  <p className="text-sm mt-2 text-gray-900">
                    {feature.description}
                  </p>
                </dl>
              </div>
            </a>
          ))}
        </div>
      </section>
      <FAQ_model_one />
      <Help_CTA />
    </>
  );
};

export default Resellers_program;
