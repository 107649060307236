import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";

const CTA_Blog = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <aside>
        <div class="max-w-screen-xl mt-20 px-4 py-12 mx-auto sm:px-6 lg:px-8 ">
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div class="p-8 text-center lg:rounded-none rounded-lg text-white bg-indigo-600 sm:col-span-2 sm:p-16 lg:py-24 bg-gradient-to-r from-pink-500 via-red-500 to-pirOrange">
              <div class="max-w-lg mx-auto space-y-4">
                <p className="text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
                  {t("ourbloguppercase")}
                </p>
                <h2 class="text-xl font-bold sm:text-4xl">
                  {t("ourblogtittle")}
                </h2>
                <p class="text-sm">{t("ourblogdescription")}</p>
              </div>

              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`${prefix}` + "/recursos/blog"}
                class="inline-flex mt-12  inline-block px-12 py-3 mt-8 text-sm items-center font-medium border rounded border-white/25 hover:bg-white hover:text-red-500"
              >
                {t("ourblogbutton")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex-shrink-0 w-4 h-4 ml-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </Link>
            </div>

            <div class="relative flex-shrink-0 hidden  sm:block h-64 lg:order-first lg:h-full ">
              <img
                src="https://img.invupos.com/webinvu/CTA/CTA%20photo%201.avif"
                alt=""
                loading="lazy"
                class="absolute inset-0 object-cover w-full h-full rounded-l-lg"
              />
            </div>

            <div class="relative flex-shrink-0 hidden sm:block h-64 lg:h-full">
              <img
                src="https://img.invupos.com/webinvu/CTA/CTA%20photo%202.avif"
                alt=""
                loading="lazy"
                class="absolute inset-0 object-cover w-full h-full rounded-r-lg"
              />
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default CTA_Blog;
