import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_tips_design_web = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle(
    "Consejos para diseñar la página web de un restaurante | Invu POS"
  );

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Está claro, como no podía ser de otro modo, que los conocimientos del propietario de un restaurante siempre van a estar más relacionados con el mundo culinario que con el del diseño, y la programación, de páginas web. "
        />
        <meta name="keywords" content="Paginas web para restaurantes" />
      </Helmet>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2011/Cover%20blog%2011.jpeg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 05, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              Consejos para diseñar la página web de un restaurante
            </h1>

            <p className="text-gray-500 max-w-xl">
              Está claro, como no podía ser de otro modo, que los conocimientos
              del propietario de un restaurante siempre van a estar más
              relacionados con el mundo culinario que con el del diseño, y la
              programación, de páginas web.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            Incluso, aunque hoy parezca difícil de creer, este desconocimiento
            del mundo digital lleva a que muchos restaurantes o no tengan página
            digital, o que la que tengan sea absolutamente inservible. ¿Por qué
            tener a clientes potenciales, hambrientos de las especialidades de
            un chef, frustrados porque no encuentran el restaurante desde el
            teléfono móvil?
          </p>

          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt=""
          />

          <p className="mt-2 text-gray-700 leading-7 mb-8">
            Unos sencillos consejos sobre cómo diseñar la página web de un
            restaurante, acabarán con este problema. Aquí van los ingredientes
            principales con los que cocinar una página web atractiva y eficaz
            para un restaurante:
          </p>

          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Elegir una plantilla <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              El primer paso es elegir una plantilla que encaje con el estilo de
              negocio. Y es que no es lo mismo un acogedor café de barrio, con
              un menú de desayuno especial los domingos, que un elegante
              restaurante cargado de estrellas Michelín. A cada uno lo suyo.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              No olvidar el toque distintivo <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Una vez elegida la plantilla, toca experimentar con el diseño.
              Textos propios, imágenes en alta resolución, videos atractivos e
              interesantes. Se debe tener empatía y pensar que gustaría
              encontrar cuando se entra en la web de un restaurante: creaciones
              exquisitas recién emplatadas; imágenes de noches divertidas y
              animadas en el local; fotografías de clientes, cuchara en mano, a
              punto de saborear alguna de las exquisiteces de la casa.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Haz la vida (digital) agradable a tus clientes <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              La gente está cansada de ver siempre lo mismo. Por esta razón,
              buscan a alguien que les alegre la vida con novedades agradables y
              divertidas. Agregar menús que, además de verse perfectamente en el
              móvil, cambien con regularidad; facilitar herramientas que
              permitan reservar mesa de una forma fácil y sin ayuda de nadie;
              proponer organizar caterings en casa, comprando todo a través del
              móvil... las posibilidades son de lo más variado.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Mantener el contacto <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Hay dos razones principales por las que clientes potenciales
              visitarán la página web de un restaurante: para curiosear (y
              desear) un apetecible menú y para encontrar información de
              contacto para ir a saborearlo. Con esto en mente, hay que
              asegurarse de que los datos de contacto estén visibles – una buena
              sugerencia sería colocarlos en el pie de cada página-. También es
              imprescindible añadir un Mapa de Google.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Presentarse a la comunidad <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              No hay mejor lugar. La página web es el sitio perfecto en el que
              hacer las presentaciones del local, equipo, logros…. A la gente le
              encantan las buenas historias. ¿Qué mejor manera de hacer crecer
              la marca de un restaurante que grabando un video “Sobre Nosotros”?
              En él se puede presentar a cada miembro del equipo y mostrar las
              interacciones entre las figuras claves del grupo como el chef, los
              camareros o el jefe de sala.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              SEO Local <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              ¿Un restaurante cerca de casa? Hacer SEO local, optimizando la
              página web- Así se conseguirá que los motores de búsqueda
              (principalmente Google, como es lógico) asocien el restaurante a
              una determinada ubicación geográfica. Hay que asegurarse de que el
              restaurante aparece en los principales directorios digitales
              locales. También es importante vigilar que la información de
              contacto que aparece sobre el local sea la misma en todos los
              canales. Vital registrar los datos en Google My Business.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Socializar <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Para un restaurante, las redes sociales son el lugar perfecto para
              demostrar que éste está involucrado con el entorno al que
              pertenece, ya sea el barrio en el que está ubicado el local o la
              comunidad culinaria a la que, por sector, pertenece su actividad.
              Es la ocasión para aprovechar, y difundir por la Red, las
              fotogénicas imágenes de platos recién salidos de la cocina.
              También es bueno asegurarse de que la página web está vinculada
              con cuentas sociales relevantes –y viceversa-.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-8 ">
              Convertirse en el lugar de encuentro de los gastronómicos{" "}
              <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              El toque final podría ser hacer de la página web del restaurante
              un sitio de encuentro de todo lo relacionado con la gastronomía.
              Por ejemplo, si se organizan eventos como talleres de cocina,
              veladas con música en vivo o programas para influencers hay que
              promoverlo entre los interesados. Tampoco estaría de más contar
              con un blog que convierta al dueño del restaurante –o, si no es la
              misma persona, al chef- en experto de referencia del gremio
              culinario.
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-8">
            ¿Qué debe tener una página web de un restaurante?
          </h2>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            En la página web de un restaurante se debe incluir toda la
            información que pudiera ser útil para los usuarios. O sea:
          </p>
          <ul class="list-decimal  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 font-semibold text-lg">
              Reservas <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Es importante que esta función esté siempre visible con un botón
              de reservas on line. Este botón redirigirá a la página en la que
              están el horario y los teléfonos del restaurante, para aquellas
              personas que deseen reservar telefónicamente o prefieran hacerlo a
              través de un formulario a rellenar (nombres y número de
              comensales, fecha y hora de la reserva, teléfono de contacto,
              correo electrónico, espacio para comentarios o exigencias
              especiales, y política de cancelación).
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Imagen profesional y personalidad <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Esto significa que la web tiene que estar bien trabajada, cuidada
              y actualizada. Un logo y unas imágenes impecables son
              sencillamente fundamentales.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Contacto <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              La información básica del restaurante puede aparecer como texto o
              en una caja gráfica que contenga los horarios, la dirección física
              y el correo electrónico.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Galería de imágenes <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              La gente quiere saber qué aspecto tiene la comida que se sirve en
              el restaurante al que está pensando ir. Es el lugar en el que
              lucirse mostrando, en imágenes, lo mejor que se tiene: fogones,
              local, personal, platos…
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              La carta <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Sería bueno que el cliente pudiera descargarse carta y menús en
              pdf. No olvidar incluir los precios, un dato fundamental que ayuda
              al cliente a decidir.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Nosotros <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Este es el espacio para contarle al cliente quienes somos;
              motivaciones, filosofía e historia de la empresa; nuevos
              proyectos. Importante emplear un lenguaje sencillo y natural.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Redes sociales <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Resulta muy útil colocar los botones que dirigen a las redes
              sociales del restaurante tanto arriba como al pie de la página.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Aviso del uso de cookies <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Son términos legales que deben incluir los sitios web.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Diseño responsive <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Ofrecer una versión de la web para móviles, tener un diseño que se
              pueda adaptar a diversos dispositivos, es, en la actualidad,
              indispensable, y más en lo que a hostelería se refiere.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Idiomas <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              Lo ideal sería traducir la página, al menos, a tres idiomas. Una
              ventaja para atraer a nuevos clientes.
            </p>

            <li className="text-gray-700 leading-7 font-semibold text-lg mt-4 ">
              Promociones <br></br>
            </li>
            <p className="mt-2 text-gray-700 leading-7 px-6 ">
              No estaría nada mal agregar un apartado con promociones,
              invitaciones a eventos, concursos, ofertas especiales…
            </p>
          </ul>

          <h2 className="font-semibold text-xl mt-8">
            Diseño página web de un restaurante de shusi
          </h2>
          <p className="mt-2 text-gray-700 leading-7 ">
            La página de inicio de un restaurante de shusi debe incluir todos
            los elementos imprescindibles para un correcto SEO de la web. Por
            ejemplo, por medio de un slider, se puede ofrecer una primera imagen
            atractiva del negocio. Luego, según el usuario se desplace por el
            diseño de la página, deberá encontrar tanto las diferentes
            categorías de la web, como imágenes de los principales platos. Es
            muy importante, en este tipo de restaurantes, diseñar una tienda on
            line que ofrezca pedidos a domicilio.
          </p>
          <p className="mt-2 text-gray-700 leading-7 mb-6">
            Igualmente, no se debe escatimar en cuanto a la calidad de la
            imagen. Un buen consejo sería contratar a un fotógrafo profesional
            que haga fotografías de los platos. En cuanto al contenido, para
            este tipo de web es aconsejable definir el contenido en función de
            la zona de trabajo del local, por lo que es importante añadir
            palabras claves tanto en las url, h1 y texto que refiera a esas
            zonas de trabajo. Por último, las campañas de Adwords deberán estar
            muy focalizadas en esas zonas.
          </p>
          <h2 className="font-semibold text-xl mt-8">
            Diseño de página web comida rápida
          </h2>
          <p className="mt-2 text-gray-700 leading-7 ">
            Muchas de las características nombradas para la web de un
            restaurante de shusi servirían para un restaurante de comida rápida.
            Otras son más específicas. Por ejemplo, se deben incluir distintas
            opciones y características para que se puedan vender cualquier
            producto con distintas alternativas posibles, pudiendo combinar
            platos con salsas, complementos…
          </p>

          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 08, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_tips_design_web;
