import React, { useState } from "react";
import Help_CTA from "../Home/Help_CTA";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import useDocumentTitle from "../../useDocumentTitle";

const navigation = {
  categories: [
    {
      id: "acceptingpayments",
      name: "Accepting Payments",
      featured: [
        {
          name: "Credit Card Processing Fees and Rates Explained.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/6684769/pexels-photo-6684769.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Accept Payments A Comprehensive Guide.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/7667441/pexels-photo-7667441.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "What Are ACH Payments and How Do ACH Transactions?",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/1181414/pexels-photo-1181414.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "What Are Mobile Payments? And How to Use Them",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/230544/pexels-photo-230544.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
      ],
    },
    {
      id: "businessmanagement",
      name: "Business Management",
      featured: [
        {
          name: "How to Start a Consulting Business.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/2182971/pexels-photo-2182971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Accept Payments: A Comprehensive Guide.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/5439137/pexels-photo-5439137.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Start a Retail Business with Invu POS.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/6347545/pexels-photo-6347545.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
      ],
    },
    {
      id: "toolsbusiness",
      name: "Tools for Running Your Business",
      featured: [
        {
          name: "How to Use Scheduling App to Book More ",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/5717934/pexels-photo-5717934.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Use Closed-Loop Gift Cards to Grow Your Business",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/1229180/pexels-photo-1229180.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "What Are ACH Payments and How Do ACH Transactions?",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/3865905/pexels-photo-3865905.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Choose the Best POS  for Your Small Business",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/7679453/pexels-photo-7679453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
      ],
    },
    {
      id: "paymenttech",
      name: "New Payment Technologies",
      featured: [
        {
          name: "A Guide to the Liability Shift: What Businesses.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/2988232/pexels-photo-2988232.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "NFC Guide: All You need to Know About Near Field.",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/4921149/pexels-photo-4921149.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
        {
          name: "How to Use Apple Pay: A Comprehensive Guide",
          href: "",
          imageSrc:
            "https://images.pexels.com/photos/5835020/pexels-photo-5835020.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          imageAlt: "",
        },
      ],
    },
  ],
};

const Guides = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Invu POS | Invu Guides ");

  return (
    <>
      <div className="mb-20">
        <div className="bg-pirBlack">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                  &mdash; INVU GUIDES
                </p>
              </div>
              <h2 className=" mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                A quick guide to our POS systems
              </h2>
              <p className="text-base text-gray-500 md:text-lg">
                Investing in a point of sale system is one of the best things
                you can do for your business.
              </p>
            </div>
            <div className="flex items-center sm:justify-center">
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
              >
                Get started
              </button>
              <a
                href="/resources-overview"
                aria-label=""
                className="inline-flex items-center font-semibold text-white transition-colors duration-200 hover:text-gray-300"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <div className="relative px-4 sm:px-0">
          <div className="absolute inset-0 bg-pirBlack h-1/2" />
          <div className="relative grid mx-auto overflow-hidden bg-pirDarkGray divide-y rounded shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-3 lg:max-w-screen-md">
            <div className="inline-block p-8 text-center">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              </div>
              <p className="font-bold tracking-wide text-gray-300">
                Make it better
              </p>
            </div>
            <div className="inline-block p-8 text-center">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <p className="font-bold tracking-wide text-gray-300">
                Do it faster
              </p>
            </div>
            <div className="inline-block p-8 text-center">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <p className="font-bold tracking-wide text-gray-300">
                Working harder
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="">
        {navigation.categories.map((category) => (
          <div class="px-4 mx-auto max-w-screen-xl px-4 py-10 mx-auto sm:px-6 lg:px-8">
            <h1 class="text-2xl font-bold sm:text-3xl">{category.name}</h1>
            <div class="grid grid-cols-1 md:gap-4 lg:gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4">
              {category.featured.map((item) => (
                <div className="mt-8 bg-gray-50 border rounded transition-shadow duration-300 border group hover:shadow-xl">
                  <a class="block p-4 rounded-lg shadow-sm shadow-indigo-100">
                    <img
                      alt=""
                      src={item.imageSrc}
                      class="object-cover w-full h-56 rounded-md"
                    />

                    <div class="mt-3">
                      <dl>
                        <div>
                          <dd class="font-medium text-sm">{item.name}</dd>
                        </div>
                      </dl>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
    </>
  );
};

export default Guides;
