import React, { useState, useEffect, useRef } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import Pricing_plans from "./Pricing_plans";
import CTA_Blog from "../Home/CTA_Blog";
import Testimonials from "../Home/Testimonials";
import Hardware from "../Home/Hardware";
import Hardware_business from "./Hardware_business";

import { useTranslation } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import Hardware_equipment from "../Home/Hardware_equipment";

import { Carousel } from "react-responsive-carousel";
import FAQ_business_types from "./FAQ_business_types";
import Global_pricing from "./Global_pricing";

import Slider from "react-slick";

const Barber = ({country}) => {
    const [isOpen, setIsOpen] = useState(false);

    useDocumentTitle("Barberias | INVU POS");
  
    const { t, i18n } = useTranslation();
    const [language, setLenguage] = useState("es");
  
    const onChangeLanguaje = () => {
      i18n.changeLanguage(language);
      if (language === "es") {
        setLenguage("en");
      } else {
        setLenguage("es");
      }
    };
  
    const location = useLocation();
    const prefix = useLocalePrefix();
  
    const cardsbusinesstypesgeneralprofessionalservices = t("cardsbusinesstypesgeneralprofessionalservices", {
      returnObjects: true,
    });
  
    const containerRef = useRef(null);
  
    useEffect(() => {
      const container = containerRef.current;
      let scrollLeft = 0;
      let animationId;
  
      function animateScroll() {
        scrollLeft += 1; 
  
        if (scrollLeft >= container.scrollWidth - container.clientWidth) {
          clearInterval(animationId);
          setTimeout(() => { 
            scrollLeft = 0;
            animationId = setInterval(animateScroll, 1000 / 60);
          }, 2000);
        }
  
        container.scrollLeft = scrollLeft;
      }
  
      setTimeout(() => {
        animationId = setInterval(animateScroll, 1000 / 60); // Iniciar la animación
      }, 2000); 
   
      return () => clearInterval(animationId); 
    }, []); 
  
  return (
    <>
      {/* NEW MODEL */}
      {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="lg:pr-10">
          <h1 class="font-bold xl:text-[50px] lg:text-5xl md:text-5xl text-4xl max-w-3xl text-[#002D33]">
          {t("barberstitlehero1")}
          </h1>
          <p class="mt-5 text-gray-700 text-lg leading-loose max-w-2xl font-light">
          {t("barberstitledescription")}
          </p>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("barbersbutton1")}
            </button>
            <Link 
            to={`${prefix}` + "/servicios-profesionales"}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-medium rounded-full border border-indigo-600 bg-white text-indigo-600 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" href="#">
              {t("barbersbutton2")}
            </Link>
          </div>
        </div>
        <div>
          <img
            className="object-cover w-full h-80 rounded-2xl shadow-lg sm:h-[480px]"
            src="https://images.pexels.com/photos/1813272/pexels-photo-1813272.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
          />
        </div>
      </div>
    </div>
    
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-10 bg-[#EFF1FF] rounded-3xl">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:max-w-md mx-auto mb-10">
          <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-indigo-600">
            &mdash; {t("barberssection2uppercase")}
          </p>
          <h5 className="mb-6 text-[50px] font-extrabold leading-none">
            {t("barberssection2title")}
          </h5>
        </div>
        <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
          <div className="max-w-md">
          <a class="group flex gap-y-6 size-full rounded-lg p-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-armchair-2 flex-shrink-0 size-8 text-indigo-600 mt-0.5 mr-3 me-6">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
              <path d="M7 20h10" />
              <path d="M9 16v4" />
              <path d="M15 16v4" />
              <path d="M9 12v-4" />
              <path d="M12 12v-1" />
              <path d="M15 12v-2" />
              <path d="M12 12v-1" />
            </svg>
            <div>
              <div>
                <h3 class="block font-bold text-gray-800 mb-1 text-lg">{t("barberssection2item1title")}</h3>
                <p class="text-gray-600">{t("barberssection2item1description")}</p>
              </div>
            </div>
            </a>
          </div>
          <div className="max-w-md">
          <a class="group flex gap-y-6 size-full rounded-lg p-5" href="#">
          <svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-armchair-2 flex-shrink-0 size-8 text-indigo-600 mt-0.5 mr-3 me-6">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z" />
            <path d="M19 16h-12a2 2 0 0 0 -2 2" />
            <path d="M9 8h6" />
          </svg>
              <div>
                <div>
                  <h3 class="block font-bold text-gray-800 mb-1 text-lg">{t("barberssection2item2title")}</h3>
                  <p class="text-gray-600">{t("barberssection2item2description")}</p>
                </div>
              </div>
            </a>
          </div>
          <div className="max-w-md">
          <a class="group flex gap-y-6 size-full rounded-lg p-5" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-armchair-2 flex-shrink-0 size-8 text-indigo-600 mt-0.5 mr-3 me-6">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
              <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
              <path d="M6 9l12 0" />
              <path d="M6 12l3 0" />
              <path d="M6 15l2 0" />
            </svg>
              <div>
                <div>
                  <h3 class="block font-bold text-gray-800 mb-1 text-lg">{t("barberssection2item3title")}</h3>
                  <p class="text-gray-600">{t("barberssection2item3description")}</p>
                </div>
              </div>
            </a>
          </div>
          <div className="max-w-md">
            <a class="group flex gap-y-6 size-full rounded-lg p-5" href="#">
              <svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-armchair-2 flex-shrink-0 size-8 text-indigo-600 mt-0.5 mr-3 me-6">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 9m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v9.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" />
                <path d="M17 3m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v15.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z" />
                <path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              </svg>
              <div>
                <div>
                  <h3 class="block font-bold text-gray-800 mb-1 text-lg">{t("barberssection2item4title")}</h3>
                  <p class="text-gray-600">{t("barberssection2item4description")}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <section className="mt-20">
  <div>
    <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full lg:px-8">
      <div className="px-4 text-left mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
        <div className="grid gap-16 row-gap-8 lg:grid-cols-5 lg:mt-8 mt-0">
          <div className="flex flex-col justify-center lg:col-span-2 lg:order-last lg:mt-8">
            <div className="max-w-2xl mb-6 mt-10 lg:-mt-12 md:mt-24 text-center">
                  <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-[35px] sm:leading-none">
                   {t("barbersposctatitle")}
                  </h2>
                  <p class="mt-5 text-gray-700 leading-loose max-w-2xl text-lg font-light">
                    {t("barbersposctadescription")}
                    </p>
                  </div>
                  
                  <div class="flex justify-center">
                    
                    <div class="mt-4 flex gap-x-8">
                    <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>

                    </div>
                  </div>
                  <div className="flex justify-center mt-10">
                    <button 
                    onClick={() => setIsOpen(true)}
                    class="py-3 px-8 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                      {t("furniturebutton2")}
                    </button>
                  </div>
                </div>

                <Carousel
                  className="z-10 relative ss lg:col-span-3"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                  transitionTime={500}
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/webinvu/Barber%20module/barber%20carousel1.png"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/webinvu/Barber%20module/barber%20carousel2.png"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/webinvu/Barber%20module/barber%20carousel3.png"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div className="relative px-4 sm:px-0 ">
          <div className="absolute inset-0 bg-[#FFE6DE] h-1/2"/>
          <div className="relative grid mx-auto overflow-hidden  bg-white divide-y rounded-full shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm  lg:max-w-screen-xl">

          </div>
        </div>
      </section>

    <section className="mt-14">
        <div className="max-w-screen-xl  px-4 py-20 mx-auto sm:px-6 lg:px-8">
          <div className="grid gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl mb-6">
                <p className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font">
                  &mdash; {t("barberssection3uppercase")}
                </p>
                <h2 class="mb-4 text-2xl font-extrabold tracking-tight  text-black md:leading-tight sm:text-left md:text-[44px]">
                  {t("barberssection3title")}
                </h2>
                <p className="mt-2 max-w-lg text-sm lg:text-lg text-gray-700 leading-loose">
                  {t("barberssection3description")}
                </p>
              </div>
              <div className="-mt-1">
                  <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-md transition-colors duration-200 group-hover:text-indigo-500">
                      {t("barberssection3item1")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-md transition-colors duration-200 group-hover:text-indigo-500">
                      {t("barberssection3item2")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-md transition-colors duration-200 group-hover:text-indigo-500">
                      {t("barberssection3item3")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-md transition-colors duration-200 group-hover:text-indigo-500">
                      {t("barberssection3item4")}
                    </span>
                  </div>

                  <div className="flex items-center max-w-auto p-2 max-w-sm mt-1 transition-colors duration-200 cursor-pointer group hover:bg-deep-purple-accent-400 hover:border-deep-purple-accent-400">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#4338CA"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-600 text-md transition-colors duration-200 group-hover:text-indigo-500">
                      {t("barberssection3item5")}
                    </span>
                  </div>

              </div>
            </div>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
                <img
                   className="object-cover mb-6 rounded shadow-lg h-40 sm:h-48 xl:h-72 w-40 sm:w-48 xl:w-64"
                  src="https://images.pexels.com/photos/3992872/pexels-photo-3992872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
                <img
                   className="object-cover w-40 h-40 rounded shadow-lg sm:h-32 xl:h-56 sm:w-32 xl:w-48"
                  src="https://images.pexels.com/photos/7518740/pexels-photo-7518740.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
              <div className="px-3">
                <img
                   className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                  src="https://images.pexels.com/photos/2061820/pexels-photo-2061820.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        {t("barberssection4title")}
        </h2>
      </div>
      <div className="grid gap-10 mb-8 md:grid-cols-2 lg:grid-cols-3 ">
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21v-13l9 -4l9 4v13" />
              <path d="M13 13h4v8h-10v-6h6" />
              <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3" />
            </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item1subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item1description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M20.925 13.163a8.998 8.998 0 0 0 -8.925 -10.163a9 9 0 0 0 0 18" />
              <path d="M9 10h.01" />
              <path d="M15 10h.01" />
              <path d="M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1" />
              <path d="M15 19l2 2l4 -4" />
            </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item2subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item2description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" />
              <path d="M3 10h18" />
              <path d="M16 19h6" />
              <path d="M19 16l3 3l-3 3" />
              <path d="M7.005 15h.005" />
              <path d="M11 15h2" />
            </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item3subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item3description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 3v18h18" />
              <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M13 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M21 3l-6 1.5" />
              <path d="M14.113 6.65l2.771 3.695" />
              <path d="M16 12.5l-5 2" />
            </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item4subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item4description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
              <path d="M12 7a5 5 0 1 0 5 5" />
              <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
              <path d="M15 6v3h3l3 -3h-3v-3z" />
              <path d="M15 9l-3 3" />
            </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item5subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item5description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
        <div className="p-5 duration-300 transform bg-[#EFF1FF] rounded-2xl shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-salad" width={32} height={32} viewBox="0 0 24 24" strokeWidth={2} stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M14 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M7 17l5 0" />
                <path d="M3 17v-6h13v6" />
                <path d="M5 11v-4h4" />
                <path d="M9 11v-6h4l3 6" />
                <path d="M22 15h-3v-10" />
                <path d="M16 13l3 0" />
              </svg>
            </div>
            <h3 class="text-2xl text-gray-800"><span class="font-bold"> {t("barberssection4item6subtitle")}</span></h3>
            <p className="font-light text-[15.5px] text-gray-800 mt-4"> {t("barberssection4item6description")}</p>
            <div class="pt-0 mt-7 mb-2">
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
  <div className="flex flex-col lg:flex-row text-center">
    <div className="max-w-xl mx-auto lg:order-last items-center align-center flex ">
      <div className="p-8">
        <div className="text-center flex justify-center">
          <div class="mt-4 flex gap-x-3 mb-6">
            <a
              class="hover:opacity-90"
              href="https://www.capterra.com.pa/software/1049125/invu-pos"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ca-ease_of_use-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.softwareadvice.com/inventory-management/invu-pos-profile/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/sa-customer_support-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.getapp.com/retail-consumer-services-software/a/invu-pos/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ga-features_and_functionality-2023.png" className="h-[80px]"/>
            </a>
          </div>
        </div>
        <hr className="mb-6 border border-gray-300" />
        <h5 className="mb-6 text-3xl max-w-lg font-extrabold leading-none">
          {t("furniturectacarousel")}
        </h5>
      </div>
    </div>

    <div className="overflow-x-hidden relative custom-container">
    <div className="overflow-x-auto custom-scrollbar" id="cardContainer" ref={containerRef}>
    <div id="carousel" className="grid gap-5 row-gap-5 sm:grid-cols-2 items-center">
      <div className="flex gap-5" style={{ width: '290px' }}>
        {/* Tarjeta 1 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
            alt="Little Caesars"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700">
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-[220px] h-full items-end mx-auto pb-3">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
              />
            </div>
          </div>
        </div>
        {/* Tarjeta 2 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
              alt="Krispy Kreme"
              className="object-cover object-center h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-44 h-full items-end mx-auto">
                <img
                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                />
              </div>
            </div>
        </div>
        {/* Tarjeta 3 */}
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
              alt="Cinnabon"
              className="object-cover h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-40 h-full items-end mx-auto">
                <img
                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                />
              </div>
            </div>
        </div>
        {/* Tarjeta 4 */}
       <div className="flex flex-shrink-0 relative w-full sm:w-72">   
          <img
            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
            alt="El 9 Yucateco"
            className="object-cover object-center h-96 w-full rounded-3xl "
          />

          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            
            <div className="flex w-24 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
              />
            </div>
          </div>
        </div>
        {/* Tarjeta 5 */}

        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
            alt="Mandrake Café"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-42 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
              />
            </div>
          </div>
        </div>

        {/* Tarjeta 6 */}

        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
            alt="Ninja Park"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-44 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
              />
            </div>
          </div>
        </div>

        {/* Tarjeta 7 */}


        {/* Repetir el contenido para crear el bucle */}
      </div>
    </div>
  </div>
</div>



  </div>
</div>

    <Hardware_equipment/>
    {(country.toLowerCase() === 'panama' || country === 'Panama' || country === 'PANAMA') && <Global_pricing />}
      <FAQ_business_types />
    </>
  )
}

export default Barber