import React from "react";
import useDocumentTitle from "../../useDocumentTitle";

const related_content_blog = [
  {
    id: 1,
    date: "26 jul 2022",
    tittle: "How outsourcing can help improve your business.",
    description:
      "A guide to what self-employment taxes are and why it's important for self employed business owners",
    imgAuthor:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    nameAuthor: "Alejandro Sánchez",
  },
  {
    id: 2,
    date: "26 jul 2022",
    tittle: "How outsourcing can help improve your business.",
    description:
      "A guide to what self-employment taxes are and why it's important for self employed business owners",
    imgAuthor:
      "https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    nameAuthor: "Marian Carrasco",
  },
  {
    id: 3,
    date: "26 jul 2022",
    tittle: "How outsourcing can help improve your business.",
    description:
      "A guide to what self-employment taxes are and why it's important for self employed business owners",
    imgAuthor:
      "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    nameAuthor: "Felipe Rivas",
  },
];

const Article_blog = () => {
  useDocumentTitle("Invu POS | Blog Details");
  return (
    <>
      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-64 md:order-1 md:h-full">
            <img
              src="https://images.pexels.com/photos/4921265/pexels-photo-4921265.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              class="object-cover w-full h-full bg-center"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                April 28, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              What is the self-employment tax and how does it work within the
              industry?
            </h1>
            <div class="flex mb-6 space-x-2">
              <strong class="bg-blue-500 border-blue-500 border text-white  px-3 py-1.5 rounded-full text-[12px] font-medium">
                #Taxes
              </strong>
              <strong class="text-blue-500 border border-current px-3 py-1.5 rounded-full text-[12px] font-medium">
                #Employment
              </strong>
              <strong class="text-blue-500 border border-current px-3 py-1.5 rounded-full text-[12px] font-medium">
                #Advice
              </strong>
            </div>
            <a class="flex items-center text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1650754894/Branding%20Invu%20/General%20business%20types/Downloader.la_-6264851396e99_zzikd0.jpg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <div class="pb-6 mb-6 border-b border-gray-200">
            <h1
              class="mb-3 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl"
              itemprop="headline"
              title="Rise of Tailwind - A Utility First CSS Framework"
            >
              How Do I Calculate My Self-Employment Tax?
            </h1>
            <p class="text-base text-gray-500">
              April 28, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-gray-600">Share this article</p>
            <a href="#" class="text-gray-600 hover:text-gray-900">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-none w-5 h-5"
                fill="currentColor"
              >
                <path d="M13.397,20.997v-8.196h2.765l0.411-3.209h-3.176V7.548c0-0.926,0.258-1.56,1.587-1.56h1.684V3.127	C15.849,3.039,15.025,2.997,14.201,3c-2.444,0-4.122,1.492-4.122,4.231v2.355H7.332v3.209h2.753v8.202H13.397z" />
              </svg>
            </a>
            <a href="#" class="text-gray-600 hover:text-gray-900">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-none w-5 h-5"
                fill="currentColor"
              >
                <path d="M19.633,7.997c0.013,0.175,0.013,0.349,0.013,0.523c0,5.325-4.053,11.461-11.46,11.461c-2.282,0-4.402-0.661-6.186-1.809	c0.324,0.037,0.636,0.05,0.973,0.05c1.883,0,3.616-0.636,5.001-1.721c-1.771-0.037-3.255-1.197-3.767-2.793	c0.249,0.037,0.499,0.062,0.761,0.062c0.361,0,0.724-0.05,1.061-0.137c-1.847-0.374-3.23-1.995-3.23-3.953v-0.05	c0.537,0.299,1.16,0.486,1.82,0.511C3.534,9.419,2.823,8.184,2.823,6.787c0-0.748,0.199-1.434,0.548-2.032	c1.983,2.443,4.964,4.04,8.306,4.215c-0.062-0.3-0.1-0.611-0.1-0.923c0-2.22,1.796-4.028,4.028-4.028	c1.16,0,2.207,0.486,2.943,1.272c0.91-0.175,1.782-0.512,2.556-0.973c-0.299,0.935-0.936,1.721-1.771,2.22	c0.811-0.088,1.597-0.312,2.319-0.624C21.104,6.712,20.419,7.423,19.633,7.997z" />
              </svg>
            </a>
            <a href="#" class="text-gray-600 hover:text-gray-900">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="flex-none w-5 h-5"
                fill="currentColor"
              >
                <circle cx="4.983" cy="5.009" r="2.188" />
                <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zM3.095 8.855H6.87V20.994H3.095z" />
              </svg>
            </a>
          </div>
          <p className="text-gray-700 mb-8 leading-7">
            This article is for educational purposes and does not constitute
            legal, employment, or tax advice. For specific advice applicable to
            your business, please contact a professional. Self-employment comes
            with a vast list of benefits, from setting your own schedule to
            keeping all of the profits your business generates. However,
            self-employed individuals are saddled with at least one drawback:
            the self-employment tax. If you run a business, it’s crucial to
            understand how self-employment taxes work and how you may save on
            these taxes with the right business structure. Here’s a beginner’s
            guide to self-employment tax and other key information you should
            know about taxes when self-employed.
          </p>
          <img
            src="https://images.pexels.com/photos/7667441/pexels-photo-7667441.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />
          <h2 className="font-semibold text-xl mt-6">
            What is the federal self-employment tax
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Self-employment tax refers to the federal payroll taxes you pay for
            income derived through self-employment. More specifically, these are
            taxes for Social Security and Medicare. Here’s how it works.
          </p>

          <h2 className="font-semibold text-lg text-gray-500 mt-4">
            If you run one of these business types, you probably have to pay
            self-employment tax:
          </h2>

          <ul class="list-disc px-6 text-indigo-600 mt-2">
            <li className="text-indigo-600 leading-7">Sole proprietorship</li>
            <li className="text-indigo-600 leading-7">
              Independent contractor
            </li>
            <li className="text-indigo-600 leading-7">Partnership</li>
            <li className="text-indigo-600 leading-7">
              Part-time self-employment
            </li>
            <li className="text-indigo-600 leading-7">Side hustles</li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            What is the federal self-employment tax
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            If you work under one of the business structures listed above,
            you’re required to pay self-employment taxes on your full earnings.
            You may not know about a method to legally lower self-employment
            taxes. Business owners that operate as an Corporation or as an LLC
            taxed as an S Corp may be able to lower their self-employment tax.
            With this model, self-employment taxes are required on your
            paychecks. Any additional income is taxed at your regular income tax
            rate and isn’t subject to self-employment taxes.
          </p>

          <h2 className="font-semibold text-xl mt-6">
            Taxes play a big role in your bottom line
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            When you run a profitable business, paying taxes comes with the
            territory. But as a business owner, arming yourself with a working
            knowledge of how self-employment taxes work can help you take steps
            to minimize what you have to pay legally.
          </p>
          <p className="mt-2 text-gray-700 leading-7">
            Many people don’t realize that their employer matches this amount.
            They also pay 6.2% of your income for the Social Security’s Old-Age,
            Survivors, and Disability Insurance (OASDI) program and 1.45% for
            Medicare. You have to cover the employee and employer portion when
            you’re self-employed. That combined tax is the self-employment tax.
            Certain limits and additional tax rates may apply for six-figure
            incomes or higher.
          </p>
        </div>
      </article>

      <section class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
        <div class="grid items-center grid-cols-1 gap-10 px-4 py-6 overflow-hidden text-pink-900 bg-pirDarkGray rounded-none card card-body sm:rounded-lg md:px-10 md:grid-cols-5 lg:gap-0">
          <div class="col-span-1 md:col-span-3">
            <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-gray-300">
              &mdash; GET THE APP
            </h2>
            <h2 class="text-4xl text-white font-semibold sm:text-4xl max-w-sm space-y-2">
              Get business insights from business experts.
            </h2>
            <button className="mt-4">
              <a
                class="inline-flex items-center px-4 py-2.5 text-black bg-white border border-white rounded hover:bg-transparent hover:text-white active:text-indigo-500 focus:outline-none focus:ring"
                href=""
              >
                <span class="text-sm font-medium"> Get started </span>
              </a>
            </button>
          </div>
          <div class="col-span-1 md:col-span-2">
            <img
              src="https://res.cloudinary.com/dslkvd23s/image/upload/v1651170544/Branding%20Invu%20/blog%20details/smartmockups_l2jc66al-removebg-preview_fs78pn.png"
              class="w-full ml-0 select-none "
              alt="Mac App"
            />
          </div>
        </div>
      </section>

      <div className="bg-pirDarkGray">
        <div class="flex flex-wrap overflow-hidden max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2">
            <h2 className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font text-pirGray-100">
              &mdash; RELATED CONTENT
            </h2>
            <div className="mb-3">
              <a
                href="/"
                aria-label="Article"
                className="inline-block text-white"
              >
                <p className="font-sans text-xl font-extrabold max-w-md leading-none tracking-tight lg:text-2xl xl:text-4xl">
                  Articles and contributions for our community.
                </p>
              </a>
            </div>

            <p class="mt-4 max-w-md leading-relaxed text-gray-400">
              Find out more about the new trends in technology and the
              innovative services we offer.
            </p>
            <div className="flex items-center mt-6">
              <a href="/" aria-label="Author" className="mr-3">
                <img
                  alt="avatar"
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1649441203/Branding%20Invu%20/Logo%20Apps/iconoapp-INVU-POS-ai_inyaz0.svg"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              </a>
              <div>
                <a
                  href="/"
                  aria-label="Author"
                  className="font-semibold text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                >
                  Invu POS
                </a>
                <p className="text-sm font-medium leading-4 text-gray-300">
                  Point of sale system
                </p>
              </div>
            </div>
          </div>

          <div class="w-full overflow-hidden mt-8 md:w-full lg:w-1/2 xl:w-1/2">
            {related_content_blog.map((blog) => (
              <div class="space-y-8 mb-8 ">
                <div className="border bg-pirBlack border-gray-700 p-6 rounded-lg transition-shadow duration-300 group hover:shadow-2xl cursor-pointer">
                  <p className="mb-2 text-xs font-semibold tracking-wide text-gray-300 uppercase">
                    {blog.date}
                  </p>
                  <div className="mb-3">
                    <a
                      href="/"
                      aria-label="Article"
                      className="inline-block text-white transition-colors duration-200"
                    >
                      <p className="font-sans text-xl font-extrabold leading-none tracking-tight lg:text-2xl">
                        {blog.tittle}
                      </p>
                    </a>
                  </div>
                  <p className="mb-4 max-w-lg leading-relaxed text-gray-400">
                    {blog.description}
                  </p>
                  <div className="flex items-center">
                    <a href="/" aria-label="Author" className="mr-3">
                      <img
                        alt="avatar"
                        src={blog.imgAuthor}
                        className="object-cover w-10 h-10 rounded-full shadow-sm"
                      />
                    </a>
                    <div>
                      <a
                        href="/"
                        aria-label="Author"
                        className="font-semibold text-white transition-colors duration-200 "
                      >
                        {blog.nameAuthor}
                      </a>
                      <p className="text-sm font-medium leading-4 text-gray-500">
                        Author
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Article_blog;
