import React from 'react'

import Latest_blog from '../../components/Home/Latest_blog';
import Members from '../../components/Home/Members';
import HeroSV from './HeroSV';
import Products_main_tabs from "../landingSV/Products_tabs/Products_main_tabs";
import Customers_carousel from "../../components/modules/Customers_carousel";
import CTA_home from './CTA_home';
import Industries_tabs_main from './Industries/Industries_tabs_main';
import Hardware_equipment from './Hardware_equipment';
import Support from './Support';
import Industries_carousel from '../../components/modules/Industries_carousel';
import Industries_carousel_sv from '../../components/modules/Industries_carousel_sv';
import Contact_sv from '../../components/Home/Contact_sv';
const LandingSV = () => {
  return (
    <>  
        <HeroSV/>
        <Products_main_tabs/>
        {/* <Customers_carousel/> */}
        <Industries_carousel_sv/>
        <CTA_home/>
        <Industries_tabs_main/>
        <Hardware_equipment/>
        <Support/>
        <Latest_blog/>
        <Members/>
        <Contact_sv/>
    </>
  )
}

export default LandingSV