import React, { useState } from "react";

import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Article_ecommerce_invu = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  useDocumentTitle("E-Commerce con Invu POS | Invu POS");
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Comercio electrónico con Invu POS, empieza ahora tu E-Commerce con nuestro software para vender en la web, transforma tu negocio y vende online."
        />
        <meta name="keywords" content="E-Commerce con INVU POS" />
      </Helmet>

      <article itemid="#" itemscope itemtype="http://schema.org/BlogPosting">
        <div class="grid items-center grid-cols-1 md:grid-cols-2">
          <div class="order-2 h-72 md:order-1 md:h-full">
            <img
              src="https://img.invupos.com/webinvu/Blog%20invu/Blog%205/Image%20details%20blog%205.jpg"
              class=" w-full h-full bg-center object-cover"
              alt=""
            />
          </div>
          <div class="order-1 w-full px-4 py-12 mx-auto text-left md:w-3/4 md:py-48 md:order-2 md:px-0">
            <p class="mb-3 text-gray-500">
              <time
                itemprop="datePublished dateModified"
                datetime="2010-08-07 11:11:03-0400"
                pubdate
              >
                July 25, 2022
              </time>
            </p>
            <h1 class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl">
              E-Commerce con Invu POS
            </h1>

            <p className="text-gray-500 max-w-xl">
              Para cualquier empresa autónoma o E-commerce dedicada a la venta,
              la gestión de los procesos de facturación puede ser una tarea
              difícil. Puesto que, implica papeleo, quita tiempo y significa
              muchos dolores de cabeza que puedes evitar si integras tu
              E-Commerce con Invu POS.
            </p>
            <a class="flex items-center mt-12 text-gray-700" href="#">
              <div class="avatar">
                <img
                  className="h-14 w-14 border rounded-full mr-3 object-cover "
                  src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/Rafael%20Turgman.jpeg"
                  alt=""
                />
              </div>
              <div class="ml-2">
                <p class="text-sm font-semibold text-gray-800">
                  Rafael Turgman
                </p>
                <p class="text-sm text-gray-500">CEO, Invu POS</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full mt-16 my-16 mx-auto prose md:w-3/4 lg:w-3/4 px-4 sm:px-6 lg:px-8">
          <p className="text-gray-700 mt-12 mb-8 leading-7">
            De allí que, los programas de facturación sean una solución
            inteligente para superar cualquier desafío sin que quite tiempo y al
            volverlo automatizado. Además, la facturación es un proceso legal al
            que están obligados todos los comercios, y no es posible de evadir.
          </p>
          <img
            src="https://img.invupos.com/webinvu/Blog%20invu/Other%20resources/section%20image%20blog.webp"
            class="object-cover mb-8 w-full h-72 bg-center rounded"
            alt="Kutty"
          />
          <h2 className="font-semibold text-xl mt-6">
            Empieza en el Comercio Electrónico con Invu E-commerce
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Invu POS te ayuda a comenzar a vender en tu tienda online sin
            importar el producto o servicio que ofreces. Además, todas las
            herramientas cuentan con funciones de protección de pagos,
            integración entre productos y gestión de riesgos para tu empresa.
          </p>
          <ul class=" list-inside mt-5">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">
                Añade pagos a tu sitio web <br></br>
              </span>{" "}
              <span className="font-semibold">
                Al integrar tu E-Commerce con Invu POS tus clientes pueden
                realizar pagos desde las principales plataformas de comercio
                electrónico.
              </span>{" "}
              Por lo que casi todas las conexiones pueden ser tramitadas con un
              par de clics. Además, estas soluciones son compatibles con Wix,
              Prestashop, Ecwid, WooCommerce, entre otras. Las integraciones de
              pago de Invu Pos te facilitan la venta online y sincronizar el
              inventario, y los datos de tus clientes en cualquier canal.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Abre una tienda online de E-commerce<br></br>
              </span>{" "}
              Puedes crear una línea de pedidos online, recibir pedidos y
              ofrecer diferentes alternativas de recogida o entrega a domicilio
              en restaurantes. O publicar una web de comercio electrónico para
              vender en Internet y expandir tus horizontes.{" "}
              <span className="font-semibold">
                {" "}
                Debes aprovechar la flexibilidad que ofrece el integrar tu
                E-Commerce con Invu POS,{" "}
              </span>{" "}
              con los distintos diseños de páginas web para hacer tus artículos
              más atractivos. No dejes de mirar el abanico de opciones, diseñado
              especialmente para ayudarte a conseguir mayores ventas por medio
              de tu sitio.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Crea enlaces de pago<br></br>
              </span>{" "}
              <span className="font-semibold">
                {" "}
                Invu Pos te permite configurar tu web de pagos en pocos minutos:{" "}
              </span>{" "}
              ponerle nombre, agregar una imagen y colocar un precio. El proceso
              de venta de productos en línea para los clientes es sencillo y
              fácil, además puedes escoger entre distintos medios de pago.
              También, escoge el formato más adaptado a tu negocio, por ejemplo,
              con enlaces de pago, botones para comprar o códigos QR. Que puedes
              compartir en redes sociales, por correo electrónico o SMS en un
              sitio web.
            </li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Diseños de páginas web optimizadas para vender en línea
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            A continuación, te muestro las funciones que Invu POS ofrece a tu
            E-Commerce para crear sitios web de acuerdo al sector donde te
            desenvuelves.
          </p>
          <h3 className="font-bold text-[#000] mt-4">
            Restaurantes<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Llega a más personas y aumenta los ingresos en tu restaurante
            gracias a estas funciones:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Importe los artículos desde Invu POS para crear una web de pedidos
              desde un dispositivo móvil.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Los pedidos online van directamente al software de facturación de
              tu negocio y a las impresoras de la cocina.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Ofrece pedidos de autoservicio a los clientes y concreta pedidos
              sin contacto alguno por medio del código QR.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Brinda servicios de comida para llevar y reparte a domicilio con
              tus propios repartidores.
            </li>
          </ul>
          <h3 className="font-bold text-[#000] mt-4">
            Comercios<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Diseña un sitio web comercial optimizado para vender en Internet:
          </p>
          <ul class="list-disc  list-inside mt-5  px-4 ">
            <li className="text-gray-700 leading-7 ">
              Resalta tus productos más vendidos, las ofertas con secciones
              dedicadas a ayudar a los compradores a navegar por tu web y
              comprar.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Vende a través de las redes sociales.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-semibold">
                Sincroniza de forma automática los pedidos online de tu
                E-Commerce integrado con Invu POS.
              </span>
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              Gestiona los pedidos de la manera en que quieras, ya sea con
              recogida en la tienda o entrega a nivel internacional o local.
            </li>
          </ul>
          <h3 className="font-bold text-[#000] mt-4">
            Consultorios<br></br>
          </h3>{" "}
          <p className="mt-2 text-gray-700 leading-7">
            Saca provecho de la flexibilidad de los diseños de sitios web para
            consultorios y hacer que tus servicios destaquen en Internet. Desde
            escritores, artistas, psicólogos, terapeutas y otros profesionales
            pueden usar los pagos online para facilitar la compra del servicio a
            sus clientes.
          </p>
          <h2 className="font-semibold text-xl mt-6">
            Ejemplos de E-commerce con Invu POS
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            A continuación te muestro algunos casos de integración de E-Commerce
            con Invu POS:
          </p>
          <ul class=" list-inside mt-5">
            <li className="text-gray-700 leading-7 ">
              <span className="font-semibold">Tienda sushi en Panamá:</span>{" "}
              <a href="https://sushiexpresspanama.com/#/" target="_blank">
                <span className="font-bold text-[#000] underline">
                  sushiexpresspanama.com/<br></br>
                </span>{" "}
              </a>
              Con Invu POS, este E-Commerce{" "}
              <span className="font-semibold">
                puede ofrecer al cliente un rápido sistema de facturación y
                gestión de pedidos online
              </span>{" "}
              de acuerdo con la ubicación del cliente y horarios disponibles.
              Encuentra desde combinaciones de sushi hasta rollos clásicos que
              dejarán a cualquiera con ganas de comprar.
            </li>
            <li className="text-gray-700 leading-7 mt-5">
              <span className="font-semibold">Restaurant:</span>{" "}
              <a href="https://ordena.tsugoipty.com/" target="_blank">
                <span className="font-bold text-[#000] underline">
                  https://ordena.tsugoipty.com/<br></br>
                </span>{" "}
              </a>
              <span className="font-semibold">
                No es igual comprar en un restaurante tradicional que en uno que
                cuenta con las funciones de Invu POS.
              </span>{" "}
              Puedes solicitar desde combos para almuerzos o cenas en familia
              sin tener que moverte de tu hogar. Además, permite resaltar el
              atractivo de cada uno de sus menús para atraer más clientes y
              aumentar las ventas.
            </li>
            <li className="text-gray-700 leading-7 mt-5">
              <span className="font-semibold">Pizzería:</span>{" "}
              <a href="https://bravapizza.clau.io/#/" target="_blank">
                <span className="font-bold text-[#000] underline">
                  https://bravapizza.clau.io/#/<br></br>
                </span>{" "}
              </a>
              Consiste en una tienda online en donde puedes solicitar desde
              pizzas, empanadas, hamburguesas, churrasco, sopas, y bebidas con
              solamente hacer un clic. Además,
              <span className="font-semibold">
                cuenta con un diseño atractivo que te permite ver los precios y
                alternativas de pago fácilmente.
              </span>{" "}
            </li>
            <li className="text-gray-700 leading-7 mt-5">
              <span className="font-semibold">Pollo:</span>{" "}
              <a href="https://pollotropical.clau.io/#/" target="_blank">
                <span className="font-bold text-[#000] underline">
                  https://pollotropical.clau.io/#/<br></br>
                </span>{" "}
              </a>
              En Pollo Tropical puedes comprar desde Sándwiches con cómodos
              precios hasta combos familiares con solamente hacer un clic.
              Puesto que, Invu POS
              <span className="font-semibold">
                le otorga un diseño atractivo para gestionar los pedidos de
                manera sencilla a los propietarios.
              </span>{" "}
            </li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Plataforma de E-commerce muy sencilla
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Todas y cada una de las funciones de Invu POS otorgan protección de
            pagos, gestión de riesgo y la integración de productos.
          </p>
          <ul class=" list-inside mt-5">
            <li className="text-gray-700 leading-7 ">
              <span className="font-bold text-[#000]">
                Pagos en línea fáciles y seguros <br></br>
              </span>{" "}
              Disfruta del procesamiento de pagos online sin comisiones en caso
              de reembolso o devoluciones. Ofrece otras alternativas como PayPal
              o Google Pay a tus clientes. Además los compradores pueden guardar
              la información de pago para conservar tu información y pagar más
              rápido.
            </li>
            <li className="text-gray-700 leading-7 mt-2">
              <span className="font-bold text-[#000]">
                Sincronización sin interrupciones<br></br>
              </span>{" "}
              <span className="font-semibold">
                {" "}
                La integración de un E-Commerce con Invu POS te permite
                sincronizar tus pedidos,{" "}
              </span>{" "}
              artículos y el inventario en tu sitio web. Emplea el administrador
              de pedidos para ver en un único lugar, todo lo que sucede sin
              estar presente.
            </li>
          </ul>
          <h2 className="font-semibold text-xl mt-6">
            Contacta con nuestro equipo y empieza tu e-commerce de inmediato
          </h2>
          <p className="mt-2 text-gray-700 leading-7">
            Invu POS es una empresa con un equipo multidisciplinario líder en el
            desarrollo de software de facturación. Por lo tanto, puedes
            contratar este servicio por medio del correo info@invupos.com, o a
            través de su número +507 201-8490.
          </p>
          <div class="pb-6 mb-6 border-b border-gray-200"></div>
          <div class="flex items-center mb-6 space-x-2">
            <p class="text-base text-gray-500">
              July 07, 2022 — Written by:{" "}
              <span className="underline font-regular">Rafael Turgman</span>
            </p>
          </div>
        </div>
      </article>
    </>
  );
};

export default Article_ecommerce_invu;
