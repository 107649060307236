import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Managing = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  return (
    <>
      <section>
        <div class="max-w-screen-xl px-4 mt-32 py-10 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <div className="grid grid-cols-2 gap-5">
              <img
                className="object-cover w-full h-96 col-span-2 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/c_scale,f_auto,q_auto,w_924/v1652469892/Branding%20Invu%20/Managing/pexels-photo-6353681_js0gac.jpg"
                loading="lazy"
                alt=""
              />
              <img
                className="object-cover w-full h-64 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/c_scale,f_auto,q_auto,w_932/v1652469888/Branding%20Invu%20/Managing/pexels-photo-6069088_r4umui.jpg"
                loading="lazy"
                alt=""
              />
              <img
                className="object-cover w-full h-64 rounded shadow-lg"
                src="https://res.cloudinary.com/dslkvd23s/image/upload/c_scale,f_auto,q_auto,w_936/v1652469889/Branding%20Invu%20/Managing/pexels-photo-3183197_dontww.jpg"
                loading="lazy"
                alt=""
              />
            </div>

            <div>
              <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
                &mdash; {t("workbetteruppercase")}
              </h2>
              <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none xl:max-w-lg">
                {t("managingtittle")}
              </h2>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <a href="/inventory-managment">
                    <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                      {t("itemtitle1")}
                    </h6>
                  </a>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      {t("itemdescriptionspan1")}
                    </span>{" "}
                    {t("itemdescription1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <a href="/payments-overview">
                    <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                      {t("itemtitle2")}
                    </h6>
                  </a>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      {t("itemdescriptionspan2")}
                    </span>{" "}
                    {t("itemdescription2")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    3
                  </p>
                </div>
                <div>
                  <a href="/customer-loyalty">
                    <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                      {t("itemtitle3")}
                    </h6>
                  </a>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    <span className="font-semibold">
                      {t("itemdescriptionspan3")}
                    </span>{" "}
                    {t("itemdescription3")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    4
                  </p>
                </div>
                <div>
                  <a href="/invu-experts">
                    <h6 className="mb-2 mt-1.5 font-semibold leading-5 underline">
                      {t("itemtitle4")}
                    </h6>
                  </a>
                  <p className="text-sm text-gray-900 leading-loose lg:max-w-md">
                    {t("itemdescription4")}{" "}
                    <span className="font-semibold">
                      {t("itemdescriptionspan4")}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Managing;
