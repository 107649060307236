import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useTranslation } from "react-i18next";

const Industries_carousel_pa = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
       <section>
        <div class="max-w-screen-xl px-4 mt-28 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-0 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-0">
            <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("ourclientsuppercasemx")}
                </p>
              </div>
              <h2 className="max-w-4lg mb-2 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("ourclientetitlemx")}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 md:px-0">
          <CarouselProvider
            className="lg:block hidden"
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={16}
            visibleSlides={2}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full flex lg:gap-4 md:gap-6 gap-12 items-center justify-start transition ease-out duration-700"
                  >
                    
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                          alt="Little Caesars"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[220px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                          alt="Krispy Kreme"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                          alt="Olive Garden"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                          alt="Cinnabon"
                          className="object-cover h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                       {/* Star new Items */}
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/Selina.jpg"
                          alt="Selina"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-10">
                          
                          <div className="flex w-36 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Selina.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/LOFE.jpg"
                          alt="Lofe"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Lofe.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                          alt="TheLookout"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                          alt="Hotel Banana Azul"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                          alt="Bardo WineBar"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0 ">
                          
                          <div className="flex w-44 mt-6 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    {/* End New Items */}

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/izlah.jpg"
                          alt="Izlah"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Izlah.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/photo-tizoncito.jpg"
                          alt="Tizoncito"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-[190px] h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/El%20tizoncito.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Tacos%20manolo%20.jpg"
                          alt="Tacos Manolo"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-9">
                          
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/TACOS%20MANOLO.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Cinnabon-mexico.jpg"
                          alt="Cinnabon Mexico"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/perfumes-europeos-img.jpg"
                          alt="Perfumes Europeos"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Perfumes%20Europeos.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                          alt="El 9 Yucateco"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-24 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
                          alt="Mandrake Café"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-42 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                          alt="Ninja Park"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Start SV */}

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Subway.jpeg"
                          alt="Subway"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Subway-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Brutto1.jpg"
                          alt="Brutto"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Brutto.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Monterrosso.webp"
                          alt="Monterrosso"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          <div className="flex w-56 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Monterroso.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-img1.png"
                          alt="Llaollao"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Trinidad y Tobago */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20tyt/cinnabon.jpg"
                          alt="Cinnabon Trinidad y Tobago"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fecdcf] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#c0102d]">Trinidad y Tobago</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Colombia */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cl/ajisen%20ramen-1.jpg"
                          alt="Ajisen Ramen CL"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fff986] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Colombia</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cl/Ajisen%20Ramen.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>




                    {/* <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/Elringbarphoto.jpg"
                          alt="El ring bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-24 h-full items-end mx-auto">
                            <img
                              src=""
                            />
                          </div>
                        </div>
                      </div>
                    </Slide> */}

                  </div>
                </Slider>
              </div>
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>

          {/* Carousel for tablet and medium size devices */}
          <CarouselProvider
            className="lg:hidden md:block hidden"
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={26}
            visibleSlides={2}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full  mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full  flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                  >

                        <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                              </span>
                            </div>
                          </div>

                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-[220px]  h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                              />
                            </div>
                          </div>

                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-32 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* End PA */}

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/Selina.jpg"
                          alt="Selina"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-10">
                          
                          <div className="flex w-36 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Selina.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/LOFE.jpg"
                          alt="Lofe"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Lofe.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-52 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-40 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                            <div className="flex w-44 mt-6 h-full items-end mx-auto">
                              <img
                               src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      {/* END CR */}

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/izlah.jpg"
                          alt="Izlah"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Izlah.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/photo-tizoncito.jpg"
                          alt="Tizoncito"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-[190px] h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/El%20tizoncito.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Tacos%20manolo%20.jpg"
                          alt="Tacos Manolo"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-9">
                          
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/TACOS%20MANOLO.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Cinnabon-mexico.jpg"
                          alt="Cinnabon Mexico"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/perfumes-europeos-img.jpg"
                          alt="Perfumes Europeos"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Perfumes%20Europeos.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                        <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-24 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>

                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-42 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                              />
                            </div>
                          </div>

                        </div>
                      </Slide>


                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                 src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                     {/* Start SV */}

                     <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Subway.jpeg"
                          alt="Subway"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Subway-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Brutto1.jpg"
                          alt="Brutto"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Brutto.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Monterrosso.webp"
                          alt="Monterrosso"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          <div className="flex w-56 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Monterroso.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-img1.png"
                          alt="Llaollao"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Trinidad y Tobago */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20tyt/cinnabon.jpg"
                          alt="Cinnabon Trinidad y Tobago"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fecdcf] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#c0102d]">Trinidad y Tobago</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Colombia */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cl/ajisen%20ramen-1.jpg"
                          alt="Ajisen Ramen CL"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fff986] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Colombia</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cl/Ajisen%20Ramen.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>



                      {/* <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Elringbarphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6 rounded-3xl">
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                 src=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide> */}

                  </div>
                </Slider>
              </div>

              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>

          {/* Carousel for mobile and Small size Devices */}
          <CarouselProvider
            className="block md:hidden "
            naturalSlideWidth={100}
            isIntrinsicHeight={true}
            totalSlides={32}
            visibleSlides={1}
            step={1}
            infinite={true}
          >
            <div className="w-full relative flex items-center justify-center">
              <ButtonBack
                role="button"
                aria-label="slide backward"
                className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
                id="prev"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonBack>
              <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                  <div
                    id="slider"
                    className="h-full w-full flex lg:gap-8 gap-4 md:gap-6 items-center justify-start transition ease-out duration-700"
                  >

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-[220px]  h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/olivegardephoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>

                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-32 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Olivegardenlogo.webp"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                            <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-40 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/bbqchicken3.jpg"
                          alt="bbq chicken"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[230px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/bbq%20chicken.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido2.jpg"
                          alt="café unido"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/cafeunido_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/krumebacker3.jpg"
                          alt="Krume Bäcker"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-[150px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/Krume.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Manglebar.jpg"
                          alt="Mangle"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-2">
                          <div className="flex w-[160px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/manglebar.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.jpg"
                          alt="Pedro Mandinga Rum Bar"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                          <div className="flex w-[200px] h-full items-end mx-auto pb-3">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/pedromandinga.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/Selina.jpg"
                          alt="Selina"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-10">
                          
                          <div className="flex w-36 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Selina.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                       <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cr/LOFE.jpg"
                          alt="Lofe"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cr/Lofe.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_rest.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-52 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/thelookout_logo_white.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20costa%20rica/images/hotelbananaazul.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                            <div className="flex w-40 h-full items-end mx-auto">
                              <img
                                src="https://img.invupos.com/invu%20costa%20rica/images/HotelBananaAzul.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/Bardo_bar1.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                          <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a7beff] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#001489]">Costa Rica</p> {""}
                              </span>
                            </div>
                          </div>
                          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-0">
                            <div className="flex w-44 mt-6 h-full items-end mx-auto">
                              <img
                               src="https://img.invupos.com/invu%20costa%20rica/images/bardo_logo.png"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/izlah.jpg"
                          alt="Izlah"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Izlah.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/photo-tizoncito.jpg"
                          alt="Tizoncito"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-[190px] h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/El%20tizoncito.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Tacos%20manolo%20.jpg"
                          alt="Tacos Manolo"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-9">
                          
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/TACOS%20MANOLO.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/Cinnabon-mexico.jpg"
                          alt="Cinnabon Mexico"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20mx/perfumes-europeos-img.jpg"
                          alt="Perfumes Europeos"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20mx/Perfumes%20Europeos.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/despechomx-photo1.jpg"
                          alt="DespechoMX"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/despechomx-logo-transformed.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/blendstationphoto.jpg"
                          alt="Blend Station"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Blendstation.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        
                        <img
                          src="https://img.invupos.com/invu%20mexico/clientes/ringnightclub1.jpg"
                          alt="Ring night club"
                          className="object-cover object-center h-96 w-full rounded-3xl "
                        />

                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                            </span>
                          </div>
                        </div>
                        
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/Ringsocialroom.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>
                    
                    <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-24 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://lh3.googleusercontent.com/p/AF1QipNNbBvGd3uwpSIDeqSyFfAUGksIYIx8ndHM2Lz0=s680-w680-h510"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                            <div className="absolute inset-x-0 text-center mt-8">
                              <span
                                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
                              >
                                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
                              </span>
                            </div>
                          </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-42 h-full items-end mx-auto">
                                <img
                                  src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                        <div className="flex flex-shrink-0 relative w-full sm:w-72 rounded-3xl">
                          <img
                            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
                            className="object-cover object-center h-96 w-full rounded-3xl"
                          />
                           <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                              <div className="flex w-44 h-full items-end mx-auto">
                                <img
                                 src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
                                />
                              </div>
                            </div>
                        </div>
                      </Slide>

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/invu%20costa%20rica/images/CHURROMANIA.jpg"
                          alt="Churromania"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-44 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20costa%20rica/images/churromania_logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                      {/* Start SV */}

                      <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Subway.jpeg"
                          alt="Subway"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-52 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Subway-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Brutto1.jpg"
                          alt="Brutto"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
                          <div className="flex w-32 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Brutto.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Monterrosso.webp"
                          alt="Monterrosso"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full">
                          <div className="flex w-56 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Monterroso.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-img1.png"
                          alt="Llaollao"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#aee6ff] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#0047ab]">El Salvador</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20sv/Llaollao-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Trinidad y Tobago */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20tyt/cinnabon.jpg"
                          alt="Cinnabon Trinidad y Tobago"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fecdcf] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#c0102d]">Trinidad y Tobago</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-4">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                    {/* Colombia */}


                    <Slide>
                      <div className="flex flex-shrink-0 relative w-full sm:w-72">
                        <img
                          src="https://img.invupos.com/webinvu/Clients%20cl/ajisen%20ramen-1.jpg"
                          alt="Ajisen Ramen CL"
                          className="object-cover object-center h-96 w-full rounded-3xl"
                        />
                        <div>
                          <div className="absolute inset-x-0 text-center mt-8">
                            <span
                              class="inline-flex items-center justify-center rounded-full bg-[#fff986] px-4 py-0.5 text-purple-700"
                            >
                              <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Colombia</p> {""}
                            </span>
                          </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-8">
                          <div className="flex w-40 h-full items-end mx-auto">
                            <img
                              src="https://img.invupos.com/webinvu/Clients%20cl/Ajisen%20Ramen.png"
                            />
                          </div>
                        </div>
                      </div>
                    </Slide>

                  </div>
                </Slider>
              </div>
              <ButtonNext
                role="button"
                aria-label="slide forward"
                className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                id="next"
              >
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L1 13"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      </div>
    </>
  );
};

export default Industries_carousel_pa;
