import React, { useState, useEffect, useRef } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import Modal_started from "../Home/Modal_started";
import FAQ_model_one from "./FAQ_model_one";
import Help_CTA from "../Home/Help_CTA";

import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../utils/useLocalePrefix";
import { t } from "i18next";
import Delivery_integrations_cta from "./Delivery_integrations_cta";
import FAQ_business_types from "./FAQ_business_types";
import Software_hardware_reports_tabs_kds from "../modules/Software_hardware_reports_tabs_kds";

const features = [
  {
    name: "Developer API",
    description:
      "Integrate Invu with your custom ecommerce website or other business systems using our extensive library of developer resources.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%201.webp",
    altImg: "",
    href: "/developers",
  },
  {
    name: "Find an agency",
    description:
      "If you need help setting up your Invu ecommerce experience, let us connect you with an agency partner from our trusted network.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%202%20.webp",
    altImg: "",
    href: "/purchase",
  },
  {
    name: "Reach out",
    description:
      "Not sure how to start? Reach out to us today and we can help you find the perfect eCommerce tools for your business needs. Get started.",
    imgSrc:
      "https://img.invupos.com/webinvu/Point%20of%20sale%20module/features%20pos%203.webp",
    altImg: "",
    href: "/ecommerce",
  },
];

const Product_KDS = () => {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Kitchen Display System | Invu POS");

  const location = useLocation();
  const prefix = useLocalePrefix();

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollLeft = 0;
    let animationId;

    function animateScroll() {
      scrollLeft += 1; 

      if (scrollLeft >= container.scrollWidth - container.clientWidth) {
        clearInterval(animationId);
        setTimeout(() => { 
          scrollLeft = 0;
          animationId = setInterval(animateScroll, 1000 / 60);
        }, 2000);
      }

      container.scrollLeft = scrollLeft;
    }

    setTimeout(() => {
      animationId = setInterval(animateScroll, 1000 / 60); // Iniciar la animación
    }, 2000); 
 
    return () => clearInterval(animationId);
  }, []); 

  const featuresinvukds1 = t("featuresinvukds1", {
    returnObjects: true,
  });

  return (
    <> 
    {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      {/* {isOpen && <Modal_started setIsOpen={setIsOpen} />}
      <section className="">
        <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <div class="flex flex-col w-full mb-2 text-center">
            <div className="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  {t("productinvukdsuppercase")}
                </p>
              </div>
              <h1 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                {t("productinvukdstitle")}
              </h1>
              <p className="mt-5 text-sm lg:text-base text-gray-600 leading-loose">
                {t("productinvukdsdescription")}
              </p>
            </div>
          </div>
          <div className="group relative">
            <div className="bg-gray-200 aspect-w-1  cursor-pointer aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 ">
              <img
                className="object-cover w-full h-86 sm:h-96 rounded-lg"
                src="https://img.invupos.com/webinvu/KDS%20module/Header%20KDS.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-pirBlack">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid flex-col-reverse items-center grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
            <div class="order-none lg:order-2 md:order-none">
              <p className="mb-3 text-xs font-semibold tracking-widest text-white uppercase title-font">
                &mdash; {t("productinvukdssection1uppercase")}
              </p>
              <h2 class="mb-4 text-2xl font-extrabold tracking-tight text-white md:leading-tight sm:text-left md:text-4xl">
                {t("productinvukdssection1title")}
              </h2>
              <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-500 leading-loose">
                {t("productinvukdssection1description")}
              </p>
              <div className="flex mt-10 my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    1
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5  font-semibold leading-5 text-white underline">
                    {t("productinvukdssection1title1")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("productinvukdssection1description1")}
                  </p>
                </div>
              </div>
              <div className="flex my-6">
                <div className="mr-4">
                  <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange cursor-pointer">
                    2
                  </p>
                </div>
                <div>
                  <h3 className="mb-2 mt-1.5 text-white font-semibold leading-5 underline">
                    {t("productinvukdssection1title2")}
                  </h3>
                  <p className="text-sm text-gray-500 leading-loose lg:max-w-md">
                    {t("productinvukdssection1description2")}
                  </p>
                </div>
              </div>
              <button
                onClick={() => setIsOpen(true)}
                className="inline-flex items-center px-8 mt-5 py-3 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="text-sm font-medium">
                  {" "}
                  {t("productinvukdssection1button1")}{" "}
                </span>

                <svg
                  class="w-5 h-5 ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            <div className="relative lg:m-auto md:-mt-24 group flex justify-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full rounded-md"
                src="https://img.invupos.com/webinvu/KDS%20module/p3-1.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div className="max-w-screen-xl mt-20 px-4 mb-12 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
          <div className="p-8 border-b sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature1title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature1description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature2title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature2description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-r lg:border-r-0">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
                  <path
                    fill-rule="evenodd"
                    d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature3title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature3description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-b-0 lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full  cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-7"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature4title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature4description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-b-0 sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                  <path
                    fill-rule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature5title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature5description")}
              </p>
            </div>
          </div>
          <div className="p-8">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("productinvukdsfeature6title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("productinvukdsfeature6description")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-pirBlack">
        <div class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold xl:block hidden leading-tight text-white">
            {t("productinvukdssection2title")}
          </h2>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple"></span>

          <h2 className="text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-white">
            {t("productinvukdssection2title")}
          </h2>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirPink to-pirOrange hover:from-pirLightBlue hover:to-pirPurple"></span>
          <div class="grid grid-cols-1 mt-8 gap-12 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
            {featuresinvukds1.map((feature) => (
              <div>
                <a class="block overflow-hidden rounded-lg shadow-sm">
                  <img
                    class="object-cover w-full h-56"
                    src={feature.imgSrc}
                    alt=""
                  />

                  <div class="p-6 bg-pirDarkGray">
                    <h3 class="text-xl font-bold text-white underline">
                      {feature.name}
                    </h3>

                    <p class="mt-4 text-sm text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div class="max-w-screen-xl mt-10 px-4 py-12 mx-auto sm:px-6 lg:px-8">
          <aside class="relative overflow-hidden text-gray-300 bg-gray-50 lg:flex shadow-xl rounded-2xl">
            <div class="w-full p-12 text-center lg:w-1/2 sm:p-16 lg:p-24 lg:text-left ">
              <div class="max-w-xl mx-auto md:text-left md:-ml-8  lg:-ml-12 text-left -ml-7 rounded-lg">
                <h2 class="mb-4 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                  {t("productinvukdssection3title")}
                </h2>
                <p className="mt-5 max-w-lg text-sm lg:text-base text-gray-700 leading-loose">
                  {t("productinvukdssection3description")}
                </p>
                <a>
                  <div class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer">
                    {t("productinvukdssection3button")} {""}
                    <span aria-hidden="true">&rarr;</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="relative w-full h-64  sm:h-96 lg:w-1/2 lg:h-auto md:-mt-12">
              <img
                src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Online%20payments.svg"
                alt="Women smiling at college"
                class="absolute inset-0 object-cover w-full h-full md:object-contain "
              />
            </div>
          </aside>

          <div class="grid mt-8 grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
            <div>
              <a class="block overflow-hidden rounded-2xl shadow-xl">
                <div class="p-4 bg-gray-50">
                  <div className="lg:ml-8 mb-6 md:ml-3">
                    <div class="flex">
                      <h2 class="mt-6 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                        {t("productinvukdssection3title2")}
                      </h2>
                    </div>
                    <p className="mt-5 max-w-md text-sm  lg:text-base text-gray-700 leading-loose">
                      {t("productinvukdssection3description2")}
                    </p>
                    <a href="/payments/risk-manage">
                      <div class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer">
                        {t("productinvukdssection3button2")} {""}
                        <span aria-hidden="true">&rarr;</span>
                      </div>
                    </a>
                  </div>
                </div>

                <img
                  class="object-cover w-full h-56 "
                  src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Manage%20risk.avif"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a class="block overflow-hidden rounded-2xl shadow-xl">
                <div class="p-4 bg-gray-50">
                  <div className="lg:ml-8 mb-6 md:ml-3">
                    <div class="flex ">
                      <h2 class="mt-6 text-xl font-extrabold underline tracking-tight  text-black md:leading-tight sm:text-left md:text-2xl">
                        {t("productinvukdssection3title3")}
                      </h2>
                    </div>
                    <p className="mt-5 max-w-md text-sm lg:text-base text-gray-700 leading-loose">
                      {t("productinvukdssection3description3")}
                    </p>
                    <button
                      onClick={() => setIsOpen(true)}
                      class="inline-block pb-1 mt-6 font-medium text-indigo-600 border-b border-indigo-600 cursor-pointer"
                    >
                      {t("productinvukdssection3button3")} {""}
                      <span aria-hidden="true">&rarr;</span>
                    </button>
                  </div>
                </div>

                <img
                  class="object-cover w-full h-56 "
                  src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Seamless.avif"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="mb-12 lg:max-w-lg lg:pr-5 lg:mb-0">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {t("readytogetstartedtitle")}
              </h2>
              <p className="text-gray-600 leading-loose">
                {t("readytogetstarteddescription")}
              </p>
            </div>
            <hr className="mb-6 border-gray-300" />
            <div className="flex">
              <a href="/" aria-label="Play Song" className="mr-3">
                <div className="flex items-center justify-center w-10 h-10 text-white transition duration-300 transform rounded-full shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 hover:scale-110">
                  <img
                    className="rounded-full w-10 h-10"
                    src="https://img.invupos.com/webinvu/Point%20of%20sale%20module/Icon%20Invu%20Circle.svg"
                  />
                </div>
              </a>
              <div className="flex flex-col">
                <div className="text-sm font-semibold">Invu POS</div>
                <div className="text-xs text-gray-700">
                  {t("readytogetstartedinvu")}
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 pt-6 pb-5 text-center border border-gray-300 rounded lg:w-2/5">
            <div className="mb-5 font-semibold">{t("createanaccount")}</div>
            <div className="flex justify-center w-full mb-3">
              <a
                href="https://admin.invupos.com/invuPos/index.php/site/login"
                target="_blank"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-indigo-600 hover:bg-indigo-700 focus:shadow-outline focus:outline-none"
              >
                <div className="flex items-center">
                  <div className="mr-3 font-semibold text-white">
                    {t("createanaccountbutton")}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                    />
                  </svg>
                </div>
              </a>
            </div>
            <div className="inline-flex items-center justify-center">
              <p className="max-w-md px-5 mb-3 text-xs text-gray-600 sm:text-sm md:mb-5 ">
                {t("createanaacounttext")}{" "}
                <Link to={`${prefix}` + "/centro-de-ayuda"}>
                  <span className="underline font-semibold cursor-pointer">
                    {t("createanaccounttextspan")}
                  </span>
                </Link>
              </p>
            </div>
            <div className="flex items-center w-full mb-5">
              <hr className="flex-1 border-gray-300" />
              <div className="px-3 text-xs text-gray-500 sm:text-sm">
                {t("createanaccountor")}
              </div>
              <hr className="flex-1 border-gray-300" />
            </div>
            <a
              href="https://admin.invupos.com/invuPos/index.php/site/login"
              target="_blank"
              className="inline-flex items-center justify-center w-full h-12 px-6 font-semibold transition duration-200 bg-white border border-gray-300 rounded md:w-auto hover:bg-gray-100 focus:shadow-outline focus:outline-none"
            >
              {t("Iniciar sesión")}
            </a>
          </div>
        </div>
      </div> */}

       {/* NEW DESIGN */}
       <section className="bg-[#121212]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="lg:pr-10 content-center">
          <h1 class="font-bold xl:text-[47px] lg:text-5xl md:text-5xl text-4xl text-white">
          {t("kdsystemtitle")}
          </h1>
          <p class="mt-5 text-gray-300 text-lg leading-loose max-w-2xl font-light">
          {t("kdsystemdescription")}
          </p>
          <div class="mt-7 grid gap-3 w-full sm:inline-flex">
            <button 
            onClick={() => setIsOpen(true)}
            class="py-4 px-5 inline-flex justify-center text-lg items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
              {t("kdsystembutton1")}
            </button>
          </div>
          <div class="mt-6 lg:mt-12 mb-6">
                    <span class="text-xs font-semibold text-gray-500 uppercase tracking-widest">{t("availableinhero")}</span>
                    <div class="mt-4 flex gap-x-8">
                      <a href="https://bit.ly/4cXCHQm" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m3.001 5.479l7.377-1.016v7.127H3zm0 13.042l7.377 1.017v-7.04H3zm8.188 1.125L21.001 21v-8.502h-9.812zm0-15.292v7.236h9.812V3z"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/3W8xWN4" target="_blank">
                        <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 315">
                            <path fill="currentColor" d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262"/>
                        </svg>
                      </a>
                      <a href="https://bit.ly/4cJt3Be" target="_blank">
                          <svg class="icon text-gray-500 hover:text-gray-800 transition duration-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
                              <path fill="currentColor" fill-rule="evenodd" d="M12.735 1.828L13.6.254a.172.172 0 0 0-.3-.164l-.874 1.59a5.987 5.987 0 0 0-2.428-.508c-.869 0-1.69.181-2.424.506L6.698.09a.171.171 0 0 0-.232-.069a.169.169 0 0 0-.069.232l.866 1.572c-1.7.874-2.85 2.537-2.849 4.447l11.166-.001c0-1.91-1.146-3.57-2.845-4.444m-5.278 2.43a.467.467 0 1 1 .001-.934a.467.467 0 0 1 0 .935M15.542 6.7l.002 8.012c0 .481-.262.897-.648 1.127a1.31 1.31 0 0 1-.675.19l-.904.001v2.734a1.237 1.237 0 0 1-1.489 1.21a1.238 1.238 0 0 1-.99-1.209V16.03H9.163v2.735A1.237 1.237 0 0 1 7.925 20a1.239 1.239 0 0 1-1.238-1.235V16.03h-.901c-.521 0-.967-.3-1.182-.736a1.298 1.298 0 0 1-.141-.581l-.002-8.01zM2.74 6.47c.684 0 1.24.553 1.24 1.234v5.17a1.238 1.238 0 0 1-1.922 1.03a1.231 1.231 0 0 1-.557-1.03L1.5 7.703c0-.68.555-1.234 1.24-1.234m14.52-.001c.684 0 1.24.552 1.24 1.234v5.169c0 .683-.555 1.235-1.239 1.235c-.685 0-1.24-.552-1.24-1.235V7.702c0-.682.554-1.235 1.238-1.234M12.54 3.325a.467.467 0 1 1 0 .933a.467.467 0 1 1 0-.933"/>
                          </svg>
                      </a>
                    </div>
                  </div>
        </div>
        <div>
          <img
            className="object-cover w-full h-80 rounded-2xl shadow-lg sm:h-[480px]"
            src="https://img.invupos.com/webinvu/KDS%20module/Header%20KDS.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    </section>

    <div className="max-w-screen-xl px-4 mt-20 mx-auto sm:px-6 lg:px-8 lg:mt-52">
      <div className="flex flex-col md:flex-row items-center lg:gap-5 bg-gradient-to-r from-[#eff1fe] to-[#cad0fb]  rounded-2xl shadow-md overflow-visible relative">
        <div className="md:w-1/2 w-full overflow-visible relative order-2 md:order-1">
          <img
            src="https://img.invupos.com/webinvu/KDS%20module/kds%20devices%283%29.png"
            alt="CTA"
            className="object-cover h-full w-full xl:ml-2 xl:-mt-32 mt-4" 
          />
        </div>
        <div className="md:w-1/2 w-full p-6 order-1 md:order-2">
          <h2 class="mb-4 font-sans text-4xl lg:mt-0 mt-8 font-bold tracking-tight text-gray-900 xl:text-[45px] md:text-[40px] sm:leading-none"> {t("kdsystemcta1title")}</h2>
          <p class="xl:text-lg lg:text-md md:text-base leading-8 text-gray-700 mb-3">{t("kdsystemcta1description")}</p>
          <div class="flex items-center">
          <Link>
            <button
              onClick={() => setIsOpen(true)}
              class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
            >
              <span
                class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
              >
                {t("autoservicecta1button")}
              </span>
              <span
                class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
              >
                <svg
                  class="h-5 w-5 rtl:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </span>
            </button>
          </Link>
        </div>
        </div>
      </div>
    </div>

    <div className="max-w-screen-xl mt-20 px-4 mb-12 py-12 mx-auto sm:px-6 lg:px-8">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg text-center mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          {t("kdsystemgeneraltitle")}
        </h2>
      </div>
        <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
          <div className="p-8 border-b sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard1title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard1description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard2title")}{" "}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard2description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-r lg:border-r-0">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
                  <path
                    fill-rule="evenodd"
                    d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard3title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard3description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b lg:border-b-0 lg:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full  cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-7"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard4title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard4description")}
              </p>
            </div>
          </div>
          <div className="p-8 border-b sm:border-b-0 sm:border-r">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                  <path
                    fill-rule="evenodd"
                    d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard5title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard5description")}
              </p>
            </div>
          </div>
          <div className="p-8">
            <div className="max-w-md text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full  cursor-pointer bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange sm:w-16 sm:h-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-7"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                </svg>
              </div>
              <h3 className="mb-2 font-semibold leading-5">
                {t("kdsystemcard6title")}
              </h3>
              <p className="mb-3 text-sm text-gray-900">
                {t("kdsystemcard6description")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Delivery_integrations_cta/>

      <Software_hardware_reports_tabs_kds/>

      <section>
        <div class="max-w-screen-xl px-4 mt-12 mx-auto sm:px-6 lg:px-8">
       <div className="flex bg-gradient-to-r from-[#eff1fe] to-[#cad0fb] flex-col justify-between px-4 mx-auto lg:flex-row md:px-12 lg:max-w-scr sm:px-6 lg:px-12een-xl rounded-2xl">
      <div className="pt-16 lg:mb-16 mb-10 lg:mb-0 lg:pt-20 lg:max-w-2xl lg:pr-5">
        <div className="max-w-xl mb-6">
          <h2 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
          {t("kdsystemctacontacttitle")}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          {t("kdsystemctacontactposdescription")}
          </p>
        </div>
        <div className="flex items-center">
        <button
        onClick={() => {
          setIsOpen(true);
        }}
            class="group flex mt-2 items-center justify-between gap-3 rounded-full border border-indigo-600 bg-indigo-600 px-5 py-2 transition-colors hover:bg-transparent focus:outline-none focus:ring"
          >
            <span
              class="font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
            >
              {t("kdsystemctacontactbutton")}
            </span>

            <span
              class="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500"
            >
              <svg
                class="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="items-center flex content-center lg:mb-0 mb-12">
        <img
          src="https://img.invupos.com/webinvu/KDS%20module/fiscalprinter.png"
          className=" rounded-3xl"
          alt=""
        />
      </div>
    </div>
    </div>
    </section>



    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-12">
  <div className="flex flex-col lg:flex-row text-center">
    <div className="max-w-xl mx-auto lg:order-last items-center align-center flex ">
      <div className="p-8">
        <div className="text-center flex justify-center">
          <div class="mt-4 flex gap-x-3 mb-6">
            <a
              class="hover:opacity-90"
              href="https://www.capterra.com.pa/software/1049125/invu-pos"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ca-ease_of_use-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.softwareadvice.com/inventory-management/invu-pos-profile/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/sa-customer_support-2023.png" className="h-[85px]"/>
            </a>
            <a
              class="hover:opacity-90"
              href="https://www.getapp.com/retail-consumer-services-software/a/invu-pos/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="https://img.invupos.com/webinvu/Footer/ga-features_and_functionality-2023.png" className="h-[80px]"/>
            </a>
          </div>
        </div>
        <hr className="mb-6 border border-gray-300" />
        <h5 className="mb-6 text-3xl max-w-lg font-extrabold leading-none">
          {t("furniturectacarousel")}
        </h5>
      </div>
    </div>

    <div className="overflow-x-hidden relative custom-container">
    <div className="overflow-x-auto custom-scrollbar" id="cardContainer" ref={containerRef}>
    <div id="carousel" className="grid gap-5 row-gap-5 sm:grid-cols-2 items-center">
      <div className="flex gap-5" style={{ width: '290px' }}>
        
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/littlecaesarsphoto.jpg"
            alt="Little Caesars"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700">
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-[220px] h-full items-end mx-auto pb-3">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Little%20Caesars.png"
              />
            </div>
          </div>
        </div>
       
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/krispykremephoto.jpg"
              alt="Krispy Kreme"
              className="object-cover object-center h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-44 h-full items-end mx-auto">
                <img
                  src="https://www.clutch.com/wp-content/uploads/2020/04/krispy-kreme-logo-white.png"
                />
              </div>
            </div>
        </div>
       
        <div className="flex flex-shrink-0 relative w-full sm:w-72">
            <img
              src="https://img.invupos.com/invu%20mexico/clientes/Cinnabonphoto.jpeg"
              alt="Cinnabon"
              className="object-cover h-96 w-full rounded-3xl"
            />
            <div>
              <div className="absolute inset-x-0 text-center mt-8">
                <span
                  class="inline-flex items-center justify-center rounded-full bg-[#ff9b9f] px-4 py-0.5 text-purple-700"
                >
                  <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#4c0306]">Panamá</p> {""}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
              <div className="flex w-40 h-full items-end mx-auto">
                <img
                  src="https://img.invupos.com/invu%20mexico/clientes/cinnabon-logo.png"
                />
              </div>
            </div>
        </div>
       
       <div className="flex flex-shrink-0 relative w-full sm:w-72">   
          <img
            src="https://img.invupos.com/invu%20mexico/el9yucatecophoto.jpg"
            alt="El 9 Yucateco"
            className="object-cover object-center h-96 w-full rounded-3xl "
          />

          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            
            <div className="flex w-24 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/el9yucateco1.png"
              />
            </div>
          </div>
        </div>
      

        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/mandrakephoto1.jpg"
            alt="Mandrake Café"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#a2f8cd] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#002f21]">México</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-42 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/Mandrake%20cafe.png"
              />
            </div>
          </div>
        </div>


        <div className="flex flex-shrink-0 relative w-full sm:w-72">
          <img
            src="https://img.invupos.com/invu%20mexico/clientes/Ninjaparkphoto.webp"
            alt="Ninja Park"
            className="object-cover object-center h-96 w-full rounded-3xl"
          />
          <div>
            <div className="absolute inset-x-0 text-center mt-8">
              <span
                class="inline-flex items-center justify-center rounded-full bg-[#ffed41] px-4 py-0.5 text-purple-700"
              >
                <p class="whitespace-nowrap text-sm font-semibold tracking-wider text-[#442304]">Venezuela</p> {""}
              </span>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 h-[320px] bg-gradient-to-b from-transparent to-[#000000] rounded-3xl w-full h-full p-6">
            <div className="flex w-44 h-full items-end mx-auto">
              <img
                src="https://img.invupos.com/invu%20mexico/clientes/ninjaparklogo.png"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>



  </div>
</div>

                  
<section class="bg-white">
    <div class="max-w-7xl px-6 py-6 mx-auto">
        <main class="relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
            <div class="absolute w-full bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-blue-700 via-blue-800 to-gray-900 -z-10 md:h-96 rounded-2xl"></div>
            
            <div class="w-full p-6 bg-blue-900 md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
                <img class="h-24 w-24 md:mx-6 rounded-full object-cover shadow-md md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl" src="https://img.invupos.com/webinvu/Casestudy/casestudy.png" alt="client photo" />
                
                <div class="mt-2 md:mx-6">

                    <p class="text-lg leading-relaxed text-white md:text-2xl">{t("supportctacontacttitle")}</p>
                    
                    <div className="mt-12 flex justify-between">
                        <div>
                          <p class="text-xl font-semibold tracking-tight text-white">Invu POS</p>
                          <p class="text-blue-200 text-sm text-light">{t("supportctacontactpos")}</p>
                        </div>
                        <div className="content-center">
                          <button
                           onClick={() => setIsOpen(true)}
                            className="group relative inline-flex items-center overflow-hidden rounded-full border px-6 py-3 text-white hover:opacity-80"
                            href="#"
                          >
                            <span class="absolute end-full transition-all group-hover:end-4">
                              <svg
                                class="size-5 rtl:rotate-180"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                                />
                              </svg>
                            </span>

                            <span class="text-sm font-medium transition-all group-hover:me-4">{t("supportctacontactbutton")}</span>
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>

        
      <FAQ_business_types />
    </>
  );
};

export default Product_KDS;
